import React, { useEffect, useState } from "react";
import { makeStyles, TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";

import Switch from "@mui/material/Switch";
import { moduleIdURL } from "../../../http/config";
import { requests } from "../../../requests";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
const useStyles = makeStyles((theme) => ({
  switch: {
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor:" rgb(241 154 48)",
  },
  },
  // ... other styles
}));
const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    paddingTop: "15px",
    flexDirection: "column",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};

const EditForm = ({ getFn, id, daysRange, status, closePopup, isPopup }) => {
  const classes1 = useStyles();
  const [fieldValues, setFieldValues] = useState({
    daysRangeInput: daysRange,
    statusInput: status,
  });
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isValid, setIsValid] = useState({
    daysRangeInput: true,
  });

  useEffect(() => {
    setFieldValues({
      daysRangeInput: daysRange,
      statusInput: status,
    });
  }, [daysRange, status, isPopup]);

  const handleReset = () => {
    setFieldValues({
      daysRangeInput: daysRange,
      statusInput: status,
    });
  };

  const onEditHandler = (e) => {
    e.preventDefault();

    if (fieldValues.daysRangeInput.length === 0) {
      setIsValid((prev) => ({ ...prev, daysRangeInput: false }));
    }

    if (fieldValues.daysRangeInput.length > 0) {
      setLoading(true);

      const patchURL = moduleIdURL("masters/payment-terms", id);
      requests({
        method: "patch",
        url: patchURL,
        data: {
          days_range: fieldValues.daysRangeInput,
          is_active: fieldValues.statusInput,
        },
      }).then((res) => {
        setIsValid({ daysRangeInput: true });
        if (res.error) {
          console.log(res, "response");
          setError(res.data[0].message[0]);
          console.log(error, "response error");
          setPushNotier(true);
        } else {
          getFn(res);
          setError("");
          setPushNoti(true);
        }
      });
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={onEditHandler}>
        <div style={classes.formGroup}>
          <TextField
            type="text"
            // onKeyDown={(evt) => {

            //   evt.key === "." && evt.preventDefault();

            //   evt.key === "e" && evt.preventDefault();

            //   evt.key === "E" && evt.preventDefault();
            //   evt.key === "-" && evt.preventDefault();
            // }}
            style={classes.textField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            variant="outlined"
            label="Range *"
            placeholder="ex: 10-15"
            value={fieldValues.daysRangeInput}
            onKeyPress={(e) => {
              const re = /[0-9 -]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 9);
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, daysRangeInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                daysRangeInput: target.value,
              }));
            }}
            error={!isValid.daysRangeInput}
            helperText={
              isValid.daysRangeInput ? "ex: 10-15" : "Enter Range of days"
            }
          />
        </div>
        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                style={{ color: "rgb(241 154 48)" }}
                className={classes1.switch}
                checked={fieldValues.statusInput}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />
          <div>
            <Button
              onClick={handleReset}
              color="primary"
              style={{
                fontSize: "14px",
                textTransform: "capitalize",
                marginRight: "20px",
              }}
            >
              Reset to default
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "rgb(241 154 48)",
                color: "white",
                textTransform: "capitalize",
              }}
            >
              update
            </Button>
          </div>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(241 154 48)" />
        </Backdrop>
      ) : (
        ""
      )}

      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          closePopup();
        }}
      >
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          Changes have been saved!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default EditForm;
