import React, { useEffect, useState } from "react";
import { makeStyles, TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import { moduleIdURL } from "../../../http/config";
import { requests } from "../../../requests";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
import { StopScroll } from "../../../Regex";
const useStyles = makeStyles((theme) => ({
  switch: {
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor:" rgb(241 154 48)",
  },
  },
  // ... other styles
}));
const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "15px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};
const EditForm = ({ getFn, id, hsn, status, closePopup, isPopup }) => {
  const classes1 = useStyles();
  const [fieldValues, setFieldValues] = useState({
    hsnCodeInput: hsn,
    statusInput: status,
  });

  const [isValid, setIsValid] = useState({
    hsnCodeInput: true,
  });
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [toggleStyle, setToggleStyle] = useState(true);

  useEffect(() => {
    setFieldValues({
      hsnCodeInput: hsn,
      statusInput: status,
    });
  }, [hsn, status, isPopup]);

  const handleReset = () => {
    setFieldValues({
      hsnCodeInput: hsn,
      statusInput: status,
    });
  };

  const onEditHandler = (e) => {
    e.preventDefault();
    if (fieldValues.hsnCodeInput.length === 0) {
      setIsValid((prev) => ({ ...prev, hsnCodeInput: false }));
    }
    {
      fieldValues.hsnCodeInput &&
        console.log(
          fieldValues.statusInput,
          fieldValues.hsnCodeInput,
          "status"
        );
      setLoading(true);
      const patchURL = moduleIdURL("masters/hsn", id);
      requests({
        method: "patch",
        url: patchURL,
        data: {
          hsn_code: fieldValues.hsnCodeInput,
          is_active: fieldValues.statusInput,
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((res) => {
        setIsValid({
          hsnCodeInput: true,
        });
        if (res.error) {
          console.log(res, "response");
          setError(res.data[0].message[0]);
          console.log(error, "response error");
          setPushNotier(true);
        } else {
          getFn(res);
          setError("");
          setPushNoti(true);
        }
      });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={onEditHandler}>
        <div style={classes.formGroup}>
          <TextField
            type="number"
            onFocus={StopScroll}
            style={classes.textField}
            onKeyDown={(evt) => {
              evt.key === "." && evt.preventDefault();

              evt.key === "e" && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }}
            variant="outlined"
            label="HSN Code *"
            value={fieldValues.hsnCodeInput}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, hsnCodeInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                hsnCodeInput: target.value,
              }));
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 9);
            }}
            error={!isValid.hsnCodeInput}
            helperText={isValid.hsnCodeInput ? "" : "Enter HSN Code"}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <p style={{ fontSize: "18px" }}>Active</p>
            <Switch
              defaultChecked={status === true ? true : false}
              onChange={(e) => {
                if (e.target.checked) {
                  setFieldValues((prev) => ({
                    ...prev,
                    statusInput: true,
                  }));
                  // setToggleStyle(true);
                } else {
                  setFieldValues((prev) => ({
                    ...prev,
                    statusInput: false,
                  }));
                  // setToggleStyle(false);
                }
              }}
            /> */}
          </div>
        </div>
        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                checked={fieldValues.statusInput}
                className={classes1.switch}
                style={{ color: "rgb(241 154 48)" }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />
          <div>
            <Button
              onClick={handleReset}
              color="primary"
              style={{
                fontSize: "14px",
                textTransform: "capitalize",
                marginRight: "20px",
              }}
            >
              Reset to default
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "rgb(241 154 48)",
                color: "white",
                textTransform: "capitalize",
              }}
            >
              update
            </Button>
          </div>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(241 154 48)" />
        </Backdrop>
      ) : (
        ""
      )}
      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          closePopup();
        }}
      >
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          Changes have been saved!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default EditForm;
