// import React, { Component } from "react";
// // import validator from "validator";
// import {
//   Backdrop,
//   Dialog,
//   MenuItem,
//   DialogActions,
//   DialogContent,
//   IconButton,
//   Tooltip,
//   FormControlLabel,
//   Checkbox,
// } from "@material-ui/core";
// import ReactDOM from "react-dom";
// import { divide, multiply, sum, subtract } from "lodash";
// import {
//   CssTextField,
//   StyledButton,
//   StyledCircularProgress,
// } from "../styles/StyledComponents";
// import { green, primary } from "../styles/colors";
// import { CrossIcon, TopArrow, BottomArrow } from "../styles/Icons";
// import { CheckCircle } from "@material-ui/icons";
// import SnackbarComponent from "./SnackbarComponent";
// import { trimEnd } from "lodash";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";
// import moment from "moment";
// import { withStyles } from "@material-ui/styles";
// import DateFnsUtils from "@date-io/date-fns"; // choose your libs
// import MultipleSelectBar from "./MultipleSelectBar";
// import { EndPoints } from "../utils/EndPoints";
// import { filterData, StopScroll } from "../Regex";
// const CssKeyboardDatePicker = withStyles({
//   root: {
//     "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
//       padding: "5px",
//     },
//     "& .MuiOutlinedInput-input ": {
//       padding: "14px 14px",
//       caretColor: "transparent",
//     },
//     "& .MuiIconButton-root ": {
//       padding: "0px",
//     },
//   },
// })(KeyboardDatePicker);

// const styles = {
//   withDivider50: { width: "calc(25% - 20px)", margin: "10px" },
//   heading: {
//     margin: 0,
//     color: "white",
//     width: "calc(100% - 32.81px)",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     textAlign: "left",
//     display: "flex",
//   },
//   bottomHeading: {
//     margin: "0 10px 0 0",
//     width: "390px",
//   },
// };
// export class CreateDialogPO extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       scroll: "paper",
//       dataAdded: false,
//       loading: false,
//       comment: "",
//       error: "",
//       success: "",
//       submittedData: {},
//       errorMsg: {},
//       tableErrorValue: [],
//       remaining_quantity: 0,
//       tableValueDetailArray: [],
//       billError: false,
//     };
//   }
//   reset = () => {
//     const { submittedData, inputArray } = this.state;
//     const { selectedList } = this.props;
//     const noData = [];
//     const errorMsg = {};
//     const newSubmittedData = {};
//     inputArray.map((singleInput) => {
//       if (submittedData[singleInput.name]) {
//         errorMsg[singleInput.name] = false;
//         newSubmittedData[singleInput.name] = "";
//         return noData.push(singleInput.name);
//       } else {
//         return (errorMsg[singleInput.name] = false);
//       }
//     });
//     if (noData.length === 0) {
//       // this.setState({ error: "Nothing to reset !", errorMsg });
//     } else {
//       const newSubmittedData = {};
//       inputArray.map((singleInput) => {
//         if (selectedList[singleInput.name]) {
//           errorMsg[singleInput.name] = false;
//           if (singleInput.type === "Month") {
//             newSubmittedData[singleInput.name] =
//               selectedList[`${singleInput.name}Month`];
//           } else if (singleInput.type === "checkBox") {
//             return (newSubmittedData[singleInput.name] =
//               selectedList[singleInput.name] === "Paid" ? true : false);
//           } else {
//             newSubmittedData[singleInput.name] = selectedList[singleInput.name];
//             newSubmittedData[`${singleInput.name}Id`] = selectedList[
//               `${singleInput.name}Id`
//             ]
//               ? selectedList[`${singleInput.name}Id`]
//               : "";

//             return newSubmittedData;
//           }
//         } else if (singleInput.defaultValue) {
//           errorMsg[singleInput.name] = false;
//           newSubmittedData[singleInput.name] =
//             singleInput.type === "checkBox" &&
//             singleInput.defaultValue.value === false
//               ? false
//               : singleInput.defaultValue.value
//               ? singleInput.defaultValue.value
//               : "";

//           newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
//             .id
//             ? singleInput.defaultValue.id
//             : "";

//           return newSubmittedData;
//         } else if (singleInput.type === "Month") {
//           newSubmittedData[singleInput.name] = new Date();
//         } else if (singleInput.type === "checkBox") {
//           newSubmittedData[singleInput.name] = false;
//         } else {
//           errorMsg[singleInput.name] = false;
//           return (newSubmittedData[singleInput.name] = "");
//         }
//       });
//       if (!this.props.editList && this.props.onAdd) {
//         this.props.onAdd();
//       } else {
//         // this.setInitialValues(this.props);
//       }
//       this.setState(
//         {
//           dataAdded: false,
//           comment: "",
//           submittedData: newSubmittedData,
//           errorMsg,
//         },
//         () => {
//           // this.setState({ success: "Reset successfully !" });
//         }
//       );
//     }
//   };

//   setInitialValues = (next) => {
//     const { selectedList, inputArray, tableErrorValue, tableValueDetailArray } =
//       next;
//     const newSubmittedData = {};
//     const errorMsg = this.state.errorMsg;

//     inputArray
//       .filter((sA) => sA)
//       .map((singleInput) => {
//         if (selectedList[singleInput.name]) {
//           errorMsg[singleInput.name] = false;
//           if (singleInput.type === "checkBox") {
//             return (newSubmittedData[singleInput.name] =
//               selectedList[singleInput.name] === "Paid" ? true : false);
//           } else if (singleInput.type === "Month") {
//             newSubmittedData[singleInput.name] =
//               selectedList[`${singleInput.name}Month`];
//           } else {
//             newSubmittedData[singleInput.name] = selectedList[singleInput.name];
//             newSubmittedData[`${singleInput.name}Id`] = selectedList[
//               `${singleInput.name}Id`
//             ]
//               ? selectedList[`${singleInput.name}Id`]
//               : "";

//             return newSubmittedData;
//           }
//         } else if (singleInput.defaultValue) {
//           errorMsg[singleInput.name] = false;
//           newSubmittedData[singleInput.name] =
//             singleInput.type === "checkBox" &&
//             singleInput.defaultValue.value === false
//               ? false
//               : singleInput.defaultValue.value
//               ? singleInput.defaultValue.value
//               : "";

//           newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
//             .id
//             ? singleInput.defaultValue.id
//             : "";

//           return newSubmittedData;
//         } else if (singleInput.type === "Month") {
//           newSubmittedData[singleInput.name] = new Date();
//         } else if (singleInput.type === "checkBox") {
//           newSubmittedData[singleInput.name] = false;
//         } else {
//           errorMsg[singleInput.name] = false;
//           return (newSubmittedData[singleInput.name] = "");
//         }
//       });
//     // if (selectedList) {
//     //   this.setState({ submittedData: newSubmittedData });
//     // } else {
//     newSubmittedData["Miller Bill Amount"] = next.tableErrorValue
//       .reduce(
//         (previousValue, currentValue) =>
//           Number(previousValue) +
//           Number(
//             filterData(
//               tableValueDetailArray,
//               "po_item_id",
//               currentValue,
//               "onlyOne"
//             )
//               ? filterData(
//                   tableValueDetailArray,
//                   "po_item_id",
//                   currentValue,
//                   "onlyOne"
//                 )["packagageArrUsed"]
//                 ? filterData(
//                     tableValueDetailArray,
//                     "po_item_id",
//                     currentValue,
//                     "onlyOne"
//                   )["packagageArrUsed"].reduce(
//                     (previousValue1, currentValue1) =>
//                       sum([
//                         Number(previousValue1),
//                         currentValue1.total_amount
//                           ? Number(currentValue1.total_amount)
//                           : 0,
//                       ]),
//                     0
//                   )
//                 : 0
//               : 0
//           ),
//         0
//       )
//       .toFixed(2);
//     newSubmittedData["Dispatch Weight"] = tableErrorValue
//       .reduce(
//         (previousValue, currentValue) =>
//           Number(previousValue) +
//           Number(
//             filterData(
//               tableValueDetailArray,
//               "po_item_id",
//               currentValue,
//               "onlyOne"
//             )
//               ? filterData(
//                   tableValueDetailArray,
//                   "po_item_id",
//                   currentValue,
//                   "onlyOne"
//                 )["packagageArrUsed"]
//                 ? filterData(
//                     tableValueDetailArray,
//                     "po_item_id",
//                     currentValue,
//                     "onlyOne"
//                   )["packagageArrUsed"].reduce(
//                     (previousValue1, currentValue1) =>
//                       Number(previousValue1) +
//                       (currentValue1.total_dispatch
//                         ? Number(currentValue1.total_dispatch)
//                         : 0),
//                     0
//                   )
//                 : 0
//               : 0
//           ),
//         0
//       )
//       .toFixed(3);

//     newSubmittedData["Total Freight"] = multiply(
//       newSubmittedData["Dispatch Weight"]
//         ? Number(newSubmittedData["Dispatch Weight"])
//         : 0,
//       newSubmittedData["Freight Rate"] ? newSubmittedData["Freight Rate"] : 0
//     ).toFixed(2);
//     newSubmittedData["Transporter Balance"] = subtract(
//       newSubmittedData["Total Freight"] ? newSubmittedData["Total Freight"] : 0,
//       newSubmittedData["Transporter Advance"]
//         ? Number(newSubmittedData["Transporter Advance"])
//         : 0
//     ).toFixed(2);
//     newSubmittedData["TCS Amount"] = newSubmittedData
//       ? multiply(
//           divide(
//             newSubmittedData["Miller Tcs"]
//               ? Number(newSubmittedData["Miller Tcs"])
//               : 0,
//             100
//           ),
//           newSubmittedData["Miller Bill Amount"]
//             ? newSubmittedData["Miller Bill Amount"]
//             : 0
//         ).toFixed(2)
//       : "-";
//     newSubmittedData["Miller Total Amount"] = sum([
//       newSubmittedData["Miller Advance"]
//         ? -Number(newSubmittedData["Miller Advance"])
//         : 0,
//       newSubmittedData["Transporter Advance"]
//         ? Number(newSubmittedData["Transporter Advance"])
//         : 0,
//       newSubmittedData["TCS Amount"]
//         ? Number(newSubmittedData["TCS Amount"])
//         : 0,
//       newSubmittedData["Miller Bill Amount"]
//         ? Number(newSubmittedData["Miller Bill Amount"])
//         : 0,
//     ]).toFixed(2);
//     this.setState({
//       submittedData: newSubmittedData,
//       inputArray: next.inputArray.filter((sA) => sA),
//       tableErrorValue: next.tableErrorValue,
//       remaining_quantity: next.remaining_quantity,
//       tableValueDetailArray: tableValueDetailArray,
//     });

//     this.setState({
//       error: next.error ? next.error : "",
//       // submittedData:newSubmittedData
//     });

//     // }
//   };
//   UNSAFE_componentWillReceiveProps(next) {
//     if (this.state.tableErrorValue.length !== next.tableErrorValue) {
//       const { tableValueDetailArray } = next;
//       const { tableErrorValue } = this.state;
//       const newSubmittedData = this.state.submittedData;

//       newSubmittedData["Miller Bill Amount"] = next.tableErrorValue
//         .reduce(
//           (previousValue, currentValue) =>
//             Number(previousValue) +
//             Number(
//               filterData(
//                 tableValueDetailArray,
//                 "po_item_id",
//                 currentValue,
//                 "onlyOne"
//               )
//                 ? filterData(
//                     tableValueDetailArray,
//                     "po_item_id",
//                     currentValue,
//                     "onlyOne"
//                   )["packagageArrUsed"]
//                   ? filterData(
//                       tableValueDetailArray,
//                       "po_item_id",
//                       currentValue,
//                       "onlyOne"
//                     )["packagageArrUsed"].reduce(
//                       (previousValue1, currentValue1) =>
//                         Number(previousValue1) +
//                         (currentValue1.total_amount
//                           ? Number(currentValue1.total_amount)
//                           : 0),
//                       0
//                     )
//                   : 0
//                 : 0
//             ),
//           0
//         )
//         .toFixed(2);
//       newSubmittedData["Dispatch Weight"] = tableErrorValue
//         .reduce(
//           (previousValue, currentValue) =>
//             Number(previousValue) +
//             Number(
//               filterData(
//                 tableValueDetailArray,
//                 "po_item_id",
//                 currentValue,
//                 "onlyOne"
//               )
//                 ? filterData(
//                     tableValueDetailArray,
//                     "po_item_id",
//                     currentValue,
//                     "onlyOne"
//                   )["packagageArrUsed"]
//                   ? filterData(
//                       tableValueDetailArray,
//                       "po_item_id",
//                       currentValue,
//                       "onlyOne"
//                     )["packagageArrUsed"].reduce(
//                       (previousValue1, currentValue1) =>
//                         Number(previousValue1) +
//                         (currentValue1.total_dispatch
//                           ? Number(currentValue1.total_dispatch)
//                           : 0),
//                       0
//                     )
//                   : 0
//                 : 0
//             ),
//           0
//         )
//         .toFixed(3);
//       newSubmittedData["Total Freight"] = multiply(
//         newSubmittedData["Dispatch Weight"]
//           ? Number(newSubmittedData["Dispatch Weight"])
//           : 0,
//         newSubmittedData["Freight Rate"] ? newSubmittedData["Freight Rate"] : 0
//       ).toFixed(2);
//       newSubmittedData["Transporter Balance"] = subtract(
//         newSubmittedData["Total Freight"]
//           ? newSubmittedData["Total Freight"]
//           : 0,
//         newSubmittedData["Transporter Advance"]
//           ? Number(newSubmittedData["Transporter Advance"])
//           : 0
//       ).toFixed(2);
//       newSubmittedData["TCS Amount"] = newSubmittedData
//         ? multiply(
//             divide(
//               newSubmittedData["Miller Tcs"]
//                 ? Number(newSubmittedData["Miller Tcs"])
//                 : 0,
//               100
//             ),
//             newSubmittedData["Miller Bill Amount"]
//               ? newSubmittedData["Miller Bill Amount"]
//               : 0
//           ).toFixed(2)
//         : "-";

//       newSubmittedData["Miller Total Amount"] = sum([
//         newSubmittedData["Miller Advance"]
//           ? -Number(newSubmittedData["Miller Advance"])
//           : 0,
//         newSubmittedData["Transporter Advance"]
//           ? Number(newSubmittedData["Transporter Advance"])
//           : 0,
//         newSubmittedData["TCS Amount"]
//           ? Number(newSubmittedData["TCS Amount"])
//           : 0,
//         newSubmittedData["Miller Bill Amount"]
//           ? Number(newSubmittedData["Miller Bill Amount"])
//           : 0,
//       ]).toFixed(2);

//       this.setState({
//         submittedData: newSubmittedData,
//         tableErrorValue: next.tableErrorValue,
//       });
//     }

//     this.setState({
//       inputArray: next.inputArray.filter((sA) => sA),
//       remaining_quantity: next.remaining_quantity,
//       tableValueDetailArray: next.tableValueDetailArray,
//       errorMsg: next.errorMsgs ? next.errorMsgs : this.state.errorMsg,
//       // submittedData:newSubmittedData
//     });

//     this.setState({
//       error: next.error ? next.error : "",
//       // submittedData:newSubmittedData
//     });
//   }
//   UNSAFE_componentWillMount() {
//     this.setInitialValues(this.props);
//   }
//   getBagsCount = (tableErrorValue) => {
//     const { tableValueDetailArray } = this.state;
//     return tableErrorValue.length > 0
//       ? tableErrorValue.reduce(
//           (previousValue, currentValue) =>
//             Number(previousValue) +
//             Number(
//               filterData(
//                 tableValueDetailArray,
//                 "po_item_id",
//                 currentValue,
//                 "onlyOne"
//               )
//                 ? filterData(
//                     tableValueDetailArray,
//                     "po_item_id",
//                     currentValue,
//                     "onlyOne"
//                   )["packagageArrUsed"]
//                   ? filterData(
//                       tableValueDetailArray,
//                       "po_item_id",
//                       currentValue,
//                       "onlyOne"
//                     )["packagageArrUsed"].reduce(
//                       (previousValue1, currentValue1) =>
//                         Number(previousValue1) +
//                         (currentValue1.used_bags
//                           ? Number(currentValue1.used_bags)
//                           : 0),
//                       0
//                     )
//                   : 0
//                 : 0
//             ),
//           0
//         )
//       : 0;
//   };

//   handleUpdate = (e) => {
//     this.setState({ cancel: false });
//     e.preventDefault();
//     let file = {};

//     if (e.target.files[0]) {
//       const kb = (e.target.files[0].size / 1024).toFixed(2);
//       const mb = (e.target.files[0].size / 1024.0 / 1024.0).toFixed(2);

//       if (kb <= 1024) {
//         const size = `${kb}${" "}kb`;
//         file.mainSize = size;
//         this.setState({ size });
//       }
//       if (1024 < kb) {
//         const size = `${mb}${" "}mb`;
//         file.mainSize = size;
//         this.setState({ size });
//       }

//       file.img = e.target.files[0];
//       file.url = URL.createObjectURL(e.target.files[0]);
//       var errorMsg = this.state.errorMsg;
//       errorMsg.file = false;
//       this.setState({ file, errorMsg });
//     }
//   };
//   render() {
//     const {
//       scroll,
//       loading,
//       dataAdded,
//       errorMsg,
//       error,
//       success,
//       submittedData,
//       inputArray,
//       tableErrorValue,
//       tableValueDetailArray,
//       billError,
//     } = this.state;
//     const { module, selectedList, editList, seterrorMsgs, errorMsgs ,noAddAnother} =
//       this.props;

//     return (
//       <Dialog
//         open={true}
//         onClose={() => {
//           if (dataAdded) {
//             // this.props.fetchAgain(billError);
//           }
//           this.props.handleClose();
//         }}
//         scroll={scroll}
//         ref={(e) => {
//           try {
//             const target = ReactDOM.findDOMNode(e).children
//               ? ReactDOM.findDOMNode(e).children[2].children[0]
//               : null;
//             target.style.borderRadius = 0;
//             target.style.width = "90%";
//             target.style.maxWidth = "100%";
//             target.style.height = "calc(100vh - 40px)";
//             target.style.minHeight = "calc(100vh - 40px)";

//             target.style.maxHeight = "calc(100vh - 40px)";
//             target.style.margin = "20px 0";
//             target.style.position = "relative";
//             target.style.overflow = "hidden";
//           } catch (e) {}
//         }}
//       >
//         {loading ? (
//           <Backdrop
//             style={{ zIndex: 1, color: "#fff", position: "absolute" }}
//             open={loading}
//           >
//             <StyledCircularProgress color="inherit" />
//           </Backdrop>
//         ) : (
//           ""
//         )}
//         {error || success ? (
//           <SnackbarComponent
//             error={error}
//             autoHideDuration={error ? 5000 : 2000}
//             success={success}
//             snackbarClose={() => {
//               if (this.props.clearError) {
//                 this.props.clearError();
//               }
//               if (success === `${module} Edited`||success === `${module} Added` ) {
//                 this.setState({ error: "", success: "" }, () => {
//                 var myInterval=  setInterval(async () => {
//                    this.props.fetchAgain(
//                     billError
//                   ); this.props.handleClose();

//             clearInterval(myInterval);
                
//                 }, 100);
//                 });
//               } 
//               // else if (success === `${module} Edited`)  {
//               //   if (success&& this.props.closeOnSave) {
//               //     // this.props.fetchAgain(billError);
//               //     this.props.handleClose();
//               //   }
//               //   this.setState({ error: "", success: "" });
//               // }
//             }}
//           />
//         ) : (
//           ""
//         )}

//         <DialogActions
//           // className="d-flex justifySB"
//           style={{ background: primary, color: "white", padding: "10px 24px" }}
//         >
//           <h3 style={styles.heading}>
//             {editList ? "Edit " : "Add "}
//             {trimEnd(module, "s")}
//           </h3>

//           <Tooltip title="close" arrow placement="top">
//             <IconButton
//               style={{ padding: "5px", borderRadius: "5px" }}
//               onClick={() => {
//                 if (dataAdded) {
//                   this.props.fetchAgain(billError);
//                 }
//                 this.props.handleClose();
//               }}
//             >
//               <CrossIcon strokeWidth="2" />
//             </IconButton>
//           </Tooltip>
//         </DialogActions>
//         <DialogContent
//           dividers={scroll === "paper"}
//           className="dashedDividerBottom"
//           style={{ height: "100%" }}
//         >
//           {dataAdded ? (
//             <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
//               <CheckCircle
//                 style={{ width: "100px", height: "100px", color: "#1a9520" }}
//               />
//               <span
//                 className="heading"
//                 style={{ color: "#1a9520", margin: "10px 0 0 0" }}
//               >
//                 {dataAdded}
//               </span>
//             </div>
//           ) : (
//             <div className="height-100 width-100 d-flex">
//               <div className="heightFC width-100 d-flex flexWrap">
//                 {inputArray &&
//                   inputArray.length > 0 &&
//                   inputArray.slice(0, 11).map((singleInput, i) =>
//                     singleInput.type === "textField" ? (
//                       <CssTextField
//                         key={`${i}input`}
//                         className={`${
//                           singleInput.upperCase
//                             ? ""
//                             : singleInput.capitalize
//                             ? "textC"
//                             : ""
//                         } ${
//                           singleInput.disabled && singleInput.disabled === true
//                             ? "disabled"
//                             : ""
//                         }`}
//                         // required={
//                         //   singleInput.required && singleInput.required === "false"
//                         //     ? false
//                         //     : singleInput.required && singleInput.required === "true"
//                         //     ? true
//                         //     : true
//                         // }
//                         required={
//                           singleInput.not_required
//                             ? !singleInput.not_required
//                             : true
//                         }
//                         error={errorMsg[singleInput.name] ? true : false}
//                         helperText={
//                           errorMsg[singleInput.name]
//                             ? errorMsg[singleInput.name]
//                             : false
//                         }
//                         autoComplete="off"
//                         disabled={
//                           singleInput.disabled ? singleInput.disabled : false
//                         }
//                         onKeyDown={(evt) => {
//                           if (singleInput.only_number === true) {
//                             evt.key === "." && evt.preventDefault();
//                           }
//                           if (singleInput.dataType === "number") {
//                             evt.key === "e" && evt.preventDefault();

//                             evt.key === "E" && evt.preventDefault();
//                             evt.key === "-" && evt.preventDefault();
//                             evt.keyCode === 38 && evt.preventDefault();
//                             evt.keyCode === 40 && evt.preventDefault();
//                           }
//                         }}
//                         onInput={(e) => {
//                           if (singleInput.onInput === "phone_number") {
//                             e.target.value = Math.max(
//                               0,
//                               parseInt(e.target.value)
//                             )
//                               .toString()
//                               .slice(0, 10);
//                           }
//                         }}
//                         InputProps={{
//                           inputProps: {
//                             min: singleInput.min ? singleInput.min : 0,
//                           },
//                           endAdornment: singleInput.endAdornment
//                             ? // ? submittedData[singleInput.name]
//                               singleInput.endAdornment
//                             : // : ''
//                               "",
//                           startAdornment: singleInput.startAdornment
//                             ? // ? submittedData[singleInput.name]
//                               singleInput.startAdornment
//                             : // : ''
//                               "",
//                         }}
//                         style={styles.withDivider50}
//                         id="outlined-basic"
//                         label={
//                           singleInput.name.charAt(0).toUpperCase() +
//                           singleInput.name.slice(1)
//                         }
//                         type={singleInput.dataType}
//                         onFocus={
//                           singleInput.dataType === "number"
//                             ? StopScroll
//                             : () => {}
//                         }
//                         value={
//                           submittedData[singleInput.name]
//                             ? submittedData[singleInput.name]
//                             : ""
//                         }
//                         name={singleInput.name}
//                         onKeyDown={(evt) => {
//                           if (singleInput.dataType === "number") {
//                             evt.key === "e" && evt.preventDefault();

//                             evt.key === "-" && evt.preventDefault();
//                             evt.key === "E" && evt.preventDefault();
//                             evt.keyCode === 38 && evt.preventDefault();
//                             evt.keyCode === 40 && evt.preventDefault();
//                           }
//                         }}
//                         onChange={(event) => {
//                           var errorMsg = this.state.errorMsg;
//                           errorMsg[event.target.name] = false;
//                           if (
//                             singleInput.name === "Tare Weight" ||
//                             singleInput.name === "Gross Weight"
//                           ) {
//                             errorMsg["Net Weight"] = false;
//                           }
//                           const submittedData = this.state.submittedData;
//                           submittedData[event.target.name] =
//                             event.target.value && singleInput.upperCase
//                               ? event.target.value.toUpperCase()
//                               : event.target.value;
//                           if (
//                             errorMsgs &&
//                             errorMsgs[singleInput.name] &&
//                             seterrorMsgs
//                           ) {
//                             seterrorMsgs(singleInput.name);
//                           }
//                           if (singleInput.onChange) {
//                             singleInput
//                               .onChange(submittedData)
//                               .then((submittedData) => {
//                                 this.setState({
//                                   submittedData,
//                                   errorMsg,
//                                 });
//                               });
//                           } else {
//                             this.setState({
//                               submittedData,
//                               errorMsg,
//                             });
//                           }
//                         }}
//                         variant="outlined"
//                       />
//                     ) : singleInput.type === "select" ? (
//                       <CssTextField
//                         key={`${i}input`}
//                         className={
//                           singleInput.disabled && singleInput.disabled === true
//                             ? "disabled"
//                             : ""
//                         }
//                         required={
//                           singleInput.not_required
//                             ? !singleInput.not_required
//                             : true
//                         }
//                         error={errorMsg[singleInput.name] ? true : false}
//                         helperText={
//                           errorMsg[singleInput.name]
//                             ? errorMsg[singleInput.name]
//                             : false
//                         }
//                         autoComplete="off"
//                         value={submittedData[singleInput.name]}
//                         disabled={
//                           singleInput.disabled ? singleInput.disabled : false
//                         }
//                         style={styles.withDivider50}
//                         id="outlined-select-currency-native"
//                         select
//                         label={
//                           singleInput.name.charAt(0).toUpperCase() +
//                           singleInput.name.slice(1)
//                         }
//                         SelectProps={{ disableunderline: "true" }}
//                         variant="outlined"
//                         onChange={(e) => {
//                           const id = `${singleInput.name}Id`;
//                           const submittedData = this.state.submittedData;
//                           submittedData[singleInput.name] = e.target.value;
//                           if (
//                             errorMsgs &&
//                             errorMsgs[singleInput.name] &&
//                             seterrorMsgs
//                           ) {
//                             seterrorMsgs(singleInput.name);
//                           }
//                           var errorMsg = this.state.errorMsg;
//                           errorMsg[singleInput.name] = false;
//                           submittedData[id] = e.currentTarget.id;
//                           if (singleInput.onChange) {
//                             singleInput
//                               .onChange(
//                                 submittedData,
//                                 () => {
//                                   this.setState({ loading: true });
//                                 },
//                                 () => {
//                                   this.setState({ loading: false });
//                                 }
//                               )
//                               .then((submittedData) => {
//                                 this.setState({
//                                   submittedData,
//                                   errorMsg,
//                                   // loading: false,
//                                 });
//                               });
//                           } else {
//                             this.setState({
//                               submittedData,
//                               errorMsg,
//                             });
//                           }
//                         }}
//                       >
//                         {singleInput &&
//                         singleInput.array &&
//                         singleInput.array.length > 0
//                           ? singleInput.array.map((option) => (
//                               <MenuItem
//                                 key={option[singleInput.value]}
//                                 value={option[singleInput.value]}
//                                 id={
//                                   option.id
//                                     ? option.id
//                                     : option[singleInput.value]
//                                 }
//                               >
//                                 {option[singleInput.value]}
//                               </MenuItem>
//                             ))
//                           : ""}
//                       </CssTextField>
//                     ) : singleInput.type === "Month" ? (
//                       <MuiPickersUtilsProvider
//                         libInstance={moment()}
//                         utils={DateFnsUtils}
//                         key={`${i}input`}
//                       >
//                         <CssKeyboardDatePicker
//                           className={
//                             singleInput.disabled &&
//                             singleInput.disabled === true
//                               ? "disabled"
//                               : ""
//                           }
//                           disabled={
//                             singleInput.disabled ? singleInput.disabled : false
//                           }
//                           variant="inline"
//                           onOpen={() => {
//                             this.setState({ [`calenderOpen${i}`]: true });
//                           }}
//                           onClose={() => {
//                             this.setState({ [`calenderOpen${i}`]: false });
//                           }}
//                           onKeyUp={(ev) => {
//                             ev.preventDefault();
//                           }}
//                           onKeyDown={(ev) => {
//                             ev.preventDefault();
//                           }}
//                           
//                           style={styles.withDivider50}
//                           
//                           // minDate={"2022-01-22"}
//                           error={false}
//                           invalidLabel=""
//                           onKeyPress={(ev) => {
//                             ev.preventDefault();
//                           }}
//                           allowKeyboardControl={false}
//                           keyboardIcon={
//                             this.state[`calenderOpen${i}`] ? (
//                               <TopArrow />
//                             ) : (
//                               <BottomArrow />
//                             )
//                           }
//                           //
//                           autoOk
//                           inputVariant="outlined"
//                           label={singleInput.name}
//                           format="do MMM, yyyy"
//                           value={
//                             submittedData[singleInput.name]
//                               ? submittedData[singleInput.name]
//                               : new Date()
//                           }
//                           views={singleInput.views && singleInput.views} //['year', 'month']}
//                           InputAdornmentProps={{ position: "end" }}
//                           onChange={(newValue) => {
//                             const submittedData = this.state.submittedData;
//                             submittedData[singleInput.name] = newValue;

//                             this.setState({
//                               submittedData,
//                             });
//                           }}
//                         />
//                       </MuiPickersUtilsProvider>
//                     ) : singleInput.type === "multiInput" ? (
//                       <MultipleSelectBar
//                         key={`${i}input`}
//                         className={
//                           singleInput.disabled && singleInput.disabled === true
//                             ? "disabled"
//                             : ""
//                         }
//                         width={"calc(25% - 20px)"}
//                         margin={"10px"}
//                         value={
//                           submittedData[singleInput.name]
//                             ? submittedData[singleInput.name]
//                             : []
//                         }
//                         array={
//                           singleInput &&
//                           singleInput.array &&
//                           singleInput.array.length > 0
//                             ? singleInput.array
//                             : []
//                         }
//                         error={errorMsg[singleInput.name] ? true : false}
//                         helperText={
//                           errorMsg[singleInput.name]
//                             ? errorMsg[singleInput.name]
//                             : false
//                         }
//                         title={singleInput.value}
//                         selectedArr={(val) => {
//                           const errorMsg = this.state.errorMsg;
//                           errorMsg[singleInput.name] = false;
//                           const submittedData = this.state.submittedData;
//                           submittedData[singleInput.name] = val;

//                           submittedData[`${singleInput.name}Id`] =
//                             val &&
//                             val.map((sA) => {
//                               return sA.id;
//                             });

//                           this.setState({ errorMsg, submittedData });
//                         }}
//                       />
//                     ) : singleInput.type === "checkBox" ? (
//                       <FormControlLabel
//                         key={`${i}input`}
//                         style={{ margin: "0 10px" }}
//                         onChange={(newValue) => {
//                           const submittedData = this.state.submittedData;
//                           submittedData[singleInput.name] =
//                             submittedData[singleInput.name] === true ||
//                             submittedData[singleInput.name] === "Paid"
//                               ? false
//                               : true;

//                           this.setState({
//                             submittedData,
//                           });
//                         }}
//                         control={
//                           <Checkbox
//                             checked={
//                               submittedData[singleInput.name]
//                                 ? submittedData[singleInput.name]
//                                 : false
//                             }
//                           />
//                         }
//                         label={singleInput.name}
//                       />
//                     ) : (
//                       ""
//                     )
//                   )}
//                 <div className="width-100 d-flex heightFC">
//                   {this.props.tableData
//                     ? this.props.tableData(editList, submittedData)
//                     : ""}
//                 </div>
//                 <div
//                   className="width-100 d-flex heightFC flexWrap"
//                   onClick={() => {
//                     if (this.props.whenAddedBeforeValue) {
//                       this.props.whenAddedBeforeValue();
//                     }
//                   }}
//                 >
//                   {inputArray &&
//                     inputArray.length > 0 &&
//                     inputArray
//                       .slice(11, inputArray.length)
//                       .map((singleInput, i) =>
//                         singleInput.type === "textField" ? (
//                           <CssTextField
//                             key={`${i}input`}
//                             className={`${
//                               singleInput.upperCase
//                                 ? ""
//                                 : singleInput.capitalize
//                                 ? "textC"
//                                 : ""
//                             }`}
//                             required={true}
//                             error={errorMsg[singleInput.name] ? true : false}
//                             helperText={
//                               errorMsg[singleInput.name]
//                                 ? errorMsg[singleInput.name]
//                                 : false
//                             }
//                             autoComplete="off"
//                             disabled={
//                               singleInput.disabled
//                                 ? singleInput.disabled
//                                 : tableErrorValue &&
//                                   tableErrorValue.length > 0 &&
//                                   this.getBagsCount(tableErrorValue) !== 0
//                                 ? false
//                                 : true
//                             }
//                             InputProps={{
//                               inputProps: {
//                                 min: singleInput.min ? singleInput.min : 0,
//                               },
//                               endAdornment: singleInput.endAdornment
//                                 ? // ? submittedData[singleInput.name]
//                                   singleInput.endAdornment
//                                 : // : ''
//                                   "",
//                               startAdornment: singleInput.startAdornment
//                                 ? // ? submittedData[singleInput.name]
//                                   singleInput.startAdornment
//                                 : // : ''
//                                   "",
//                             }}
//                             style={styles.withDivider50}
//                             id="outlined-basic"
//                             label={
//                               singleInput.name.charAt(0).toUpperCase() +
//                               singleInput.name.slice(1)
//                             }
//                             type={singleInput.dataType}
//                             onFocus={
//                               singleInput.dataType === "number"
//                                 ? StopScroll
//                                 : () => {}
//                             }
//                             value={submittedData[singleInput.name]}
//                             name={singleInput.name}
//                             onKeyDown={(evt) => {
//                               if (singleInput.dataType === "number") {
//                                 evt.key === "e" && evt.preventDefault();

//                                 evt.key === "-" && evt.preventDefault();
//                                 evt.key === "E" && evt.preventDefault();
//                                 evt.keyCode === 38 && evt.preventDefault();
//                                 evt.keyCode === 40 && evt.preventDefault();
//                               }
//                             }}
//                             onChange={(event) => {
//                               if (
//                                 this.props.whenAddedBeforeValue() &&
//                                 this.props.whenAddedBeforeValue() === true
//                               ) {
//                                 var errorMsg = this.state.errorMsg;
//                                 errorMsg[event.target.name] = false;
//                                 if (
//                                   singleInput.name === "Tare Weight" ||
//                                   singleInput.name === "Gross Weight"
//                                 ) {
//                                   errorMsg["Net Weight"] = false;
//                                 }
//                                 const submittedData = this.state.submittedData;
//                                 if (
//                                   errorMsgs &&
//                                   errorMsgs[singleInput.name] &&
//                                   seterrorMsgs
//                                 ) {
//                                   seterrorMsgs(singleInput.name);
//                                 }
//                                 submittedData[event.target.name] =
//                                   event.target.value && singleInput.upperCase
//                                     ? event.target.value.toUpperCase()
//                                     : event.target.value;
//                                 if (singleInput.onChange) {
//                                   singleInput
//                                     .onChange(submittedData)
//                                     .then((submittedData) => {
//                                       this.setState({
//                                         submittedData,
//                                         errorMsg,
//                                       });
//                                     });
//                                 } else {
//                                   this.setState({
//                                     submittedData,
//                                     errorMsg,
//                                   });
//                                 }
//                               } else {
//                               }
//                             }}
//                             variant="outlined"
//                           />
//                         ) : singleInput.type === "select" ? (
//                           <CssTextField
//                             key={`${i}input`}
//                             required={
//                               singleInput.not_required
//                                 ? !singleInput.not_required
//                                 : true
//                             }
//                             error={errorMsg[singleInput.name] ? true : false}
//                             helperText={
//                               errorMsg[singleInput.name]
//                                 ? errorMsg[singleInput.name]
//                                 : false
//                             }
//                             autoComplete="off"
//                             value={submittedData[singleInput.name]}
//                             disabled={
//                               singleInput.disabled
//                                 ? singleInput.disabled
//                                 : tableErrorValue &&
//                                   tableErrorValue.length > 0 &&
//                                   this.getBagsCount(tableErrorValue) !== 0
//                                 ? false
//                                 : true
//                             }
//                             style={styles.withDivider50}
//                             id="outlined-select-currency-native"
//                             select
//                             label={
//                               singleInput.name.charAt(0).toUpperCase() +
//                               singleInput.name.slice(1)
//                             }
//                             SelectProps={{ disableunderline: "true" }}
//                             variant="outlined"
//                             onChange={(e) => {
//                               const id = `${singleInput.name}Id`;
//                               const submittedData = this.state.submittedData;
//                               submittedData[singleInput.name] = e.target.value;
//                               var errorMsg = this.state.errorMsg;
//                               if (
//                                 errorMsgs &&
//                                 errorMsgs[singleInput.name] &&
//                                 seterrorMsgs
//                               ) {
//                                 seterrorMsgs(singleInput.name);
//                               }
//                               errorMsg[singleInput.name] = false;
//                               submittedData[id] = e.currentTarget.id;
//                               if (singleInput.onChange) {
//                                 singleInput
//                                   .onChange(
//                                     submittedData,
//                                     () => {
//                                       this.setState({ loading: true });
//                                     },
//                                     () => {
//                                       this.setState({ loading: false });
//                                     }
//                                   )
//                                   .then((submittedData) => {
//                                     this.setState({
//                                       submittedData,
//                                       errorMsg,
//                                     });
//                                   });
//                               } else {
//                                 this.setState({
//                                   submittedData,
//                                   errorMsg,
//                                 });
//                               }
//                             }}
//                           >
//                             {singleInput &&
//                             singleInput.array &&
//                             singleInput.array.length > 0
//                               ? singleInput.array.map((option) => (
//                                   <MenuItem
//                                     key={option[singleInput.value]}
//                                     value={option[singleInput.value]}
//                                     id={
//                                       option.id
//                                         ? option.id
//                                         : option[singleInput.value]
//                                     }
//                                   >
//                                     {option[singleInput.value]}
//                                   </MenuItem>
//                                 ))
//                               : ""}
//                           </CssTextField>
//                         ) : singleInput.type === "Month" ? (
//                           <MuiPickersUtilsProvider
//                             libInstance={moment()}
//                             utils={DateFnsUtils}
//                             key={`${i}input`}
//                           >
//                             <CssKeyboardDatePicker
//                               className={
//                                 singleInput.disabled &&
//                                 singleInput.disabled === true
//                                   ? "disabled"
//                                   : tableErrorValue &&
//                                     tableErrorValue.length > 0 &&
//                                     this.getBagsCount(tableErrorValue) !== 0
//                                   ? ""
//                                   : "disabled"
//                               }
//                               disabled={
//                                 singleInput.disabled
//                                   ? singleInput.disabled
//                                   : tableErrorValue &&
//                                     tableErrorValue.length > 0 &&
//                                     this.getBagsCount(tableErrorValue) !== 0
//                                   ? false
//                                   : true
//                               }
//                               variant="inline"
//                               onOpen={() => {
//                                 this.setState({ [`calenderOpen${i}`]: true });
//                               }}
//                               onClose={() => {
//                                 this.setState({ [`calenderOpen${i}`]: false });
//                               }}
//                               onKeyUp={(ev) => {
//                                 ev.preventDefault();
//                               }}
//                               onKeyDown={(ev) => {
//                                 ev.preventDefault();
//                               }}
//                               
//                               style={styles.withDivider50}
//                               
//                               // minDate={"2022-01-22"}
//                               error={false}
//                               invalidLabel=""
//                               onKeyPress={(ev) => {
//                                 ev.preventDefault();
//                               }}
//                               allowKeyboardControl={false}
//                               keyboardIcon={
//                                 this.state[`calenderOpen${i}`] ? (
//                                   <TopArrow />
//                                 ) : (
//                                   <BottomArrow />
//                                 )
//                               }
//                               //
//                               autoOk
//                               inputVariant="outlined"
//                               label={singleInput.name}
//                               format="do MMM, yyyy"
//                               value={
//                                 submittedData[singleInput.name]
//                                   ? submittedData[singleInput.name]
//                                   : new Date()
//                               }
//                               views={singleInput.views && singleInput.views} //['year', 'month']}
//                               InputAdornmentProps={{ position: "end" }}
//                               onChange={(newValue) => {
//                                 const submittedData = this.state.submittedData;
//                                 submittedData[singleInput.name] = newValue;

//                                 this.setState({
//                                   submittedData,
//                                 });
//                               }}
//                             />
//                           </MuiPickersUtilsProvider>
//                         ) : singleInput.type === "multiInput" ? (
//                           <MultipleSelectBar
//                             key={`${i}input`}
//                             width={"calc(25% - 20px)"}
//                             margin={"10px"}
//                             value={
//                               submittedData[singleInput.name]
//                                 ? submittedData[singleInput.name]
//                                 : []
//                             }
//                             array={
//                               singleInput &&
//                               singleInput.array &&
//                               singleInput.array.length > 0
//                                 ? singleInput.array
//                                 : []
//                             }
//                             error={errorMsg[singleInput.name] ? true : false}
//                             helperText={
//                               errorMsg[singleInput.name]
//                                 ? errorMsg[singleInput.name]
//                                 : false
//                             }
//                             title={singleInput.value}
//                             selectedArr={(val) => {
//                               const errorMsg = this.state.errorMsg;
//                               errorMsg[singleInput.name] = false;
//                               const submittedData = this.state.submittedData;
//                               submittedData[singleInput.name] = val;

//                               submittedData[`${singleInput.name}Id`] =
//                                 val &&
//                                 val.map((sA) => {
//                                   return sA.id;
//                                 });

//                               this.setState({ errorMsg, submittedData });
//                             }}
//                           />
//                         ) : singleInput.type === "checkBox" ? (
//                           <FormControlLabel
//                             key={`${i}input`}
//                             style={{ margin: "0 10px" }}
//                             onChange={(newValue) => {
//                               const submittedData = this.state.submittedData;
//                               submittedData[singleInput.name] =
//                                 submittedData[singleInput.name] === true ||
//                                 submittedData[singleInput.name] === "Paid"
//                                   ? false
//                                   : true;

//                               this.setState({
//                                 submittedData,
//                               });
//                             }}
//                             control={
//                               <Checkbox
//                                 checked={
//                                   submittedData[singleInput.name]
//                                     ? submittedData[singleInput.name]
//                                     : false
//                                 }
//                               />
//                             }
//                             label={singleInput.name}
//                           />
//                         ) : (
//                           ""
//                         )
//                       )}
//                 </div>
//               </div>
//             </div>
//           )}
//         </DialogContent>
//         <DialogActions
//           style={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "flex-end",
//           }}
//         >
//           <div
//             className="d-flex justifyFE"
//             style={{ width: "calc(100% - 400px)" }}
//           >
//             {editList && dataAdded ? (
//               ""
//             ) : !editList && dataAdded ? 
//             noAddAnother ?"":(
//               <StyledButton
//                 onClick={() => {
//                   this.reset();
//                 }}
//                 name={"Add Another"}
//                 withBg={true}
//                 background={"#1a9520"}
//                 width="120px"
//               />
//             ) : (
//               <>
//                 <StyledButton
//                   onClick={() => {
//                     this.reset();
//                   }}
//                   name={editList ? "Reset to default" : "Reset"}
//                   // withBg={true}
//                   // background={primary}
//                   // width="120px"
//                   margin="0 10px 0 0"
//                 />
//                 <StyledButton
//                   onClick={() => {
//                     const { submittedData } = this.state;
//                     let submitData = {};
//                     const correctData = [];
//                     inputArray
//                       .filter((sA) => sA.api)
//                       .map((singleInput, i) => {
//                         if (i === 12) {
//                           if (
//                             this.props.tableErrorValue &&
//                             this.props.tableErrorValue.length > 0
//                           ) {
//                           } else {
//                             this.props.settableErrorValue();
//                           }
//                         }
//                         if (
//                           (singleInput.type === "checkBox" &&
//                             (submittedData[singleInput.name] === false ||
//                               submittedData[singleInput.name] === true)) ||
//                           singleInput.not_required === true ||
//                           submittedData[singleInput.name]
//                         ) {
//                           if (singleInput.type === "Month") {
//                             correctData.push(singleInput.name);

//                             if (singleInput.api) {
//                               submitData[singleInput.api] = moment(
//                                 submittedData[singleInput.name]
//                               ).format("YYYY-MM-DD");
//                             }
//                           } else {
//                             correctData.push(singleInput.name);

//                             if (singleInput.api) {
//                               submitData[singleInput.api] = submittedData[
//                                 `${singleInput.name}Id`
//                               ]
//                                 ? submittedData[`${singleInput.name}Id`] === "-"
//                                   ? null
//                                   : singleInput.numeric
//                                   ? Number(
//                                       submittedData[`${singleInput.name}Id`]
//                                     )
//                                   : submittedData[`${singleInput.name}Id`]
//                                 : singleInput.dataType === "number"
//                                 ? singleInput.onInput === "gst_in" ||
//                                   singleInput.onInput === "pincode"
//                                   ? submittedData[singleInput.name]
//                                   : Number(submittedData[singleInput.name])
//                                 : submittedData[singleInput.name] === "-"
//                                 ? null
//                                 : submittedData[singleInput.name];
//                             }
//                           }
//                         } else {
//                           var errorMsg = this.state.errorMsg;
//                           errorMsg[
//                             singleInput.name
//                           ] = `Please add ${singleInput.name} !`;
//                           return this.setState({ errorMsg });
//                         }
//                       });

//                     if (
//                       inputArray &&
//                       inputArray.length &&
//                       inputArray.filter((sA) => sA.api).length ===
//                         correctData.length
//                     ) {
//                       this.setState({ loading: true });

//                       submitData["total_bags"] = tableErrorValue
//                         .reduce(
//                           (previousValue, currentValue) =>
//                             Number(previousValue) +
//                             Number(
//                               filterData(
//                                 tableValueDetailArray,
//                                 "po_item_id",
//                                 currentValue,
//                                 "onlyOne"
//                               )
//                                 ? filterData(
//                                     tableValueDetailArray,
//                                     "po_item_id",
//                                     currentValue,
//                                     "onlyOne"
//                                   )["packagageArrUsed"]
//                                   ? filterData(
//                                       tableValueDetailArray,
//                                       "po_item_id",
//                                       currentValue,
//                                       "onlyOne"
//                                     )["packagageArrUsed"].reduce(
//                                       (previousValue1, currentValue1) =>
//                                         Number(previousValue1) +
//                                         (currentValue1.used_bags
//                                           ? Number(currentValue1.used_bags)
//                                           : 0),
//                                       0
//                                     )
//                                   : 0
//                                 : 0
//                             ),
//                           0
//                         )
//                         .toFixed(3);
//                       submitData["dispatch_weight"] = tableErrorValue
//                         .reduce(
//                           (previousValue, currentValue) =>
//                             Number(previousValue) +
//                             Number(
//                               filterData(
//                                 tableValueDetailArray,
//                                 "po_item_id",
//                                 currentValue,
//                                 "onlyOne"
//                               )
//                                 ? filterData(
//                                     tableValueDetailArray,
//                                     "po_item_id",
//                                     currentValue,
//                                     "onlyOne"
//                                   )["packagageArrUsed"]
//                                   ? filterData(
//                                       tableValueDetailArray,
//                                       "po_item_id",
//                                       currentValue,
//                                       "onlyOne"
//                                     )["packagageArrUsed"].reduce(
//                                       (previousValue1, currentValue1) =>
//                                         Number(previousValue1) +
//                                         (currentValue1.total_dispatch
//                                           ? Number(currentValue1.total_dispatch)
//                                           : 0),
//                                       0
//                                     )
//                                   : 0
//                                 : 0
//                             ),
//                           0
//                         )
//                         .toFixed(3);

//                       const newPOTOADDF = {};
//                       newPOTOADDF.dispatch = submitData;
//                       const newDispatchPackagingARr = [];
//                       const newDataArr = tableErrorValue.map((currentValue) => {
//                         const arrVal =
//                           tableValueDetailArray.filter(
//                             (sA) => sA.po_item_id === currentValue
//                           ) &&
//                           tableValueDetailArray.filter(
//                             (sA) => sA.po_item_id === currentValue
//                           )[0];

//                         if (arrVal) {
//                           console.log(arrVal, "arrVal");
//                           const submitData2 = {
//                             total_bags: arrVal.total_bags
//                               ? arrVal.total_bags
//                               : arrVal["packagageArrUsed"] &&
//                                 arrVal["packagageArrUsed"].length > 0
//                               ? arrVal["packagageArrUsed"].reduce(
//                                   (previousValue, currentValue) =>
//                                     Number(previousValue) +
//                                     Number(
//                                       currentValue.used_bags
//                                         ? currentValue.used_bags
//                                         : 0
//                                     ),
//                                   0
//                                 )
//                               : 0,
//                             is_active: true,
//                             brand: arrVal["Brandid"],
//                             item_category: arrVal["Item Categoryid"],
//                             item_type: arrVal["Item Typeid"],
//                             dispatch_packaging:
//                               arrVal["packagageArrUsed"] &&
//                               arrVal["packagageArrUsed"].length > 0
//                                 ? arrVal["packagageArrUsed"].map((sp) => {
//                                     const newObj = {
//                                       dispatch_items: sp.dispatch_items
//                                         ? sp.dispatch_items
//                                         : "",
//                                       net_weight: sp.net_weight
//                                         ? sp.net_weight
//                                         : "",
//                                       total_bags_dispatched: Number(
//                                         sp.used_bags
//                                       ),
//                                       packaging: sp.packaging,
//                                       id: sp.id,
//                                     };
//                                     newDispatchPackagingARr.push(newObj);

//                                     const newIb = {
//                                       packaging: Number(sp.id),
//                                       total_bags_dispatched: Number(
//                                         sp.used_bags
//                                       ),
//                                     };
//                                     return newIb;
//                                   })
//                                 : [],
//                             hsn_code: arrVal["HSN Codeid"],
//                             packaging:
//                               arrVal["packagageArrUsed"] &&
//                               arrVal["packagageArrUsed"].length > 0
//                                 ? arrVal["packagageArrUsed"].map((sp) => {
//                                     return sp.id;
//                                   })
//                                 : [],
//                           };
//                           submitData2["total_quantity"] =
//                             arrVal["packagageArrUsed"] &&
//                             arrVal["packagageArrUsed"].length > 0
//                               ? arrVal["packagageArrUsed"].reduce(
//                                   (previousValue1, currentValue1) =>
//                                     Number(previousValue1) +
//                                     (currentValue1.total_dispatch
//                                       ? Number(currentValue1.total_dispatch)
//                                       : 0),
//                                   0
//                                 )
//                               : 0;
//                           if (editList) {
//                             submitData2["dispatch"] = selectedList.id;

//                             submitData2["id"] = arrVal.id;

//                             submitData2["po_item"] = arrVal.po_item;
//                           } else {
//                             submitData2["po_item"] = arrVal.po_item_id;
//                           }

//                           return submitData2;
//                         } else {
//                           return false;
//                         }
//                       });

//                       newPOTOADDF.dispatch_items = newDataArr.filter(
//                         (sD) => sD !== false
//                       );
//                       if (
//                         (submitData["dispatch_weight"]
//                           ? submitData["dispatch_weight"]
//                           : 0) <=
//                         (this.state.remaining_quantity
//                           ? this.state.remaining_quantity
//                           : 0)
                          
//                         //  ( submitData["remaining_quantity"] =
//                         //   arrVal["packagageArrUsed"] &&
//                         //   arrVal["packagageArrUsed"].length > 0
//                         //     ? arrVal["packagageArrUsed"].reduce(
//                         //         (previousValue1, currentValue1) =>
//                         //           Number(previousValue1) +
//                         //           (currentValue1.total_dispatch
//                         //             ? Number(currentValue1.total_dispatch)
//                         //             : 0),
//                         //         0
//                         //       )
//                         //     : 0

                          
//                         //   )
                          
//                       ) {
//                         this.props
//                           .postData(
//                             editList ? "patch" : "post",

//                             editList ? this.props.editApi : this.props.postApi,
//                             editList ? selectedList.id : null,
//                             "postdata",
//                             editList ? submitData : newPOTOADDF,
//                             null,
//                             "noeditparam"
//                           )
//                           .then((res) => {
//                             if (res.error) {
//                               console.log(res, "response");
//                               this.setState({
//                                 error:
//                                   typeof res.data === "string"
//                                     ? res.data
//                                     : res.data.length > 0
//                                     ? res.data
//                                         .map((s) => {
//                                           return `${
//                                             s.field ? `${s.field} :` : ""
//                                           } ${
//                                             typeof s.message === "string"
//                                               ? s.message
//                                               : s.message.length > 0 &&
//                                                 s.message[0] &&
//                                                 s.message[0]
//                                           }`;
//                                         })
//                                         .join(" ,")
//                                     : res.data,
//                                 loading: false,
//                               });
//                             } else {
//                               this.setState({ billError: res.toString() });

//                               if (editList) {
//                                 this.props
//                                   .postData(
//                                     "put",
//                                     "dispatch/dispatch-packaging/bulk-update",
//                                     null,
//                                     "postdata",
//                                     newDispatchPackagingARr
//                                   )
//                                   .then((res2) => {
//                                     if (res2.error) {
//                                       this.setState({
//                                         error:
//                                           typeof res2.data === "string"
//                                             ? res2.data
//                                             : res2.data.length > 0
//                                             ? res2.data
//                                                 .map((s) => {
//                                                   return `${
//                                                     s.field
//                                                       ? `${s.field} :`
//                                                       : ""
//                                                   } ${
//                                                     typeof s.message ===
//                                                     "string"
//                                                       ? s.message
//                                                       : s.message.length > 0 &&
//                                                         s.message[0] &&
//                                                         s.message[0]
//                                                   }`;
//                                                 })
//                                                 .join(" ,")
//                                             : res2.data,
//                                         loading: false,
//                                       });
//                                     } else {
//                                       this.props
//                                         .postData(
//                                           "put",

//                                           "dispatch/dispatch-items/bulk-update",
//                                           null,
//                                           "postdata",
//                                           newDataArr.filter(
//                                             (sD) => sD !== false
//                                           )
//                                         )
//                                         .then((res1) => {
//                                           if (res1.error) {
//                                             this.setState({
//                                               error: res1.data,
//                                               loading: false,
//                                             });
//                                           } else {
//                                             this.setState(
//                                               {
//                                                 dataAdded: editList
//                                                   ? `${module} Edited`
//                                                   : `New ${module} Added`,
//                                                 loading: false,
//                                               },
//                                               () => {
//                                                 // this.setState(
//                                                 //   {
//                                                 //     success: editList
//                                                 //       ? `${module} Edited`
//                                                 //       : "",
//                                                 //   },)
//                                                 this.props.fetchAgain(
//                                                   billError,editList
//                                                         ? `${module} Edited`
//                                                         :`${module} Added`
//                                                 )
//                                               }
//                                             );
//                                           }
//                                         });
//                                     }
//                                   });
//                               } else {
                                
//                                  this.setState(
//                                               {
//                                                 dataAdded: `New ${module} Added`,
//                                                 loading: false,
//                                               },
//                                               () => {
//                                                 // this.setState(
//                                                 //   {
//                                                 //     success: editList
//                                                 //       ? `${module} Added`
//                                                 //       : "",
//                                                 //   },)
//                                                 this.props.fetchAgain(
//                                                   billError,editList
//                                                         ? `${module} Edited`
//                                                         :`${module} Added`
//                                                 )
//                                               }
//                                             );
//                                 // this.setState(
//                                 //   {
//                                 //     dataAdded: `New ${module} Added`,
//                                 //     loading: false,
//                                 //   },
//                                 // ()=>{
//                                 //   this.setState(
//                                 //   {
//                                 //     success: `${module} Added` ,
                                   
                                
//                                 //   },
//                                 //   () => {
                                  
//                                 //     if (editList||this.props.closeOnSave) {
                                   
//                                 //       this.props.fetchAgain(
//                                 //         this.state.billError
//                                 //       );
//                                 //     }
//                                 //   }
//                                 // );})
//                               }
//                             }
//                           });
//                       } else {
//                         this.setState({
//                           loading: false,
//                           error: `Maximum ${this.state.remaining_quantity} PO quantity is available!`,
//                         });
//                       }
//                     } else {
//                     }
//                   }}
//                   name={editList ? "Update" : "Submit"}
//                   withBg={true}
//                   background={"#1a9520"}
//                   width="120px"
//                 />
//               </>
//             )}
//           </div>{" "}
//         </DialogActions>
//       </Dialog>
//     );
//   }
// }

// export default CreateDialogPO;
import React, { Component } from "react";
// import validator from "validator";
import {
  Backdrop,
  Dialog,
  MenuItem,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import { divide, multiply, sum, subtract } from "lodash";
import {
  CssTextField,
  StyledButton,
  StyledCircularProgress,
} from "../styles/StyledComponents";
import { green, primary } from "../styles/colors";
import { CrossIcon, TopArrow, BottomArrow } from "../styles/Icons";
import { CheckCircle } from "@material-ui/icons";
import SnackbarComponent from "./SnackbarComponent";
import { trimEnd } from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import MultipleSelectBar from "./MultipleSelectBar";
import { EndPoints } from "../utils/EndPoints";
import { filterData, StopScroll } from "../Regex";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
      caretColor: "transparent",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);

const styles = {
  withDivider50: { width: "calc(25% - 20px)", margin: "10px" },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  bottomHeading: {
    margin: "0 10px 0 0",
    width: "390px",
  },
};
export class CreateDialogPO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: "paper",
      dataAdded: false,
      loading: false,
      comment: "",
      error: "",
      success: "",
      submittedData: {},
      errorMsg: {},
      tableErrorValue: [],
      remaining_quantity: 0,
      tableValueDetailArray: [],
      billError: false,
    };
  }
  reset = () => {
    const { submittedData, inputArray } = this.state;
    const { selectedList } = this.props;
    const noData = [];
    const errorMsg = {};
    const newSubmittedData = {};
    inputArray.map((singleInput) => {
      if (submittedData[singleInput.name]) {
        errorMsg[singleInput.name] = false;
        newSubmittedData[singleInput.name] = "";
        return noData.push(singleInput.name);
      } else {
        return (errorMsg[singleInput.name] = false);
      }
    });
    if (noData.length === 0) {
      // this.setState({ error: "Nothing to reset !", errorMsg });
    } else {
      const newSubmittedData = {};
      inputArray.map((singleInput) => {
        if (selectedList[singleInput.name]) {
          errorMsg[singleInput.name] = false;
          if (singleInput.type === "Month") {
            newSubmittedData[singleInput.name] =
              selectedList[`${singleInput.name}Month`];
          } else if (singleInput.type === "checkBox") {
            return (newSubmittedData[singleInput.name] =
              selectedList[singleInput.name] === "Paid" ? true : false);
          } else {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name];
            newSubmittedData[`${singleInput.name}Id`] = selectedList[
              `${singleInput.name}Id`
            ]
              ? selectedList[`${singleInput.name}Id`]
              : "";

            return newSubmittedData;
          }
        } else if (singleInput.defaultValue) {
          errorMsg[singleInput.name] = false;
          newSubmittedData[singleInput.name] =
            singleInput.type === "checkBox" &&
            singleInput.defaultValue.value === false
              ? false
              : singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

          newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";

          return newSubmittedData;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] = new Date();
        } else if (singleInput.type === "checkBox") {
          newSubmittedData[singleInput.name] = false;
        } else {
          errorMsg[singleInput.name] = false;
          return (newSubmittedData[singleInput.name] = "");
        }
      });
      if (!this.props.editList && this.props.onAdd) {
        this.props.onAdd();
      } else {
        // this.setInitialValues(this.props);
      }
      this.setState(
        {
          dataAdded: false,
          comment: "",
          submittedData: newSubmittedData,
          errorMsg,
        },
        () => {
          // this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };

  setInitialValues = (next) => {
    const { selectedList, inputArray, tableErrorValue, tableValueDetailArray } =
      next;
    const newSubmittedData = {};
    const errorMsg = this.state.errorMsg;

    inputArray
      .filter((sA) => sA)
      .map((singleInput) => {
        if (selectedList[singleInput.name]) {
          errorMsg[singleInput.name] = false;
          if (singleInput.type === "checkBox") {
            return (newSubmittedData[singleInput.name] =
              selectedList[singleInput.name] === "Paid" ? true : false);
          } else if (singleInput.type === "Month") {
            newSubmittedData[singleInput.name] =
              selectedList[`${singleInput.name}Month`];
          } else {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name];
            newSubmittedData[`${singleInput.name}Id`] = selectedList[
              `${singleInput.name}Id`
            ]
              ? selectedList[`${singleInput.name}Id`]
              : "";

            return newSubmittedData;
          }
        } else if (singleInput.defaultValue) {
          errorMsg[singleInput.name] = false;
          newSubmittedData[singleInput.name] =
            singleInput.type === "checkBox" &&
            singleInput.defaultValue.value === false
              ? false
              : singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

          newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";

          return newSubmittedData;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] = new Date();
        } else if (singleInput.type === "checkBox") {
          newSubmittedData[singleInput.name] = false;
        } else {
          errorMsg[singleInput.name] = false;
          return (newSubmittedData[singleInput.name] = "");
        }
      });
    // if (selectedList) {
    //   this.setState({ submittedData: newSubmittedData });
    // } else {
    newSubmittedData["Miller Bill Amount"] = next.tableErrorValue
      .reduce(
        (previousValue, currentValue) =>
          Number(previousValue) +
          Number(
            filterData(
              tableValueDetailArray,
              "po_item_id",
              currentValue,
              "onlyOne"
            )
              ? filterData(
                  tableValueDetailArray,
                  "po_item_id",
                  currentValue,
                  "onlyOne"
                )["packagageArrUsed"]
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"].reduce(
                    (previousValue1, currentValue1) =>
                      sum([
                        Number(previousValue1),
                        currentValue1.total_amount
                          ? Number(currentValue1.total_amount)
                          : 0,
                      ]),
                    0
                  )
                : 0
              : 0
          ),
        0
      )
      .toFixed(2);
    newSubmittedData["Dispatch Weight"] = tableErrorValue
      .reduce(
        (previousValue, currentValue) =>
          Number(previousValue) +
          Number(
            filterData(
              tableValueDetailArray,
              "po_item_id",
              currentValue,
              "onlyOne"
            )
              ? filterData(
                  tableValueDetailArray,
                  "po_item_id",
                  currentValue,
                  "onlyOne"
                )["packagageArrUsed"]
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"].reduce(
                    (previousValue1, currentValue1) =>
                      Number(previousValue1) +
                      (currentValue1.total_dispatch
                        ? Number(currentValue1.total_dispatch)
                        : 0),
                    0
                  )
                : 0
              : 0
          ),
        0
      )
      .toFixed(3);

    newSubmittedData["Total Freight"] = multiply(
      newSubmittedData["Dispatch Weight"]
        ? Number(newSubmittedData["Dispatch Weight"])
        : 0,
      newSubmittedData["Freight Rate"] ? newSubmittedData["Freight Rate"] : 0
    ).toFixed(2);
    newSubmittedData["Transporter Balance"] = subtract(
      newSubmittedData["Total Freight"] ? newSubmittedData["Total Freight"] : 0,
      newSubmittedData["Transporter Advance"]
        ? Number(newSubmittedData["Transporter Advance"])
        : 0
    ).toFixed(2);
    newSubmittedData["TCS Amount"] = newSubmittedData
      ? multiply(
          divide(
            newSubmittedData["Miller Tcs"]
              ? Number(newSubmittedData["Miller Tcs"])
              : 0,
            100
          ),
          newSubmittedData["Miller Bill Amount"]
            ? newSubmittedData["Miller Bill Amount"]
            : 0
        ).toFixed(2)
      : "-";
    newSubmittedData["Miller Total Amount"] = subtract(
      sum([
        newSubmittedData["Miller Advance"]
          ? -Number(newSubmittedData["Miller Advance"])
          : 0,
        newSubmittedData["Transporter Advance"]
          ? Number(newSubmittedData["Transporter Advance"])
          : 0,
        newSubmittedData["TCS Amount"]
          ? Number(newSubmittedData["TCS Amount"])
          : 0,
        newSubmittedData["Miller Bill Amount"]
          ? Number(newSubmittedData["Miller Bill Amount"])
          : 0,
        newSubmittedData["Other Amount"]
          ? Number(newSubmittedData["Other Amount"])
          : 0,
      ]),
      newSubmittedData["Other Deduction"]
        ? Number(newSubmittedData["Other Deduction"])
        : 0
    ).toFixed(2);
    this.setState({
      submittedData: newSubmittedData,
      inputArray: next.inputArray.filter((sA) => sA),
      tableErrorValue: next.tableErrorValue,
      remaining_quantity: next.remaining_quantity,
      tableValueDetailArray: tableValueDetailArray,
    });

    this.setState({
      error: next.error ? next.error : "",
      // submittedData:newSubmittedData
    });

    // }
  };
  UNSAFE_componentWillReceiveProps(next) {
    if (this.state.tableErrorValue.length !== next.tableErrorValue) {
      const { tableValueDetailArray } = next;
      const { tableErrorValue } = this.state;
      const newSubmittedData = this.state.submittedData;

      newSubmittedData["Miller Bill Amount"] = next.tableErrorValue
        .reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              filterData(
                tableValueDetailArray,
                "po_item_id",
                currentValue,
                "onlyOne"
              )
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"]
                  ? filterData(
                      tableValueDetailArray,
                      "po_item_id",
                      currentValue,
                      "onlyOne"
                    )["packagageArrUsed"].reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) +
                        (currentValue1.total_amount
                          ? Number(currentValue1.total_amount)
                          : 0),
                      0
                    )
                  : 0
                : 0
            ),
          0
        )
        .toFixed(2);
      newSubmittedData["Dispatch Weight"] = tableErrorValue
        .reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              filterData(
                tableValueDetailArray,
                "po_item_id",
                currentValue,
                "onlyOne"
              )
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"]
                  ? filterData(
                      tableValueDetailArray,
                      "po_item_id",
                      currentValue,
                      "onlyOne"
                    )["packagageArrUsed"].reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) +
                        (currentValue1.total_dispatch
                          ? Number(currentValue1.total_dispatch)
                          : 0),
                      0
                    )
                  : 0
                : 0
            ),
          0
        )
        .toFixed(3);
      newSubmittedData["Total Freight"] = multiply(
        newSubmittedData["Dispatch Weight"]
          ? Number(newSubmittedData["Dispatch Weight"])
          : 0,
        newSubmittedData["Freight Rate"] ? newSubmittedData["Freight Rate"] : 0
      ).toFixed(2);
      newSubmittedData["Transporter Balance"] = subtract(
        newSubmittedData["Total Freight"]
          ? newSubmittedData["Total Freight"]
          : 0,
        newSubmittedData["Transporter Advance"]
          ? Number(newSubmittedData["Transporter Advance"])
          : 0
      ).toFixed(2);
      newSubmittedData["TCS Amount"] = newSubmittedData
        ? multiply(
            divide(
              newSubmittedData["Miller Tcs"]
                ? Number(newSubmittedData["Miller Tcs"])
                : 0,
              100
            ),
            newSubmittedData["Miller Bill Amount"]
              ? newSubmittedData["Miller Bill Amount"]
              : 0
          ).toFixed(2)
        : "-";

      newSubmittedData["Miller Total Amount"] = subtract(
        sum([
          newSubmittedData["Miller Advance"]
            ? -Number(newSubmittedData["Miller Advance"])
            : 0,
          newSubmittedData["Transporter Advance"]
            ? Number(newSubmittedData["Transporter Advance"])
            : 0,
          newSubmittedData["TCS Amount"]
            ? Number(newSubmittedData["TCS Amount"])
            : 0,
          newSubmittedData["Miller Bill Amount"]
            ? Number(newSubmittedData["Miller Bill Amount"])
            : 0,
          newSubmittedData["Other Amount"]
            ? Number(newSubmittedData["Other Amount"])
            : 0,
        ]),
        newSubmittedData["Other Deduction"]
          ? Number(newSubmittedData["Other Deduction"])
          : 0
      ).toFixed(2);

      this.setState({
        submittedData: newSubmittedData,
        tableErrorValue: next.tableErrorValue,
      });
    }

    this.setState({
      inputArray: next.inputArray.filter((sA) => sA),
      remaining_quantity: next.remaining_quantity,
      tableValueDetailArray: next.tableValueDetailArray,
      errorMsg: next.errorMsgs ? next.errorMsgs : this.state.errorMsg,
      // submittedData:newSubmittedData
    });

    this.setState({
      error: next.error ? next.error : "",
      // submittedData:newSubmittedData
    });
  }
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  getBagsCount = (tableErrorValue) => {
    const { tableValueDetailArray } = this.state;
    return tableErrorValue.length > 0
      ? tableErrorValue.reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              filterData(
                tableValueDetailArray,
                "po_item_id",
                currentValue,
                "onlyOne"
              )
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"]
                  ? filterData(
                      tableValueDetailArray,
                      "po_item_id",
                      currentValue,
                      "onlyOne"
                    )["packagageArrUsed"].reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) +
                        (currentValue1.used_bags
                          ? Number(currentValue1.used_bags)
                          : 0),
                      0
                    )
                  : 0
                : 0
            ),
          0
        )
      : 0;
  };

  handleUpdate = (e) => {
    this.setState({ cancel: false });
    e.preventDefault();
    let file = {};

    if (e.target.files[0]) {
      const kb = (e.target.files[0].size / 1024).toFixed(2);
      const mb = (e.target.files[0].size / 1024.0 / 1024.0).toFixed(2);

      if (kb <= 1024) {
        const size = `${kb}${" "}kb`;
        file.mainSize = size;
        this.setState({ size });
      }
      if (1024 < kb) {
        const size = `${mb}${" "}mb`;
        file.mainSize = size;
        this.setState({ size });
      }

      file.img = e.target.files[0];
      file.url = URL.createObjectURL(e.target.files[0]);
      var errorMsg = this.state.errorMsg;
      errorMsg.file = false;
      this.setState({ file, errorMsg });
    }
  };
  render() {
    const {
      scroll,
      loading,
      dataAdded,
      errorMsg,
      error,
      success,
      submittedData,
      inputArray,
      tableErrorValue,
      tableValueDetailArray,
      billError,
    } = this.state;
    const { module, selectedList, editList, seterrorMsgs, errorMsgs ,noAddAnother} =
      this.props;

    return (
      <Dialog
        open={true}
        onClose={() => {
          if (dataAdded) {
            // this.props.fetchAgain(billError);
          }
          this.props.handleClose();
        }}
        scroll={scroll}
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children
              ? ReactDOM.findDOMNode(e).children[2].children[0]
              : null;
            target.style.borderRadius = 0;
            target.style.width = "90%";
            target.style.maxWidth = "100%";
            target.style.height = "calc(100vh - 40px)";
            target.style.minHeight = "calc(100vh - 40px)";

            target.style.maxHeight = "calc(100vh - 40px)";
            target.style.margin = "20px 0";
            target.style.position = "relative";
            target.style.overflow = "hidden";
          } catch (e) {}
        }}
      >
        {loading ? (
          <Backdrop
            style={{ zIndex: 1, color: "#fff", position: "absolute" }}
            open={loading}
          >
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 2000}
            success={success}
            snackbarClose={() => {
              if (this.props.clearError) {
                this.props.clearError();
              }
              if (success === `${module} Edited`||success === `${module} Added` ) {
                this.setState({ error: "", success: "" }, () => {
                var myInterval=  setInterval(async () => {
                   this.props.fetchAgain(
                    billError
                  ); this.props.handleClose();

            clearInterval(myInterval);
                
                }, 100);
                });
              } 
              // else if (success === `${module} Edited`)  {
              //   if (success&& this.props.closeOnSave) {
              //     // this.props.fetchAgain(billError);
              //     this.props.handleClose();
              //   }
              //   this.setState({ error: "", success: "" });
              // }
            }}
          />
        ) : (
          ""
        )}

        <DialogActions
          // className="d-flex justifySB"
          style={{ background: primary, color: "white", padding: "10px 24px" }}
        >
          <h3 style={styles.heading}>
            {editList ? "Edit " : "Add "}
            {trimEnd(module, "s")}
          </h3>

          <Tooltip title="close" arrow placement="top">
            <IconButton
              style={{ padding: "5px", borderRadius: "5px" }}
              onClick={() => {
                if (dataAdded) {
                  this.props.fetchAgain(billError);
                }
                this.props.handleClose();
              }}
            >
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>
        <DialogContent
          dividers={scroll === "paper"}
          className="dashedDividerBottom"
          style={{ height: "100%" }}
        >
          {dataAdded ? (
            <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
              <CheckCircle
                style={{ width: "100px", height: "100px", color: "#1a9520" }}
              />
              <span
                className="heading"
                style={{ color: "#1a9520", margin: "10px 0 0 0" }}
              >
                {dataAdded}
              </span>
            </div>
          ) : (
            <div className="height-100 width-100 d-flex">
              <div className="heightFC width-100 d-flex flexWrap">
                {inputArray &&
                  inputArray.length > 0 &&
                  inputArray.slice(0, 11).map((singleInput, i) =>
                    singleInput.type === "textField" ? (
                      <>
                      <CssTextField
                        key={`${i}input`}
                        className={`${
                          singleInput.upperCase
                            ? ""
                            : singleInput.capitalize
                            ? "textC"
                            : ""
                        } ${
                          singleInput.disabled && singleInput.disabled === true
                            ? "disabled"
                            : ""
                        }`}
                        // required={
                        //   singleInput.required && singleInput.required === "false"
                        //     ? false
                        //     : singleInput.required && singleInput.required === "true"
                        //     ? true
                        //     : true
                        // }
                        required={!singleInput.not_required}
                        error={errorMsg[singleInput.name] ? true : false}
                        helperText={
                          errorMsg[singleInput.name]
                            ? errorMsg[singleInput.name]
                            : false
                        }
                        autoComplete="off"
                        disabled={
                          singleInput.disabled ? singleInput.disabled : false
                        }
                        onKeyDown={(evt) => {
                          if (singleInput.only_number === true) {
                            evt.key === "." && evt.preventDefault();
                          }
                          if (singleInput.dataType === "number") {
                            evt.key === "e" && evt.preventDefault();

                            evt.key === "E" && evt.preventDefault();
                            evt.key === "-" && evt.preventDefault();
                            evt.keyCode === 38 && evt.preventDefault();
                            evt.keyCode === 40 && evt.preventDefault();
                          }
                        }}
                        onInput={(e) => {
                         
                          if (singleInput.onInput === "phone_number") {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            min: singleInput.min ? singleInput.min : 0,
                            max:singleInput.endAdornment==="%"?100: singleInput.max ? singleInput.max : 0,
                          },
                          endAdornment: singleInput.endAdornment
                            ? // ? submittedData[singleInput.name]
                              singleInput.endAdornment
                            : // : ''
                              "",
                          startAdornment: singleInput.startAdornment
                            ? // ? submittedData[singleInput.name]
                              singleInput.startAdornment
                            : // : ''
                              "",
                        }}
                        style={styles.withDivider50}
                        id="outlined-basic"
                        label={
                          singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1)
                        }
                        type={singleInput.dataType}
                        onFocus={
                          singleInput.dataType === "number"
                            ? StopScroll
                            : () => {}
                        }
                        value={
                          submittedData[singleInput.name]
                            ? submittedData[singleInput.name]
                            : ""
                        }
                        name={singleInput.name}
                        // onKeyDown={(evt) => {
                        //   if (singleInput.dataType === "number") {
                        //     evt.key === "e" && evt.preventDefault();

                        //     evt.key === "-" && evt.preventDefault();
                        //     evt.key === "E" && evt.preventDefault();
                        //     evt.keyCode === 38 && evt.preventDefault();
                        //     evt.keyCode === 40 && evt.preventDefault();
                        //   }
                        // }}
                        onChange={(event) => {
                          var errorMsg = this.state.errorMsg;
                          errorMsg[event.target.name] = false;
                          if (
                            singleInput.name === "Tare Weight" ||
                            singleInput.name === "Gross Weight"
                          ) {
                            errorMsg["Net Weight"] = false;
                          }
                          const submittedData = this.state.submittedData;
                          if(singleInput.endAdornment==="%"&&event.target.value>100){
                           
                          }else{
                          submittedData[event.target.name] =
                            event.target.value && singleInput.upperCase
                              ? event.target.value.toUpperCase()
                              : event.target.value;
                          }
                          if (
                            errorMsgs &&
                            errorMsgs[singleInput.name] &&
                            seterrorMsgs
                          ) {
                            seterrorMsgs(singleInput.name);
                          }
                          if (singleInput.onChange) {
                            singleInput
                              .onChange(submittedData)
                              .then((submittedData) => {
                                this.setState({
                                  submittedData,
                                  errorMsg,
                                });
                              });
                          } else {
                            this.setState({
                              submittedData,
                              errorMsg,
                            });
                          }
                        }}
                        variant="outlined"
                      />
                      {console.log("TEST",submittedData, singleInput.name, )}
                      </>
                    ) : singleInput.type === "select" ? (
                      <CssTextField
                        key={`${i}input`}
                        className={
                          singleInput.disabled && singleInput.disabled === true
                            ? "disabled"
                            : ""
                        }
                        required={
                          singleInput.not_required
                            ? !singleInput.not_required
                            : true
                        }
                        error={errorMsg[singleInput.name] ? true : false}
                        helperText={
                          errorMsg[singleInput.name]
                            ? errorMsg[singleInput.name]
                            : false
                        }
                        autoComplete="off"
                        value={submittedData[singleInput.name]}
                        disabled={
                          singleInput.disabled ? singleInput.disabled : false
                        }
                        style={styles.withDivider50}
                        id="outlined-select-currency-native"
                        select
                        label={
                          singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1)
                        }
                        SelectProps={{ disableunderline: "true" }}
                        variant="outlined"
                        onChange={(e) => {
                          const id = `${singleInput.name}Id`;
                          const submittedData = this.state.submittedData;
                          submittedData[singleInput.name] = e.target.value;
                          if (
                            errorMsgs &&
                            errorMsgs[singleInput.name] &&
                            seterrorMsgs
                          ) {
                            seterrorMsgs(singleInput.name);
                          }
                          var errorMsg = this.state.errorMsg;
                          errorMsg[singleInput.name] = false;
                          submittedData[id] = e.currentTarget.id;
                          if (singleInput.onChange) {
                            singleInput
                              .onChange(
                                submittedData,
                                () => {
                                  this.setState({ loading: true });
                                },
                                () => {
                                  this.setState({ loading: false });
                                }
                              )
                              .then((submittedData) => {
                                this.setState({
                                  submittedData,
                                  errorMsg,
                                  // loading: false,
                                });
                              });
                          } else {
                            this.setState({
                              submittedData,
                              errorMsg,
                            });
                          }
                        }}
                      >
                        {singleInput &&
                        singleInput.array &&
                        singleInput.array.length > 0
                          ? singleInput.array.map((option) => (
                              <MenuItem
                                key={option[singleInput.value]}
                                value={option[singleInput.value]}
                                id={
                                  option.id
                                    ? option.id
                                    : option[singleInput.value]
                                }
                              >
                                {option[singleInput.value]}
                              </MenuItem>
                            ))
                          : ""}
                      </CssTextField>
                    ) : singleInput.type === "Month" ? (
                      <MuiPickersUtilsProvider
                        libInstance={moment()}
                        utils={DateFnsUtils}
                        key={`${i}input`}
                      >
                        <CssKeyboardDatePicker
                          className={
                            singleInput.disabled &&
                            singleInput.disabled === true
                              ? "disabled"
                              : ""
                          }
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          variant="inline"
                          onOpen={() => {
                            this.setState({ [`calenderOpen${i}`]: true });
                          }}
                          onClose={() => {
                            this.setState({ [`calenderOpen${i}`]: false });
                          }}
                          onKeyUp={(ev) => {
                            ev.preventDefault();
                          }}
                          onKeyDown={(ev) => {
                            ev.preventDefault();
                          }}
                          
                          style={styles.withDivider50}
                          
                          // minDate={"2022-01-22"}
                          error={false}
                          invalidLabel=""
                          onKeyPress={(ev) => {
                            ev.preventDefault();
                          }}
                          allowKeyboardControl={false}
                          keyboardIcon={
                            this.state[`calenderOpen${i}`] ? (
                              <TopArrow />
                            ) : (
                              <BottomArrow />
                            )
                          }
                          //
                          autoOk
                          inputVariant="outlined"
                          label={singleInput.name}
                          format="do MMM, yyyy"
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : new Date()
                          }
                          views={singleInput.views && singleInput.views} //['year', 'month']}
                          InputAdornmentProps={{ position: "end" }}
                          onChange={(newValue) => {
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = newValue;

                            this.setState({
                              submittedData,
                            });
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    ) : singleInput.type === "multiInput" ? (
                      <MultipleSelectBar
                        key={`${i}input`}
                        className={
                          singleInput.disabled && singleInput.disabled === true
                            ? "disabled"
                            : ""
                        }
                        width={"calc(25% - 20px)"}
                        margin={"10px"}
                        value={
                          submittedData[singleInput.name]
                            ? submittedData[singleInput.name]
                            : []
                        }
                        array={
                          singleInput &&
                          singleInput.array &&
                          singleInput.array.length > 0
                            ? singleInput.array
                            : []
                        }
                        error={errorMsg[singleInput.name] ? true : false}
                        helperText={
                          errorMsg[singleInput.name]
                            ? errorMsg[singleInput.name]
                            : false
                        }
                        title={singleInput.value}
                        selectedArr={(val) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg[singleInput.name] = false;
                          const submittedData = this.state.submittedData;
                          submittedData[singleInput.name] = val;

                          submittedData[`${singleInput.name}Id`] =
                            val &&
                            val.map((sA) => {
                              return sA.id;
                            });

                          this.setState({ errorMsg, submittedData });
                        }}
                      />
                    ) : singleInput.type === "checkBox" ? (
                      <FormControlLabel
                        key={`${i}input`}
                        style={{ margin: "0 10px" }}
                        onChange={(newValue) => {
                          const submittedData = this.state.submittedData;
                          submittedData[singleInput.name] =
                            submittedData[singleInput.name] === true ||
                            submittedData[singleInput.name] === "Paid"
                              ? false
                              : true;

                          this.setState({
                            submittedData,
                          });
                        }}
                        control={
                          <Checkbox
                            checked={
                              submittedData[singleInput.name]
                                ? submittedData[singleInput.name]
                                : false
                            }
                          />
                        }
                        label={singleInput.name}
                      />
                    ) : (
                      ""
                    )
                  )}
                <div className="width-100 d-flex heightFC">
                  {this.props.tableData
                    ? this.props.tableData(editList, submittedData)
                    : ""}
                </div>
                <div
                  className="width-100 d-flex heightFC flexWrap"
                  onClick={() => {
                    if (this.props.whenAddedBeforeValue) {
                      this.props.whenAddedBeforeValue();
                    }
                  }}
                >
                  {inputArray &&
                    inputArray.length > 0 &&
                    inputArray
                      .slice(11, inputArray.length)
                      .map((singleInput, i) =>
                        singleInput.type === "textField" ? (
                          <>
                          <CssTextField
                            key={`${i}input`}
                            className={`${
                              singleInput.upperCase
                                ? ""
                                : singleInput.capitalize
                                ? "textC"
                                : ""
                            }`}
                            required={!singleInput.not_required}
                            error={errorMsg[singleInput.name] ? true : false}
                            helperText={
                              errorMsg[singleInput.name]
                                ? errorMsg[singleInput.name]
                                : false
                            }
                            autoComplete="off"
                            disabled={
                              singleInput.disabled
                                ? singleInput.disabled
                                : tableErrorValue &&
                                  tableErrorValue.length > 0 &&
                                  this.getBagsCount(tableErrorValue) !== 0
                                ? false
                                : true
                            }
                            onInput={(e) => {
                              
                              if (singleInput.onInput === "phone_number") {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 10);
                              }
                            }}
                            InputProps={{
                              inputProps: {
                                min: singleInput.min ? singleInput.min : 0,
                                max:singleInput.endAdornment==="%"?100: singleInput.max ? singleInput.max : 0,
                              },
                              endAdornment: singleInput.endAdornment
                                ? // ? submittedData[singleInput.name]
                                  singleInput.endAdornment
                                : // : ''
                                  "",
                              startAdornment: singleInput.startAdornment
                                ? // ? submittedData[singleInput.name]
                                  singleInput.startAdornment
                                : // : ''
                                  "",
                            }}
                            style={styles.withDivider50}
                            id="outlined-basic"
                            label={
                              singleInput.name.charAt(0).toUpperCase() +
                              singleInput.name.slice(1)
                            }
                            type={singleInput.dataType}
                            onFocus={
                              singleInput.dataType === "number"
                                ? StopScroll
                                : () => {}
                            }
                            value={submittedData[singleInput.name]}
                            name={singleInput.name}
                            onKeyDown={(evt) => {
                              if (singleInput.dataType === "number") {
                                evt.key === "e" && evt.preventDefault();

                                evt.key === "-" && evt.preventDefault();
                                evt.key === "E" && evt.preventDefault();
                                evt.keyCode === 38 && evt.preventDefault();
                                evt.keyCode === 40 && evt.preventDefault();
                              }
                            }}
                            onChange={(event) => {
                              if (
                                this.props.whenAddedBeforeValue() &&
                                this.props.whenAddedBeforeValue() === true
                              ) {
                                var errorMsg = this.state.errorMsg;
                                errorMsg[event.target.name] = false;
                                if (
                                  singleInput.name === "Tare Weight" ||
                                  singleInput.name === "Gross Weight"
                                ) {
                                  errorMsg["Net Weight"] = false;
                                }
                                const submittedData = this.state.submittedData;
                                if (
                                  errorMsgs &&
                                  errorMsgs[singleInput.name] &&
                                  seterrorMsgs
                                ) {
                                  seterrorMsgs(singleInput.name);
                                }
                                if(singleInput.endAdornment==="%"&&event.target.value>100){
                           
                                }else{
                                submittedData[event.target.name] =
                                  event.target.value && singleInput.upperCase
                                    ? event.target.value.toUpperCase()
                                    : event.target.value;
                                }
                                if (singleInput.onChange) {
                                  singleInput
                                    .onChange(submittedData)
                                    .then((submittedData) => {
                                      this.setState({
                                        submittedData,
                                        errorMsg,
                                      });
                                    });
                                } else {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                }
                              } else {
                              }
                            }}
                            variant="outlined"
                          />
                          {console.log("TEST2",submittedData, singleInput.name, )}
                          </>
                        ) : singleInput.type === "select" ? (
                          <CssTextField
                            key={`${i}input`}
                            required={
                              singleInput.not_required
                                ? !singleInput.not_required
                                : true
                            }
                            error={errorMsg[singleInput.name] ? true : false}
                            helperText={
                              errorMsg[singleInput.name]
                                ? errorMsg[singleInput.name]
                                : false
                            }
                            autoComplete="off"
                            value={submittedData[singleInput.name]}
                            disabled={
                              singleInput.disabled
                                ? singleInput.disabled
                                : tableErrorValue &&
                                  tableErrorValue.length > 0 &&
                                  this.getBagsCount(tableErrorValue) !== 0
                                ? false
                                : true
                            }
                            style={styles.withDivider50}
                            id="outlined-select-currency-native"
                            select
                            label={
                              singleInput.name.charAt(0).toUpperCase() +
                              singleInput.name.slice(1)
                            }
                            SelectProps={{ disableunderline: "true" }}
                            variant="outlined"
                            onChange={(e) => {
                              const id = `${singleInput.name}Id`;
                              const submittedData = this.state.submittedData;
                              submittedData[singleInput.name] = e.target.value;
                              var errorMsg = this.state.errorMsg;
                              if (
                                errorMsgs &&
                                errorMsgs[singleInput.name] &&
                                seterrorMsgs
                              ) {
                                seterrorMsgs(singleInput.name);
                              }
                              errorMsg[singleInput.name] = false;
                              submittedData[id] = e.currentTarget.id;
                              if (singleInput.onChange) {
                                singleInput
                                  .onChange(
                                    submittedData,
                                    () => {
                                      this.setState({ loading: true });
                                    },
                                    () => {
                                      this.setState({ loading: false });
                                    }
                                  )
                                  .then((submittedData) => {
                                    this.setState({
                                      submittedData,
                                      errorMsg,
                                    });
                                  });
                              } else {
                                this.setState({
                                  submittedData,
                                  errorMsg,
                                });
                              }
                            }}
                          >
                            {singleInput &&
                            singleInput.array &&
                            singleInput.array.length > 0
                              ? singleInput.array.map((option) => (
                                  <MenuItem
                                    key={option[singleInput.value]}
                                    value={option[singleInput.value]}
                                    id={
                                      option.id
                                        ? option.id
                                        : option[singleInput.value]
                                    }
                                  >
                                    {option[singleInput.value]}
                                  </MenuItem>
                                ))
                              : ""}
                          </CssTextField>
                        ) : singleInput.type === "Month" ? (
                          <MuiPickersUtilsProvider
                            libInstance={moment()}
                            utils={DateFnsUtils}
                            key={`${i}input`}
                          >
                            <CssKeyboardDatePicker
                              className={
                                singleInput.disabled &&
                                singleInput.disabled === true
                                  ? "disabled"
                                  : tableErrorValue &&
                                    tableErrorValue.length > 0 &&
                                    this.getBagsCount(tableErrorValue) !== 0
                                  ? ""
                                  : "disabled"
                              }
                              disabled={
                                singleInput.disabled
                                  ? singleInput.disabled
                                  : tableErrorValue &&
                                    tableErrorValue.length > 0 &&
                                    this.getBagsCount(tableErrorValue) !== 0
                                  ? false
                                  : true
                              }
                              variant="inline"
                              onOpen={() => {
                                this.setState({ [`calenderOpen${i}`]: true });
                              }}
                              onClose={() => {
                                this.setState({ [`calenderOpen${i}`]: false });
                              }}
                              onKeyUp={(ev) => {
                                ev.preventDefault();
                              }}
                              onKeyDown={(ev) => {
                                ev.preventDefault();
                              }}
                              
                              style={styles.withDivider50}
                              
                              // minDate={"2022-01-22"}
                              error={false}
                              invalidLabel=""
                              onKeyPress={(ev) => {
                                ev.preventDefault();
                              }}
                              allowKeyboardControl={false}
                              keyboardIcon={
                                this.state[`calenderOpen${i}`] ? (
                                  <TopArrow />
                                ) : (
                                  <BottomArrow />
                                )
                              }
                              //
                              autoOk
                              inputVariant="outlined"
                              label={singleInput.name}
                              format="do MMM, yyyy"
                              value={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : new Date()
                              }
                              views={singleInput.views && singleInput.views} //['year', 'month']}
                              InputAdornmentProps={{ position: "end" }}
                              onChange={(newValue) => {
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] = newValue;

                                this.setState({
                                  submittedData,
                                });
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        ) : singleInput.type === "multiInput" ? (
                          <MultipleSelectBar
                            key={`${i}input`}
                            width={"calc(25% - 20px)"}
                            margin={"10px"}
                            value={
                              submittedData[singleInput.name]
                                ? submittedData[singleInput.name]
                                : []
                            }
                            array={
                              singleInput &&
                              singleInput.array &&
                              singleInput.array.length > 0
                                ? singleInput.array
                                : []
                            }
                            error={errorMsg[singleInput.name] ? true : false}
                            helperText={
                              errorMsg[singleInput.name]
                                ? errorMsg[singleInput.name]
                                : false
                            }
                            title={singleInput.value}
                            selectedArr={(val) => {
                              const errorMsg = this.state.errorMsg;
                              errorMsg[singleInput.name] = false;
                              const submittedData = this.state.submittedData;
                              submittedData[singleInput.name] = val;

                              submittedData[`${singleInput.name}Id`] =
                                val &&
                                val.map((sA) => {
                                  return sA.id;
                                });

                              this.setState({ errorMsg, submittedData });
                            }}
                          />
                        ) : singleInput.type === "checkBox" ? (
                          <FormControlLabel
                            key={`${i}input`}
                            style={{ margin: "0 10px" }}
                            onChange={(newValue) => {
                              const submittedData = this.state.submittedData;
                              submittedData[singleInput.name] =
                                submittedData[singleInput.name] === true ||
                                submittedData[singleInput.name] === "Paid"
                                  ? false
                                  : true;

                              this.setState({
                                submittedData,
                              });
                            }}
                            control={
                              <Checkbox
                                checked={
                                  submittedData[singleInput.name]
                                    ? submittedData[singleInput.name]
                                    : false
                                }
                              />
                            }
                            label={singleInput.name}
                          />
                        ) : (
                          ""
                        )
                      )}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="d-flex justifyFE"
            style={{ width: "calc(100% - 400px)" }}
          >
            {editList && dataAdded ? (
              ""
            ) : !editList && dataAdded ? 
            noAddAnother ?"":(
              <StyledButton
                onClick={() => {
                  this.reset();
                }}
                name={"Add Another"}
                withBg={true}
                background={"#1a9520"}
                width="120px"
              />
            ) : (
              <>
                <StyledButton
                  onClick={() => {
                    this.reset();
                  }}
                  name={editList ? "Reset to default" : "Reset"}
                  // withBg={true}
                  // background={primary}
                  // width="120px"
                  margin="0 10px 0 0"
                />
                <StyledButton
                  onClick={() => {
                    const { submittedData } = this.state;
                    let submitData = {};
                    const correctData = [];
                    inputArray
                      .filter((sA) => sA.api)
                      .map((singleInput, i) => {
                        if (i === 12) {
                          if (
                            this.props.tableErrorValue &&
                            this.props.tableErrorValue.length > 0
                          ) {
                          } else {
                            this.props.settableErrorValue();
                          }
                        }
                        if (
                          (singleInput.type === "checkBox" &&
                            (submittedData[singleInput.name] === false ||
                              submittedData[singleInput.name] === true)) ||
                          singleInput.not_required === true ||
                          submittedData[singleInput.name]
                        ) {
                          if (singleInput.type === "Month") {
                            correctData.push(singleInput.name);

                            if (singleInput.api) {
                              submitData[singleInput.api] = moment(
                                submittedData[singleInput.name]
                              ).format("YYYY-MM-DD");
                            }
                          } else {
                            correctData.push(singleInput.name);

                            if (singleInput.api) {
                              submitData[singleInput.api] = submittedData[
                                `${singleInput.name}Id`
                              ]
                                ? submittedData[`${singleInput.name}Id`] === "-"
                                  ? null
                                  : singleInput.numeric
                                  ? Number(
                                      submittedData[`${singleInput.name}Id`]
                                    )
                                  : submittedData[`${singleInput.name}Id`]
                                : singleInput.dataType === "number"
                                ? singleInput.onInput === "gst_in" ||
                                  singleInput.onInput === "pincode"
                                  ? submittedData[singleInput.name]
                                  : Number(submittedData[singleInput.name])
                                : submittedData[singleInput.name] === "-"
                                ? null
                                : submittedData[singleInput.name];
                            }
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg[
                            singleInput.name
                          ] = `Please add ${singleInput.name} !`;
                          return this.setState({ errorMsg });
                        }
                      });

                    if (
                      inputArray &&
                      inputArray.length &&
                      inputArray.filter((sA) => sA.api).length ===
                        correctData.length
                    ) {
                      this.setState({ loading: true });

                      submitData["total_bags"] = tableErrorValue
                        .reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) +
                            Number(
                              filterData(
                                tableValueDetailArray,
                                "po_item_id",
                                currentValue,
                                "onlyOne"
                              )
                                ? filterData(
                                    tableValueDetailArray,
                                    "po_item_id",
                                    currentValue,
                                    "onlyOne"
                                  )["packagageArrUsed"]
                                  ? filterData(
                                      tableValueDetailArray,
                                      "po_item_id",
                                      currentValue,
                                      "onlyOne"
                                    )["packagageArrUsed"].reduce(
                                      (previousValue1, currentValue1) =>
                                        Number(previousValue1) +
                                        (currentValue1.used_bags
                                          ? Number(currentValue1.used_bags)
                                          : 0),
                                      0
                                    )
                                  : 0
                                : 0
                            ),
                          0
                        )
                        .toFixed(3);
                      submitData["dispatch_weight"] = tableErrorValue
                        .reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) +
                            Number(
                              filterData(
                                tableValueDetailArray,
                                "po_item_id",
                                currentValue,
                                "onlyOne"
                              )
                                ? filterData(
                                    tableValueDetailArray,
                                    "po_item_id",
                                    currentValue,
                                    "onlyOne"
                                  )["packagageArrUsed"]
                                  ? filterData(
                                      tableValueDetailArray,
                                      "po_item_id",
                                      currentValue,
                                      "onlyOne"
                                    )["packagageArrUsed"].reduce(
                                      (previousValue1, currentValue1) =>
                                        Number(previousValue1) +
                                        (currentValue1.total_dispatch
                                          ? Number(currentValue1.total_dispatch)
                                          : 0),
                                      0
                                    )
                                  : 0
                                : 0
                            ),
                          0
                        )
                        .toFixed(3);

                      const newPOTOADDF = {};
                      newPOTOADDF.dispatch = submitData;
                      const newDispatchPackagingARr = [];
                      const newDataArr = tableErrorValue.map((currentValue) => {
                        const arrVal =
                          tableValueDetailArray.filter(
                            (sA) => sA.po_item_id === currentValue
                          ) &&
                          tableValueDetailArray.filter(
                            (sA) => sA.po_item_id === currentValue
                          )[0];

                        if (arrVal) {
                          console.log(arrVal, "arrVal");
                          const submitData2 = {
                            total_bags: arrVal.total_bags
                              ? arrVal.total_bags
                              : arrVal["packagageArrUsed"] &&
                                arrVal["packagageArrUsed"].length > 0
                              ? arrVal["packagageArrUsed"].reduce(
                                  (previousValue, currentValue) =>
                                    Number(previousValue) +
                                    Number(
                                      currentValue.used_bags
                                        ? currentValue.used_bags
                                        : 0
                                    ),
                                  0
                                )
                              : 0,
                            is_active: true,
                            brand: arrVal["Brandid"],
                            item_category: arrVal["Item Categoryid"],
                            item_type: arrVal["Item Typeid"],
                            dispatch_packaging:
                              arrVal["packagageArrUsed"] &&
                              arrVal["packagageArrUsed"].length > 0
                                ? arrVal["packagageArrUsed"].map((sp) => {
                                    const newObj = {
                                      dispatch_items: sp.dispatch_items
                                        ? sp.dispatch_items
                                        : "",
                                      net_weight: sp.net_weight
                                        ? sp.net_weight
                                        : "",
                                      total_bags_dispatched: Number(
                                        sp.used_bags
                                      ),
                                      packaging: sp.packaging,
                                      id: sp.id,
                                    };
                                    if( Number(
                                      sp.used_bags
                                    )!==0&& Number(
                                      sp.used_bags
                                    )!==null){
                                    newDispatchPackagingARr.push(newObj);}

                                    const newIb = {
                                      packaging: Number(sp.id),
                                      total_bags_dispatched: Number(
                                        sp.used_bags
                                      ),
                                    };
                                    return newIb;
                                  }).filter((sp)=>  sp.total_bags_dispatched&&  sp.total_bags_dispatched!==null)
                                : [],
                            hsn_code: arrVal["HSN Codeid"],
                            packaging:
                              arrVal["packagageArrUsed"] &&
                              arrVal["packagageArrUsed"].length > 0
                                ? arrVal["packagageArrUsed"].map((sp) => {
                                    return sp.id;
                                  })
                                : [],
                          };
                          submitData2["total_quantity"] =
                            arrVal["packagageArrUsed"] &&
                            arrVal["packagageArrUsed"].length > 0
                              ? arrVal["packagageArrUsed"].reduce(
                                  (previousValue1, currentValue1) =>
                                    Number(previousValue1) +
                                    (currentValue1.total_dispatch
                                      ? Number(currentValue1.total_dispatch)
                                      : 0),
                                  0
                                )
                              : 0;
                          if (editList) {
                            submitData2["dispatch"] = selectedList.id;

                            submitData2["id"] = arrVal.id;

                            submitData2["po_item"] = arrVal.po_item;
                          } else {
                            submitData2["po_item"] = arrVal.po_item_id;
                          }

                          return submitData2;
                        } else {
                          return false;
                        }
                      });

                      newPOTOADDF.dispatch_items = newDataArr.filter(
                        (sD) => sD !== false
                      );
                      if (
                        (submitData["dispatch_weight"]
                          ? submitData["dispatch_weight"]
                          : 0) <=
                        (this.state.remaining_quantity
                          ? this.state.remaining_quantity
                          : 0)
                          
                        //  ( submitData["remaining_quantity"] =
                        //   arrVal["packagageArrUsed"] &&
                        //   arrVal["packagageArrUsed"].length > 0
                        //     ? arrVal["packagageArrUsed"].reduce(
                        //         (previousValue1, currentValue1) =>
                        //           Number(previousValue1) +
                        //           (currentValue1.total_dispatch
                        //             ? Number(currentValue1.total_dispatch)
                        //             : 0),
                        //         0
                        //       )
                        //     : 0

                          
                        //   )
                          
                      ) {
                        this.props
                          .postData(
                            editList ? "patch" : "post",

                            editList ? this.props.editApi : this.props.postApi,
                            editList ? selectedList.id : null,
                            "postdata",
                            editList ? submitData : newPOTOADDF,
                            null,
                            "noeditparam"
                          )
                          .then((res) => {
                            if (res.error) {
                              console.log(res, "response");
                              this.setState({
                                error:
                                  typeof res.data === "string"
                                    ? res.data
                                    : res.data.length > 0
                                    ? res.data
                                        .map((s) => {
                                          return `${
                                            s.field ? `${s.field} :` : ""
                                          } ${
                                            typeof s.message === "string"
                                              ? s.message
                                              : s.message.length > 0 &&
                                                s.message[0] &&
                                                s.message[0]
                                          }`;
                                        })
                                        .join(" ,")
                                    : res.data,
                                loading: false,
                              });
                            } else {
                              this.setState({ billError: res.toString() });

                              if (editList) {
                                this.props
                                  .postData(
                                    "put",
                                    "dispatch/dispatch-packaging/bulk-update",
                                    null,
                                    "postdata",
                                    newDispatchPackagingARr
                                  )
                                  .then((res2) => {
                                    if (res2.error) {
                                      this.setState({
                                        error:
                                          typeof res2.data === "string"
                                            ? res2.data
                                            : res2.data.length > 0
                                            ? res2.data
                                                .map((s) => {
                                                  return `${
                                                    s.field
                                                      ? `${s.field} :`
                                                      : ""
                                                  } ${
                                                    typeof s.message ===
                                                    "string"
                                                      ? s.message
                                                      : s.message.length > 0 &&
                                                        s.message[0] &&
                                                        s.message[0]
                                                  }`;
                                                })
                                                .join(" ,")
                                            : res2.data,
                                        loading: false,
                                      });
                                    } else {
                                      this.props
                                        .postData(
                                          "put",

                                          "dispatch/dispatch-items/bulk-update",
                                          null,
                                          "postdata",
                                          newDataArr.filter(
                                            (sD) => sD !== false
                                          )
                                        )
                                        .then((res1) => {
                                          if (res1.error) {
                                            this.setState({
                                              error: res1.data,
                                              loading: false,
                                            });
                                          } else {
                                            this.setState(
                                              {
                                                dataAdded: editList
                                                  ? `${module} Edited`
                                                  : `New ${module} Added`,
                                                loading: false,
                                              },
                                              () => {
                                                // this.setState(
                                                //   {
                                                //     success: editList
                                                //       ? `${module} Edited`
                                                //       : "",
                                                //   },)
                                                this.props.fetchAgain(
                                                  billError,editList
                                                        ? `${module} Edited`
                                                        :`${module} Added`
                                                )
                                              }
                                            );
                                          }
                                        });
                                    }
                                  });
                              } else {
                                
                                 this.setState(
                                              {
                                                dataAdded: `New ${module} Added`,
                                                loading: false,
                                              },
                                              () => {
                                                // this.setState(
                                                //   {
                                                //     success: editList
                                                //       ? `${module} Added`
                                                //       : "",
                                                //   },)
                                                this.props.fetchAgain(
                                                  billError,editList
                                                        ? `${module} Edited`
                                                        :`${module} Added`
                                                )
                                              }
                                            );
                                // this.setState(
                                //   {
                                //     dataAdded: `New ${module} Added`,
                                //     loading: false,
                                //   },
                                // ()=>{
                                //   this.setState(
                                //   {
                                //     success: `${module} Added` ,
                                   
                                
                                //   },
                                //   () => {
                                  
                                //     if (editList||this.props.closeOnSave) {
                                   
                                //       this.props.fetchAgain(
                                //         this.state.billError
                                //       );
                                //     }
                                //   }
                                // );})
                              }
                            }
                          });
                      } else {
                        this.setState({
                          loading: false,
                          error: `Maximum ${this.state.remaining_quantity} PO quantity is available!`,
                        });
                      }
                    } else {
                    }
                  }}
                  name={editList ? "Update" : "Submit"}
                  withBg={true}
                  background={"#1a9520"}
                  width="120px"
                />
              </>
            )}
          </div>{" "}
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateDialogPO;
