import React, { useEffect, useState } from "react";
import { makeStyles, TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import { moduleIdURL } from "../../../http/config";
import { requests } from "../../../requests";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
import { StopScroll } from "../../../Regex";
const useStyles = makeStyles((theme) => ({
  switch: {
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor:" rgb(241 154 48)",
  },
  },
  // ... other styles
}));
const allUnits = [
  {
    value: "Kgs",
    label: "Kgs",
  },
  {
    value: "Qtl",
    label: "Qtl",
  },
  {
    value: "MT",
    label: "MT",
  },
];

const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "15px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};
const EditForm = ({
  getFn,
  id,
  size,
  unit,
  status,
  closePopup,
  bag,
  isPopup,
}) => {
  const classes1 = useStyles();
  const [selectUnit, setSelectUnit] = useState(unit);

  const [fieldValues, setFieldValues] = useState({
    sizeInput: size,
    unitInput: unit,
    bagInput: bag,
    statusInput: status,
  });

  const [isValid, setIsValid] = useState({
    bagInput: true,
    sizeInput: true,
    unitInput: true,
  });
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFieldValues({
      sizeInput: size,
      unitInput: unit,
      bagInput: bag,
      statusInput: status,
    });
  }, [size, unit, bag, status, isPopup]);

  const handleReset = () => {
    setFieldValues({
      sizeInput: size,
      unitInput: unit,
      bagInput: bag,
      statusInput: status,
    });
  };

  const handleChange = (event) => {
    setSelectUnit(event.target.value);
    setFieldValues((prev) => ({
      ...prev,
      unitInput: event.target.value,
    }));
    if (event.target.value.length !== 0) {
      setIsValid((prev) => ({ ...prev, unitInput: true }));
    }
  };

  const onEditHandler = (e) => {
    e.preventDefault();
    if (fieldValues.sizeInput.length === 0) {
      setIsValid((prev) => ({ ...prev, sizeInput: false }));
    }
    if (fieldValues.unitInput.length === 0) {
      setIsValid((prev) => ({ ...prev, unitInput: false }));
    }
    if (fieldValues.bagInput.length === 0) {
      setIsValid((prev) => ({ ...prev, bagInput: false }));
    }
    if (
      fieldValues.sizeInput.length > 0 &&
      fieldValues.bagInput.length > 0 &&
      fieldValues.unitInput.length > 0
    ) {
      setLoading(true);

      const patchURL = moduleIdURL("masters/packaging", id);
      requests({
        method: "patch",
        url: patchURL,
        data: {
          size: fieldValues.sizeInput,
          unit: fieldValues.unitInput,
          bag_type: fieldValues.bagInput,
          is_active: fieldValues.statusInput,
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((res) => {
        setIsValid({ bagInput: true, sizeInput: true, unitInput: true });
        if (res.error) {
          console.log(res, "response");
          // setError(res.data[0].message[0]);
          setError(
            typeof res.data === "string"
              ? res.data
              : res.data.length > 0
              ? res.data
                  .map((s) => {
                    return `${s.field ? `${s.field} :` : ""} ${
                      typeof s.message === "string"
                        ? s.message
                        : s.message.length > 0 && s.message[0] && s.message[0]
                    }`;
                  })
                  .join(" ,")
              : res.data.response
              ? res.data.response.data.msg.error
                ? res.data.response.data.msg.error
                : res.data.response.data.msg
              : "Error !"
          );
          console.log(error, "response error");
          setPushNotier(true);
        } else {
          getFn(res);
          setError("");
          setPushNoti(true);
        }
      });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={onEditHandler}>
        <div style={classes.formGroup}>
          <TextField
            type="number"
            onFocus={StopScroll}
            onKeyDown={(evt) => {
              // evt.key === "." && evt.preventDefault();

              evt.key === "e" && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }}
            style={classes.textField}
            variant="outlined"
            label="Size *"
            value={fieldValues.sizeInput}
            onChange={({ target }) => {
              setFieldValues((prev) => ({
                ...prev,
                sizeInput: target.value,
              }));
              if (target.length !== 0 > 0) {
                setIsValid((prev) => ({ ...prev, sizeInput: true }));
              }
            }}
            error={!isValid.sizeInput}
            helperText={isValid.sizeInput ? "" : "Enter Size "}
          />
          <TextField
            type="text"
            select
            style={classes.textField}
            variant="outlined"
            label="Unit *"
            value={fieldValues.unitInput}
            // onChange={({ target }) =>
            //   setFieldValues((prev) => ({
            //     ...prev,
            //     unitInput: target.value,
            //   }))
            // }
            onChange={handleChange}
            error={!isValid.unitInput}
            helperText={isValid.unitInput ? "" : "Select Unit"}
          >
            {allUnits.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <TextField
          type="text"
          style={classes.textField}
          variant="outlined"
          label="Bag Type *"
          value={fieldValues.bagInput}
          onChange={({ target }) => {
            if (target.length !== 0 > 0) {
              setIsValid((prev) => ({ ...prev, bagInput: true }));
            }
            setFieldValues((prev) => ({
              ...prev,
              bagInput: target.value,
            }));
          }}
          error={!isValid.bagInput}
          helperText={isValid.bagInput ? "" : "Enter Bag Type "}
        />

        {/* <div style={classes.formGroup}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            
          </div>
        </div> */}
        <div style={classes.formActions}>
          {/* <p style={{ fontSize: "18px" }}>
              {fieldValues.statusInput ? "Active" : "Inactive"}
            </p> */}
          <FormControlLabel
            control={
              <Switch
                checked={fieldValues.statusInput}
                className={classes1.switch}
                style={{ color: "rgb(241 154 48)" }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />

          <div>
            <Button
              onClick={handleReset}
              // variant="outlined"
              color="primary"
              style={{
                fontSize: "14px",
                textTransform: "capitalize",
                marginRight: "20px",
              }}
            >
              Reset to default
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "rgb(241 154 48)",
                color: "white",
                textTransform: "capitalize",
              }}
            >
              update
            </Button>
          </div>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(241 154 48)" />
        </Backdrop>
      ) : (
        ""
      )}
      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          closePopup();
        }}
      >
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          Changes have been saved!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default EditForm;
