import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import { primary, green } from "../styles/colors";
import { mediumScreen } from "../Regex";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    "&.MuiLinearProgress-root": {
      // height: "6px",
      overflow: "hidden",
      position: "relative",
    },
    borderRadius: "20px",
    color: green,
  },
  colorPrimary: {
    backgroundColor: "#E5E5E5",
  },
  bar: {
    backgroundColor: green,
  },
}))(LinearProgress);
const BorderLinearProgressList = withStyles((theme) => ({
  root: {
    "&.MuiLinearProgress-root": {
      // height: "6px",
      overflow: "hidden",
      position: "relative",
    },
    borderRadius: "20px",
    color: "#0d8d44",
  },
  colorPrimary: {
    backgroundColor: "#c8c0c0",
  },
  bar: {
    backgroundColor: "#0d8d44",
  },
}))(LinearProgress);
function LinearProgressWithLabel(props) {
  return (
    <div className="width-100">
      <div className="width-100">
        {props.selectedindex === "true" ? (
          <BorderLinearProgressList variant="determinate" {...props} />
        ) : (
          <BorderLinearProgress variant="determinate" {...props} />
        )}
        {console.log("IN", props.selectedindex)}
        <div
          style={{
            fontSize: mediumScreen() ? "0.6rem" : "0.7rem",
            // color: primary,
            color: (window.location.pathname.includes("/SalesOrders/dispatch"), props.selectedindex) 
            ? 'rgb(125 73 9)'
            : 'rgb(241 154 48)',
            padding: "4px 5px 0 0",
          }}
        >
          {`${props.title1} : ${props.completedchapters}${props.unit ? props.unit : ""} /  ${props.totalchapters}${
            props.unit ? props.unit : ""
          }`}
        </div>
      </div>
    </div>
  );
}

export default function ProgressBar(props) {
  return (
    <div className="width-100 height-100">
      <LinearProgressWithLabel
        unit={props.unit}
        selectedindex={props.selectedIndex}
        value={parseInt((props.completedChapters / props.totalChapters) * 100)}
        totalchapters={props.totalChapters}
        title1={props.title1}
        title2={props.title2}
        completedchapters={props.completedChapters}
      />
    </div>
  );
}
