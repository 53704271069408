import React, { Component } from "react";
import Layout from "../../layouts/Layout";

import NoData from "../../styles/Illustrations/NoData";
import { StyledCircularProgress } from "../../styles/StyledComponents";

import { EndPoints } from "../../utils/EndPoints";
import moment from "moment";
import CreateDialog from "../../layoutComponents/CreateDialog";
import EditIcon from "@mui/icons-material/Edit";
import { Paper, Tooltip } from "@material-ui/core";
export class PrecarriersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Precarriers"],
        tooltip: "Click to approve pending Precarriers",
        data: [],
        route: "/Precarriers/Precarriers",
        goBackRoute: "/Precarriers",
        primaryField: "precarrier",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Precarriers: [],
      singlelistView: null,
      loading: false,
      editList: false,
      openDialog: false,
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Precarriers"].split("/")[0] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {}
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState({
      singlelistView: value,
    });
  };

  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["Precarriers"]] &&
        props[EndPoints["Precarriers"]].length > 0
          ? props[EndPoints["Precarriers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      listViewArray:
        props[EndPoints["Precarriers"]] &&
        props[EndPoints["Precarriers"]].length > 0
          ? props[EndPoints["Precarriers"]]
          : [],
      Precarriers: props.Precarriers,
      tabs,
      loading: props.loading,
    });
  }
  fetchList = (res) => {
    if (
      this.state.singlelistView &&
      this.state.singlelistView.id &&
      res &&
      res.id &&
      res.id === this.state.singlelistView.id
    ) {
      this.setState({ singlelistView: res });
    }
    this.props.BrandsManagementsFunction("get", EndPoints["Precarriers"]);
  };
  render() {
    const {
      tabs,
      Precarriers,
      listViewArray,
      singlelistView,
      loading,
      editList,
      openDialog,
    } = this.state;
    console.log(editList,"editList")
    // const { isMobile ,user} = this.props;
    return (
      <Layout
        loading={loading[EndPoints["Precarriers"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: "Search Precarriers",
          labelField: "precarrier",
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
        fabClickHandler={() => {
          this.setState({ openDialog: true, editList: false });
        }}
      >
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openDialog === true ? (
            <CreateDialog
              module={"Precarrier"}
              closeOnSave={true}
              selectedList={editList ? editList : false}
              editList={editList ? editList : false}
              handleClose={() => {
                this.setState({ openDialog: false });
              }}
              fetchAgain={this.fetchList}
              postApi="masters/precarrier/list"
              editApi="masters/precarrier"
              textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
              heightNew="300px"
              maxHeight="calc(100vh - 140px)"
              widthNew="700px"
              inputArray={[
                {
                  name: "Active",
                  type: "toggle",
                  defaultValue: {
                    value: editList ? editList.is_active : true,
                  },
                  api: "is_active",
                },
                {
                  name: "Precarrier",
                  type: "addressTextField",
                  dataType: "text",
                  api: "precarrier",
                  defaultValue: {
                    value:
                      editList && editList.precarrier
                        ? editList.precarrier
                        : "",
                  },
                },
              ]}
              postData={this.props.BrandsManagementsFunction}
            />
          ) : (
            ""
          )}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading[EndPoints["Precarriers"]] === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
            <div style={{ width: "90%" }}>
                <h1 style={{ fontWeight: "400", fontSize: "32px" }}>
                Precarrier Details
                </h1>
              </div>
            <Paper
                className="padding15 backgroundWhite"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
   
              <div className="width-100 d-flex justifyFE">
                <Tooltip title="Edit">
                  <EditIcon
                    onClick={() => {
                      this.setState({
                        openDialog: true,
                        editList: singlelistView,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
              <div
                className="overflowYA scrollBluePrimary"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                  height: "calc(100% - 31.7px)",
                  // textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Name:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",

                      // height: "20vh",
                      // wordWrap: "break-word",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: "capitalize",
                      wordBreak: "break-all",
                    }}
                  >
                    {singlelistView.precarrier}
                  </p>
                </div>
              </div>
            </Paper>
          </>)}
        </Paper>
      </Layout>
    );
  }
}

export default PrecarriersComponent;
