// //imports
// import PrecarriersContainer from "../Modules/Precarriers/PrecarriersContainer.js";
// import PolicysContainer from "../Modules/Policys/PolicysContainer.js";
// import SOpaymentTermsContainer from "../Modules/SOpaymentTerms/SOpaymentTermsContainer.js";
// import IncotermsContainer from "../Modules/Incoterms/IncotermsContainer.js";
// import ShippingTermsContainer from "../Modules/ShippingTerms/ShippingTermsContainer.js";
// import CurrencysContainer from "../Modules/Currencys/CurrencysContainer.js";
// import NotifysContainer from "../Modules/Notifys/NotifysContainer.js";
// import RiceBagSellersContainer from "../Modules/RiceBagSellers/RiceBagSellersContainer.js";
// import BeneficiarysContainer from "../Modules/Beneficiarys/BeneficiarysContainer.js";
// import ShippersContainer from "../Modules/Shippers/ShippersContainer.js";
// import UsersAndPermissionsContainer from "../Modules/UsersAndPermissions/UsersAndPermissionsContainer.js";

// import BrandsManagementsContainer from "../Modules/BrandsManagements/BrandsManagementsContainer.js";
// import MillersContainer from "../Modules/Millers/MillersContainer.js";
// import BrokersContainer from "../Modules/Brokers/BrokersContainer.js";
// import TransportersContainer from "../Modules/Transporters/TransportersContainer.js";
// import PortsContainer from "../Modules/Ports/PortsContainer.js";
// import HsNsContainer from "../Modules/HsNs/HsNsContainer.js";
// import PackagingsContainer from "../Modules/Packagings/PackagingsContainer.js";
// import ContractTypesContainer from "../Modules/ContractTypes/ContractTypesContainer.js";
// import PaymentTermsContainer from "../Modules/PaymentTerms/PaymentTermsContainer.js";
// import ItemTypesContainer from "../Modules/ItemTypes/ItemTypesContainer.js";
// import PurchaseOrdersContainer from "../Modules/PurchaseOrders/PurchaseOrdersContainer.js";
// import SalesOrdersContainer from "../Modules/SalesOrders/SalesOrdersContainer.js";
// import ItemCategoriesContainer from "../Modules/ItemCategories/ItemCategoriesContainer.js";
// import SurveyCompanysContainer from "../Modules/SurveyCompanys/SurveyCompanysContainer.js";
// import ClientsContainer from "../Modules/Clients/ClientsContainer.js";
// import PendingScreen from "../Modules/PendingScreen.js";

// const routes = [
//   {
//     path: "/pending",
//     component: PendingScreen,
//     access: "all",
//   },
//   {
//     path: "/Precarriers/new",
//     component: PrecarriersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Precarriers/:tab/:id",
//     component: PrecarriersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Precarriers",
//     component: PrecarriersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Policys/new",
//     component: PolicysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Policys/:tab/:id",
//     component: PolicysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Policys",
//     component: PolicysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/SOpaymentTerms/new",
//     component: SOpaymentTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/SOpaymentTerms/:tab/:id",
//     component: SOpaymentTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/SOpaymentTerms",
//     component: SOpaymentTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Incoterms/new",
//     component: IncotermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Incoterms/:tab/:id",
//     component: IncotermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Incoterms",
//     component: IncotermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ShippingTerms/new",
//     component: ShippingTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ShippingTerms/:tab/:id",
//     component: ShippingTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ShippingTerms",
//     component: ShippingTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Currencys/new",
//     component: CurrencysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Currencys/:tab/:id",
//     component: CurrencysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Currencys",
//     component: CurrencysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Notifys/new",
//     component: NotifysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Notifys/:tab/:id",
//     component: NotifysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Notifys",
//     component: NotifysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/RiceBagSellers/new",
//     component: RiceBagSellersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/RiceBagSellers/:tab/:id",
//     component: RiceBagSellersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/RiceBagSellers",
//     component: RiceBagSellersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Beneficiarys/new",
//     component: BeneficiarysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Beneficiarys/:tab/:id",
//     component: BeneficiarysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Beneficiarys",
//     component: BeneficiarysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Shippers/new",
//     component: ShippersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Shippers/:tab/:id",
//     component: ShippersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Shippers",
//     component: ShippersContainer,
//     access: "Masters",
//   },
//   {
//     path: "/UsersAndPermissions/new",
//     component: UsersAndPermissionsContainer,
//     access: "Users & Permissions",
//   },
//   {
//     path: "/UsersAndPermissions/:tab/:id",
//     component: UsersAndPermissionsContainer,
//     access: "Users & Permissions",
//   },
//   {
//     path: "/UsersAndPermissions",
//     component: UsersAndPermissionsContainer,
//     access: "Users & Permissions",
//   },

//   {
//     path: "/BrandsManagements/new",
//     component: BrandsManagementsContainer,
//     access: "Brands Management",
//   },
//   {
//     path: "/BrandsManagements/:tab/:id",
//     component: BrandsManagementsContainer,
//     access: "Brands Management",
//   },
//   {
//     path: "/BrandsManagements",
//     component: BrandsManagementsContainer,
//     access: "Brands Management",
//   },
//   {
//     path: "/Millers/new",
//     component: MillersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Millers/:tab/:id",
//     component: MillersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Millers",
//     component: MillersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Brokers/new",
//     component: BrokersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Brokers/:tab/:id",
//     component: BrokersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Brokers",
//     component: BrokersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Transporters/new",
//     component: TransportersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Transporters/:tab/:id",
//     component: TransportersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Transporters",
//     component: TransportersContainer,
//     access: "Partners",
//   },
//   {
//     path: "/Ports/new",
//     component: PortsContainer,
//     access: "Ports",
//   },
//   {
//     path: "/Ports/:tab/:id",
//     component: PortsContainer,
//     access: "Ports",
//   },
//   {
//     path: "/Ports",
//     component: PortsContainer,
//     access: "Ports",
//   },
//   {
//     path: "/HsNs/new",
//     component: HsNsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/HsNs/:tab/:id",
//     component: HsNsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/HsNs",
//     component: HsNsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Packagings/new",
//     component: PackagingsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Packagings/:tab/:id",
//     component: PackagingsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Packagings",
//     component: PackagingsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ContractTypes/new",
//     component: ContractTypesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ContractTypes/:tab/:id",
//     component: ContractTypesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ContractTypes",
//     component: ContractTypesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/PaymentTerms/new",
//     component: PaymentTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/PaymentTerms/:tab/:id",
//     component: PaymentTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/PaymentTerms",
//     component: PaymentTermsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ItemTypes/new",
//     component: ItemTypesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ItemTypes/:tab/:id",
//     access: "Masters",
//     component: ItemTypesContainer,
//   },
//   {
//     path: "/ItemTypes",
//     component: ItemTypesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ItemCategories/new",
//     component: ItemCategoriesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ItemCategories/masters/item-category/list/:id",
//     component: ItemCategoriesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/ItemCategories",
//     component: ItemCategoriesContainer,
//     access: "Masters",
//   },
//   {
//     path: "/SurveyCompanys/new",
//     component: SurveyCompanysContainer,
//     access: "Masters",
//   },
//   {
//     path: "SurveyCompanys/masters/survey-company/list/:id",
//     component: SurveyCompanysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/SurveyCompanys",
//     component: SurveyCompanysContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Clients/new",
//     component: ClientsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/Clients/masters/clients/list/:id",
//     access: "Masters",
//     component: ClientsContainer,
//   },
//   {
//     path: "/Clients",
//     component: ClientsContainer,
//     access: "Masters",
//   },
//   {
//     path: "/PurchaseOrders/new",
//     component: PurchaseOrdersContainer,
//     access: "Purchase Orders",
//   },
//   {
//     path: "/PurchaseOrders/:tab/:id",
//     component: PurchaseOrdersContainer,
//     access: "Purchase Orders",
//   },
//   {
//     path: "/PurchaseOrders",
//     component: PurchaseOrdersContainer,
//     access: "Purchase Orders",
//   },
//   {
//     path: "/SalesOrders/new",
//     component: SalesOrdersContainer,
//     access: "Sales Orders",
//   },
//   {
//     path: "/SalesOrders/:tab/:id",
//     component: SalesOrdersContainer,
//     access: "Sales Orders",
//   },
//   {
//     path: "/SalesOrders",
//     component: SalesOrdersContainer,
//     access: "Sales Orders",
//   },
// ];

// export default routes;
//imports
// import ChatsContainer from "../Modules/Chats/ChatsContainer.js";
import HomesContainer from "../Modules/Homes/HomesContainer.js";
import PrecarriersContainer from "../Modules/Precarriers/PrecarriersContainer.js";
import PolicysContainer from "../Modules/Policys/PolicysContainer.js";
import SOpaymentTermsContainer from "../Modules/SOpaymentTerms/SOpaymentTermsContainer.js";
import IncotermsContainer from "../Modules/Incoterms/IncotermsContainer.js";
import ShippingTermsContainer from "../Modules/ShippingTerms/ShippingTermsContainer.js";
import CurrencysContainer from "../Modules/Currencys/CurrencysContainer.js";
import NotifysContainer from "../Modules/Notifys/NotifysContainer.js";
import RiceBagSellersContainer from "../Modules/RiceBagSellers/RiceBagSellersContainer.js";
import BeneficiarysContainer from "../Modules/Beneficiarys/BeneficiarysContainer.js";
import ShippersContainer from "../Modules/Shippers/ShippersContainer.js";
import UsersAndPermissionsContainer from "../Modules/UsersAndPermissions/UsersAndPermissionsContainer.js";

import BrandsManagementsContainer from "../Modules/BrandsManagements/BrandsManagementsContainer.js";
import MillersContainer from "../Modules/Millers/MillersContainer.js";
import BrokersContainer from "../Modules/Brokers/BrokersContainer.js";
import TransportersContainer from "../Modules/Transporters/TransportersContainer.js";
import PortsContainer from "../Modules/Ports/PortsContainer.js";
import HsNsContainer from "../Modules/HsNs/HsNsContainer.js";
import PackagingsContainer from "../Modules/Packagings/PackagingsContainer.js";
import ContractTypesContainer from "../Modules/ContractTypes/ContractTypesContainer.js";
import PaymentTermsContainer from "../Modules/PaymentTerms/PaymentTermsContainer.js";
import ItemTypesContainer from "../Modules/ItemTypes/ItemTypesContainer.js";
import PurchaseOrdersContainer from "../Modules/PurchaseOrders/PurchaseOrdersContainer.js";
import SalesOrdersContainer from "../Modules/SalesOrders/SalesOrdersContainer.js";
import ItemCategoriesContainer from "../Modules/ItemCategories/ItemCategoriesContainer.js";
import SurveyCompanysContainer from "../Modules/SurveyCompanys/SurveyCompanysContainer.js";
import ClientsContainer from "../Modules/Clients/ClientsContainer.js";
import PendingScreen from "../Modules/PendingScreen.js";
import SiginComponent from "../Modules/SiginComponent.js";
import SpecificationTypesContainer from "../Modules/SpecificationTypes/SpecificationTypesContainer.js";
import SpecificationConditionsContainer from "../Modules/SpecificationConditions/SpecificationConditionsContainer.js";
import ReportsContainer from "../Modules/Reports/ReportsContainer.js";
const routes = [
  {
    path: "/pending",
    component: PendingScreen,
    access: "all",
  },
  // {
  //   path: "/Chats/new",
  //   component: ChatsContainer,
  //   access: "Chats",
  // },
  // {
  //   path: "/Chats/:tab/:id",
  //   component: ChatsContainer,
  //   access: "Chats",
  // },
  // {
  //   path: "/Chats",
  //   component: ChatsContainer,
  //   access: "Chats",
  // },
  {
    path: "/Homes/new",
    component: HomesContainer,
    access: "Homes",
  },
  {
    path: "/Homes/:tab/:id",
    component: HomesContainer,
    access: "Homes",
  },
  {
    path: "/Homes",
    component: HomesContainer,
    access: "Homes",
  },
  {
    path: "/Precarriers/new",
    component: PrecarriersContainer,
    access: "Masters",
  },
  {
    path: "/Precarriers/:tab/:id",
    component: PrecarriersContainer,
    access: "Masters",
  },
  {
    path: "/Precarriers",
    component: PrecarriersContainer,
    access: "Masters",
  },
  {
    path: "/Policys/new",
    component: PolicysContainer,
    access: "Masters",
  },
  {
    path: "/Policys/:tab/:id",
    component: PolicysContainer,
    access: "Masters",
  },
  {
    path: "/Policys",
    component: PolicysContainer,
    access: "Masters",
  },
  {
    path: "/SOpaymentTerms/new",
    component: SOpaymentTermsContainer,
    access: "Masters",
  },
  {
    path: "/SOpaymentTerms/:tab/:id",
    component: SOpaymentTermsContainer,
    access: "Masters",
  },
  {
    path: "/SOpaymentTerms",
    component: SOpaymentTermsContainer,
    access: "Masters",
  },
  {
    path: "/Incoterms/new",
    component: IncotermsContainer,
    access: "Masters",
  },
  {
    path: "/Incoterms/:tab/:id",
    component: IncotermsContainer,
    access: "Masters",
  },
  {
    path: "/Incoterms",
    component: IncotermsContainer,
    access: "Masters",
  },
  {
    path: "/ShippingTerms/new",
    component: ShippingTermsContainer,
    access: "Masters",
  },
  {
    path: "/ShippingTerms/:tab/:id",
    component: ShippingTermsContainer,
    access: "Masters",
  },
  {
    path: "/ShippingTerms",
    component: ShippingTermsContainer,
    access: "Masters",
  },
  {
    path: "/Currencys/new",
    component: CurrencysContainer,
    access: "Masters",
  },
  {
    path: "/Currencys/:tab/:id",
    component: CurrencysContainer,
    access: "Masters",
  },
  {
    path: "/Currencys",
    component: CurrencysContainer,
    access: "Masters",
  }, {
    path: "/specification-types/new",
    component: SpecificationTypesContainer,
    access: "Masters",
  },
  {
    path: "/specification-types/:tab/:id",
    component: SpecificationTypesContainer,
    access: "Masters",
  },
  {
    path: "/specification-types",
    component: SpecificationTypesContainer,
    access: "Masters",
  },{
    path: "/specification-conditions/new",
    component: SpecificationConditionsContainer,
    access: "Masters",
  },
  {
    path: "/specification-conditions/:tab/:id",
    component: SpecificationConditionsContainer,
    access: "Masters",
  },
  {
    path: "/specification-conditions",
    component: SpecificationConditionsContainer,
    access: "Masters",
  },
  {
    path: "/Notifys/new",
    component: NotifysContainer,
    access: "Masters",
  },
  {
    path: "/Notifys/:tab/:id",
    component: NotifysContainer,
    access: "Masters",
  },
  {
    path: "/Notifys",
    component: NotifysContainer,
    access: "Masters",
  },
  {
    path: "/RiceBagSellers/new",
    component: RiceBagSellersContainer,
    access: "Masters",
  },
  {
    path: "/RiceBagSellers/:tab/:id",
    component: RiceBagSellersContainer,
    access: "Masters",
  },
  {
    path: "/RiceBagSellers",
    component: RiceBagSellersContainer,
    access: "Masters",
  },
  {
    path: "/Beneficiarys/new",
    component: BeneficiarysContainer,
    access: "Masters",
  },
  {
    path: "/Beneficiarys/:tab/:id",
    component: BeneficiarysContainer,
    access: "Masters",
  },
  {
    path: "/Beneficiarys",
    component: BeneficiarysContainer,
    access: "Masters",
  },
  {
    path: "/Shippers/new",
    component: ShippersContainer,
    access: "Masters",
  },
  {
    path: "/Shippers/:tab/:id",
    component: ShippersContainer,
    access: "Masters",
  },
  {
    path: "/Shippers",
    component: ShippersContainer,
    access: "Masters",
  },
  {
    path: "/UsersAndPermissions/new",
    component: UsersAndPermissionsContainer,
    access: "Users & Permissions",
  },
  {
    path: "/UsersAndPermissions/:tab/:id",
    component: UsersAndPermissionsContainer,
    access: "Users & Permissions",
  },
  {
    path: "/UsersAndPermissions",
    component: UsersAndPermissionsContainer,
    access: "Users & Permissions",
  },

  {
    path: "/BrandsManagements/new",
    component: BrandsManagementsContainer,
    access: "Brands Management",
  },
  {
    path: "/BrandsManagements/:tab/:id",
    component: BrandsManagementsContainer,
    access: "Brands Management",
  },
  {
    path: "/BrandsManagements",
    component: BrandsManagementsContainer,
    access: "Brands Management",
  },
  {
    path: "/Millers/new",
    component: MillersContainer,
    access: "Millers",
  },
  {
    path: "/Millers/:tab/:id",
    component: MillersContainer,
    access: "Millers",
  },
  {
    path: "/Millers",
    component: MillersContainer,
    access: "Millers",
  },
  {
    path: "/Brokers/new",
    component: BrokersContainer,
    access: "Brokers",
  },
  {
    path: "/Signin",
    component: SiginComponent,
    access: "all",
  },
  {
    path: "/Brokers/:tab/:id",
    component: BrokersContainer,
    access: "Brokers",
  },
  {
    path: "/Brokers",
    component: BrokersContainer,
    access: "Brokers",
  },
  {
    path: "/Transporters/new",
    component: TransportersContainer,
    access: "Transporters",
  },
  {
    path: "/Transporters/:tab/:id",
    component: TransportersContainer,
    access: "Transporters",
  },
  {
    path: "/Transporters",
    component: TransportersContainer,
    access: "Transporters",
  },
  {
    path: "/Ports/new",
    component: PortsContainer,
    access: "Ports",
  },
  {
    path: "/Ports/:tab/:id",
    component: PortsContainer,
    access: "Ports",
  },
  {
    path: "/Ports",
    component: PortsContainer,
    access: "Ports",
  },
  {
    path: "/HsNs/new",
    component: HsNsContainer,
    access: "Masters",
  },
  {
    path: "/HsNs/:tab/:id",
    component: HsNsContainer,
    access: "Masters",
  },
  {
    path: "/HsNs",
    component: HsNsContainer,
    access: "Masters",
  },
  {
    path: "/Packagings/new",
    component: PackagingsContainer,
    access: "Masters",
  },
  {
    path: "/Packagings/:tab/:id",
    component: PackagingsContainer,
    access: "Masters",
  },
  {
    path: "/Packagings",
    component: PackagingsContainer,
    access: "Masters",
  },
  {
    path: "/ContractTypes/new",
    component: ContractTypesContainer,
    access: "Masters",
  },
  {
    path: "/ContractTypes/:tab/:id",
    component: ContractTypesContainer,
    access: "Masters",
  },
  {
    path: "/ContractTypes",
    component: ContractTypesContainer,
    access: "Masters",
  },
  {
    path: "/PaymentTerms/new",
    component: PaymentTermsContainer,
    access: "Masters",
  },
  {
    path: "/PaymentTerms/:tab/:id",
    component: PaymentTermsContainer,
    access: "Masters",
  },
  {
    path: "/PaymentTerms",
    component: PaymentTermsContainer,
    access: "Masters",
  },
  {
    path: "/ItemTypes/new",
    component: ItemTypesContainer,
    access: "Masters",
  },
  {
    path: "/ItemTypes/:tab/:id",
    access: "Masters",
    component: ItemTypesContainer,
  },
  {
    path: "/ItemTypes",
    component: ItemTypesContainer,
    access: "Masters",
  },
  {
    path: "/ItemCategories/new",
    component: ItemCategoriesContainer,
    access: "Masters",
  },
  {
    path: "/ItemCategories/masters/item-category/list/:id",
    component: ItemCategoriesContainer,
    access: "Masters",
  },
  {
    path: "/ItemCategories",
    component: ItemCategoriesContainer,
    access: "Masters",
  },
  {
    path: "/SurveyCompanys/new",
    component: SurveyCompanysContainer,
    access: "Masters",
  },
  {
    path: "SurveyCompanys/masters/survey-company/list/:id",
    component: SurveyCompanysContainer,
    access: "Masters",
  },
  {
    path: "/SurveyCompanys",
    component: SurveyCompanysContainer,
    access: "Masters",
  },
  {
    path: "/Clients/new",
    component: ClientsContainer,
    access: "Masters",
  },
  {
    path: "/Clients/masters/clients/list/:id",
    access: "Masters",
    component: ClientsContainer,
  },
  {
    path: "/Clients",
    component: ClientsContainer,
    access: "Masters",
  },
  {
    path: "/PurchaseOrders/new",
    component: PurchaseOrdersContainer,
    access: "Purchase Orders",
  },
  {
    path: "/PurchaseOrders/:tab/:id",
    component: PurchaseOrdersContainer,
    access: "Purchase Orders",
  },
  {
    path: "/PurchaseOrders",
    component: PurchaseOrdersContainer,
    access: "Purchase Orders",
  },
  {
    path: "/SalesOrders/new",
    component: SalesOrdersContainer,
    access: "Sales Orders",
  },
  {
    path: "/SalesOrders/:tab/:id",
    component: SalesOrdersContainer,
    access: "Sales Orders",
  },
  {
    path: "/SalesOrders",
    component: SalesOrdersContainer,
    access: "Sales Orders",
  },
  {
    path: "/Reports",
    component: ReportsContainer,
    access: "Reports",
  },
];

export default routes;
