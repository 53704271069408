import React, { useEffect } from "react";
import { connect } from "react-redux";
import PackagingsComponent from "./PackagingsComponent";
import { PackagingsFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function PackagingsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    //if (props.user.uid) {
    props.PackagingsFunction("get", EndPoints["Packagings"]);
    // }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <PackagingsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  Packagings: state.modules.Packagings,
  loading: state.modules.loading,
  [EndPoints["Packagings"]]: state.modules[EndPoints["Packagings"]],
});
const mapDispatchToProps = {
  PackagingsFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagingsContainer);
