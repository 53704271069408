import React, { Component } from "react";
import Layout from "../../layouts/Layout";

import NoData from "../../styles/Illustrations/NoData";

import { StyledCircularProgress } from "../../styles/StyledComponents";

import { EndPoints } from "../../utils/EndPoints";
import moment from "moment";
import CreateDialog from "../../layoutComponents/CreateDialog";
import EditIcon from "@mui/icons-material/Edit";
import { Paper, Tooltip } from "@material-ui/core";
import { filterData } from "../../Regex";
import parse from "html-react-parser";

export class PolicysComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Policys"],
        tooltip: "Click to approve pending Policys",
        data: [],
        route: "/Policys/Policys",
        goBackRoute: "/Policys",
        primaryField: "title",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Policys: [],
      listViewArray: null,
      loading: false,
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Policys"].split("/")[0] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {}
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    console.log(value, "list click");
    this.setState({
      singlelistView: value,
    });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };

  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["Policys"]] && props[EndPoints["Policys"]].length > 0
          ? props[EndPoints["Policys"]]
              .map((item) => {
                item.title = parse(item.policy);
                return item;
              })
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [];
      return tab;
    });
    this.setState({
      incotermList:
        props[EndPoints["Incoterms"]] &&
        props[EndPoints["Incoterms"]].length > 0
          ? props[EndPoints["Incoterms"]]
          : [],
      shippingTermList:
        props[EndPoints["ShippingTerms"]] &&
        props[EndPoints["ShippingTerms"]].length > 0
          ? props[EndPoints["ShippingTerms"]]
          : [],
      listViewArray:
        props[EndPoints["Policys"]] && props[EndPoints["Policys"]].length > 0
          ? props[EndPoints["Policys"]]
              .map((item) => {
                item.title = `${item.inco_term_name} - ${item.shipping_term_name}`;
                return item;
              })
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [],

      Policys: props.Policys,
      tabs,
      loading: props.loading,
    });
  }
  fetchList = (res) => {
    if (
      this.state.singlelistView &&
      this.state.singlelistView.id &&
      res &&
      res.id &&
      res.id === this.state.singlelistView.id
    ) {
      this.setState({ singlelistView: res });
    }
    this.props.BrandsManagementsFunction("get", EndPoints["Policys"]);
  };
  render() {
    const {
      tabs,
      Policys,
      listViewArray,
      singlelistView,
      loading,
      editList,
      openDialog,
      shippingTermList,
      incotermList,
    } = this.state;
    console.log(listViewArray, "list view");
    var labelField = "title";
    // const { isMobile ,user} = this.props;
    return (
      <Layout
        loading={loading[EndPoints["Policys"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: "Search Policys",
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
        fabClickHandler={() => {
          this.setState({ openDialog: true, editList: false });
        }}
      >
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openDialog === true ? (
            <CreateDialog
              module={"Policy"}
              closeOnSave={true}
              selectedList={editList ? editList : false}
              editList={editList ? editList : false}
              handleClose={() => {
                this.setState({ openDialog: false });
              }}
              noAddAnother={true}
              fetchAgain={this.fetchList}
              postApi="masters/so-policy/list"
              editApi="masters/so-policy"
              textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
              heightNew="500px"
              maxHeight="calc(100vh - 140px)"
              widthNew="700px"
              inputArray={[
                {
                  name: "Active",
                  type: "toggle",
                  defaultValue: {
                    value: editList ? editList.is_active : true,
                  },
                  api: "is_active",
                },
                {
                  name: "Incoterm",
                  type: "select",
                  dataType: "text",
                  array:
                    incotermList && incotermList.length > 0 ? incotermList : [],
                  api: "inco_term",
                  value: "name",
                  defaultValue: {
                    value:
                      editList && editList.inco_term
                        ? filterData(
                            incotermList,
                            "id",
                            editList.inco_term,
                            "onlyOne"
                          )
                          ? filterData(
                              incotermList,
                              "id",
                              editList.inco_term,
                              "onlyOne"
                            ).name
                          : ""
                        : "",
                    id:
                      editList && editList.inco_term ? editList.inco_term : "",
                  },
                },
                {
                  name: "Shipping Term",
                  type: "select",
                  array:
                    shippingTermList && shippingTermList.length > 0
                      ? shippingTermList
                      : [],
                  dataType: "text",
                  api: "shipping_term",
                  value: "value",
                  defaultValue: {
                    value:
                      editList && editList.shipping_term
                        ? filterData(
                            shippingTermList,
                            "id",
                            editList.shipping_term,
                            "onlyOne"
                          )
                          ? filterData(
                              shippingTermList,
                              "id",
                              editList.shipping_term,
                              "onlyOne"
                            ).value
                          : ""
                        : "",
                    id:
                      editList && editList.shipping_term
                        ? editList.shipping_term
                        : "",
                  },
                },
                {
                  name: "Policy",
                  // type: "textField",
                  not_required: true,
                  type: "richTextField",
                  dataType: "text",
                  api: "policy",
                  defaultValue: {
                    value: editList && editList.policy ? editList.policy : "",
                  },
                },
              ]}
              postData={this.props.BrandsManagementsFunction}
            />
          ) : (
            ""
          )}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading[EndPoints["Policys"]] === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
            <div style={{ width: "90%" }}>
                <h1 style={{ fontWeight: "400", fontSize: "32px" }}>
                Policy Details
                </h1>
              </div>
            <Paper
                className="padding15 backgroundWhite"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
              <div className="width-100 d-flex justifyFE">
                <Tooltip title="Edit">
                  <EditIcon
                    onClick={() => {
                      this.setState({
                        openDialog: true,
                        editList: singlelistView,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
              <div
                className="overflowYA scrollBluePrimary"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                  height: "calc(100% - 31.7px)",
                  // textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Incoterm:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",

                      // height: "20vh",
                      // wordWrap: "break-word",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: "capitalize",
                      wordBreak: "break-all",
                    }}
                  >
                    {filterData(
                      incotermList,
                      "id",
                      singlelistView.inco_term,
                      "onlyOne"
                    )
                      ? filterData(
                          incotermList,
                          "id",
                          singlelistView.inco_term,
                          "onlyOne"
                        ).name
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Shipping Term:
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {filterData(
                      shippingTermList,
                      "id",
                      singlelistView.shipping_term,
                      "onlyOne"
                    )
                      ? filterData(
                          shippingTermList,
                          "id",
                          singlelistView.shipping_term,
                          "onlyOne"
                        ).value
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                      marginTop: "25px",
                    }}
                  >
                    Policy:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      wordBreak: "break-all",
                    }}
                  >
                    {parse(singlelistView.policy)}
                  </p>
                </div>
              </div>
            </Paper>
        </>  )}
        </Paper>
      </Layout>
    );
  }
}

export default PolicysComponent;
