import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SalesOrdersComponent from "./SalesOrdersComponent";
import { SalesOrdersFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function SalesOrdersContainer(props) {
  const [masters, setMasters] = useState({});

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchUsers();
    fetchAllData();
  }, []);

  const fetchAllData = () => {
    if (props.user.uid) {
      props.SalesOrdersFunction("get", EndPoints["SalesOrders"],
        null,
        null,
        null,
        {
          status: "OPEN",
        }
      );
    }
  
    // setLoader(true);
    props
      .SalesOrdersFunction(
        "get",
        "masters/brand/list",
        null,
        //state.singlelistView.id,
        "BrandList",
        null,
        { is_active: "True" }
      )
      .then((BrandList) => {
        if (BrandList) {
          const newMasters = masters;
          newMasters.BrandList = BrandList;
          setMasters(newMasters);
        }
      });

    props
      .SalesOrdersFunction(
        "get",
        "masters/hsn/list",
        null,
        //state.singlelistView.id,
        "HSNlist",
        null,
        { is_active: "True" }
      )
      .then((HSNlist) => {
        if (HSNlist) {
          const newMasters = masters;
          newMasters.HSNlist = HSNlist;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/clients/list",
        null,
        "ClientList",
        null,
        { is_active: "True" }
      )
      .then((ClientList) => {
        if (ClientList) {
          const newMasters = masters;
          newMasters.ClientList = ClientList;
          setMasters(newMasters);
        }
      });

    props
      .SalesOrdersFunction(
        "get",
        "masters/item-category/list",
        null,
        //state.singlelistView.id,
        "ItemCategoryList",
        null,
        { is_active: "True" }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          const newMasters = masters;
          newMasters.ItemCateoryList = ItemCateoryList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/item-type/list",
        null,
        //state.singlelistView.id,
        "ItemTypeList",
        null,
        { is_active: "True" }
      )
      .then((ItemList) => {
        if (ItemList) {
          const newMasters = masters;
          newMasters.ItemList = ItemList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/port/list",
        null,
        //state.singlelistView.id,
        "PortList",
        null
      )
      .then((PortList) => {
        if (PortList) {
          const newMasters = masters;
          newMasters.PortsList = PortList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/notify/list",
        null,
        //state.singlelistView.id,
        "notifyList",
        null
      )
      .then((notifyList) => {
        console.log(notifyList, "notifysssss");
        if (notifyList) {
          const newMasters = masters;
          newMasters.notifyList = notifyList;
          setMasters(newMasters);
        }
      });

    props
      .SalesOrdersFunction(
        "get",
        "dispatch/sales-order/list",
        null,
        //state.singlelistView.id,
        "SalesOrderList"
      )
      .then((SalesOrderList) => {
        if (SalesOrderList) {
          const newMasters = masters;
          newMasters.SalesOrderList = SalesOrderList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/inco-term/list",
        null,
        //state.singlelistView.id,
        "IncotermsList",
        null,
        { is_active: "True" }
      )
      .then((IncotermsList) => {
        if (IncotermsList) {
          const newMasters = masters;
          newMasters.Incoterms = IncotermsList;
          setMasters(newMasters);
        }
      });

    props
      .SalesOrdersFunction(
        "get",
        "masters/currency/list",
        null,
        //state.singlelistView.id,
        "CurrencyList",
        null,
        { is_active: "True" }
      )
      .then((CurrencyList) => {
        if (CurrencyList) {
          const newMasters = masters;
          newMasters.CurrencyList = CurrencyList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/shipping-term/list",
        null,
        //state.singlelistView.id,
        "ShippingTermsList",
        null,
        { is_active: "True" }
      )
      .then((ShippingTermsList) => {
        console.log(ShippingTermsList, "notifysssddddddddddddddddddddddss");
        if (ShippingTermsList) {
          const newMasters = masters;
          newMasters.ShippingTerms = ShippingTermsList;
          setMasters(newMasters);
        }
      });

    props
      .SalesOrdersFunction(
        "get",
        "masters/so-payment-term/list",
        null,
        //state.singlelistView.id,
        "SoPaymentTermsList",
        null,
        { is_active: "True" }
      )
      .then((SoPaymentTermsList) => {
        if (SoPaymentTermsList) {
          const newMasters = masters;
          newMasters.SoPaymentList = SoPaymentTermsList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "dispatch/so-packaging/list",
        null,
        //state.singlelistView.id,
        "soPackahging",
        null,
        {
          is_active: "True",
        }
      )
      .then((soPackahging) => {
        if (soPackahging) {
          const newMasters = masters;
          newMasters.soPackahging = soPackahging;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/so-policy/list",
        null,
        //state.singlelistView.id,
        "soPolicyList",
        null,
        {
          is_active: "True",
        }
      )
      .then((soPolicyList) => {
        if (soPolicyList) {
          const newMasters = masters;
          newMasters.soPolicyList = soPolicyList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/payment-terms/list",
        null,
        //state.singlelistView.id,
        "PaymentTermList",
        null,
        { is_active: "True" }
      )
      .then((PaymentTermList) => {
        if (PaymentTermList) {
          const newMasters = masters;
          newMasters.PaymentTermList = PaymentTermList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/benificiary/list",
        null,
        "benificiarysList",
        null,
        { is_active: "True" }
      )
      .then((benificiarysList) => {
        if (benificiarysList) {
          const newMasters = masters;
          newMasters.benificiarysList = benificiarysList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction("get", EndPoints["Shippers"], null, "shippersList")
      .then((shippersList) => {
        if (shippersList) {
          const newMasters = masters;
          newMasters.shippersList = shippersList;
          setMasters(newMasters);
        }
      });
    props
      .SalesOrdersFunction(
        "get",
        "masters/packaging/list",
        null,
        //state.singlelistView.id,
        "PackagingList",
        null,
        { is_active: "True" }
      )
      .then((PackagingList) => {
        if (PackagingList) {
          const newMasters = masters;
          newMasters.PackagingList = PackagingList;
          setMasters(newMasters);
         }
         props
         .SalesOrdersFunction(
           "get",
           "masters/specification-type/list",
           null,
           //state.singlelistView.id,
           "specificationTypesList",
           null,
           {
             is_active:
               "True",
           }
         )
         .then(
           (
            specifiationTypesList
           ) => {
             if (
              specifiationTypesList
             ) {
               const newMasters =
                 masters;
               newMasters.specifiationTypes =
               specifiationTypesList;
               setMasters(
                 newMasters
               );
               setLoader(
                 false
               );
             } else {
               setLoader(
                 false
               );
             }
           }
         );
      });
  };

  const fetchUsers = () => {
    if (props.user.uid) {
      props.SalesOrdersFunction("get", EndPoints["SalesOrders"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return (
    <SalesOrdersComponent
      {...props}
      params={params}
      masters={masters}
      loader={loader}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  SalesOrders: state.modules.SalesOrders,
  loading: state.modules.loading,
  [EndPoints["SalesOrders"]]: state.modules[EndPoints["SalesOrders"]],
  SODispatchedList: state.modules.SODispatchedList,
  SO_Items_List: state.modules.SO_Items_List,
});
const mapDispatchToProps = {
  SalesOrdersFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesOrdersContainer);
