import React, { useEffect } from "react";
import { connect } from "react-redux";
import ClientsComponent from "./ClientsComponent";
import { ClientsFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function ClientsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    // if (props.user.uid) {
    props.ClientsFunction("get", EndPoints["Clients"]);
    // }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <ClientsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  // Clients: state.modules.Clients,
  loading: state.modules.loading,
  [EndPoints["Clients"]]: state.modules[EndPoints["Clients"]],
});
const mapDispatchToProps = {
  ClientsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer);
