import React, { Component } from "react";
import Layout from "../../layouts/Layout";

import { StyledCircularProgress } from "../../styles/StyledComponents";
import NoData from "../../styles/Illustrations/NoData";

import { EndPoints } from "../../utils/EndPoints";
import { Paper } from "@material-ui/core";
import Icon from "@mui/material/Icon";
import { ModeEdit } from "@mui/icons-material";
import Tooltip from "@material-ui/core/Tooltip";

import Popup from "./components/Popup";
import EditForm from "./components/EditForm";
import NewForm from "./components/NewForm";
import moment from "moment";

export class ItemTypesComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["ItemTypes"],
        tooltip: `Click to approve pending ${EndPoints["ItemTypes"]}`,
        data: [],
        route: `/ItemTypes/${EndPoints["ItemTypes"].split("/")[0]}`,
        goBackRoute: "/ItemTypes",
        primaryField: "brokenPercent",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      ItemTypes: [],
      singlelistView: null,
      loading: false,
      isPopup: false,
      isNew: false,
    };
  }

  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["ItemTypes"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.ItemTypesFunction(
                "get",
                EndPoints["ItemTypes"],
                this.state.singlelistView.id,
                "ItemTypes"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState(
      {
        singlelistView: value,
      },
      () => {
        // if (this.state.singlelistView && this.state.singlelistView.id) {
        //   this.props.ItemTypesFunction(
        //     "get",
        //     EndPoints["ItemTypes"],
        //     this.state.singlelistView.id,
        //     "ItemTypes"
        //   );
        // }
      }
    );
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
    // if (value && value.monthId) {
    //   if (this.state.selectedMonth.monthId !== value.monthId) {
    //     this.setState({ selectedMonth: value, singleDate: null, singleDateTrips: [], singleDateTripsCopy: [] });
    //     this.props.fetchlistViewArray(this.props.user.user.uid, value.monthId, value.yearId).then((dateRR) => {
    //       this.props.setlistViewArray(dateRR);
    //     });
    //   }
    // }
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["ItemTypes"]] &&
        props[EndPoints["ItemTypes"]].length > 0
          ? props[EndPoints["ItemTypes"]]
              .map((sT) => {
                sT.brokenPercent = `${sT.broken_percentage} %`;
                return sT;
              })
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [];
      return tab;
    });
    this.setState({
      listViewArray:
        props[EndPoints["ItemTypes"]] &&
        props[EndPoints["ItemTypes"]].length > 0
          ? props[EndPoints["ItemTypes"]]
              .map((sT) => {
                sT.brokenPercent = `${sT.broken_percentage} %`;
                return sT;
              })
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [],
      ItemTypes: props.ItemTypes,
      tabs,
      loading: props.loading,
    });
  }

  render() {
    const { tabs, listViewArray, ItemTypes, singlelistView, loading } =
      this.state;
    // console.log(listViewArray, "list view array");
    var list = [];
    listViewArray.map((item) => {
      list.push(item.broken_percentage + " %");
    });
    // console.log(list, "broken listt");
    // const { isMobile ,user} = this.props;
    // var labelField = "title";
    // if (listViewArray && listViewArray.length > 0 && listViewArray[0]) {
    //   Object.keys(listViewArray[0]).map((sKey) => {
    //     if (typeof listViewArray[0][sKey] === "string") {

    //       return (labelField = sKey);
    //     } else {
    //       return null;
    //     }
    //   });
    // }
    var labelField = "broken_percentage"; //assign search field
    return (
      <Layout
        loading={loading[EndPoints["ItemTypes"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Item Types`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          this.setState({
            isPopup: true,
            isNew: true,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        <Paper
          className="width-100 height-100 padding15 backgroundWhite"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.ItemTypes === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <div style={{ width: "90%" }}>
                <h1 style={{ fontWeight: "400", fontSize: "32px" }}>
                  Item Type Details
                </h1>
              </div>
              <Paper
                className="padding15 backgroundWhite"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "4%",
                  }}
                >
                  <Tooltip title="Edit">
                    <Icon
                      onClick={() => this.setState({ isPopup: true })}
                      style={{ cursor: "pointer" }}
                      component={ModeEdit}
                    />
                  </Tooltip>
                </div>
                <div
                  className="overflowYA scrollBluePrimary"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 50px",
                    height: "calc(100% - 31.7px)",
                    // textTransform: "capitalize",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Broken Percentage:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {parseFloat(singlelistView.broken_percentage).toFixed(2) +
                        " %"}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Status:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.is_active ? "Active" : "Inactive"}
                    </p>
                  </div>
                </div>
              </Paper>
            </>
          )}
        </Paper>
        <Popup
          isNew={this.state.isNew}
          openPopup={this.state.isPopup}
          setOpenPopup={this.setStateOfPopup}
        >
          {this.state.isNew ? (
            <NewForm
              closePopup={this.setStateOfPopup}
              getFn={() =>
                this.props.ItemTypesFunction("get", EndPoints["ItemTypes"])
              }
            />
          ) : (
            singlelistView !== null && (
              <EditForm
                isPopup={this.state.isPopup}
                getFn={(response) => {
                  if (response.data) {
                    this.setState({ singlelistView: response.data });
                  }
                  this.props.ItemTypesFunction("get", EndPoints["ItemTypes"]);
                  // if (response.data.is_active === false) {
                  //   this.setState({ singlelistView: null });
                  //   this.setStateOfPopup(false);
                  // }
                  // this.props
                  //   .ItemTypesFunction(
                  //     "get",
                  //     "masters/item-type",
                  //     singlelistView.id,
                  //     "ItemTypes"
                  //   )
                  //   .then((res) => {
                  //     if (res) {
                  //       this.setState({ singlelistView: res });
                  //     }
                  //   })
                }}
                id={singlelistView.id}
                brokenPercentage={singlelistView.broken_percentage}
                status={singlelistView.is_active}
                closePopup={this.setStateOfPopup}
              />
            )
          )}
        </Popup>
      </Layout>
    );
  }
}

export default ItemTypesComponent;
