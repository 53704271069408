const initialState = {
  loading: true,
  user: null,
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_USER_REQUEST":
      return { user: null, loading: true, ...state };
    case "AUTH_USER_LOGGEDIN":
      state.user = action.payload;
      state.loading = false;
      return { ...state };
    default:
      return { ...state };
  }
};

export default authenticationReducer;
