import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import store from "./redux/store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "./snackbarContext";
ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </React.Fragment>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
