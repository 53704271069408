import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import TableComponent from "../../layoutComponents/TableComponent";
import SummaryTable from "../../layoutComponents/SummaryTable";
import NoData from "../../styles/Illustrations/NoData";
import { CrossIcon, EditIcon } from "../../styles/Icons";
import ShowSpecifications, {
  getSpecificationId,
} from "../../layoutComponents/ShowSpecifications";
// import { Paper } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import CreateDialog from "../../layoutComponents/CreateDialog";
import { filterData } from "../../Regex";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import { primary, white, mostLightGrey, lightGrey } from "../../styles/colors";
import { divide, multiply, sum, subtract } from "lodash";
import {
  CssTextField,
  StyledButton,
  CssTextField95,
} from "../../styles/StyledComponents";
import {
  Divider,
  Paper,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  MenuItem,
  Tooltip,
  Button,
  Backdrop,
  DialogContent,
  Checkbox,
} from "@material-ui/core";

import DetailsSo from "./DetailsSo";
import debounce from "lodash/debounce";
export class SalesOrdersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["SalesOrders"],
        tooltip: `Click to approve pending ${EndPoints["SalesOrders"]}`,
        data: [],
        route: `/SalesOrders/${EndPoints["SalesOrders"].split("/")[0]}`,
        goBackRoute: "/SalesOrders",
        colorArr: [white, lightGrey],
        primaryTitle: "Buyer",
        primaryField: "so_number",
        primarySecondValue: "used_quantity",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "id",
        primaryFieldThirdFallback: "total_quantity",
        avatarField: "src",
        unit: true,
        dispatchList: true,
        progressBar: true,
        salesList: true,
        selectedSoid: false,
        dispatchPackagingArray: [],
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      SalesOrders: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      status: [
        { name: "DRAFT" }, 
        { name: "OPEN" }, 
        { name: "CLOSED" }, 
        { name: "CANCELLED" }
      ],
      openDialog: false,
      requiredToggle: true,
      survayorList: false,
      SODispatchedList: [],
      SO_Items_List: [],
      summaryData: [],
      PurchaseOrders: [],
      editingDispatch: false,
      loadingFun: false,
      success: false,
      error: false,
      calenderOpen: false,
      selectedSrvayorid: false,
      selectedSoIdinSiurvayor: "",
      statusDispatch: [
        { name: "dispatched" },
        { name: "reached" },
        { name: "full_return" },
        { name: "semi_returned" },
        { name: "unloaded" },
      ],
      selctedSOStatus: "OPEN",
      total_quantity: "",
      last_survey: new Date(),
      unit: [{ name: "Kgs" }, { name: "MT" }, { name: "Qtl" }],
      selctedStatus: "OPEN",
      searchedData: new Date(),
      calenderOpen: false,
      calenderOpenLS: false,
      soItemheader: [
        "Brand",
        "Item Category",
        "Item Type",
        "Packaging",
        "HSN Code",
        "Total Item Quantity",
        "Contract Rate",
        "Buyer Marking",
        "Specifications",
        "Quantity Tolerance",
        "Bag Weight",
      ],
     
      widthList: {},
      dates: [],
      selectedPVItemArray: [],
      contractTypeName: "",
      contractTypeId: "",
      PaymentTerm: "",
      PaymentTermId: "",
      MillerName: "",
      MillerNameId: "",
      MillerAddress: "",
      MillerAddressId: "",
      MillerGST: "",
      MillerGSTId: "",
      ContactPerson: "",
      ContactPersonId: "",
      ItemCategory: "",
      ItemCategoryId: "",
      ItemType: "",
      ItemTypeId: "",
      BorkerName: "",
      BorkerNameId: "",
      BrandName: false,
      selectedBrandMultipleName: false,
      BrandNameId: "",
      Borkerage: "",
      BorkerageId: "",
      Remark: "",
      Unit: "",
      UnitId: "",
      portArry: [],
      packagingArr: [],
      selectedValue: 0,
      added: false,
      cashdiscount: "no",
      addItemMultiple: "false",
      contarctTypeFields: [{ type: "ex factory" }, { type: "FOR" }],
      HSN_id: "",
      soItemsArray: [],
      soItemsArrayCopy: [],
      soItemsArrayTOPreview: [],
      soItemsArrayTOPreviewCopy: [],
      soItemsArrayTOPreviewCopy2: [],
      alreadyFetched: false,
      alreadyFetchedData: false,
      loader: false,
      loadingRight: false,
      submittedDataOfUsedBags: {},
      surveyReport: [],
      surveyorItemArrayCopy: [],
      errorMsg: {
        Buyer: false,
        Terms: false,
        TotalRate: false,
        contractTypeName: false,
        PaymentTerm: false,
        MillerName: false,
        MillerAddress: false,
        MillerGST: false,
        ContactPerson: false,
        ItemCategory: false,
        ItemType: false,
        BorkerName: false,
        BrandName: false,
        Borkerage: false,
        Remark: false,
        portArry: false,
        packagingArr: false,
        Rate: false,
        Unit: false,
        HSN: false,
      },
      openAddBagDialog: false,
      editDialog: false,
      masters: {},
      searchText: "",
      dateSelectedFrom: "",
      dateSelectedTo: "",
    };
    this.debouncedFilterSearchApi = debounce(this.filterSearchApi, 500); 
  }
  handleClearFields = () => {
    this.setState({
      added: false,
      loading: false,
      loadingFun: false,
      success: false,
      error: false,
    });
  };
  reset = (noSnack) => {
    const {
      Buyer,
      TotalRate,
      contractTypeName,
      PaymentTerm,
      MillerName,
      ContactPerson,
      BorkerName,
      BrandName,
      Terms,
      portArry,
      packagingArr,
      Rate,
      Unit,
      HSN,
      SalesOrder,
    } = this.state;

    if (
      !TotalRate &&
      !Buyer &&
      !Rate &&
      !Unit &&
      !HSN &&
      !Terms &&
      !BrandName &&
      !BorkerName &&
      !ContactPerson &&
      !MillerName &&
      !PaymentTerm &&
      !contractTypeName &&
      packagingArr.length === 0 &&
      portArry.length === 0 &&
      !SalesOrder
    ) {
      this.setState({
        error: noSnack ? false : "Nothing to reset !",
        errorMsg: {
          TotalRate: false,
          Buyer: false,
          Unit: false,
          contractTypeName: false,
          BorkerName: false,
          Borkerage: false,
          portArry: false,
          MillerName: false,
          BrandName: false,
          HSN: false,
          Rate: false,
          PaymentTerm: false,
          Terms: false,
          packagingArr: false,
        },
      });
    } else {
      this.setState(
        {
          TotalRate: "",
          Buyer: "",
          BrandNameId: "",
          BrandName: false,
          selectedBrandMultipleName: false,
          selctedSOStatus: "OPEN",
          SalesOrder: "",
          SalesOrderId: "",
          PaymentTerm: "",
          MillerName: "",
          MillerAddress: "",
          MillerGST: "",
          ContactPerson: "",
          ItemCategory: "",
          ItemTypeId: "",
          ItemCategoryId: "",
          ItemType: "",
          BorkerName: "",
          Terms: "",
          portArry: [],
          packagingArr: [],
          Rate: "",
          Unit: "",
          HSN: "",
          HSN_id: "",
          errorMsg: {
            TotalRate: false,
            TotalQ: false,
            Unit: false,
            BrandName: false,
            HSN: false,
            Rate: false,
            PaymentTerm: false,
            Terms: false,
            portArry: false,
            packagingArr: false,
          },
        },
        () => {
          this.setState({ success: noSnack ? false : "Reset successfully !" });
        }
      );
    }
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    // this.setInitialValues(this.props);

    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["SalesOrders"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            // if (this.state.singlelistView && this.state.singlelistView.id) {
            //   this.props.SalesOrdersFunction(
            //     "get",
            //     this.props.user.uid,
            //     EndPoints["SalesOrders"],
            //     this.state.singlelistView.id,
            //     "SalesOrders"
            //   );
            // }
          }
        );
      } else if (tab === "NEW") {
        // this.setState({ singlelistView: null });
      }
    } else {
      // this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  listClickHandler = (value) => {
   
    this.setState({ loadingRight: true, singlelistView: value }, () => {
      
      if (this.state.singlelistView && this.state.singlelistView.id) {
        this.props
          .SalesOrdersFunction(
            "get",
            "dispatch/so-items/list",
            null,
            "SO_Items_List",
            null,
            {
              so_id: this.state.singlelistView.id,
            }
          )
          .then(() => {
            this.props
              .SalesOrdersFunction(
                "get",
                "dispatch/so-packaging/list",
                null,
                //state.singlelistView.id,
                "soPackahging",
                null,
                { is_active: "True" }
              )
              .then((soPackahging) => {
                if (soPackahging) {
                  this.setState({ soPackahging }, () => {
                    this.setData();
                  });
                } else {
                  this.setData();
                }
              });
          });
      }
    });
  };
  selectHandler = (value) => {
 
    this.setState({ selctedStatus: value }, () => {
      this.props.SalesOrdersFunction(
        "get",
        EndPoints["SalesOrders"],
        null,
        null,
        null,
        {
          status: this.state.selctedStatus,
        }
      );
    });
  };
  searchHandler = (value) => {
    // this.listClickHandler(value);
    this.setState({ selectedMonth: value }, () => {
      const { selectedMonth } = this.state;
      this.props.SalesOrdersFunction(
        "get",
        EndPoints["SalesOrders"],
        null,
        null,
        null,
        {
          month: selectedMonth.getMonth() + 1,
          status: this.state.selctedStatus,
        }
      );
    });
    // if (value && value.monthId) {
    //   if (this.state.selectedMonth.monthId !== value.monthId) {
    //     this.setState({ selectedMonth: value, singleDate: null, singleDateTrips: [], singleDateTripsCopy: [] });
    //     this.props.fetchPosts(this.props.user.uid, value.monthId, value.yearId).then((dateRR) => {
    //       this.props.setPosts(dateRR);
    //     });
    //   }
    // }
  };
  setInitialValues(props, newData="") {

    const data = newData 
    ? newData
    : props[EndPoints["SalesOrders"]] && props[EndPoints["SalesOrders"]].results
        ? props[EndPoints["SalesOrders"]].results
        : [];
    if (props && data && data.length > 0 && typeof data === "string") {
      data.sort((a, b) => b.id - a.id);
    }
    console.log(this.props)
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;

      tab.data =
        data && data.length > 0
          ? data
              .map((data, index) => {
                data["Buyer"] = filterData(
                  this.props.masters.ClientList, "id", data.buyer, "onlyOne"
                )?.client_name || ""
                return data;
              })
              .filter((sD) => {
                if (sD.status) {
                  return sD.status === this.state.selctedStatus;
                } else {
                  return null;
                }
              })
              .sort((a, b) => b.id - a.id)
          : [];

      if (this.state.selctedStatus && tab.data.length > 0) {
        tab.data = tab.data
          .map((data, index) => {
            return data;
          })
          .filter((sD) => {
            if (sD.status) {
              return sD.status === this.state.selctedStatus;
            } else {
              return null;
            }
          });
      }

      return tab;
    });

    const masterDetails = props.masters;
    if (masterDetails) {
      this.setState({
        loader: props.loader,

        BrandList: masterDetails["BrandList"] ? masterDetails["BrandList"] : [],
        CurrencyList: masterDetails["CurrencyList"]
          ? masterDetails["CurrencyList"]
          : [],
        soPolicyList: masterDetails["soPolicyList"]
          ? masterDetails["soPolicyList"]
          : [],
        SoPaymentList: masterDetails["SoPaymentList"]
          ? masterDetails["SoPaymentList"]
          : [],
        dispatchPackagingArray: masterDetails["dispatchPackagingArray"]
          ? masterDetails["dispatchPackagingArray"]
          : [],
        notifyList: masterDetails["notifyList"]
          ? masterDetails["notifyList"]
          : [],
        soPackahging: masterDetails["soPackahging"]
          ? masterDetails["soPackahging"]
          : [],
        ClientList: masterDetails["ClientList"]
          ? masterDetails["ClientList"]
          : [],
        ItemCateoryList: masterDetails["ItemCateoryList"]
          ? masterDetails["ItemCateoryList"]
          : [],
        ItemList: masterDetails["ItemList"] ? masterDetails["ItemList"] : [],
        ClientList: masterDetails["ClientList"]
          ? masterDetails["ClientList"]
          : [],
        PortsList: masterDetails["PortsList"] ? masterDetails["PortsList"] : [],
        PackagingList: masterDetails["PackagingList"]
          ? masterDetails["PackagingList"]
          : [],
          specifiationTypes: masterDetails["specifiationTypes"]
          ? masterDetails["specifiationTypes"]
          : [],
        HSNlist: masterDetails["HSNlist"] ? masterDetails["HSNlist"] : [],
        SalesOrderList: masterDetails["SalesOrderList"]
          ? masterDetails["SalesOrderList"]
          : [],
        masters: { specifiationTypes: masterDetails["specifiationTypes"]
          ? masterDetails["specifiationTypes"]
          : [],
          salesOrderList:
            props[EndPoints["SalesOrders"]] &&
            props[EndPoints["SalesOrders"]].length > 0
              ? props[EndPoints["SalesOrders"]].sort((a, b) => b.id - a.id)
              : [],
          PackagingList: masterDetails["PackagingList"]
            ? masterDetails["PackagingList"]
            : [],
          notifyList: masterDetails["notifyList"]
            ? masterDetails["notifyList"]
            : [],
          ItemCateoryList: masterDetails["ItemCateoryList"]
            ? masterDetails["ItemCateoryList"]
            : [],
          shippersList: masterDetails["shippersList"]
            ? masterDetails["shippersList"]
            : [],
          benificiarysList: masterDetails["benificiarysList"]
            ? masterDetails["benificiarysList"]
            : [],
          buyersList: masterDetails["ClientList"]
            ? masterDetails["ClientList"]
            : [],
          HSNlist: masterDetails["HSNlist"] ? masterDetails["HSNlist"] : [],

          PaymentTermList: masterDetails["PaymentTermList"]
            ? masterDetails["PaymentTermList"]
            : [],
          Incoterms: masterDetails["Incoterms"]
            ? masterDetails["Incoterms"]
            : [],
          SoPaymentList: masterDetails["SoPaymentList"]
            ? masterDetails["SoPaymentList"]
            : [],
          ShippingTerms: masterDetails["ShippingTerms"]
            ? masterDetails["ShippingTerms"]
            : [],
          CurrencyList: masterDetails["CurrencyList"]
            ? masterDetails["CurrencyList"]
            : [],
        },
      });
    }
    console.log(this.state.ShippingTerms, "tabssssssssssssssssssss");
    this.setState(
      {
        pagination:
          data.length > 0
            ? {
                count: props[EndPoints["SalesOrders"]].total_count,
                loadMore: () => {
                  const { selectedMonth, selctedStatus } = this.state;
                  this.props.SalesOrdersFunction(
                    "get",
                    EndPoints["SalesOrders"],
                    null,
                    null,
                    null,
                    {
                      month: selectedMonth
                        ? selectedMonth.getMonth() + 1
                        : undefined,
                      status: selctedStatus,
                    },
                    null,
                    true,
                    data,
                    props[EndPoints["SalesOrders"]].next
                  );
                },
                next: props[EndPoints["SalesOrders"]].next,
                currentLength: data.length,
              }
            : undefined,
        listViewArray:
        data && data.length > 0
          ? data.map((data, index) => {
              return data;
            })
          : [],
        SalesOrders: props[EndPoints["SalesOrders"]],
        tabs,
        loading: props.loading,

        SO_Items_List:
          props.SO_Items_List && props.SO_Items_List.length > 0
            ? props.SO_Items_List
            : [],
      },
      () => {
        const {
          singlelistView,
          listViewArray,

          ItemCateoryList,
          ItemList,
          HSNlist,
        } = this.state;
        if (
          singlelistView &&
          listViewArray.length > 0 &&
          HSNlist &&
          HSNlist.length > 0 &&
          ItemCateoryList &&
          ItemCateoryList.length > 0 &&
          ItemList &&
          ItemList.length > 0
        ) {
          if (this.state.alreadyFetchedData === false) {
            // console.log(listViewArray, "listview1");
            this.props
              .SalesOrdersFunction(
                "get",
                "dispatch/so-items/list",
                null,
                "SO_Items_List",
                null,
                {
                  so_id: this.state.singlelistView.id,
                }
              )
              .then((SO_Items_List) => {
                this.props
                  .SalesOrdersFunction(
                    "get",
                    "dispatch/so-packaging/list",
                    null,
                    "soPackahging",
                    null,
                    {
                      is_active: "True",
                    }
                  )
                  .then((soPackahging) => {
                    if (soPackahging) {
                      this.setState({ soPackahging }, () => {
                        this.setData();
                      });
                    } else {
                      this.setData();
                    }
                  });
              });

            this.setState({ alreadyFetchedData: true });
          }

          const newsinglelistView =
            listViewArray.filter((sLV) => sLV.id === singlelistView.id) &&
            listViewArray.filter((sLV) => sLV.id === singlelistView.id)[0];
          if (newsinglelistView && newsinglelistView.status) {
            if (newsinglelistView.status === this.state.selctedStatus) {
              // this.listClickHandler(newsinglelistView);
            } else {
              this.setState({ singlelistView: null }, () => {
                this.reset("no snac");
              });
            }
          } else {
            this.setState({ singlelistView: null }, () => {
              this.reset("no snac");
            });
          }
        } else if (listViewArray.length > 0) {
          const next = this.props;

          if (next.params && next.params.tab) {
            let tab = next.params.tab;

            if (
              tab === EndPoints["SalesOrders"].split("/")[0] &&
              next.params.id
            ) {
              if (this.state.alreadyFetched === false) {
                this.listClickHandler(this.state.tabs[0].data[next.params.id]);
                this.setState({ alreadyFetched: true });
              }
            } else if (tab === "NEW") {
              this.setState({ singlelistView: null }, () => {
                this.reset("no snac");
              });
            }
          } else {
            this.setState({ singlelistView: null }, () => {
              this.reset("no snac");
            });
          }
        } else {
          // this.setState({ singlelistView: null }, () => {
          //   this.reset("no snac");
          // });
        }
      }
    );
  }

  updateSelectedTabData = (newTabData,tabId, TabIndex) => {
    console.log("CALLED", newTabData, tabId, TabIndex)
    let tabData = [...this.state.tabs]
    this.setState({ loading: true, tabs: []  })
    tabData[0].data = tabData[0].data.map(d => {
      return  d.id === tabId
      ? newTabData
      : d
    })

    this.setState({ tabs: tabData, loading: false, selectedValue: 0 }, () => {
      this.setInitialValues(this.props,tabData[0].data)
      this.listClickHandler(newTabData)
      console.log(this.state.tabs)
    })
    
  }
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };

  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  setWidth = (list, header) => {
    const { soItemheader } = this.state;
    if (list && list.length > 0) {
      if (list[0]) {
        const widthList = {};
        const array = header
          ? header
          : soItemheader
          ? soItemheader
          : Object.keys(list[0]);

        if (array && array.length > 0) {
          array.map((single, i) => {
            if (single) {
              return (widthList[array[i]] = `${
                Math.floor(
                  10 *
                    (single.toString().length > this.longest(single, list)
                      ? single.toString().length
                      : this.longest(single, list)
                      ? this.longest(single, list)
                      : 1)
                ) < 110
                  ? 110
                  : Math.floor(
                      10 *
                        (single.toString().length > this.longest(single, list)
                          ? single.toString().length
                          : this.longest(single, list)
                          ? this.longest(single, list)
                          : 1)
                    )
              }px`);
            } else {
              return single;
            }
          });
        }
        this.setState({ widthList });
      }
    }
  };
  setData = () => {
    const {
      editDialog,
      BrandList,
      ClientList,
      ItemCateoryList,
      ItemList,
      PackagingList,
      PortsList,
      SO_Items_List,
      soItemheader,
      HSNlist,
      singlelistView,
    } = this.state;

    const oldnewPortArr = editDialog.ports ? editDialog.ports : [];
    const newPortArr = [];
    if (PortsList && PortsList.length > 0) {
      PortsList.map((sPack) => {
        if (oldnewPortArr.includes(sPack.id.toString())) {
          return newPortArr.push(sPack);
        } else {
          return null;
        }
      });
    }

    const newSOItemArray = [];
    if (SO_Items_List && SO_Items_List.length > 0) {
      console.log(
        SO_Items_List,
        this.state.soPackahging,
        "so items list & sopackaging"
      );
      SO_Items_List &&
        SO_Items_List.map((sList) => {
          const packaging_details = [];

          const sId = filterData(this.state.soPackahging, "so_items", sList.id)
            ? filterData(this.state.soPackahging, "so_items", sList.id).map(
                (s) => {
                  return s.packaging.toString();
                }
              )
            : [];
          console.log(sList, "sList");
          if (PackagingList && PackagingList.length > 0) {
            PackagingList.map((sPack) => {
              const newObject =
                this.state.soPackahging &&
                filterData(this.state.soPackahging, "so_items", sList.id) &&
                filterData(
                  this.state.soPackahging &&
                    filterData(this.state.soPackahging, "so_items", sList.id),
                  "packaging",
                  sPack.id,
                  "onlyOne"
                )
                  ? filterData(
                      this.state.soPackahging &&
                        filterData(
                          this.state.soPackahging,
                          "so_items",
                          sList.id
                        ),
                      "packaging",
                      sPack.id,
                      "onlyOne"
                    )
                  : null;

              if (sId.includes(sPack.id.toString()) || sId.includes(sPack.id)) {
                sPack.remaining_bags = newObject ? newObject.remaining_bags : 0;
                sPack.used_bags = newObject
                  ? newObject.used_bags
                    ? newObject.used_bags
                    : 0
                  : 0;
                return packaging_details.push(sPack);
              } else {
                return null;
              }
            });
          }

          console.log(packaging_details, "packaging details");
          const addData = {
            brand: sList.brand,

            hsn_code: sList.hsn_code ? sList.hsn_code : "",
            packagingArr: packaging_details.map((sP) => {
              sP.sizeUnit = `${sP.size} ${sP.unit}`;
              return sP;
            }),
            total_quantity: sList.total_quantity,

            packaging: sList.packaging,
          };
          addData["id"] = sList.id;
          addData["remaining_bags"] = sList.remaining_bags;
          addData["dispatched_quantity"] = sList.dispatched_quantity;
          addData[soItemheader[1]] =
            ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemCateoryList.filter((sM) =>
              sM.id === sList.item_category ? sList.item_category : 0
            )[0]
              ? ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name &&
                ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name
              : "";
          addData[`${soItemheader[1]}id`] = sList.item_category;
console.log(sList,"sListsList")
          const spArr = sList["so_specifications"]
            ? sList["so_specifications"]
            : [];
          addData["Specifications"] = spArr;
          addData["specificationArr"] = spArr;

          addData[soItemheader[2]] =
            ItemList &&
            ItemList.length > 0 &&
            ItemList.filter((sM) =>
              sM.id === sList.item_type ? sList.item_type : 0
            )[0]
              ? ItemList.filter((sM) =>
                  sM.id === sList.item_type ? sList.item_type : 0
                )[0].broken_percentage
              : "";
          addData[`${soItemheader[2]}id`] = sList.item_type;

          addData[soItemheader[0]] =
            BrandList &&
            BrandList.length > 0 &&
            BrandList.filter((sM) =>
              sM.id === sList.brand ? sList.brand : 0
            )[0]
              ? BrandList.filter((sM) =>
                  sM.id === sList.brand ? sList.brand : 0
                )[0].brand_name
              : "";
          addData[`${soItemheader[0]}id`] = sList.brand;

          addData.selectedBrandMultipleName = `${
            filterData(BrandList, "id", sList.brand, "onlyOne")
              ? filterData(BrandList, "id", sList.brand, "onlyOne").brand_name
              : ""
          }-${
            filterData(ItemCateoryList, "id", sList.item_category, "onlyOne")
              ? filterData(
                  ItemCateoryList,
                  "id",
                  sList.item_category,
                  "onlyOne"
                ).name
              : ""
          }-${
            filterData(ItemList, "id", sList.item_type, "onlyOne")
              ? filterData(ItemList, "id", sList.item_type, "onlyOne")
                  .broken_percentage
              : ""
          } % `;
          addData[soItemheader[3]] =
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }) &&
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }).length > 0
              ? packaging_details.map((sP) => {
                  return `${sP.size} ${sP.unit}`;
                })[0]
              : "-";
          addData[`${soItemheader[3]}Id`] = packaging_details;
          addData["packagageArrUsed"] = packaging_details
            .map((sP) => {
              const newObj = {};
              newObj.id = sP.id;
              newObj.bag_type = sP.bag_type;
              newObj.size = sP.size;
              newObj.unit = sP.unit;
              newObj.sizeUnit = `${sP.size} ${sP.unit}`;
              newObj.used_bags_so = sP.used_bags ? sP.used_bags : 0;

              newObj.remaining_bags = sP.remaining_bags;
              return newObj;
            })
            .map((s) => {
              s.used_bags = 0;
              return s;
            });
          addData[soItemheader[4]] =
            HSNlist &&
            HSNlist.length > 0 &&
            HSNlist.filter(
              (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
            )[0]
              ? HSNlist.filter(
                  (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
                )[0].hsn_code
              : "";
              console.log(sList,"sListsList")
          addData[soItemheader[7]] = sList.buyers_marking;
          addData[soItemheader[9]] =sList.quantity_tolerance 
          addData[soItemheader[10]] =sList.bag_weight
          addData["quantity_tolerance"] =sList.quantity_tolerance 
          addData["bag_weight"] =sList.bag_weight
          addData[`${soItemheader[4]}id`] = sList.hsn_code
            ? sList.hsn_code
            : "";
          addData[soItemheader[5]] = sList.total_quantity;
          addData[`${soItemheader[5]}Unit`] = `${sList.total_quantity} ${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;

          addData[soItemheader[6]] = sList.contract_rate;

          addData[`${soItemheader[6]}Unit`] = `${sList.contract_rate}/${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;

          addData.id = sList.id;
          addData.so_item_id = sList.id;
          newSOItemArray.push(addData);
          return sList;
        });
    }

    this.setState(
      {
        soItemsArrayTOPreview: newSOItemArray,
        soItemsArrayTOPreviewCopy: newSOItemArray,
        soItemsArrayTOPreviewCopy2: newSOItemArray,
        soItemsArray: [],
        soItemsArrayCopy: [],
        loadingRight: false,
      },
      () => {
        if (
          this.state.soItemsArrayTOPreview &&
          this.state.soItemsArrayTOPreview.length > 0
        ) {
          this.setWidth(this.state.soItemsArrayTOPreview);
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.debouncedFilterApi?.cancel) {
      this.debouncedFilterApi.cancel();
    }
  }

  filterSearchApi = () => {
    const { searchText, selctedStatus, dateSelectedFrom, dateSelectedTo } = this.state;
    console.log(dateSelectedFrom)
    // Call the API function with appropriate query parameters
    this.props.SalesOrdersFunction(
      "get",
      EndPoints["SalesOrders"],
      null,
      null,
      null,
      dateSelectedFrom !== ""
        ? {
            status: selctedStatus,
            search: searchText,
            date_from: dateSelectedFrom,
            date_to: dateSelectedTo,
          }
        : dateSelectedFrom !== "" && !Boolean(searchText)
        ? {
            status: selctedStatus,
            date_from: dateSelectedFrom,
            date_to: dateSelectedTo,
          }
        : searchText
        ? {
            status: selctedStatus,
            search: searchText,
          }
        : { status: selctedStatus }
    );
  };

  handleSOSearch = (data) => {
    this.setState({ searchText: data }, () => {
      this.debouncedFilterSearchApi();
    });
  };
  handleSODateRange = (data) => {
    this.setState({ dateSelectedFrom: data?.startDate, dateSelectedTo: data?.endDate }, 
      () => {
      console.log("dateSelectedFrom", this.state.dateSelectedFrom,data, data?.startDate)
      this.props.SalesOrdersFunction(
        "get",
        EndPoints["SalesOrders"],
        null,
        null,
        null,
        this.state?.searchText && Boolean(data?.startDate) 
        ? {
          status: this.state.selctedStatus,
          search: this.state.searchText,
          date_from: this.state.dateSelectedFrom,
          date_to: this.state.dateSelectedTo,
        }
        : this.state?.searchText && !Boolean(data?.startDate) 
        ? {
          status: this.state.selctedStatus,
          search: this.state.searchText,
        }
        : !Boolean(this.state.searchText) && Boolean(data?.startDate)
        ? {
          status: this.state.selctedStatus,
          date_from: this.state.dateSelectedFrom,
          date_to: this.state.dateSelectedTo,
        }
        : { status: this.state.selctedStatus, }
      );
    })
  }

  render() {
    const {
      loadingRight,
      tabs,
      listViewArray,
      singlelistView,
      loading,
      selctedStatus,
      status,
      submittedDataOfUsedBags,
      loader,
      selectedPVItemArray,
      soItemheader,
      widthList,
      colorArr,
      errorMsg,
      ssId,
      soItemsArrayCopy,
      selectedMonth,
    } = this.state;

    const SOITemTable = (
      edit,
      array,
      noTotalQuantity,
      showTotal,
      width,
      addBag,
      submittedData,
      fromSO,
      editFunct,
      Unit,
      noMargin
    ) => {
      return (
        <div
          style={{
            margin: noMargin ? "0" : "0px 0 20px 0",
            height: "auto",
            width: width ? width : edit ? "100%" : "calc(100% - 30px)",
            minWidth: width && "100%",
            fontSize: "0.9rem",
          }}>
          <Paper
            className="d-flex d-flex-column overflowXA positionR scrollBluePrimary"
            style={{
              height: "auto",
              width: "100%",
              borderRadius: "7px",
            }}>
            <div
              style={{
                backgroundColor: "#8080801a",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}>
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgb(128 128 128 / 64%)" }}>
                {edit === "checkBox" ? (
                  <div
                    onClick={() => {
                      var errorMsg = this.state.errorMsg;
                      delete errorMsg.selectedPVItemArray;
                      if (selectedPVItemArray.length === array.length) {
                        this.setState({ selectedPVItemArray: [], errorMsg });
                      } else {
                        const pvArr1 = [];
                        const totalBag = [];
                        array.map((s) => {
                          pvArr1.push(s.so_item_id);

                          s["packagageArrUsed"].map((sData) => {
                            if (sData.remaining_bags && sData.used_bags) {
                              totalBag.push(sData);
                            } else {
                            }
                          });
                        });
                        if (totalBag.length > 0) {
                          if (pvArr1.length === array.length) {
                            this.setState({
                              selectedPVItemArray: pvArr1,
                              errorMsg,
                            });
                          }
                        } else {
                          this.setState({ error: "Please add rice bags!" });
                        }
                      }
                    }}
                    className="justifyC d-flex alignC"
                    style={{
                      margin: "0px 10px",
                      width: "65px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}>
                    <Checkbox
                      style={{ margin: "0 0 0 10px" }}
                      edge="start"
                      checked={array.length === selectedPVItemArray.length}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": "head" }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {soItemheader &&
                  soItemheader.map((name, i) =>
                    noTotalQuantity && name === "Total Item Quantity" ? (
                      ""
                    ) : (
                      <div
                        key={name}
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "12px 10px",
                          width:
                            widthList && widthList[soItemheader[i]]
                              ? name === "Specifications"
                                ? "180px"
                                : name === "Packaging" && addBag
                                ? `222.08px`
                                : name === "Packaging"
                                ? "135.49px"
                                : widthList[soItemheader[i]]
                              : "fit-content",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}>
                        {name}
                      </div>
                    )
                  )}
                {edit && edit !== "checkBox" && (
                  <div
                    className="justifyC d-flex alignC"
                    style={{
                      margin: "12px 10px",
                      width: "65px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}>
                    Edit
                  </div>
                )}
              </div>
            </div>
            {array && array.length > 0 ? (
              <>
                <div
                  className="scrollBluePrimary"
                  style={{
                    height: "fit-content",
                    overflowY: "auto",
                    width: "fit-content",
                    minWidth: "100%",
                    position: "relative",
                    background: "rgb(128 128 128 / 10%)",
                  }}>
                  {array.map(
                    (singleDate, i) =>
                      singleDate && (
                        <div
                          key={`${i}tal`}
                          className="d-flex width-100 d-flex-column">
                          <div className="d-flex width-100 d-flex-column justifySE">
                            <div
                              className="d-flex width-100 justifySE dividerBorder"
                              style={{
                                backgroundColor:
                                  colorArr && colorArr[i % colorArr.length],
                                height: edit === "checkBox" ? "44px" : "35px",
                              }}>
                              {console.log(singleDate, "singledate")}
                              {edit === "checkBox" ? (
                                <div
                                  className="d-flex alignC justifyC minWidthFC"
                                  style={{
                                    margin: "0 10px",
                                    width: "65px",
                                    // minWidth: '65px',
                                    textTransform: "capitalize",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    const errorMsg = this.state.errorMsg;
                                    delete errorMsg.soItemsArrayTOPreview;
                                    const currentIndex =
                                      selectedPVItemArray.indexOf(
                                        singleDate.so_item_id
                                      );

                                    const newChecked = [...selectedPVItemArray];

                                    if (currentIndex === -1) {
                                      newChecked.push(singleDate.so_item_id);
                                    } else {
                                      newChecked.splice(currentIndex, 1);
                                    }

                                    const totalBag = [];
                                    singleDate["packagageArrUsed"].map(
                                      (sData) => {
                                        if (
                                          sData.remaining_bags &&
                                          sData.used_bags
                                        ) {
                                          totalBag.push(sData);
                                        } else {
                                        }
                                      }
                                    );
                                    if (totalBag.length > 0) {
                                      this.setState({
                                        selectedPVItemArray: newChecked,
                                        errorMsg,
                                      });
                                    } else {
                                      this.setState({
                                        error: "Please add rice bags!",
                                      });
                                    }
                                  }}>
                                  {errorMsg.soItemsArrayTOPreview ? (
                                    <Checkbox
                                      style={{
                                        margin: "0 0 0 10px",
                                        border: "1px solid red",
                                      }}
                                      edge="start"
                                      checked={
                                        selectedPVItemArray.indexOf(
                                          singleDate.so_item_id
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby":
                                          singleDate.so_item_id,
                                      }}
                                    />
                                  ) : (
                                    <Checkbox
                                      style={{ margin: "0 0 0 10px" }}
                                      edge="start"
                                      checked={
                                        selectedPVItemArray.indexOf(
                                          singleDate.so_item_id
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby":
                                          singleDate.so_item_id,
                                      }}
                                    />
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {soItemheader &&
                                soItemheader.map((name) =>
                                  noTotalQuantity &&
                                  name === "Total Item Quantity" ? (
                                    ""
                                  ) : (
                                    <div
                                      key={name}
                                      className="d-flex alignC justifyC minWidthFC"
                                      style={{
                                        margin: "12px 10px",
                                        width:
                                          widthList && widthList[name]
                                            ? name === "Specifications"
                                              ? "180px"
                                              : name === "Packaging" && addBag
                                              ? `222.08px`
                                              : name === "Packaging"
                                              ? "135.49px"
                                              : widthList[name]
                                            : "fit-content",
                                        minWidth:
                                          widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        textTransform: "capitalize",
                                      }}>
                                      {console.log(
                                        "name",
                                        name,
                                        singleDate[name],
                                        singleDate,
                                        singleDate[`packagageArrUsed`]
                                      )}
                                      {name === "Packaging" &&
                                      singleDate[name] ? (
                                        <ShowPackaging
                                          // addBag={addBag}
                                          noTotalbags={true}
                                          returned_bags={false}
                                          noBags={true}
                                          details={
                                            singleDate[`packagageArrUsed`] &&
                                            singleDate[`packagageArrUsed`]
                                              .length > 0
                                              ? singleDate[
                                                  `packagageArrUsed`
                                                ].map((sP) => {
                                                  sP.new_remaining_bags =
                                                    subtract(
                                                      this.state
                                                        .editingDispatch !==
                                                        false
                                                        ? sum([
                                                            sP.remaining_bags,
                                                            sP.used_bags_fordiapatch,
                                                          ])
                                                        : sP.remaining_bags,
                                                      sP.used_bags
                                                        ? sP.used_bags
                                                        : 0
                                                    );
                                                  if (edit === "checkBox") {
                                                  } else {
                                                    sP.bag_type = sP.bag_type
                                                      ? sP.bag_type
                                                      : filterData(
                                                          singleDate.packagingArr,
                                                          "packaging",
                                                          sP.packaging,
                                                          "onlyOne"
                                                        )
                                                      ? filterData(
                                                          singleDate.packagingArr,
                                                          "packaging",
                                                          sP.packaging,
                                                          "onlyOne"
                                                        ).packaging_details
                                                          .bag_type &&
                                                        filterData(
                                                          singleDate.packagingArr,
                                                          "packaging",
                                                          sP.packaging,
                                                          "onlyOne"
                                                        ).packaging_details
                                                          .bag_type
                                                      : "";

                                                    sP.used_bags = fromSO
                                                      ? sP.used_bags_so
                                                      : sP.used_bags
                                                      ? sP.used_bags
                                                      : sP.used_bags_so
                                                      ? sP.used_bags_so
                                                      : 0;
                                                  }
                                                  return sP;
                                                })
                                              : []
                                          }
                                        />
                                      ) : name === "Specifications" &&
                                        singleDate[name] ? (
                                        <ShowSpecifications
                                          details={
                                            singleDate[name] &&
                                            singleDate[name].length > 0
                                              ? singleDate[name].map((sP) => {
                                                  // sP.used_bags =0
                                                  const ss=sP.nameId?Number(sP.nameId):sP.name
                                                  const filterS=this.state.specifiationTypes.find((st)=>st.id===ss)
                                                 return {...sP,name:filterS?`${filterS.text.toLowerCase()}`:"",
                                                 value:filterS?`${sP.value} ${filterS.unit.toLowerCase()}`:sP.value};
                                                })
                                              : []
                                          }
                                        />
                                      ) : singleDate[name] ? (
                                        singleDate[`${name}Unit`] ? (
                                          singleDate[`${name}Unit`]
                                        ) : name === "Item Type" ? (
                                          `${singleDate[name]} %`
                                        ) : (
                                          singleDate[name]
                                        )
                                      ) : (
                                        "-"
                                      )}

                                      {name === "Packaging" && addBag ? (
                                        <StyledButton
                                          onClick={() => {
                                            const newData =
                                              submittedDataOfUsedBags;
                                            newData[singleDate.so_item_id] =
                                              singleDate["packagageArrUsed"]
                                                ? singleDate["packagageArrUsed"]
                                                : [];
                                            const totalBag = [];
                                            singleDate["packagageArrUsed"].map(
                                              (sData) => {
                                                if (sData.remaining_bags) {
                                                  this.setState({
                                                    [`${sData.id}Remaining`]:
                                                      this.state
                                                        .editingDispatch !==
                                                      false
                                                        ? sum([
                                                            sData.remaining_bags,
                                                            sData.used_bags_fordiapatch,
                                                          ])
                                                        : sData.remaining_bags,
                                                  });
                                                  totalBag.push(sData);
                                                } else {
                                                }
                                                return sData;
                                              }
                                            );
                                            if (totalBag.length > 0) {
                                              this.setState({
                                                enteredDispatchdData:
                                                  submittedData,
                                                openAddBagDialog: singleDate,
                                                submittedDataOfUsedBags:
                                                  newData,
                                              });
                                            } else {
                                              this.setState({
                                                error: "Please add rice bags!",
                                              });
                                            }
                                          }}
                                          name={
                                            addBag === "showbagOnly"
                                              ? "Edit Bags"
                                              : "add bags"
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )
                                )}
                              {edit && edit !== "checkBox" && (
                                <div
                                  className="d-flex alignC justifyC minWidthFC"
                                  style={{
                                    margin: "12px 10px",
                                    width: "65px",
                                    minWidth: "50px",
                                    textTransform: "capitalize",
                                  }}>
                                  <StyledButton
                                    tooltip="Edit"
                                    name={<EditIcon />}
                                    width="60px"
                                    onClick={() => {
                                      if (editFunct) {
                                        editFunct(singleDate);
                                      } else {
                                        const newArr = [];
                                        soItemsArrayCopy.map((singleItem) => {
                                          if (
                                            singleItem.so_item_id ===
                                            singleDate.so_item_id
                                          ) {
                                          } else {
                                            newArr.push(singleItem);
                                          }
                                          return singleItem;
                                        });
                                        this.setState(
                                          {
                                            selectedSoid: singleDate.so_item_id,
                                            itemsId: singleDate["Item Typeid"],
                                            ItemType: singleDate["Item Type"],
                                            ItemCategoryId:
                                              singleDate["Item Categoryid"],
                                            ItemCategory:
                                              singleDate["Item Category"],
                                            packagingArr:
                                              singleDate.packagingArr,
                                            soItemsArray: newArr,
                                            soItemsArrayCopy: soItemsArrayCopy,
                                            BrandNameId: singleDate["Brandid"],
                                            BrandName: singleDate["Brand"],
                                            HSN_id: singleDate.hsn_code,
                                            HSN: singleDate["HSN Code"],
                                            total_quantity:
                                              singleDate["Total Item Quantity"],
                                            Rate: singleDate["Contract Rate"],
                                            selectedBrandMultipleName: `${singleDate["Brand"]}-${singleDate["Item Category"]}-${singleDate["Item Type"]} % `,
                                            // Quantity Tolerance:singleDate.Quantity Tolerance
                                          },
                                          () => {}
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            {/* <Divider className="width-100" /> */}
                          </div>

                          {/* <Divider /> */}
                        </div>
                      )
                  )}
                </div>
                {showTotal && (
                  <div className="d-flex alignC width-100">
                    {edit === "checkBox" ? (
                      <div
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "0px 10px",
                          width: "65px",
                          minWidth: "65px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}>
                        {selectedPVItemArray.length === 0
                          ? ""
                          : selectedPVItemArray.length}
                      </div>
                    ) : (
                      ""
                    )}
                    {soItemheader &&
                      soItemheader.map((name, i) =>
                        noTotalQuantity && name === "Total Item Quantity" ? (
                          ""
                        ) : (
                          <div
                            key={name}
                            className="justifyC d-flex alignC"
                            style={{
                              margin: "12px 10px",
                              width:
                                widthList && widthList[name]
                                  ? name === "Specifications"
                                    ? "180px"
                                    : name === "Packaging" && addBag
                                    ? `222.08px`
                                    : name === "Packaging"
                                    ? "135.49px"
                                    : widthList[name]
                                  : "fit-content",
                              minWidth:
                                widthList && widthList[name]
                                  ? name === "Specifications"
                                    ? "180px"
                                    : name === "Packaging" && addBag
                                    ? `222.08px`
                                    : name === "Packaging"
                                    ? "135.49px"
                                    : widthList[name]
                                  : "fit-content",
                              display: "flex",
                              alignItems: "center",
                              color: "#100f0f",
                              textTransform: "capitalize",
                              flexDirection:
                                name === "Packaging" && addBag && "column",
                            }}>
                            {name === "Packaging" && addBag ? (
                              <>
                                {`${selectedPVItemArray
                                  .reduce(
                                    (previousValue, currentValue) =>
                                      Number(previousValue) +
                                      Number(
                                        filterData(
                                          array,
                                          "so_item_id",
                                          currentValue,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              array,
                                              "so_item_id",
                                              currentValue,
                                              "onlyOne"
                                            )["packagageArrUsed"]
                                            ? filterData(
                                                array,
                                                "so_item_id",
                                                currentValue,
                                                "onlyOne"
                                              )["packagageArrUsed"].reduce(
                                                (
                                                  previousValue1,
                                                  currentValue1
                                                ) =>
                                                  Number(previousValue1) +
                                                  (currentValue1.total_dispatch
                                                    ? Number(
                                                        currentValue1.total_dispatch
                                                      )
                                                    : 0),
                                                0
                                              )
                                            : 0
                                          : 0
                                      ),
                                    0
                                  )
                                  .toFixed(3)}${
                                  singlelistView && singlelistView.unit
                                    ? singlelistView.unit
                                    : ""
                                }   `}
                                <div
                                  style={{
                                    color: "#FF3D00",
                                    minWidth: "334px",
                                  }}>
                                  {submittedData["Net Weight"] ===
                                  selectedPVItemArray.reduce(
                                    (previousValue, currentValue) =>
                                      Number(previousValue) +
                                      Number(
                                        filterData(
                                          array,
                                          "so_item_id",
                                          currentValue,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              array,
                                              "so_item_id",
                                              currentValue,
                                              "onlyOne"
                                            )["packagageArrUsed"]
                                            ? filterData(
                                                array,
                                                "so_item_id",
                                                currentValue,
                                                "onlyOne"
                                              )["packagageArrUsed"].reduce(
                                                (
                                                  previousValue1,
                                                  currentValue1
                                                ) =>
                                                  Number(previousValue1) +
                                                  (currentValue1.total_dispatch
                                                    ? Number(
                                                        currentValue1.total_dispatch
                                                      )
                                                    : 0),
                                                0
                                              )
                                            : 0
                                          : 0
                                      ),
                                    0
                                  )
                                    ? ""
                                    : selectedPVItemArray.length > 0
                                    ? singlelistView &&
                                      singlelistView.contract_type_details &&
                                      singlelistView.contract_type_details
                                        .contract_type === "ex factory"
                                      ? "(Net Weight and Total Quantity is not same!)"
                                      : ""
                                    : ""}
                                </div>
                              </>
                            ) : i === 0 ? (
                              <span style={{ fontWeight: "500" }}>
                                {"Total"}
                              </span>
                            ) : i === 6 ? (
                              " "
                            ) : i === 5 ? (
                              `${array.reduce(
                                (previousValue1, currentValue1) =>
                                  Number(previousValue1) +
                                  (currentValue1["Total Item Quantity"]
                                    ? Number(
                                        currentValue1["Total Item Quantity"]
                                      )
                                    : 0),
                                0
                              )} ${Unit} `
                            ) : (
                              ""
                            )}
                          </div>
                        )
                      )}
                    {edit === "checkBox"
                      ? ""
                      : edit && (
                          <div
                            className="justifyC d-flex alignC"
                            style={{
                              margin: "12px 10px",
                              width: "65px",
                              display: "flex",
                              alignItems: "center",
                              color: "#100f0f",
                              textTransform: "capitalize",
                            }}></div>
                        )}
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                  No SO Item added !
                </span>
              </div>
            )}
          </Paper>
        </div>
      );
    };

    // const { isMobile ,user} = this.props;
    var labelField = "title";
    console.log(singlelistView, "singlelistview");
    // console.log(listViewArray, "listview2");

    if (listViewArray && listViewArray.length > 0 && listViewArray[0]) {
      Object.keys(listViewArray[0]).map((sKey) => {
        if (typeof listViewArray[0][sKey] === "string") {
          return (labelField = sKey);
        } else {
          return null;
        }
      });
      // labelField="" //assign search field
    }
    const detailDialog = (onlyDialog, addendum) => {
      return (
        <DetailsSo
          onlyDialog={onlyDialog ? onlyDialog : true}
          {...this.props}
          {...this.state}
          SOITemTable={SOITemTable}
          closeDialog={(id) => {
            this.setState({
              openDialog: false,
            });
            // this.props.SalesOrdersFunction(
            //   "get",
            //   EndPoints["SalesOrders"],
            //   null,
            //   null,
            //   null,
            //   selctedStatus === "CLOSED" && selectedMonth
            //     ? {
            //         month: selectedMonth.getMonth() + 1,
            //         status: selctedStatus,
            //       }
            //     : {
            //         status: selctedStatus,
            //       }
            if(id){
            this.props.SalesOrdersFunction(
              "get",
              `dispatch/sales-order/${id}`,
              null,
              null,
              null,
              null,
            ).then((resp) => {
              this.updateSelectedTabData(resp, resp.id, window.location.pathname.split("/")[3])
            });
            }
          }}
          setSoItems={(soItemsArrayCopy, soItemsArray) => {
            console.log("2set", soItemsArrayCopy, soItemsArray)
            this.setState(
              soItemsArrayCopy && soItemsArray
                ? {
                    soItemsArrayCopy,
                    soItemsArray,
                  }
                : soItemsArrayCopy
                ? { soItemsArrayCopy }
                : soItemsArray
                ? { soItemsArray }
                : {},
              () => {
                this.setWidth(this.state.soItemsArray);
              }
            );
          }}
          setSoItemsPrev={(
            soItemsArrayTOPreview,
            soItemsArrayTOPreviewCopy,
            soItemsArrayTOPreviewCopy2
          ) => {
            this.setState({
              soItemsArrayTOPreview,
              soItemsArrayTOPreviewCopy,
              soItemsArrayTOPreviewCopy2,
            });
          }}
        />
      );
    };
    // const SOITemTable = (
    //   edit,
    //   array,
    //   noTotalQuantity,
    //   showTotal,
    //   width,
    //   addBag,
    //   submittedData,
    //   fromSO,
    //   editFunct,
    //   Unit
    // ) => {
    //   return (
    //     <div
    //       style={{
    //         margin: "20px 0 20px 0",
    //         height: "auto",
    //         width: width ? width : edit ? "100%" : "calc(100% - 30px)",
    //         minWidth: width && "100%",
    //         fontSize: "0.9rem",
    //       }}
    //     >
    //       <Paper
    //         className="d-flex d-flex-column  overflowXA positionR scrollBluePrimary"
    //         style={{
    //           height: "auto",
    //           width: "100%",
    //           borderRadius: "7px",
    //         }}
    //       ></Paper>
    //     </div>
    //   );
    // };
    return (
      <Layout
        pagination={this.state.pagination}
        // RaisedMonthBar={
        //   selctedStatus === "CLOSED"
        //     ? { hintText: "Search Months", labelField: "month" }
        //     : null
        // }
        // loading={loading.Posts}
        loading={
          loading[EndPoints["SalesOrders"]]
            ? loading[EndPoints["SalesOrders"]] === true
              ? true
              : loader === true
              ? true
              : false
            : loader === true
            ? true
            : false
        }
        tabs={tabs}
        noTab={true}
        RaisedSelectBar={{
          data: status ? status : [],
          hintText: "Select status",
          labelField: "name",
          selctedData: "OPEN",
        }}
        fabClickHandler={(value) => {
          this.setState(
            {
              soItemsArray: [],
              soItemsArrayCopy: [],
              openDialog: true,
              requiredToggle: true,
              loadingFun: false,
              added: false,
              editDialog: false,
              BrandName: false,
              selectedBrandMultipleName: false,
            },
            () => {
              this.reset("no snac");
              this.handleClearFields();
            }
          );
        }}
        // fabClickHandler={
        //   loader === true
        //     ? false
        //     : (value) => {
        //         this.setState({
        //           openDialog: true,
        //           loadingFun: false,
        //           // selctedtMiller: false,
        //           // selectedReceiver: false,
        //           // editList: false,
        //         });
        //       }
        // }
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        selectHandler={this.selectHandler}
        searchData={{
          search: true,
          searchPlaceholder: 'Search SO',
          onChangeSearchSelect: this.handleSOSearch,
        }}
        dateRange={{
          datePicker: true,
          onChangeDateSelect: this.handleSODateRange,
        }}
        selectedStatus={this.state?.selctedStatus}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px">
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {singlelistView === null ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                }}>
                {detailDialog()}
                <div>
                  <NoData />
                </div>
              </div>
            </>
          ) : loading.SalesOrders === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="rgb(241, 154, 48)" />
            </div>
          ) : loadingRight === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="rgb(241, 154, 48)" />
            </div>
          ) : (
            <>
              <DetailsSo
                {...this.props}
                {...this.state}
                SOITemTable={SOITemTable}
                closeDialog={(id) => {
                  this.setState({
                    openDialog: false,
                  });
                  if(id){
                  this.props.SalesOrdersFunction(
                    "get",
                    `dispatch/sales-order/${id}`,
                    null,
                    null,
                    null,
                    null,
                  ).then((resp) => {
                    this.updateSelectedTabData(resp, resp.id, window.location.pathname.split("/")[3])
                  });
                  }
                  // this.props.SalesOrdersFunction(
                  //   "get",
                  //   EndPoints["SalesOrders"],
                  //   null,
                  //   null,
                  //   null,
                  //   selctedStatus === "CLOSED" && selectedMonth
                  //     ? {
                  //         month: selectedMonth.getMonth() + 1,
                  //         status: selctedStatus,
                  //       }
                  //     : {
                  //         status: selctedStatus,
                  //       }
                  // )
                }}
                setSoItems={(soItemsArrayCopy, soItemsArray) => {
                  console.log("1set", soItemsArrayCopy, soItemsArray)
                  this.setState(
                    soItemsArrayCopy && soItemsArray
                      ? {
                          soItemsArrayCopy,
                          soItemsArray,
                        }
                      : soItemsArrayCopy
                      ? { soItemsArrayCopy }
                      : soItemsArray
                      ? { soItemsArray }
                      : {},
                    () => {
                      this.setWidth(this.state.soItemsArray);
                    }
                  );
                }}
                setSoItemsPrev={(
                  soItemsArrayTOPreview,
                  soItemsArrayTOPreviewCopy,
                  soItemsArrayTOPreviewCopy2
                ) => {
                  this.setState(
                    {
                      soItemsArrayTOPreview,
                      soItemsArrayTOPreviewCopy,
                      soItemsArrayTOPreviewCopy2,
                    },
                    () => {
                      // if (this.state.soItemsArray.lenght > 0) {
                      this.setWidth(this.state.soItemsArrayTOPreview);
                      // }
                    }
                  );
                }}
              />
            </>
            // {
            //   name: "Dispatches",
            //   component: <></>,
            // },

            // {
            //   name: "Summary",
            //   component: (
            //     <SummaryTable
            //       summaryData={summaryData}
            //       {...this.state}
            //       {...this.props}
            //     />
            //   ),
            // },
          )}
        </Paper>
      </Layout>
    );
  }
}

export default SalesOrdersComponent;
