import React, { useEffect, useState } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import SubMenuList from "../layoutComponents/SubMenuList";
import { mediumScreen } from "../Regex";

const styles = {
  container: (isMobile, centerWidth) => ({
    marginTop: "55px",
    height: "calc(100% - 52px)",//isMobile ? 0 : "calc(100% - 64px)",
    width: isMobile 
    ? "100%" 
    : mediumScreen() ? 175 
    : centerWidth ? "230px" : 291.47,
    minWidth: isMobile ? "100%" 
    : mediumScreen() ? 175 
    : centerWidth ? centerWidth : 270,
    maxWidth: isMobile ? "100%" 
    : mediumScreen() ? 175 
    : centerWidth ? "230px" : 291.47,
    // marginTop: isMobile ? 0 : 64,
    position: "relative",
    borderRight: centerWidth && "1px solid rgb(30 53 89 / 12%)",
    // display: mediumScreen() ? "flex" : 'block',
    // flexDirection: mediumScreen() ? "column" : 'inherit',
    // flexWrap: mediumScreen() ? "nowrap" : 'inherit',
  }),
  fab: { position: "absolute", bottom: 16, right: 16,color:"white" },
};

function SubMenuLayout(props) {
  const [loading, setloading] = useState(false);
  const fabHandler = (e) => {
    e.preventDefault();
    if (props.setOpen) props.setOpen(false);
    props.fabClickHandler();
  };
  useEffect(() => {
    setloading(props.loading ? props.loading : false);
  }, [props.loading]);

  return (
    <div style={styles.container(props.isMobile, props.centerWidth)}>
      
        <SubMenuList {...props} />
      {props.tabs && props.fabClickHandler ? (
        loading === true ? (
          ""
        ) : (
          <Fab style={styles.fab} color="primary" aria-label="add" size="medium" onClick={fabHandler}>
            <AddIcon />
          </Fab>
        )
      ) : (
        ""
      )}
    </div>
   
  );
}

export default SubMenuLayout;
