// import React, { Component } from "react";
// import Layout from "../../layouts/Layout";
// import { Paper, Tooltip } from "@material-ui/core";
// import { EndPoints } from "../../utils/EndPoints";
// import { color1, lightGrey, color4, color3, primary, color5 } from "../../styles/colors";

// import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
// import CreateDialog from "../../layoutComponents/CreateDialog";
// import { CssTextField, HeadingLine, StyledButton, StyledCircularProgress } from "../../styles/StyledComponents";
// import NoData from "../../styles/Illustrations/NoData";
// import EditIcon from "@mui/icons-material/Edit";
// import ConfirmDialog from "../../layoutComponents/ConfirmDialog";
// import moment from "moment";
// import { filterData } from "../../Regex";
// export class UsersAndPermissionsComponent extends Component {
//   constructor(props) {
//     const templateTabs = [
//       {
//         label: "Pending",
//         tooltip: `Click to approve pending ${EndPoints["UsersAndPermissions"]}`,
//         data: [],
//         route: `/UsersAndPermissions/${EndPoints["UsersAndPermissions"].split("/")[0]}`,
//         goBackRoute: "/UsersAndPermissions",
//         colorArr: ["white", lightGrey],
//         primaryField: "name",
//         secondaryField: "email",
//         unit: true,
//         avatarField: "src",
//         progressBar: true,
//         dispatchPackagingArray: [],
//         decorators: {
//           conditionField: "status",
//           options: ["pending", "approved", "unidentified"],
//           colors: ["yellow", "#1a9520", "#FF3D00"],
//         },
//       },
//     ];
//     super(props);
//     this.state = {
//       tabs: templateTabs,
//       listViewArray: [],
//       loading: false,
//       openRoles: false,
//       openUsers: false,
//       allRoles: [],
//       internalUsers: [],
//       selectedRole: false,
//       selctedTab: "All Modules",
//       selctedStatus: "approved",
//       status: [{ name: "approved" }, { name: "pending" }, { name: "CANCELLED" }],
//       openDialog: false,
//       editList: false,
//       changePassword: false,
//       modulesArr: [
//         { id: 0, name: "Purchase Orders" },
//         { id: 1, name: "Ports" },
//         { id: 2, name: "Sales Orders" },
//         { id: 3, name: "Transporters" },
//         { id: 4, name: "Brokers" },
//         { id: 5, name: "Millers" },
//         { id: 6, name: "Brands Management" },
//         { id: 7, name: "Users & Permissions" },
//         { id: 8, name: "Masters" },
//       ],
//       modulesArrUser: [
//         { id: 0, name: "Purchase Orders" },
//         { id: 1, name: "Ports" },
//         { id: 2, name: "Sales Orders" },
//         { id: 3, name: "Transporters" },
//         { id: 4, name: "Brokers" },
//         { id: 5, name: "Millers" },
//         { id: 6, name: "Brands Management" },
//         { id: 8, name: "Masters" },
//       ],
//       passLoader: false,
//       passArr: [
//         {
//           name: "Old Password",
//           type: "textField",
//           api: "old_password",
//         },
//         {
//           name: "New Password",
//           type: "textField",
//           api: "password",
//           validate: (submittedData) => {
//             if (submittedData["New Password"] === submittedData["Confirm New Password"]) {
//               return true;
//             } else {
//               return false;
//             }
//           },
//         },
//         {
//           name: "Confirm New Password",
//           type: "textField",
//           api: "c_password",
//           validate: (submittedData) => {
//             if (submittedData["New Password"] === submittedData["Confirm New Password"]) {
//               return true;
//             } else {
//               return false;
//             }
//           },
//         },
//       ],
//       submittedPass: {},
//       errorMsg: {},
//     };
//   }
//   getId = (name) => {
//     switch (name) {
//       case "Purchase Orders":
//         return 0;
//       case "Ports":
//         return 1;
//       case "Sales Orders":
//         return 2;
//       case "Transporters":
//         return 3;
//       case "Brokers":
//         return 4;
//       case "Millers":
//         return 5;
//       case "Brands Management":
//         return 6;
//       case "Users & Permissions":
//         return 7;
//       case "Masters":
//         return 8;
//       default:
//     }
//   };
//   setInitialValues(next) {
//     const tabs = this.state.tabs.map((e, i) => {
//       const tab = e;
//       tab.data =
//         next[EndPoints["UsersAndPermissions"]] && next[EndPoints["UsersAndPermissions"]].length > 0
//           ? next[EndPoints["UsersAndPermissions"]]
//               .map((s) => {
//                 return {
//                   ...s,
//                   name: `${s.first_name ? s.first_name : ""} ${s.last_name ? s.last_name : ""}`,
//                   email: s.email,
//                 };
//               })
//               .sort((a, b) => {
//                 var textA = a.name.toUpperCase();
//                 var textB = b.name.toUpperCase();
//                 return textA < textB ? -1 : textA > textB ? 1 : 0;
//               })
//           : [];
//       return tab;
//     });
//     this.setState({
//       tabs,
//       listViewArray:
//         next[EndPoints["UsersAndPermissions"]] && next[EndPoints["UsersAndPermissions"]].length > 0
//           ? next[EndPoints["UsersAndPermissions"]]
//               .map((s) => {
//                 return {
//                   ...s,
//                   name: `${s.first_name ? s.first_name : ""} ${s.last_name ? s.last_name : ""}`,
//                   email: s.email,
//                 };
//               })
//               .sort((a, b) => {
//                 var textA = a.name.toUpperCase();
//                 var textB = b.name.toUpperCase();
//                 return textA < textB ? -1 : textA > textB ? 1 : 0;
//               })
//           : [],
//       loading: next.loading,
//     });
//   }
//   UNSAFE_componentWillReceiveProps(next) {
//     this.setInitialValues(next);
//   }
//   UNSAFE_componentWillMount() {
//     this.fetchRolesList();
//     this.setInitialValues(this.props);
//   }

//   fetchRolesList = () => {
//     this.setState({ loading: true });
//     this.props.BrandsManagementsFunction("get", "users-and-permissions/roles", null, "allRoles").then((allRoles) => {
//       this.setState({ allRoles: allRoles && allRoles.length > 0 ? allRoles : [], loading: false });
//     });
//   };
//   setRoleUser = (role, userId) => {
//     this.props
//       .BrandsManagementsFunction("post", "users-and-permissions/roles-to-users", null, "postdata", {
//         role: role,
//         assigned_to: userId,
//       })
//       .then((res) => {
//         this.setState({ loading: false });
//       });
//   };
//   // setModuleUser = (role, userId) => {
//   //   this.props
//   //     .BrandsManagementsFunction("post", "users-and-permissions/roles-to-users", null, "postdata", {
//   //       role: role,
//   //       assigned_to: userId,
//   //     })
//   //     .then((res) => {
//   //       this.setState({ loading: false });
//   //     });
//   // };

//   // fetchModulesList = () => {
//   //   this.setState({ loading: true });
//   //   this.props
//   //     .BrandsManagementsFunction("get", "users-and-permissions/modules", null, "allModules")
//   //     .then((allModules) => {
//   //       this.setState({ allModules: allModules && allModules.length > 0 ? allModules : [], loading: false });
//   //     });
//   // };
//   // fetchRoleToModuleList = (selectedRole) => {
//   //   this.setState({ loading: true });
//   //   this.props.BrandsManagementsFunction("get", "users-and-permissions/modules-to-roles").then((allRoleModules) => {
//   //     this.setState({
//   //       allRoleModules:
//   //         allRoleModules && allRoleModules.length > 0
//   //           ? selectedRole
//   //             ? filterData(allRoleModules, "role", selectedRole.id)
//   //               ? filterData(allRoleModules, "role", selectedRole.id)
//   //               : []
//   //             : allRoleModules
//   //           : [],
//   //       loading: false,
//   //     });
//   //   });
//   // };

//   // fetchRoleToUsersList = (selectedRole) => {
//   //   this.setState({ loading: true });
//   //   this.props.BrandsManagementsFunction("get", "users-and-permissions/roles-to-users").then((allRoleUsers) => {
//   //     this.setState({
//   //       allRoleUsers:
//   //         allRoleUsers && allRoleUsers.length > 0
//   //           ? selectedRole
//   //             ? filterData(allRoleUsers, "role", selectedRole.id)
//   //               ? filterData(allRoleUsers, "role", selectedRole.id)
//   //               : []
//   //             : allRoleUsers
//   //           : [],
//   //       loading: false,
//   //     });
//   //   });
//   // };
//   // fetchInternalUsersList = () => {
//   //   this.setState({ loading: true });
//   //   this.props
//   //     .BrandsManagementsFunction("get", "users-and-permissions/internal-users", null, "internalUsers")
//   //     .then((internalUsers) => {
//   //       this.setState({
//   //         internalUsers: internalUsers && internalUsers.length > 0 ? internalUsers : [],
//   //         loading: false,
//   //       });
//   //     });
//   // };

//   listClickHandler = (value) => {
//     this.setState({
//       singlelistView: value,
//     });
//   };
//   searchHandler = (value) => {
//     this.listClickHandler(value);
//   };
//   render() {
//     const {
//       loading,
//       allRoles,
//       tabs,
//       listViewArray,
//       openDialog,
//       editList,
//       modulesArr,
//       modulesArrUser,
//       singlelistView,
//       changePassword,
//       passLoader,
//       passArr,
//       submittedPass,
//       errorMsg,
//     } = this.state;

//     return (
//       <Layout
//         loading={
//           loading[EndPoints["UsersAndPermissions"]]
//             ? loading[EndPoints["UsersAndPermissions"]] === true
//               ? true
//               : false
//             : false
//         }
//         tabs={tabs}
//         noTab={true}
//         RaisedSearchBar={{
//           data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
//           hintText: "Search Users",
//           labelField: "name",
//         }}
//         listClickHandler={this.listClickHandler}
//         fabClickHandler={() => {
//           this.setState({ openDialog: true, editList: false });
//         }}
//         serchClick={this.searchHandler}
//         centerWidth="250px"
//         openWidth="485px"
//         closeWidth="307px"
//       >
//         <Paper className="width-100 height-100 padding20 backgroundWhitef">
//           <div className="d-flex width-100 height-100 d-flex-column">
//             {openDialog === true ? (
//               <CreateDialog
//                 module={"Users"}
//                 closeOnSave={true}
//                 selectedList={editList ? editList : false}
//                 editList={editList ? editList : false}
//                 handleClose={() => {
//                   this.setState({ openDialog: false });
//                 }}
//                 fetchAgain={() => {
//                   this.props.BrandsManagementsFunction("get", EndPoints["UsersAndPermissions"]).then((res) => {
//                     if (res) {
//                       console.log(res, filterData(res, "id", Number(singlelistView.id), "onlyOne"), "refilshs");
//                       this.setState({
//                         singlelistView: filterData(res, "id", Number(singlelistView.id), "onlyOne")
//                           ? filterData(res, "id", Number(singlelistView.id), "onlyOne")
//                           : singlelistView,
//                       });
//                     }
//                   });
//                 }}
//                 addApi2={
//                   editList
//                     ? false
//                     : {
//                         api: "users",
//                         formatData: (data, res) => {
//                           return {
//                             phone: data["Phone number"],
//                             modules_access: data["Module Permissions"].map((s) => {
//                               return s.name;
//                             }),
//                             role: data["Role"],
//                             status: data["Status"],
//                             id: res.user_id,
//                             first_name: data["First Name"],
//                             email: data["Email"],
//                             date_of_birth: moment(data["Date of Birth"]).format("YYYY-MM-DD"),
//                           };
//                         },
//                       }
//                 }
//                 postApi="registration/user"
//                 editApi="users"
//                 textFieldWidth="calc(50% - 20px)"
//                 textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
//                 heightNew="calc(100vh - 80px)"
//                 maxHeight="calc(100vh - 80px)"
//                 widthNew="800px"
//                 inputArray={[
//                   // {
//                   //   name: "Active",
//                   //   type: "toggle",
//                   //   defaultValue: {
//                   //     value: editList ? editList.is_active : true,
//                   //   },
//                   //   api: "is_active",
//                   // },
//                   {
//                     name: "First Name",
//                     type: "textField",
//                     dataType: "text",
//                     api: "first_name",
//                     defaultValue: {
//                       value: editList && editList.first_name ? editList.first_name : "",
//                     },
//                   },
//                   {
//                     name: "Last Name",
//                     type: "textField",
//                     dataType: "text",
//                     not_required: true,
//                     api: "last_name",
//                     defaultValue: {
//                       value: editList && editList.last_name ? editList.last_name : "",
//                     },
//                   },
//                   {
//                     name: "Date of Birth",
//                     type: "Month",
//                     dataType: "text",
//                     api: "date_of_birth",
//                     defaultValue: {
//                       value: editList && editList.date_of_birth ? editList.date_of_birth : new Date(),
//                     },
//                   },
//                   {
//                     name: "Phone number",
//                     type: "textField",
//                     dataType: "number",
//                     onInput: "phone_number",
//                     not_required: true,
//                     only_number: true,
//                     api: "phone",
//                     defaultValue: {
//                       value:
//                         editList && editList.phone && editList.phone !== 0 && editList.phone !== "0"
//                           ? editList.phone
//                           : "",
//                     },
//                   },
//                   {
//                     name: "Email",
//                     type: "textField",
//                     dataType: "text",
//                     api: "email",
//                     onInput: "email",
//                     defaultValue: {
//                       value: editList && editList.email,
//                     },
//                   },

//                   editList === false && {
//                     name: "Password",
//                     type: "textField",
//                     dataType: "password",
//                     api: "password",
//                     password: true,
//                     validate: (submittedData) => {
//                       if (submittedData["Password"] === submittedData["Confirm Password"]) {
//                         return true;
//                       } else {
//                         return false;
//                       }
//                     },
//                   },
//                   editList === false && {
//                     name: "Confirm Password",
//                     type: "textField",
//                     password: true,
//                     dataType: "password",
//                     api: "password",
//                     validate: (submittedData) => {
//                       if (submittedData["Password"] === submittedData["Confirm Password"]) {
//                         return true;
//                       } else {
//                         return false;
//                       }
//                     },
//                   },
//                   {
//                     name: "Status",
//                     type: "select",
//                     dataType: "text",
//                     array: [
//                       { name: "approved", id: "approved" },
//                       { name: "pending", id: "pending" },
//                       { name: "cancelled", id: "cancelled" },
//                     ],
//                     value: "name",
//                     api: "status",
//                     defaultValue: {
//                       value: editList && editList.status ? editList.status : "approved",
//                     },
//                   },
//                   {
//                     name: "Role",
//                     type: "select",
//                     dataType: "text",
//                     array: allRoles,
//                     value: "role_name",
//                     api: "role",
//                     sendNameNotId: true,
//                     defaultValue: {
//                       value: editList && editList.role ? editList.role : "admin",
//                       id: filterData(allRoles, "id", editList && editList.role ? editList.role : "admin", "onlyOne")
//                         ? filterData(allRoles, "id", editList && editList.role ? editList.role : "admin", "onlyOne").id
//                         : 0,
//                     },
//                     onChange: async (submittedData) => {
//                       if (submittedData["Role"] === "admin") {
//                         submittedData["Module Permissions"] = modulesArr;
//                         submittedData["Module PermissionsId"] = modulesArr.map((s) => {
//                           return s.id;
//                         });
//                       } else {
//                         submittedData["Module Permissions"] = modulesArr.filter(
//                           (s) => s.name !== "Users & Permissions"
//                         );
//                         submittedData["Module PermissionsId"] = modulesArr
//                           .map((s) => {
//                             return s.id;
//                           })
//                           .filter((s) => s !== 7);
//                       }

//                       return submittedData;
//                     },
//                   },
//                   {
//                     name: "Module Permissions",
//                     type: "multiInput",
//                     placeholder: "Modules",
//                     dataType: "text",
//                     array: modulesArr,
//                     value: "name",
//                     api: "modules_access",
//                     sendName: true,
//                     defaultValue: {
//                       value: editList
//                         ? editList.modules_access && editList.modules_access.length > 0
//                           ? editList.modules_access.map((s) => {
//                               return modulesArr.filter((ss) => ss.name === s)[0];
//                             })
//                           : []
//                         : modulesArr,
//                       id: editList
//                         ? editList.modules_access && editList.modules_access.length > 0
//                           ? editList.modules_access.map((s) => {
//                               return modulesArr.filter((ss) => ss.name === s)[0].id;
//                             })
//                           : []
//                         : modulesArr.map((s) => {
//                             return s.id;
//                           }),
//                     },
//                   },
//                 ]}
//                 postData={this.props.BrandsManagementsFunction}
//               />
//             ) : (
//               ""
//             )}

//             {this.state.success || this.state.error ? (
//               <SnackbarComponent
//                 error={this.state.error}
//                 autoHideDuration={this.state.error ? 3000 : 2000}
//                 success={this.state.success}
//                 snackbarClose={() => {
//                   this.setState({
//                     error: "",
//                     success: "",
//                   });
//                 }}
//               />
//             ) : (
//               ""
//             )}
//             {singlelistView === null ? (
//               <div className="d-flex width-100 height-100 justifyC alignC">
//                 <NoData />
//               </div>
//             ) : loading[EndPoints["UsersAndPermissions"]] === true ? (
//               <div className="alignC justiyC d-flex width-100 height-100">
//                 <StyledCircularProgress color="inherit" />
//               </div>
//             ) : (
//               singlelistView && (
//                 <Paper
//                   className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     flexDirection: "column",
//                     height: "100%",
//                   }}
//                 >
//                   {changePassword ? (
//                     <ConfirmDialog
//                       header={"Change Password"}
//                       loader={passLoader}
//                       onSubmit={(e) => {
//                         const correctData = [];
//                         passArr
//                           .filter((sA) => sA.api)
//                           .map((singleInput) => {
//                             if (submittedPass[singleInput.name]) {
//                               if (
//                                 !singleInput.validate ||
//                                 (singleInput.validate && singleInput.validate(submittedPass) === true)
//                               ) {
//                                 correctData.push(singleInput.name);
//                               } else {
//                                 var errorMsgs = this.state.errorMsg;
//                                 errorMsgs[singleInput.name] = "Passwords do not match !";
//                                 return this.setState({ errorMsg: errorMsgs });
//                               }
//                             } else {
//                               var errorMsgs = this.state.errorMsg;
//                               errorMsgs[singleInput.name] = `Please add ${singleInput.name} !`;
//                               return this.setState({ errorMsg: errorMsgs });
//                             }
//                             return singleInput;
//                           });
//                         if (correctData.length === 3) {
//                           this.setState({ passLoader: true });
//                           this.props
//                             .BrandsManagementsFunction("post", "auth/change-password", null, "posted", {
//                               email: singlelistView.email,
//                               old_password: submittedPass["Old Password"],
//                               password: submittedPass["New Password"],
//                             })
//                             .then((res) => {
//                               if (
//                                 res.error ||
//                                 (res.response && res.response.data && res.response.data.status === "error")
//                               ) {
//                                 this.setState({
//                                   error:
//                                     typeof res.data === "string"
//                                       ? res.data
//                                       : res.data.length > 0
//                                       ? res.data
//                                           .map((s) => {
//                                             return `${s.field ? `${s.field} :` : ""} ${
//                                               typeof s.message === "string"
//                                                 ? s.message
//                                                 : s.message.length > 0 && s.message[0] && s.message[0]
//                                             }`;
//                                           })
//                                           .join(" ,")
//                                       : res.data.response
//                                       ? res.data.response.data.msg.error
//                                         ? res.data.response.data.msg.error
//                                         : res.data.response.data.msg
//                                       : "Error !",
//                                   passLoader: false,
//                                 });
//                               } else {
//                                 this.setState({ success: "Password Updated!" }, () => {
//                                   this.setState({
//                                     passLoader: false,
//                                     changePassword: false,
//                                     submittedPass: {},
//                                     errorMsg: {},
//                                   });
//                                 });
//                               }
//                             });
//                         }
//                       }}
//                       height="473px"
//                       width="450px"
//                       maxWidth="450px"
//                       action={"Update"}
//                       setOpenDialog={() => {
//                         this.setState({ changePassword: false, submittedPass: {}, errorMsg: {} });
//                       }}
//                       openDialog={changePassword !== false ? true : false}
//                       content={() => {
//                         return (
//                           <>
//                             {this.state.success || this.state.error ? (
//                               <SnackbarComponent
//                                 autoHideDuration={this.state.error ? 3000 : 2000}
//                                 error={this.state.error}
//                                 success={this.state.success}
//                                 snackbarClose={() => {
//                                   this.setState({
//                                     error: "",
//                                     success: "",
//                                   });
//                                 }}
//                               />
//                             ) : (
//                               ""
//                             )}
//                             {passLoader ? (
//                               <StyledCircularProgress />
//                             ) : (
//                               <div className="width-100 height-100">
//                                 {passArr.map((single) => (
//                                   <CssTextField
//                                     required={true}
//                                     onWheel={(event) => {
//                                       event.preventDefault();
//                                     }}
//                                     inputProps={{
//                                       autocomplete: "new-password",
//                                       form: {
//                                         autocomplete: "off",
//                                       },
//                                     }}
//                                     style={{ width: "calc(100% - 40px)", margin: "20px" }}
//                                     id="outlined-basic"
//                                     label={single.name}
//                                     error={errorMsg[single.name] ? true : false}
//                                     helperText={errorMsg[single.name] ? errorMsg[single.name] : false}
//                                     variant="outlined"
//                                     name={single.name}
//                                     value={submittedPass[single.name] ? submittedPass[single.name] : ""}
//                                     type="password"
//                                     onChange={(event) => {
//                                       const errorMsgs = this.state.errorMsg;
//                                       errorMsgs[event.target.name] = false;
//                                       const submittedData = submittedPass;
//                                       submittedData[event.target.name] = event.target.value;

//                                       this.setState({
//                                         submittedPass: submittedData,
//                                         errorMsg: errorMsgs,
//                                       });
//                                     }}
//                                   />
//                                 ))}
//                               </div>
//                             )}
//                           </>
//                         );
//                       }}
//                     />
//                   ) : (
//                     ""
//                   )}
//                   <div className="width-100 d-flex justifyFE alignC">
//                     <StyledButton
//                       tooltip="Edit"
//                       // name="Edit"
//                       icon={<EditIcon />}
//                       minWidth="20px"
//                       padding="0"
//                       onClick={() => {
//                         this.setState({
//                           openDialog: true,
//                           editList: singlelistView,
//                         });
//                       }}
//                     />

//                     <StyledButton
//                       name="Change Password"
//                       onClick={() => {
//                         this.setState({ changePassword: true, submittedPass: {}, errorMsg: {} });
//                       }}
//                       margin="0 0 0 10px"
//                       withBg={true}
//                     />
//                   </div>

//                   <div
//                     className="overflowYA scrollBluePrimary"
//                     style={{
//                       width: "100%",
//                       display: "flex",
//                       flexDirection: "column",
//                       padding: "0 50px",
//                       height: "calc(100% - 31.7px)",
//                       textTransform: "capitalize",
//                     }}
//                   >
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                       }}
//                     >
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 ",
//                           minWidth: "200px",
//                         }}
//                       >
//                         Name:{" "}
//                       </p>
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 10px 10px",
//                           color: "#808080",
//                           textTransform: "capitalize",
//                         }}
//                       >
//                         {`${singlelistView.first_name} ${singlelistView.last_name ? singlelistView.last_name : ""}`}
//                       </p>
//                     </div>{" "}
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                       }}
//                     >
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 ",
//                           minWidth: "200px",
//                         }}
//                       >
//                         Date of Birth:
//                       </p>{" "}
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 10px 10px",
//                           color: "#808080",
//                         }}
//                       >
//                         {singlelistView.date_of_birth ? moment(singlelistView.date_of_birth).format("ddd, MMM Do YYYY") : ""}
//                       </p>
//                     </div>
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                       }}
//                     >
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 ",
//                           minWidth: "200px",
//                         }}
//                       >
//                         Phone Number:
//                       </p>{" "}
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 10px 10px",
//                           color: "#808080",
//                         }}
//                       >
//                         {singlelistView.phone && singlelistView.phone !== 0 && singlelistView.phone !== "0"
//                           ? singlelistView.phone
//                           : "-"}
//                       </p>
//                     </div>
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                       }}
//                     >
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 ",
//                           minWidth: "200px",
//                         }}
//                       >
//                         Email:{" "}
//                       </p>{" "}
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 10px 10px",
//                           color: "#808080",
//                           textTransform: "lowercase",
//                         }}
//                       >
//                         {singlelistView.email}
//                       </p>
//                     </div>
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                       }}
//                     >
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 ",
//                           minWidth: "200px",
//                         }}
//                       >
//                         Status:{" "}
//                       </p>{" "}
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 10px 10px",
//                           color: "#808080",
//                         }}
//                       >
//                         {singlelistView.status}
//                       </p>
//                     </div>{" "}
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                       }}
//                     >
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 ",
//                           minWidth: "200px",
//                         }}
//                       >
//                         Role:{" "}
//                       </p>{" "}
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 10px 10px",
//                           color: "#808080",
//                         }}
//                       >
//                         {singlelistView.role}
//                       </p>
//                     </div>
//                     <div
//                       style={{
//                         width: "100%",
//                         display: "flex",
//                       }}
//                     >
//                       <p
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 ",
//                           minWidth: "200px",
//                           padding: "15px 0 0 0",
//                         }}
//                       >
//                         All Modules:{" "}
//                       </p>{" "}
//                       <div
//                         className="flexWrap d-flex"
//                         style={{
//                           fontSize: "18px",
//                           margin: "10px 0 10px 0",
//                           color: "#808080",
//                         }}
//                       >
//                         {singlelistView.modules_access &&
//                           singlelistView.modules_access.length > 0 &&
//                           singlelistView.modules_access.map((s, i) => (
//                             <div
//                               style={{
//                                 height: "35.74px",
//                                 background:
//                                   i === 0
//                                     ? color1
//                                     : i === 1
//                                     ? color4
//                                     : i === 2
//                                     ? color3
//                                     : i === 3
//                                     ? primary
//                                     : i === 4
//                                     ? color5
//                                     : i === 5
//                                     ? color1
//                                     : i === 6
//                                     ? color4
//                                     : i === 7
//                                     ? color3
//                                     : primary,
//                                 width: "fit-content",
//                                 margin: "10px",
//                                 padding: "5px 10px",
//                               }}
//                               className=" borderRadius7 minWidthFC textCW margin10"
//                             >
//                               {s}
//                             </div>
//                           ))}
//                       </div>
//                     </div>
//                   </div>
//                 </Paper>
//               )
//             )}
//           </div>
//         </Paper>
//       </Layout>
//     );
//   }
// }

// export default UsersAndPermissionsComponent;
import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import { Paper, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import {
  color1,
  lightGrey,
  color4,
  color3,
  primary,
  color5,
} from "../../styles/colors";

import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import CreateDialog from "../../layoutComponents/CreateDialog";
import {
  CssTextField,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import NoData from "../../styles/Illustrations/NoData";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../layoutComponents/ConfirmDialog";
import moment from "moment";
import { filterData } from "../../Regex";
export class UsersAndPermissionsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: "Pending",
        tooltip: `Click to approve pending ${EndPoints["UsersAndPermissions"]}`,
        data: [],
        route: `/UsersAndPermissions/${
          EndPoints["UsersAndPermissions"].split("/")[0]
        }`,
        goBackRoute: "/UsersAndPermissions",
        colorArr: ["white", lightGrey],
        primaryField: "name",
        secondaryField: "email",
        unit: true,
        avatarField: "src",
        progressBar: true,
        dispatchPackagingArray: [],
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      listViewArray: [],
      loading: false,
      openRoles: false,
      openUsers: false,
      allRoles: [],
      internalUsers: [],
      selectedRole: false,
      selctedTab: "All Modules",
      selctedStatus: "approved",
      MillerList: "",
      BrokerList: "",
      status: [
        { name: "approved" },
        { name: "pending" },
        { name: "CANCELLED" },
      ],
      openDialog: false,
      editList: false,
      changePassword: false,
      modulesArr: [
        { id: 0, name: "Purchase Orders" },
        { id: 1, name: "Ports" },
        { id: 2, name: "Sales Orders" },
        { id: 3, name: "Transporters" },
        { id: 4, name: "Brokers" },
        { id: 5, name: "Millers" },
        { id: 6, name: "Brands Management" },
        { id: 7, name: "Users & Permissions" },
        { id: 8, name: "Masters" },
        { id: 9, name: "Homes" },
        // { id: 10, name: "Chats" },
      ],
      modulesArrUser: [
        { id: 0, name: "Purchase Orders" },
        { id: 1, name: "Ports" },
        { id: 2, name: "Sales Orders" },
        { id: 3, name: "Transporters" },
        { id: 4, name: "Brokers" },
        { id: 5, name: "Millers" },
        { id: 6, name: "Brands Management" },
        { id: 8, name: "Masters" },
        { id: 9, name: "Homes" },
        // { id: 10, name: "Chats" },
      ],
      passLoader: false,
      passArr: [
        {
          name: "Old Password",
          type: "textField",
          api: "old_password",
        },
        {
          name: "New Password",
          type: "textField",
          api: "password",
          validate: (submittedData) => {
            if (
              submittedData["New Password"] ===
              submittedData["Confirm New Password"]
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          name: "Confirm New Password",
          type: "textField",
          api: "c_password",
          validate: (submittedData) => {
            if (
              submittedData["New Password"] ===
              submittedData["Confirm New Password"]
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
      ],
      submittedPass: {},
      errorMsg: {},
    };
  }
  getId = (name) => {
    switch (name) {
      case "Purchase Orders":
        return 0;
      case "Ports":
        return 1;
      case "Sales Orders":
        return 2;
      case "Transporters":
        return 3;
      case "Brokers":
        return 4;
      case "Millers":
        return 5;
      case "Brands Management":
        return 6;
      case "Users & Permissions":
        return 7;
      case "Masters":
        return 8;
      case "Homes":
        return 9;

      // case "Chats":
      //   return 10;
      default:
    }
  };
  setInitialValues(next) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        next[EndPoints["UsersAndPermissions"]] &&
        next[EndPoints["UsersAndPermissions"]].length > 0
          ? next[EndPoints["UsersAndPermissions"]]
              .map((s) => {
                return {
                  ...s,
                  name: `${s.first_name ? s.first_name : ""} ${
                    s.last_name ? s.last_name : ""
                  }`,
                  email: s.email,
                };
              })
              .sort((a, b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
          : [];
      return tab;
    });
    this.setState({
      tabs,
      listViewArray:
        next[EndPoints["UsersAndPermissions"]] &&
        next[EndPoints["UsersAndPermissions"]].length > 0
          ? next[EndPoints["UsersAndPermissions"]]
              .map((s) => {
                return {
                  ...s,
                  name: `${s.first_name ? s.first_name : ""} ${
                    s.last_name ? s.last_name : ""
                  }`,
                  email: s.email,
                };
              })
              .sort((a, b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
          : [],
      loading: next.loading,
    });
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.fetchRolesList();
    this.setInitialValues(this.props);
  }

  fetchRolesList = () => {
    this.setState({ loading: true });
    this.props
      .BrandsManagementsFunction(
        "get",
        "users-and-permissions/roles",
        null,
        "allRoles"
      )
      .then((allRoles) => {
        this.setState({
          allRoles: allRoles && allRoles.length > 0 ? allRoles : [],
          loading: false,
        });
      });
    this.props
      .BrandsManagementsFunction(
        "get",
        "masters/miller/list",
        null,
        "MillerList",
        null,
        { is_active: "True" }
      )
      .then((MillerList) => {
        this.setState({ MillerList, loading: false });
      });
    this.props
      .BrandsManagementsFunction(
        "get",
        "masters/broker/list",
        null,
        "BrokerList",
        null,
        { is_active: "True" }
      )
      .then((BrokerList) => {
        this.setState({ BrokerList, loading: false });
      });
  };
  setRoleUser = (role, userId) => {
    this.props
      .BrandsManagementsFunction(
        "post",
        "users-and-permissions/roles-to-users",
        null,
        "postdata",
        {
          role: role,
          assigned_to: userId,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
      });
  };
  // setModuleUser = (role, userId) => {
  //   this.props
  //     .BrandsManagementsFunction("post", "users-and-permissions/roles-to-users", null, "postdata", {
  //       role: role,
  //       assigned_to: userId,
  //     })
  //     .then((res) => {
  //       this.setState({ loading: false });
  //     });
  // };

  // fetchModulesList = () => {
  //   this.setState({ loading: true });
  //   this.props
  //     .BrandsManagementsFunction("get", "users-and-permissions/modules", null, "allModules")
  //     .then((allModules) => {
  //       this.setState({ allModules: allModules && allModules.length > 0 ? allModules : [], loading: false });
  //     });
  // };
  // fetchRoleToModuleList = (selectedRole) => {
  //   this.setState({ loading: true });
  //   this.props.BrandsManagementsFunction("get", "users-and-permissions/modules-to-roles").then((allRoleModules) => {
  //     this.setState({
  //       allRoleModules:
  //         allRoleModules && allRoleModules.length > 0
  //           ? selectedRole
  //             ? filterData(allRoleModules, "role", selectedRole.id)
  //               ? filterData(allRoleModules, "role", selectedRole.id)
  //               : []
  //             : allRoleModules
  //           : [],
  //       loading: false,
  //     });
  //   });
  // };

  // fetchRoleToUsersList = (selectedRole) => {
  //   this.setState({ loading: true });
  //   this.props.BrandsManagementsFunction("get", "users-and-permissions/roles-to-users").then((allRoleUsers) => {
  //     this.setState({
  //       allRoleUsers:
  //         allRoleUsers && allRoleUsers.length > 0
  //           ? selectedRole
  //             ? filterData(allRoleUsers, "role", selectedRole.id)
  //               ? filterData(allRoleUsers, "role", selectedRole.id)
  //               : []
  //             : allRoleUsers
  //           : [],
  //       loading: false,
  //     });
  //   });
  // };
  // fetchInternalUsersList = () => {
  //   this.setState({ loading: true });
  //   this.props
  //     .BrandsManagementsFunction("get", "users-and-permissions/internal-users", null, "internalUsers")
  //     .then((internalUsers) => {
  //       this.setState({
  //         internalUsers: internalUsers && internalUsers.length > 0 ? internalUsers : [],
  //         loading: false,
  //       });
  //     });
  // };

  listClickHandler = (value) => {
    this.setState({
      singlelistView: value,
    });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  render() {
    const {
      loading,
      allRoles,
      tabs,
      listViewArray,
      openDialog,
      editList,
      modulesArr,
      modulesArrUser,
      singlelistView,
      changePassword,
      passLoader,
      passArr,
      submittedPass,
      errorMsg,
      MillerList,
      BrokerList,
      selectedRole,
      role,
    } = this.state;
    
    return (
      <Layout
        loading={
          loading[EndPoints["UsersAndPermissions"]]
            ? loading[EndPoints["UsersAndPermissions"]] === true
              ? true
              : false
            : false
        }
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: "Search Users",
          labelField: "name",
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          this.setState({ openDialog: true, editList: false });
        }}
        serchClick={this.searchHandler}
        centerWidth="250px"
        openWidth="485px"
        closeWidth="307px"
      >
        {/* {openDialog === true ? (
          <CreateDialog
            noAddAnother={true}
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            onEditUpdate={(res) => {
              this.onEditUpdate(res);
            }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}
            module={"Brokers"}
            selectedList={editList ? editList : false}
            editList={editList ? editList : false}
            handleClose={() => {
              this.setState({
                openDialog: false,
                loadingFun: false,
                editList: true,
              });
            }}
            postApi="masters/broker/list"
            editApi="masters/broker"
            inputArray={[
              {
                name: "Active",
                type: "toggle",
                defaultValue: {
                  value: editList ? editList.is_active : true,
                },
                api: "is_active",
              },
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "phone number",
                type: "textField",
                dataType: "number",
                onInput: "phone_number",
                not_required: true,
                only_number: true,
                api: "phone",
                defaultValue: {
                  value: editList && editList.phone,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                api: "address",
                defaultValue: {
                  value: editList && editList.address,
                },
              },
              {
                name: "GST IN",
                type: "textField",
                dataType: "text",
                not_required: true,
                onKeyPress: "alphaNum",
                // only_number: true,
                onInput: "gst_in",
                api: "gstin",
                defaultValue: {
                  value: editList && editList.gstin,
                },
                helperText: "ex: 22AAAAA0000A1Z5",
              },

              {
                name: "contact person",
                type: "textField",
                dataType: "text",
                api: "contact_person",
                defaultValue: {
                  value: editList && editList.contact_person,
                },
              },

              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                onKeyPress: "textOnly",
                defaultValue: {
                  value: editList && editList.city,
                },
              },

              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },
            ]}
            postData={this.props.BrokersFunction}
            fetchAgain={() => {
              this.props.BrokersFunction("get", EndPoints["Brokers"]);
            }}
          />
        ) : (
          ""
        )} */}

        <Paper className="width-100 height-100 padding20 backgroundWhitef">
          <div className="d-flex width-100 height-100 d-flex-column">
            {openDialog === true ? (
              <>
                <CreateDialog
                  module={"Users"}
                  noAddAnother={true}
                  closeOnSave={true}
                  selectedList={editList ? editList : false}
                  editList={editList ? editList : false}
                  handleClose={() => {
                    this.setState({ openDialog: false });
                  }}
                  fetchAgain={() => {
                    this.props
                      .BrandsManagementsFunction(
                        "get",
                        EndPoints["UsersAndPermissions"]
                      )
                      .then((res) => {
                        if (res) {
                          console.log(
                            singlelistView,
                            "singlelistView"
                          );
                          this.setState({
                            singlelistView: singlelistView&&singlelistView.id&&filterData(
                              res,
                              "id",
                              Number(singlelistView.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  res,
                                  "id",
                                  Number(singlelistView.id),
                                  "onlyOne"
                                )
                              : singlelistView,
                          });
                        }
                      });
                  }}
                  addApi2={
                    editList
                      ? false
                      : {
                          api: "users",
                          formatData: (data, res) => {
                            return {
                              phone: data["Phone number"],
                              modules_access: data["Module Permissions"].map(
                                (s) => {
                                  return s.name;
                                }
                              ),
                              role: data["Role"],
                              status: data["Status"],
                              id: res.user_id,
                              first_name: data["First Name"],
                              email: data["Email"],
                              date_of_birth: moment(
                                data["Date of Birth"]
                              ).format("YYYY-MM-DD"),
                            };
                          },
                        }
                  }
                  postApi="registration/user"
                  editApi="users"
                  textFieldWidth="calc(50% - 20px)"
                  textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
                  heightNew="calc(100vh - 80px)"
                  maxHeight="calc(100vh - 80px)"
                  widthNew="800px"
                  inputArray={[
                    // {
                    //   name: "Active",
                    //   type: "toggle",
                    //   defaultValue: {
                    //     value: editList ? editList.is_active : true,
                    //   },
                    //   api: "is_active",
                    // },
                    {
                      name: "Role",
                      type: "select",
                      dataType: "text",
                      array: allRoles,
                      value: "role_name",
                      api: "role",
                      sendNameNotId: true,
                      defaultValue: {
                        value:
                          editList && editList.role ? editList.role : "Admin",
                        id: filterData(
                          allRoles,
                          "id",
                          editList && editList.role ? editList.role : "Admin",
                          "onlyOne"
                        )
                          ? filterData(
                              allRoles,
                              "id",
                              editList && editList.role
                                ? editList.role
                                : "admin",
                              "onlyOne"
                            ).id
                          : 0,
                      },
                      onChange: async (submittedData) => {
                        if (submittedData["Role"] === "Admin") {
                          submittedData["Module Permissions"] = modulesArr;
                          submittedData["Module PermissionsId"] =
                            modulesArr.map((s) => {
                              return s.id;
                            });
                          submittedData["First Name"] = "";
                          submittedData["Phone number"] = "";
                        } else {
                          submittedData["Module Permissions"] =
                            modulesArr.filter(
                              (s) => s.name !== "Users & Permissions"
                            );
                          submittedData["Module PermissionsId"] = modulesArr
                            .map((s) => {
                              return s.id;
                            })
                            .filter((s) => s !== 7);
                        }
                        this.setState({ selectedRole: submittedData["Role"] });

                        return submittedData;
                      },
                    },
                    selectedRole !== false && selectedRole === "Miller"
                      ? {
                          name: "Miller",
                          type: "select",
                          dataType: "text",
                          not_required: true,
                          array:
                            MillerList && MillerList.length > 0
                              ? MillerList
                              : [],
                          value: "name",
                          api: "miller",

                          defaultValue: {
                            value:
                              singlelistView && singlelistView.MillerList
                                ? singlelistView.MillerList.name //default_brokerage
                                : "",
                            id:
                              singlelistView && singlelistView.MillerList
                                ? singlelistView.MillerList.id
                                : "",
                          },
                          onChange: async (submittedData) => {
                            if (submittedData["Miller"]) {
                              const selected = filterData(
                                MillerList && MillerList.length > 0
                                  ? MillerList
                                  : [],
                                "id",
                                Number(submittedData["MillerId"]),
                                "onlyOne"
                              )
                                ? filterData(
                                    MillerList && MillerList.length > 0
                                      ? MillerList
                                      : [],
                                    "id",
                                    Number(submittedData["MillerId"]),
                                    "onlyOne"
                                  )
                                : {};
                              submittedData["First Name"] = selected
                                ? selected.name
                                : "";
                              submittedData["Phone number"] = selected
                                ? selected.phone
                                : "";
                            }
                            this.setState({
                              selectedMiller: submittedData["Miller"],
                            });

                            return submittedData;
                          },
                        }
                      : false,
                    selectedRole !== false && selectedRole === "Broker"
                      ? {
                          name: "Broker",
                          type: "select",
                          dataType: "text",
                          not_required: true,
                          array:
                            BrokerList && BrokerList.length > 0
                              ? BrokerList
                              : [],
                          value: "name",
                          api: "broker",
                          onChange: async (submittedData) => {
                            if (submittedData["Broker"]) {
                              const selected = filterData(
                                BrokerList && BrokerList.length > 0
                                  ? BrokerList
                                  : [],
                                "id",
                                Number(submittedData["BrokerId"]),
                                "onlyOne"
                              )
                                ? filterData(
                                    BrokerList && BrokerList.length > 0
                                      ? BrokerList
                                      : [],
                                    "id",
                                    Number(submittedData["BrokerId"]),
                                    "onlyOne"
                                  )
                                : {};
                              console.log(selected, "selected");
                              submittedData["First Name"] = selected
                                ? selected.name
                                : "";
                              submittedData["Phone number"] = selected
                                ? selected.phone
                                : "";
                            }
                            this.setState({
                              selectedBroker: submittedData["Broker"],
                            });

                            return submittedData;
                          },
                          defaultValue: {
                            value:
                              singlelistView && singlelistView.broker_details
                                ? singlelistView.broker_details.name //default_brokerage
                                : "",
                            id:
                              singlelistView && singlelistView.broker_details
                                ? singlelistView.broker_details.id
                                : "",
                          },
                        }
                      : false,
                    {
                      name: "First Name",
                      type: "textField",
                      dataType: "text",
                      not_required: true,
                      api: "first_name",
                      defaultValue: {
                        value:
                          editList && editList.first_name
                            ? editList.first_name
                            : "",
                      },
                    },
                    selectedRole !== false &&
                    (selectedRole === "Broker" || selectedRole === "Miller")
                      ? false
                      : {
                          name: "Last Name",
                          type: "textField",
                          dataType: "text",
                          not_required: true,
                          api: "last_name",
                          defaultValue: {
                            value:
                              editList && editList.last_name
                                ? editList.last_name
                                : "",
                          },
                        },
                    {
                      name: "Date of Birth",
                      type: "Month",
                      dataType: "text",
                      api: "date_of_birth",
                      not_required: true,
                      defaultValue: {
                        value:
                          editList && editList.date_of_birth
                            ? editList.date_of_birth
                            : new Date(),
                      },
                    },
                    {
                      name: "Phone number",
                      type: "textField",
                      dataType: "number",
                      onInput: "phone_number",
                      not_required: true,
                      only_number: true,
                      api: "phone",
                      defaultValue: {
                        value:
                          editList &&
                          editList.phone &&
                          editList.phone !== 0 &&
                          editList.phone !== "0"
                            ? editList.phone
                            : "",
                      },
                    },
                    {
                      name: "Email",
                      type: "textField",
                      dataType: "text",
                      api: "email",
                      onInput: "email",
                      defaultValue: {
                        value: editList && editList.email,
                      },
                    },

                    editList === false && {
                      name: "Password",
                      type: "textField",
                      dataType: "password",
                      api: "password",
                      password: true,
                      validate: (submittedData) => {
                        if (
                          submittedData["Password"] ===
                          submittedData["Confirm Password"]
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    editList === false && {
                      name: "Confirm Password",
                      type: "textField",
                      password: true,
                      dataType: "password",
                      api: "password",
                      validate: (submittedData) => {
                        if (
                          submittedData["Password"] ===
                          submittedData["Confirm Password"]
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                    },
                    {
                      name: "Status",
                      type: "select",
                      dataType: "text",
                      array: [
                        { name: "approved", id: "approved" },
                        { name: "pending", id: "pending" },
                        { name: "cancelled", id: "cancelled" },
                      ],
                      value: "name",
                      api: "status",
                      defaultValue: {
                        value:
                          editList && editList.status
                            ? editList.status
                            : "approved",
                      },
                    },

                    {
                      name: "Module Permissions",
                      type: "multiInput",
                      placeholder: "Modules",
                      dataType: "text",
                      array: modulesArr,
                      value: "name",
                      api: "modules_access",
                      sendName: true,
                      defaultValue: {
                        value: editList
                          ? editList.modules_access &&
                            editList.modules_access.length > 0
                            ? editList.modules_access.map((s) => {
                                return modulesArr.filter(
                                  (ss) => ss.name === s
                                )[0];
                              })
                            : []
                          : modulesArr,
                        id: editList
                          ? editList.modules_access &&
                            editList.modules_access.length > 0
                            ? editList.modules_access.map((s) => {
                                return modulesArr.filter(
                                  (ss) => ss.name === s
                                )[0].id;
                              })
                            : []
                          : modulesArr.map((s) => {
                              return s.id;
                            }),
                      },
                    },
                    {
                      name: "Status",
                      type: "accordin",
                      dataType: "text",
                      // array: [
                      //   { name: "approved", id: "approved" },
                      //   { name: "pending", id: "pending" },
                      //   { name: "cancelled", id: "cancelled" },
                      // ],
                      value: "name",
                      api: "status",
                      defaultValue: {
                        value:
                          editList && editList.status
                            ? editList.status
                            : "approved",
                      },
                    },
                  ]}
                  postData={this.props.BrandsManagementsFunction}
                />
              </>
            ) : (
              ""
            )}

            {this.state.success || this.state.error ? (
              <SnackbarComponent
                error={this.state.error}
                autoHideDuration={this.state.error ? 3000 : 2000}
                success={this.state.success}
                snackbarClose={() => {
                  this.setState({
                    error: "",
                    success: "",
                  });
                }}
              />
            ) : (
              ""
            )}
            {singlelistView === null ? (
              <div className="d-flex width-100 height-100 justifyC alignC">
                <NoData />
              </div>
            ) : loading[EndPoints["UsersAndPermissions"]] === true ? (
              <div className="alignC justiyC d-flex width-100 height-100">
                <StyledCircularProgress color="inherit" />
              </div>
            ) : (
              singlelistView && (
                <Paper
                  className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  {changePassword ? (
                    <ConfirmDialog
                      header={"Change Password"}
                      loader={passLoader}
                      onSubmit={(e) => {
                        const correctData = [];
                        passArr
                          .filter((sA) => sA.api)
                          .map((singleInput) => {
                            if (submittedPass[singleInput.name]) {
                              if (
                                !singleInput.validate ||
                                (singleInput.validate &&
                                  singleInput.validate(submittedPass) === true)
                              ) {
                                correctData.push(singleInput.name);
                              } else {
                                var errorMsgs = this.state.errorMsg;
                                errorMsgs[singleInput.name] =
                                  "Passwords do not match !";
                                return this.setState({ errorMsg: errorMsgs });
                              }
                            } else {
                              var errorMsgs = this.state.errorMsg;
                              errorMsgs[
                                singleInput.name
                              ] = `Please add ${singleInput.name} !`;
                              return this.setState({ errorMsg: errorMsgs });
                            }
                            return singleInput;
                          });
                        if (correctData.length === 3) {
                          this.setState({ passLoader: true });
                          this.props
                            .BrandsManagementsFunction(
                              "post",
                              "auth/change-password",
                              null,
                              "posted",
                              {
                                email: singlelistView.email,
                                old_password: submittedPass["Old Password"],
                                password: submittedPass["New Password"],
                              }
                            )
                            .then((res) => {
                              if (
                                res.error ||
                                (res.response &&
                                  res.response.data &&
                                  res.response.data.status === "error")
                              ) {
                                this.setState({
                                  error:
                                    typeof res.data === "string"
                                      ? res.data
                                      : res.data.length > 0
                                      ? res.data
                                          .map((s) => {
                                            return `${
                                              s.field ? `${s.field} :` : ""
                                            } ${
                                              typeof s.message === "string"
                                                ? s.message
                                                : s.message.length > 0 &&
                                                  s.message[0] &&
                                                  s.message[0]
                                            }`;
                                          })
                                          .join(" ,")
                                      : res.data.response
                                      ? res.data.response.data.msg.error
                                        ? res.data.response.data.msg.error
                                        : res.data.response.data.msg
                                      : "Error !",
                                  passLoader: false,
                                });
                              } else {
                                this.setState(
                                  { success: "Password Updated!" },
                                  () => {
                                    this.setState({
                                      passLoader: false,
                                      changePassword: false,
                                      submittedPass: {},
                                      errorMsg: {},
                                    });
                                  }
                                );
                              }
                            });
                        }
                      }}
                      height="473px"
                      width="450px"
                      maxWidth="450px"
                      action={"Update"}
                      setOpenDialog={() => {
                        this.setState({
                          changePassword: false,
                          submittedPass: {},
                          errorMsg: {},
                        });
                      }}
                      openDialog={changePassword !== false ? true : false}
                      content={() => {
                        return (
                          <>
                            {this.state.success || this.state.error ? (
                              <SnackbarComponent
                                autoHideDuration={
                                  this.state.error ? 3000 : 2000
                                }
                                error={this.state.error}
                                success={this.state.success}
                                snackbarClose={() => {
                                  this.setState({
                                    error: "",
                                    success: "",
                                  });
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {passLoader ? (
                              <StyledCircularProgress />
                            ) : (
                              <div className="width-100 height-100">
                                {passArr.map((single) => (
                                  <CssTextField
                                    required={true}
                                    onWheel={(event) => {
                                      event.preventDefault();
                                    }}
                                    inputProps={{
                                      autocomplete: "new-password",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    style={{
                                      width: "calc(100% - 40px)",
                                      margin: "20px",
                                    }}
                                    id="outlined-basic"
                                    label={single.name}
                                    error={errorMsg[single.name] ? true : false}
                                    helperText={
                                      errorMsg[single.name]
                                        ? errorMsg[single.name]
                                        : false
                                    }
                                    variant="outlined"
                                    name={single.name}
                                    value={
                                      submittedPass[single.name]
                                        ? submittedPass[single.name]
                                        : ""
                                    }
                                    type="password"
                                    onChange={(event) => {
                                      const errorMsgs = this.state.errorMsg;
                                      errorMsgs[event.target.name] = false;
                                      const submittedData = submittedPass;
                                      submittedData[event.target.name] =
                                        event.target.value;

                                      this.setState({
                                        submittedPass: submittedData,
                                        errorMsg: errorMsgs,
                                      });
                                    }}
                                  />
                                ))}
                              </div>
                            )}
                          </>
                        );
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <div className="width-100 d-flex justifyFE alignC">
                    <StyledButton
                      tooltip="Edit"
                      // name="Edit"
                      icon={<EditIcon />}
                      minWidth="20px"
                      padding="0"
                      onClick={() => {
                        this.setState({
                          openDialog: true,
                          editList: singlelistView,
                        });
                      }}
                    />

                    <StyledButton
                      name="Change Password"
                      onClick={() => {
                        this.setState({
                          changePassword: true,
                          submittedPass: {},
                          errorMsg: {},
                        });
                      }}
                      margin="0 0 0 10px"
                      withBg={true}
                    />
                  </div>

                  <div
                    className="overflowYA scrollBluePrimary"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 50px",
                      height: "calc(100% - 31.7px)",
                      textTransform: "capitalize",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}
                      >
                        Name:{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                          textTransform: "capitalize",
                        }}
                      >
                        {`${singlelistView.first_name} ${
                          singlelistView.last_name
                            ? singlelistView.last_name
                            : ""
                        }`}
                      </p>
                    </div>{" "}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}
                      >
                        Date of Birth:
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}
                      >
                        {singlelistView.date_of_birth
                          ? moment(singlelistView.date_of_birth).format(
                              "ddd, MMM Do YYYY"
                            )
                          : ""}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}
                      >
                        Phone Number:
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}
                      >
                        {singlelistView.phone &&
                        singlelistView.phone !== 0 &&
                        singlelistView.phone !== "0"
                          ? singlelistView.phone
                          : "-"}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}
                      >
                        Email:{" "}
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                          textTransform: "lowercase",
                        }}
                      >
                        {singlelistView.email}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}
                      >
                        Status:{" "}
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}
                      >
                        {singlelistView.status}
                      </p>
                    </div>{" "}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                        }}
                      >
                        Role:{" "}
                      </p>{" "}
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 10px",
                          color: "#808080",
                        }}
                      >
                        {singlelistView.role}
                      </p>
                    </div>
                    {singlelistView.role === "Miller" ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 ",
                            minWidth: "200px",
                          }}
                        >
                          Miller Name:{" "}
                        </p>
                        {console.log(singlelistView, "aaaaaaaaaaaaaa")}
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 10px 10px",
                            color: "#808080",
                            textTransform: "capitalize",
                          }}
                        >
                          {/* {`${singlelistView.first_name} ${
                            singlelistView.last_name
                              ? singlelistView.last_name
                              : ""
                          }`} */}

                          {singlelistView && singlelistView.broker_details
                            ? singlelistView.broker_details.name
                            : ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {singlelistView.role === "Broker" ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 ",
                            minWidth: "200px",
                          }}
                        >
                          Broker Name:{" "}
                        </p>
                        {console.log(singlelistView, "aaaaaaaaaaaaaa")}
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "10px 0 10px 10px",
                            color: "#808080",
                            textTransform: "capitalize",
                          }}
                        >
                          {/* {`${singlelistView.first_name} ${
                            singlelistView.last_name
                              ? singlelistView.last_name
                              : ""
                          }`} */}

                          {singlelistView && singlelistView.broker_details
                            ? singlelistView.broker_details.name
                            : ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 ",
                          minWidth: "200px",
                          padding: "15px 0 0 0",
                        }}
                      >
                        All Modules:{" "}
                      </p>{" "}
                      <div
                        className="flexWrap d-flex"
                        style={{
                          fontSize: "18px",
                          margin: "10px 0 10px 0",
                          color: "#808080",
                        }}
                      >
                        {singlelistView.modules_access &&
                          singlelistView.modules_access.length > 0 &&
                          singlelistView.modules_access.map((s, i) => (
                            <div
                              style={{
                                height: "35.74px",
                                background:
                                  i === 0
                                    ? color1
                                    : i === 1
                                    ? color4
                                    : i === 2
                                    ? color3
                                    : i === 3
                                    ? primary
                                    : i === 4
                                    ? color5
                                    : i === 5
                                    ? color1
                                    : i === 6
                                    ? color4
                                    : i === 7
                                    ? color3
                                    : primary,
                                width: "fit-content",
                                margin: "10px",
                                padding: "5px 10px",
                              }}
                              className=" borderRadius7 minWidthFC textCW margin10"
                            >
                              {s}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Paper>
              )
            )}
          </div>
        </Paper>
      </Layout>
    );
  }
}

export default UsersAndPermissionsComponent;

