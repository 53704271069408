// import React from "react";
// import clsx from "clsx";
// import { makeStyles } from "@material-ui/core/styles";
// import Drawer from "@material-ui/core/Drawer";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import DrawerContents from "../layoutComponents/DrawerContents";

// const drawerWidth = 235;
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     width: "100%",
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: "nowrap",
//     border: "none",
//   },
//   drawerOpen: {
//     // zIndex: "10000",
//     width: drawerWidth,
//     overflowX: "hidden",
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     background: "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
//     color: "white",
//     borderRadius: "0 14px 14px 0",
//   },
//   drawerClose: {
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     background: "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
//     color: "white",
//     overflowX: "hidden",
//     width: theme.spacing(7) + 1,
//   },
//   rightIcon: {
//     position: "absolute",
//     right: 24,
//   },
// }));

// export default function WebViewAppBar(props) {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//       <CssBaseline />
//       <Drawer
//         variant="permanent"
//         className={clsx(classes.drawer, {
//           [classes.drawerOpen]: props.open,
//           [classes.drawerClose]: !props.open,
//         })}
//         classes={{
//           paper: clsx({
//             [classes.drawerOpen]: props.open,
//             [classes.drawerClose]: !props.open,
//           }),
//         }}
//         open={props.open}
//         style={{ border: "none" }}
//       >
//         <DrawerContents {...props} />
//       </Drawer>
//     </div>
//   );
// }

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import DrawerContents from "../layoutComponents/DrawerContents";

const drawerWidth = 235;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    border: "none",
  },
  drawerOpen: {
    // zIndex: "10000",
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
   // background: "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
    background: "rgba(241, 154, 48, 1)",
    color: "white",
    borderRadius: "0 14px 14px 0",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  //  background: "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
    background: "rgba(241, 154, 48, 1)",
    color: "white",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  rightIcon: {
    position: "absolute",
    right: 24,
  },
}));

export default function WebViewAppBar(props) {
  const classes = useStyles();
  const handleDrawerOpen=()=>{
  props.setOpen(true)} 
  const handleDrawerClose=()=>{
    props.setOpen(false)}

  return (
    <div className={classes.root}>
      
      <CssBaseline />
     
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
          }),
        }} 
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        open={props.open}
        style={{ border: "none" ,color:"white"}}
      >
       
        <DrawerContents {...props} />
      </Drawer>
    
    </div>
  );
}

