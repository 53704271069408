import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HomesComponent from "./HomesComponent";
import { HomesFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";
function HomesContainer(props) {
  const [masters, setMasters] = useState({});
  const [loader, setLoader] = useState(true);

  const fetchAllData = () => {
    console.log("wdwewedwedwe");
    props
      .HomesFunction(
        "get",
        "masters/item-category/list",
        null,
        //state.singlelistView.id,
        "ItemCateoryList",
        null,
        { is_active: "True" }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          const newMasters = masters;
          newMasters.ItemCateoryList = ItemCateoryList;
          setMasters(newMasters);
        }
        setLoader(false);
        props
          .HomesFunction(
            "get",
            "masters/item-type/list",
            null,
            //state.singlelistView.id,
            "ItemList",
            null,
            { is_active: "True" }
          )
          .then((ItemList) => {
            if (ItemList) {
              const newMasters = masters;
              newMasters.ItemList = ItemList;
              setMasters(newMasters);
            }

            // props
            //   .HomesFunction(
            //     "get",
            //     "dispatch/purchase-rate/list",
            //     null,
            //     //state.singlelistView.id,
            //     "PurchaseRate",
            //     null,
            //     { is_active: "True" }
            //   )
            //   .then((PurchaseRate) => {
            //     console.log(PurchaseRate, "ddddddddd");
            //     if (PurchaseRate) {
            //       const newMasters = masters;
            //       newMasters.PurchaseRate = PurchaseRate;
            //       setMasters(newMasters);
            //     }

            //     setLoader(false);
            //   });
          });
      });
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  console.log(masters, "masters");
  return (
    <HomesComponent
      {...props}
      masters={masters}
      params={params}
      loader={loader}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  loading: state.modules.loading,
  Homes: state.modules.Homes,
  [EndPoints["Homes"]]: state.modules[EndPoints["Homes"]],
});
const mapDispatchToProps = {
  HomesFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomesContainer);
