import { Snackbar, Alert } from "@mui/material";
import React, { createContext, useState, useContext } from "react";
// import Alert from '@mui/material/Alert';
// import Snackbar from '@mui/material/Snackbar';

export const SnackbarContext = createContext();

export function useSnackbar() {
  return useContext(SnackbarContext);
}

const MuiAlert = React.forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} variant="filled" {...props} ref={ref} style={{ fontFamily: 'Poppins' }} />;
});

const styles = {
  root: {
    "& > * + *": {
      marginTop: 2,
    },
    "& .MuiAlert-icon": {
      padding: "7px 0px"
    }
  },
};

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: "success",
    duration: 4000
  });

  // const handleSnackbarMessageBox = (data) => {
  //   console.log(data)
  //   return (
  //     data.map((item, index) => {
  //       return (
  //         <p key={index} style={{ margin: 0, fontFamily: 'Poppins' }}>
  //           {item}
  //         </p>
  //       )
  //     })
  //   )
  // }
  
  // const checkApiError = (inputValue) => {
  //   let errorArray = [];
  //   console.log("here", inputValue?.status, inputValue)
  //   if (inputValue?.status !== undefined && [500, 404].includes(inputValue?.status)) {
  //     errorArray.push(inputValue?.statusText + "  :  " + inputValue?.status)
  //     return errorArray;
  //   }
  //   else {
  //     let input = inputValue?.data !== undefined ? inputValue?.data : inputValue
  //     for (let key in input) {
  //       if (typeof (input[key][0]) !== typeof (String())) {
  //         for (let newkey in input[key][0]) {
  //           errorArray.push(newkey + "  :  " + (input[key][0][newkey]))
  //         }
  //       }
  //       else {
  //         errorArray.push(key + "  :  " + input[key])
  //       }
  //     }
  //   }
  //   return errorArray;
  // }

  const showSnackbar = (message, type = "success", duration = 4000) => {
    console.log("CALEDD SNACKBAR", message, type)
    let messageText = message
    if (type === "error") {
      messageText = typeof message === 'string' 
      ? message 
      : message[0]?.message
      ? message[0]?.message
      : message[0]
    }
    setSnackbar({ 
      open: true, 
      message: messageText, 
      type, 
      duration: duration ? duration : type === 'error' ? 5000 : duration
    });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <div style={styles.root}>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert onClose={handleClose} severity={snackbar.type}>
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </div>
    </SnackbarContext.Provider>
  );
}
