export const PO_ORDER_DETAIL_HEADER = [
  { label: "S. No", key: "s_no" },
  { label: "CONT. DATE", key: "cont_date" },
  { label: "PO NO", key: "po_no" },
  { label: "MILLER NAME", key: "miller_name" },
  { label: "BROKER NAME", key: "broker_name" },
  { label: "BRAND NAME", key: "brand_name" },
  { label: "ITEM CATEGORY", key: "item_category" },
  { label: "ITEM TYPE", key: "item_type" },
  { label: "PACKAGING", key: "packaging" },
  { label: "QTY IN QTL", key: "qty_in_qtl" },
  { label: "CONT. RATE", key: "cont_rate" },
  { label: "DISPATCH QTY", key: "dispatch_qty" },
  { label: "BAL QTY TO DISPATCH", key: "bal_qty_to_dispatch" },
  { label: "DELIVERY PLACE", key: "delivery_place" },
  { label: "STATUS", key: "status" },
  { label: "CONTRACT TYPE", key: "contract_type" }
];

export const DISPATCH_REPORT_HEADER = [
  { label: "S. No", key: "s_no" },
  { label: "Arrival Date", key: "arrival_date" },
  { label: "Port Gross Weight", key: "port_gross_weight" },
  { label: "Port Net Weight", key: "port_net_weight" },
  { label: "No. of bags Rcvd", key: "no_of_bags_rcvd" },
  { label: "Miller Addresss", key: "miller_address" },
  { label: "Dispatched To", key: "dispatched_to" },
  { label: "Miller Name", key: "miller_name" },
  { label: "Dispatches Date", key: "dispatches_date" },
  { label: "Miller Bill No", key: "miller_bill_no" },
  { label: "Nos of Bags", key: "nos_of_bags" },
  { label: "Packing Wt (IN QTL)", key: "packing_wt_qtl" },
  { label: "Dispatches Net Weight", key: "dispatches_net_weight" },
  { label: "Contract Rate", key: "contract_rate" },
  { label: "Miller Total Amount", key: "miller_total_amount" },
  { label: "TCS/GST", key: "tcs_gst" },
  { label: "Advance", key: "advance" },
  { label: "Miller Total Amount", key: "miller_total_amount_2" }, // Duplicated "Miller Total Amount"
  { label: "Transporter Name", key: "transporter_name" },
  { label: "Bill/Bilty No", key: "bill_bilty_no" },
  { label: "Bill/Bilty Date", key: "bill_bilty_date" },
  { label: "Truck No.", key: "truck_no" },
  { label: "QTY IN QTL", key: "qty_in_qtl" },
  { label: "Freight Rate", key: "freight_rate" },
  { label: "Total Freight", key: "total_freight" },
  { label: "Advance", key: "advance_2" }, // Duplicated "Advance"
  { label: "Bal to Pay", key: "bal_to_pay" },
  { label: "Brand", key: "brand" },
  { label: "HSN CODE", key: "hsn_code" }
];

export const BROKER_REPORT_HEADER = [
  { label: "PARTY NAME", key: "party_name" },
  { label: "BROKER", key: "broker" },
  { label: "CONT. DATE", key: "cont_date" },
  { label: "QTY IN QTL", key: "qty_in_qtl" },
  { label: "CONT. RATE", key: "cont_rate" },
  { label: "QTY LIFTED", key: "qty_lifted" },
  { label: "BAL QTY TO LIFT", key: "bal_qty_to_lift" },
  { label: "DELIVERY PLACE", key: "delivery_place" },
  { label: "BRAND NAME", key: "brand_name" },
  { label: "ITEM TYPE", key: "item_type" }
];

export const STOCK_REPORT_HEADER = [
  { label: "PORT NAME", key: "port_name" },
  { label: "GODOWN NAME", key: "godown_name" },
  { label: "BRAND", key: "brand" },
  { label: "TOTAL INVENTRY", key: "total_inventory" },
  { label: "SHIPMENT DATE", key: "shipment_date" },
  { label: "TOTAL BAG SHIPPED", key: "total_bag_shipped" },
  { label: "TOTAL QTY SHIPPED", key: "total_qty_shipped" },
  { label: "BAL QTY IN GODOWN", key: "bal_qty_in_godown" },
  { label: "BAL BAG IN GODOWN", key: "bal_bag_in_godown" }
];

export const BAG_REPORT_HEADER = [
  { label: "Supplier Name", key: "supplier_name" },
  { label: "Bill Date", key: "bill_date" },
  { label: "Bill No", key: "bill_no" },
  { label: "Marking", key: "marking" },
  { label: "Packing", key: "packing" },
  { label: "Qty", key: "qty" },
  { label: "Rate", key: "rate" },
  { label: "Amount", key: "amount" },
  { label: "Tax", key: "tax" },
  { label: "Freight/VAT", key: "freight_vat" },
  { label: "Total Amount", key: "total_amount" },
  { label: "Delivery", key: "delivery" },
  { label: "BAGS USED", key: "bags_used" },
  { label: "SHORT/EXTRA", key: "short_extra" },
  { label: "Balance As Per Book", key: "balance_as_per_book" }
];

export const EXPENSE_DETAIL_REPORT_HEADER = [
  { label: "S. No.", key: "s_no" },
  { label: "Custom Inv. No.", key: "custom_inv_no" },
  { label: "Custom Inv Date", key: "custom_inv_date" },
  { label: "Commercial Inv No.", key: "commercial_inv_no" },
  { label: "Commercial Inv Date", key: "commercial_inv_date" },
  { label: "CHA", key: "cha" },
  { label: "OCEAN FREIGHT", key: "ocean_freight" },
  { label: "OCEAN FREIGHT", key: "ocean_freight" },
  { label: "THC", key: "thc" },
  { label: "THC", key: "thc" },
  { label: "OTHER", key: "other" },
  { label: "OTHER", key: "other" },
  { label: "TOTAL EXPENSE", key: "total_expense" },
  { label: "PAY STATUS", key: "pay_status" }
];

