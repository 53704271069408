import React, { useEffect, useState } from "react";
import { CssDialog } from "../styles/StyledComponents";

function ConfirmDialog(props) {
  const {
    openDialog,
    resetText,
    setOpenDialog,
    maxWidth,
    onSubmit,
    content,
    action,
    height,
    width,
    loader,
    header,
    reset,
  } = props;
  const [loaders, setLoader] = useState(false);
  useEffect(() => {
    setLoader(loader);
  }, [loader]);
  return openDialog === true ? (
    <CssDialog
      loader={loaders}
      maxWidth={maxWidth && maxWidth}
      headerPrimary={true}
      resetText={resetText ? resetText : "Cancel"}
      height={height ? height : "297.330px"}
      width={width ? width : "300px"}
      header={header ? header : `Are you sure !`}
      addButtonWidth="60px"
      addButton={action ? action : "Yes"}
      reset={() => {
        if (reset) {
          reset();
        }
        setOpenDialog(false);
      }}
      onClose={() => {
        setOpenDialog(false);
      }}
      snackbarClose={() => {
        setOpenDialog(false);
      }}
      onSubmit={(e) => {
        if (onSubmit) {
          onSubmit(e);
        }
      }}
      children={
        content
          ? content
          : () => {
              return <div></div>;
            }
      }
    />
  ) : (
    ""
  );
}

export default ConfirmDialog;
