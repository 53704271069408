import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TableComponent from "../../layoutComponents/TableComponent";
import ReactDOM from "react-dom";
import { EndPoints } from "../../utils/EndPoints";
import NoData from "../../styles/Illustrations/NoData";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import TabComponent from "../../layoutComponents/TabComponent";
import MainView from "../../layoutComponents/MainView";
import {
  primary,
  white,
  red,
  mostLightGrey,
  lightGrey,
} from "../../styles/colors";
import {
  Divider,
  Paper,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  MenuItem,
  Tooltip,
  Button,
  Backdrop,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import { CrossIcon, EditIcon, PlusIcon } from "../../styles/Icons";
import {
  CssKeyboardDatePicker,
  CssTextField,
  CssTextField95,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { filterData } from "../../Regex";
import MetabaseDashboard from "./MetabaseDashboard";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
};
export class HomesComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Homes"],
        tooltip: `Click to approve pending ${EndPoints["Homes"]}`,
        data: [],
        route: `/Homes/${EndPoints["Homes"].split("/")[0]}`,
        goBackRoute: "/Homes",
        primaryField: "category_Weight",
        primarySecondValue: "amount",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "amount_unit",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        // avatarField: "src",
        salesList: true,
        onlySecondary: true,
        dispatchList: true,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "green", "red"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Homes: [],
      listViewArray: [],
      tabList: [],
      singlelistView: null,
      loading: false,
      openDialog: false,
      unit: [{ name: "Kgs" }, { name: "MT" }, { name: "Qtl" }],
      ItemCateoryName: "",
      ItemTypeName: "",
      Unit: "",
      Amount: "",
      Quanitity: "",
      MillerName: "",
      MillerNumber: "",
      ItemCateoryNameId: "",
      ItemTypeNameId: "",
      UnitId: "",
      error: "",
      success: "",
      editDialog: false,
      loadingRight: false,
      salesintent: false,
      salesIntentLIst: [],
      loader: false,
      addDailog: false,
      userRole:
        this.props.user && this.props.user.roles
          ? this.props.user.roles.toString().toLowerCase()
          : "",
      selectedRate: "",
      selctedTab: "details",
      fetchFirst: true,
      loadingPurchaseItent: true,
      errorMsg: {
        Unit: false,
        ItemCateoryName: false,
        ItemTypeName: false,
        Amount: false,
        Quanitity: false,
      },
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Homes"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.HomesFunction(
                "get",
                EndPoints["Homes"],
                this.state.singlelistView.id,
                "Homes"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  fetchPurchaseRate = async (category) => {
    console.log(category, "categoryetrgyuh");
    this.setState({ loadingPurchaseItent: true });
    // this.props
    //   .HomesFunction(
    //     "get",
    //     "dispatch/purchase-rate/list",
    //     null,
    //     //state.singlelistView.id,
    //     "PurchaseRate",
    //     null,
    //     category
    //       ? { is_active: "True", item_category: category.id }
    //       : { is_active: "True" }
    //     // purchase_rate: this.state.singlelistView.id
    //   )
    //   .then((res) => {
    //     this.setState({
    //       loadingPurchaseItent: false,
    //       listViewArray: res,
    //       fetchFirst: false,
    //     });
    //   });
  };

  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  setInitialValues(props) {
    const { masters } = props;
    // const dataArr =
    //   props[EndPoints["Homes"]] && props[EndPoints["Homes"]].length > 0
    //     ? props[EndPoints["Homes"]].map((s) => {
    //         return {
    //           ...s,
    //           category_Weight: `₹${s.amount}/ ${s.unit}`,

    //           amount_unit: `${
    //             filterData(
    //               masters.ItemCateoryList,
    //               "id",
    //               Number(s.item_category),
    //               "onlyone"
    //             )
    //               ? filterData(
    //                   masters.ItemCateoryList,
    //                   "id",
    //                   Number(s.item_category),
    //                   "onlyone"
    //                 ).name
    //               : ""
    //           } - W ${s.quantity} `,
    //           // ${moment(
    //           //   s.created_at
    //           // ).format("YY-MM-DD")}`,
    //           // ${
    //           //   filterData(
    //           //     masters.ItemList,
    //           //     "id",
    //           //     Number(s.item_type),
    //           //     "onlyone"
    //           //   )
    //           //     ? filterData(
    //           //         masters.ItemList,
    //           //         "id",
    //           //         Number(s.item_type),
    //           //         "onlyone"
    //           //       ).broken_percentage
    //           //     : ""
    //           // }
    //         };
    //       })
    //     : [];
    // const tabs = this.state.tabs.map((e, i) => {
    //   const tab = e;
    //   tab.data = dataArr.sort((a, b) => {
    //     var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
    //     var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
    //     return new Date(dateA) - new Date(dateB);
    //   });

    //   return tab;
    // });

    if (
      masters.ItemCateoryList &&
      masters.ItemCateoryList.length > 1 &&
      this.state.fetchFirst === true
    ) {
      this.fetchPurchaseRate(masters.ItemCateoryList[0]);
    }
    // if (
    //   masters.ItemList &&
    //   masters.ItemList.length > 1 &&
    //   this.state.fetchFirst === true
    // ) {
    //   this.fetchPurchaseRate(masters.ItemList[0]);
    // }
    this.setState({
      userRole:
        props.user && props.user.roles
          ? props.user.roles.toString().toLowerCase()
          : "admin",
      loader: props.loader,
      // listViewArray: masters.PurchaseRate,
      tabList: masters.ItemCateoryList,
      // tabListItemType: masters.ItemList,
      // Homes: dataArr,
      // tabs,
      loading: props.loading,
    });
  }
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };
  rateHandle = () => {
    this.setState({ addDailog: true });
  };
  handleClose = () => {
    this.setState({ addDailog: false });
  };
  render() {
    const {
      tabs,
      listViewArray,
      tabList,
      Homes,
      singlelistView,
      loading,
      openDialog,
      AddRate,
      unit,
      ItemCateoryName,
      ItemTypeName,
      Unit,
      Amount,
      Quanitity,
      errorMsg,
      ItemCateoryNameId,
      ItemTypeNameId,
      editDialog,
      error,
      success,
      loadingRight,
      selectedRate,
      loader,
      userRole,
      salesintent,
      MillerName,
      MillerNumber,
      loadingSalesItent,
      salesIntentLIst,
      loadingPurchaseItent,
      tabListItemType,
      addDailog,
    } = this.state;

    const { masters, ItemList } = this.props;
    var labelField = "title";

    return (
      <Layout
        loading={loading[EndPoints["Homes"]]}
        // tabs={tabs}
        noTab={true}
        loading={
          loading[EndPoints["Homes"]]
            ? loading[EndPoints["Homes"]] === true
              ? true
              : loader === true
              ? true
              : false
            : loader === true
            ? true
            : false
        }
        // RaisedSearchBar={{
        //   data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
        //   hintText: `Search Homes`,
        //   labelField: labelField,
        // }}
        // fabClickHandler={
        //   userRole === "admin"
        //     ? (value) => {
        //         this.setState({
        //           //     poItemsArray: [],
        //           //     poItemsArrayCopy: [],
        //           openDialog: true,
        //           //     loadingFun: false,
        //           //     added: false,
        //           //     editDialog: false,
        //           //     BrandName: false,
        //           //     selectedBrandMultipleName: false,
        //           //   },
        //           //   () => {
        //           //     this.reset("no snac");
        //           //     this.handleClearFields();
        //         });
        //       }
        //     : undefined
        // }
        // listClickHandler={this.listClickHandler}
        // serchClick={this.searchHandler}
        centerWidth="0"
        openWidth="235px"
        closeWidth="57px"
      >
  
        <Paper style={{height:"100%",width:"100%",padding:"15px",background:"white", overflow:"auto"}}>
        <MetabaseDashboard />
          {Homes === false? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Homes === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
             
            </div>
          ) : (
           ""
          )}
          
        </Paper>
      </Layout>
    );
  }
}

export default HomesComponent;
