import React, { useEffect } from "react";
import { connect } from "react-redux";
import BrokersComponent from "./BrokersComponent";
import { BrokersFunction } from "../../redux/index";
import { useParams, useNavigate, useLocation } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function BrokersContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrokersFunction("get", EndPoints["Brokers"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, state } = useLocation();
  return <BrokersComponent {...props} params={params} navigate={navigate} location={location} pathname={pathname} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  Brokers: state.modules.Brokers,
  loading: state.modules.loading,
  [EndPoints["Brokers"]]: state.modules[EndPoints["Brokers"]],
});
const mapDispatchToProps = {
  BrokersFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrokersContainer);
