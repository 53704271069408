import moment from "moment";
import {
  calculateGstAmount,
  calculatePercentage,
  filterData,
} from "../../../Regex";
import converter from "number-to-words";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function CommercialPackingListPDF({
  masters,
  downloadCI,
  tableList,
  comInLists,
  soItems,
}) {
  const selectedSo =
    downloadCI &&
    downloadCI !== false &&
    downloadCI &&
    downloadCI.custom_invoice_details &&
    downloadCI.custom_invoice_details.length > 0 &&
    downloadCI.custom_invoice_details[0].sales_order_details &&
    downloadCI.custom_invoice_details[0].sales_order_details[0]
      ? downloadCI.custom_invoice_details[0].sales_order_details[0]
      : false;

      const list = tableList[0];
      const selectedShipper =
              list && list.shipper
                ? filterData(
                    masters && masters.shippersList,
                    "id",
                    list.shipper,
                    "onlyOne"
                  )
                  ? filterData(
                      masters && masters.shippersList,
                      "id",
                      list.shipper,
                      "onlyOne"
                    )
                  : ""
                : "";
            const selectedBenificiary =
              list && list.beneficiary
                ? filterData(
                    masters && masters.benificiarysList,
                    "id",
                    list.beneficiary,
                    "onlyOne"
                  )
                  ? filterData(
                      masters && masters.benificiarysList,
                      "id",
                      list.beneficiary,
                      "onlyOne"
                    )
                  : ""
                : "";
    
            const buyer =
              list && list.buyer
                ? filterData(
                    masters && masters.buyersList,
                    "id",
                    list.buyer,
                    "onlyOne"
                  )
                  ? filterData(
                      masters && masters.buyersList,
                      "id",
                      list.buyer,
                      "onlyOne"
                    )
                  : ""
                : "";
            const items = list?.subList && list.subList.length > 0 && list.subList;
            console.log("L", list, list?.subList);
            
            const soItemDetail = (soItemID) => {
              return filterData(soItems, "id", soItemID, "onlyOne")
                ? filterData(soItems, "id", soItemID, "onlyOne")
                : false;
            };

  const dd1 = [
    {
      columns: [
        {
          width: "*",
          text: "PACKING LIST",
          alignment: "center",
          bold: true,
          fontSize: 14,
          fontFamily: "Arial",
        },
      ],
      margin: [0, 0, 0, 15], // Optional: Add some bottom margin for spacing
    },
    
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            widths: [128, 128, 128, 140],
            body: [
              [
                {
                  text: "COMM INVOICE NO.:",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "COMM DATE :",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "CONTRACT NUMBER:",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "DATED :",
                  style: "basicLeft",
                  border: [true, true, true, false],
                },
              ],
              [
                {
                  text: downloadCI && downloadCI.invoice_number,
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text:
                    downloadCI?.invoice_date &&
                    moment(downloadCI?.invoice_date).format("DD.MM.YYYY"),
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text:
                    selectedSo && selectedSo.so_number
                      ? selectedSo.so_number
                      : "",
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text:
                    selectedSo?.contract_date &&
                    moment(selectedSo?.contract_date).format("DD.MM.YYYY"),
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
    {
      columns: [
            {
              width: "*",
              margin: [0, 0, 0, 0],
              stack: [
                {
                  table: {
                    widths: [214, 146, 173],
                    body: [
                      [
                        {
                          text: "SHIPPER :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: "CUSTOM INVOICE :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: "DATE :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          text: selectedShipper && selectedShipper.name,
                          style: "basicLeft",
                          border: [true, false, false, false],
                        },
                        {
                          text:
                            list && list.invoice_number
                              ? list.invoice_number
                              : "",
                          style: "basicLeft",
                          border: [true, false, false, true],
                        },
                        {
                          text:
                            list?.invoice_date &&
                            moment(list.invoice_date).format("DD.MM.YYYY"),
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text:
                            selectedShipper &&
                            selectedShipper?.address.includes(
                              "C/O. ANAND OIL MILL,"
                            )
                              ? selectedShipper?.address
                                  .replace("C/O. ANAND OIL MILL,", "")
                                  .replace(/\n/g, " ")
                              : selectedShipper.address.replace(/\n/g, " "),
                          style: "basicLeft",
                          margin: [0, -4, 0, 0],
                          border: [true, false, false, false],
                        },
                        {
                          text: "BL NO. & Date :",
                          style: "basicLeft",
                          border: [true, false, false, false],
                        },
                        {
                          text: "EXPORTER REF :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          text: `GST Number: ${
                            selectedShipper && selectedShipper.gstin
                              ? selectedShipper.gstin
                              : ""
                          }`,
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, false, true],
                        },
                        {
                          // text: list && list.bl_number ? list.bl_number : "",
                          text: `BL NO. & Date :\n${
                          downloadCI.custom_invoice_details[0].bl_number || ""}\t${downloadCI.custom_invoice_details[0].bl_date ? moment(downloadCI.custom_invoice_details[0].bl_date).format("DD/MM/YYYY") : "" }`,
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, false, true],
                        },
                        {
                          text:
                            list && list.exporter_ref_no
                              ? list.exporter_ref_no
                              : "",
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: "CONSIGNEE :",
                          style: "basicLeft",
                          border: [true, false, false, false],
                        },
                        {
                          text: "BUYER :",
                          colSpan: 2,
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {},
                      ],
                      [
                        {
                          text:
                            selectedSo && selectedSo.consignee
                              ? selectedSo.consignee
                              : "",
                          style: "basicLeft",
                          margin: [0, -2, 0, 0],
                          border: [true, false, false, false],
                        },
                        {
                          text: buyer ? buyer.client_name : "",
                          margin: [0, -2, 0, 0],
                          style: "basicLeft",
                          colSpan: 2,
                          border: [true, false, true, false],
                        },
                        {},
                      ],
                      [
                        {
                          text: "",
                          style: "basicLeft",
                          margin: [0, -2, 0, 0],
                          border: [true, false, false, true],
                        },
                        {
                          text:
                          (buyer ? [buyer?.address.replace(/\n/g, " ").replace(/,\s*$/, ""), buyer.city?.replace(/,\s*$/, ""), buyer.state?.replace(/,\s*$/, ""), buyer.country?.replace(/,\s*$/, "")].filter(Boolean).join(", ")
                          : "" ) +
                          `\n${buyer.phone ? 'Phone:' + buyer.phone : ""}`,
                          style: "basicLeft",
                          margin: [0, -2, 0, 0],
                          colSpan: 2,
                          border: [true, false, true, true],
                        },
                        {},
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {
                  table: {
                    widths: [100, 105, 129, 190],
                    body: [
                      [
                        {
                          text: "NOTIFY 1 :",
                          style: "basicLeft",
                          border: [true, false, false, false],
                        },
                        {
                          text: "NOTIFY 2 :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: "NOTIFY 3 :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: " COUNTRY OF ORIGIN OF GOODS: INDIA",
                          style: "basicCenter",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: `${
                            downloadCI &&
                            downloadCI.notify_1 &&
                            filterData(
                              masters && masters.notifyList,
                              "id",
                              downloadCI &&
                                downloadCI.notify_1
                                ? Number(
                                    downloadCI
                                      .notify_1
                                  )
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.notifyList,
                                  "id",
                                  downloadCI &&
                                    downloadCI
                                      .notify_1
                                    ? Number(
                                        downloadCI
                                          .notify_1
                                      )
                                    : "",
                                  "onlyOne"
                                ).notify
                              : " "
                          }`,
                          style: "basicLeft",
                          border: [true, false, false, true],
                        },
                        {
                          text: `${
                            downloadCI &&
                            downloadCI.notify_2 &&
                            filterData(
                              masters && masters.notifyList,
                              "id",
                              downloadCI &&
                                downloadCI.notify_2
                                ? Number(
                                    downloadCI
                                      .notify_2
                                  )
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.notifyList,
                                  "id",
                                  downloadCI &&
                                    downloadCI
                                      .notify_2
                                    ? Number(
                                        downloadCI
                                          .notify_2
                                      )
                                    : "",
                                  "onlyOne"
                                ).notify
                              : ""
                          }`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {
                          text: `${
                            downloadCI &&
                            downloadCI.notify_3 &&
                            filterData(
                              masters && masters.notifyList,
                              "id",
                              downloadCI &&
                                downloadCI.notify_3
                                ? Number(
                                    downloadCI
                                      .notify_3
                                  )
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.notifyList,
                                  "id",
                                  downloadCI &&
                                    downloadCI
                                      .notify_3
                                    ? Number(
                                        downloadCI
                                          .notify_3
                                      )
                                    : "",
                                  "onlyOne"
                                ).notify
                              : " "
                          }`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {
                          text: [
                            { text: "PLACE OF DELIVERY/SUPPLY: " + "\n" },
                            {
                              text:
                                list && list.delivery_place
                                  ? list.delivery_place.includes("CIF") ? list.delivery_place.slice(4) : list.delivery_place
                                  : "",
                              margin: [0, 8, 0, 0],
                            },
                          ],
                          style: "basicCenter",
                          margin: [0, 2, 0, 0],
                          border: [true, false, true, true],
                        },
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {
                  table: {
                    widths: [214, 129, 190],
                    body: [
                      [
                        {
                          text: "NAME OF CARRYING VESSEL/FLIGHT NO.",
                          style: "basicLeft",
                          border: [true, false, false, true],
                        },
                        {
                          text: "TERM OF DELIVERY & PAYMENT :",
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {
                          text: "BENEFICIARY NAME :",
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: `${list?.vessel_number ? list?.vessel_number : ""}  ${
                            list?.flight_number ?  list?.flight_number : ""
                          }`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {
                          text: `Delivery : ${
                            filterData(
                              masters && masters.incoTermList,
                              "id",
                              list.sales_order_details[0].inco_term,
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.incoTermList,
                                  "id",
                                  list.sales_order_details[0].inco_term,
                                  "onlyOne"
                                ).name
                              : ""
                          }, ${
                            list && list.delivery_place
                              ? list.delivery_place.includes("CIF") ? list.delivery_place.slice(4) : list.delivery_place
                              : ""
                          } ,`,
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: selectedBenificiary && selectedBenificiary.name,
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          text: "PORT OF LOADING:",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          rowSpan: 3,
                          text: `Payment : ${
                            filterData(
                              masters && masters.soPaymentTermsList,
                              "id",
                              selectedSo && selectedSo.so_payment_term
                                ? Number(selectedSo.so_payment_term)
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.soPaymentTermsList,
                                  "id",
                                  selectedSo && selectedSo.so_payment_term
                                    ? Number(selectedSo.so_payment_term)
                                    : "",
                                  "onlyOne"
                                ).term
                              : "false"
                          } `,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        { rowSpan: 3,
                          text: `A/C NO.: ${
                            selectedBenificiary &&
                            selectedBenificiary.account_no
                          }\nBANK NAME : ${
                            selectedBenificiary && selectedBenificiary.bank_name
                          }\nBANK ADDRESS : ${
                            selectedBenificiary &&
                            selectedBenificiary.bank_address 
                          }\nSWIFT CODE : ${
                            selectedBenificiary &&
                            selectedBenificiary.swift_code
                          }`,
                          style: "basicLeft",
                          border: [false, false, true, true],
                        },
                      ],
                      [
                        {
                          text: `${
                            downloadCI &&
                            filterData(
                              masters && masters.PortList,
                              "id",
                              downloadCI?.custom_invoice_details?.[0]?.loading_port,
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.PortList,
                                  "id",
                                  downloadCI?.custom_invoice_details?.[0]?.loading_port,
                                  "onlyOne"
                                )?.name.toUpperCase() + ", INDIA"
                              : ""
                          }`,
                          style: "basicLeft",
                          margin: [0, -3, 0, 0],
                          border: [true, false, true, true],
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `PORT OF DISCHARGE\n${
                          list && list.discharge_port
                              ? list.discharge_port.toUpperCase()
                              : ""}`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {},
                        {},
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {table: {
                        widths: [98, 107, 70, 50, 191],
                        body: [
                          [
                            {
                              text: "MARKS AND NUMBER OF CONTAINER NO.",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "NO. AND KIND OF PACKAGE",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "DESCRIPTION OF GOODS",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `QUANTITY IN (${
                                downloadCI &&
                                downloadCI.custom_invoice_details[0].unit
                                  ? downloadCI.custom_invoice_details[0].unit
                                  : ""
                              })`,
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `REMARK`,
                              style: "basicCenter",
                              border: [true, false, true, true],
                            },
                          ],
                          [
                            {
                              text:items &&
                              items
                                .map((d, i) => {
                                  const soItem = soItemDetail(d.so_item);
                                  if (
                                    soItem &&
                                    soItem.brand_details &&
                                    soItem.brand_details.brand_name
                                  ) {
                                    return (
                                      soItem.brand_details.brand_name +
                                      (i !== items.length - 1 ? ", \n" : "")
                                    );
                                  }
                                  return "";
                                })
                                .filter(Boolean)
                                .join(""),
                              style: "basicCenter",
                              margin: [0, 10, 0, 0],
                              border: [true, false, false, false],
                            },
                            {
                              text:
                                items &&
                                selectedSo &&
                                (selectedSo?.total_no_of_containers
                                  ? `${selectedSo.total_no_of_containers} X  20' CONTS`
                                  : "") +
                                  "\n" +
                                  "\n" +
                                  `${
                                    items
                                      ? `Number of bags: ${items.reduce(
                                          (totalBags, d) => {
                                            const soItem = soItemDetail(d.so_item);
                                            if (soItem) {
                                              const numberOfBags = soItem.packaging
                                                ? soItem.packaging.reduce(
                                                    (prev, curr) =>
                                                      Number(prev) +
                                                      Number(
                                                        d.pkg_counter[curr]
                                                          ? d.pkg_counter[curr]
                                                          : 0
                                                      ),
                                                    0
                                                  )
                                                : 0;
                                              return totalBags + numberOfBags;
                                            }
                                            return totalBags;
                                          },
                                          0
                                        )}` // Initial totalBags is 0, accumulate the sum for each item
                                      : ""
                                  }`,
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text:items && filterData(
                                masters.ItemCateoryList,
                                "id",
                                soItemDetail(items[0].so_item).item_category,
                                "onlyOne"
                              ) ? filterData(
                                masters.ItemCateoryList,
                                "id",
                                soItemDetail(items[0].so_item).item_category,
                                "onlyOne"
                              ).name
                              : "",
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: `GROSS WT.\n${
                                    list && list.gross_weight
                                  }`,
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: items &&
                              items.map((d, i) => { return d["Remark"] ? d["Remark"] + "\n" : "" }),
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, true, false],
                            },
                          ],
                          [
                            {
                              text: items && items.map((d, i) => {
                                return d.container_numbers && d.container_numbers.length > 0
                                  ? d.container_numbers.map((ss) => ss + "\n").join('')
                                  : "";
                              }),
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: items
                                ? `Packaging in\n${
                                    items.map(d => {
                                      const soItem = soItemDetail(d.so_item);
                                      if (soItem) {
                                        return soItem.packaging.map((sP, index, arr) => {
                                          const pVal = filterData( masters.PackagingList,"id",Number(sP),"onlyOne") || {};

                                            const size = pVal.size ? Number(pVal.size) : "";
                                            const unit = pVal.unit ? pVal.unit : "";
                                            const bagType = pVal.bag_type ? pVal.bag_type : "";

                                            // Join the parts together and trim the result
                                            const packagingString = `${size}${unit} ${bagType}`.trim();

                                            // Add a comma and a newline unless it's the last item
                                            return packagingString + (index !== arr.length - 1 ? ",\n" : "");
                                        }).join("") 
                                      }
                                    }).join("\n")
                                  }`
                                : "",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text:  `HSN CODE :\n${items && filterData(
                              masters.HSNlist,
                              "id",
                              soItemDetail(items[0].so_item).hsn_code,
                              "onlyOne"
                            ) ? filterData(
                              masters.HSNlist,
                              "id",
                              soItemDetail(items[0].so_item).hsn_code,
                              "onlyOne"
                            ).hsn_code
                            : "" }`,
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `NET WT.\n${
                                list && list.net_weight
                              }`,
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, true, true],
                            },
                          ],
                        ],
                      },
                      layout: {
                        hLineColor: function (i, node) {
                          return "#222";
                        },
                        vLineColor: function (i, node) {
                          return "#222";
                        },
                      },
                },
                {
                  table: {
                    dontBreakRows: true,
                        headerRows: 2, 
                    widths: [214, 328],
                    body: [
                      [
                        { text: "", border: [true, false, false, false] },
                        {
                          text: "SRI SAINATH INDUSTRY PVT LTD\n\n\n\n",
                          style: "basicRight",
                          margin: [0, 0, 20, 0],
                          border: [false, false, true, false],
                        },
                      ],
                      [
                        { text: "", border: [true, false, false, true] },
                        {
                          text: "Authorized Signatory Director",
                          fontSize: 7.5,
                          margin: [0, 0, 40, 0],
                          style: "basicRight",
                          border: [false, false, true, true],
                        },
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {
                  table: {
                        dontBreakRows: true,
                        headerRows: 4, 
                        widths: ["*"],
                        body: [
                          [
                            {
                              text: "\nCIN NUMBER : U14200CT2007PTC020290",
                              style: "basicLeft",
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "EMAIL : sainath@srisainath.com",
                              style: "basicLeft",
                              margin: [0,-5,0,0],
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "WEB : www.srisainath.com",
                              style: "basicLeft",
                              margin: [0,-5,0,0],
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "FAX : +91 771 4032240",
                              style: "basicLeft",
                              margin: [0,-5,0,0],
                              border: [false, false, false, false],
                            },
                          ],
                        ],
                      },
                },
              ],
            },
      ],
    },
  ];

  const documentDefinition = {
    pageMargins: [20, 20, 20, 10],
    content: [
      dd1, // First page content
    ],

    styles: {
      basicLeft: {
        color: "#333",
        fontSize: 8,
        alignment: "left",
      },
      basicRight: {
        color: "#333",
        fontSize: 8,
        alignment: "right",
      },
      basicCenter: {
        color: "#333",
        fontSize: 8,
        alignment: "center",
      },
    },
  };

  // pdfMake.createPdf(documentDefinition).download(`${downloadCI.invoice_number}.pdf`);
  pdfMake.createPdf(documentDefinition).open();
}

export default CommercialPackingListPDF;
