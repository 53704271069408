let projectServiceBaseURL;
//uncomment 4 lines after props.LoginFunction and   props.verifyOtp( in LoginComponent.js once you provide correct to for login
//remove 13 lines from //--start-- in logincomponent.js

// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "http://142.93.212.36:8001/api/";
// } else {
//   projectServiceBaseURL = "http://142.93.212.36:8001/api/";
// }

// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "https://ssipldev.grainbyte.app/api/";
// } else {
//   projectServiceBaseURL = "https://ssipldev.grainbyte.app/api/";
// // }
if (process.env.NODE_ENV === "production") {
  // if(process.env.PLATFORM === 'prod'){
    projectServiceBaseURL = "https://ssipl.grainbyte.app/api/";
  // }
  // else{
    // projectServiceBaseURL = "https://dev.grainbyte.app/api/";
  // }
} else {
  projectServiceBaseURL = "https://dev.grainbyte.app/api/";
}
// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "https://dev.grainbyte.app/api/";
// } else {
//   projectServiceBaseURL = "https://dev.grainbyte.app/api/";
// }

// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "http://103.71.21.114:5000/api/";
// } else {
//   projectServiceBaseURL = "http://103.71.21.114:5000/api/";
// }

export const loginURL = projectServiceBaseURL + "auth/";

export const verifyOtpURL = projectServiceBaseURL + "auth/user-auth/verify-otp/";

export const baseURL = () => {
  return projectServiceBaseURL;
};

export const moduleURL = (module) => {
  return projectServiceBaseURL + module + "/";
};
export const moduleIdURL = (module, id) => {
  return projectServiceBaseURL + module + "/" + id + "/";
};
export const noFmoduleIdURL = (module) => {
  return projectServiceBaseURL + module;
};
