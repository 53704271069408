import React, { useEffect } from "react";
import { connect } from "react-redux";
import ItemTypesComponent from "./ItemTypesComponent";
import { ItemTypesFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function ItemTypesContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    // if (props.user.uid) {
    props.ItemTypesFunction("get", EndPoints["ItemTypes"]);
    // }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <ItemTypesComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  ItemTypes: state.modules.ItemTypes,
  loading: state.modules.loading,
  [EndPoints["ItemTypes"]]: state.modules[EndPoints["ItemTypes"]],
});
const mapDispatchToProps = {
  ItemTypesFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemTypesContainer);
