import { Paper, Typography } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useState } from "react";
import TabComponent from "../../layoutComponents/TabComponent";
import ReportPage from "./ReportPage";
import { DISPATCH_REPORT_HEADER, PO_ORDER_DETAIL_HEADER } from "./headers";


const ReportsComponent = (props) => {

  const tabValue = [
    { label: "Purchase Order", value: 0 },
    { label: "Sales Order", value: 1 },
    { label: "Custom Invoice", value: 2 },
  ];

  const POSubTabValue = [
    { label: "Order Detail", value: 0 },
    { label: "Dispatch", value: 1 },
  ];

  const [tabNumber, setTabNumber] = useState(0);
  const [innerTabNumber, setInnerTabNumber] = useState(0);

  const SubTabComponent = (props) => {
    const [subTabNumber, setSubTabNumber] = useState(props?.innerTabNumber || 0);
  
    const { 
      innerTabNumber, 
      subTabValue, 
    } = props;
  
    return (
      <div style={{ margin: "15px 0 0 0" }}>
        <TabComponent
          selectedValue={subTabNumber}
          onChange={(newVal) => {
            setSubTabNumber(newVal);
          }}
          list={subTabValue.map((item) => {
              return {
                name: item.label,
                component: <ReportPage {...props} />,
              }
            })
          }
        />
      </div>
    );
  };


  return (
    <Layout centerWidth="0px" openWidth="235px" closeWidth="52px">
      <Paper className="width-100 height-100 padding15 backgroundWhite">
        <div>
          <Typography
            variant="h6"
            className="text-center fontWeightBold textColorGrey"
          >
            Reports
          </Typography>
        </div>
        <div style={{ margin: "15px 0 0 0" }}>
          <TabComponent
            selectedValue={tabNumber}
            onChange={(newVal) => {
              setTabNumber(newVal);
            }}
            list={[
              {
                name: tabValue[0].label,
                component: (
                  <SubTabComponent 
                    innerTabNumber={innerTabNumber}
                    subTabValue={POSubTabValue}
                    tabModule={tabValue[0].label}
                    header={
                      innerTabNumber === 0 
                      ? PO_ORDER_DETAIL_HEADER
                      : DISPATCH_REPORT_HEADER
                    }
                  />
                ),
              },
              {
                name: tabValue[1].label,
                component: (
                  <ReportPage
                    tabModule={tabValue[1].label}
                    header={[]}
                  />
                ),
              },
              {
                name: tabValue[2].label,
                component: (
                  <ReportPage
                    tabModule={tabValue[2].label}
                    header={[]}
                  />
                ),
              },
            ]}
          />
        </div>
      </Paper>
    </Layout>
  );
};

export default ReportsComponent;
