import React, { useRef } from "react";
import moment from "moment";
import { StyledButton } from "../../styles/StyledComponents";
import { PDFExport } from "@progress/kendo-react-pdf";
import { PageTemplate } from "../PurchaseOrders/PurchaseOrderPdf";

function BagPurchasePdf(props) {
  const { singleDispatch, singlelistView } = props;
  const contentArea = useRef(null);
  console.log(singleDispatch, singlelistView, "aaaaaaaaaaaa");
  const handleExportWithFunction = (event) => {
    if (contentArea.current) {
      contentArea.current.save();
    }
  };

  return (<>
    <div
                className="pdf"
                style={{
                  height: "0",
                  width: "0",
                  overflow: "hidden",
                  textTransform: "initial !important",
                  fontFamily: "Arial",
                }}
              >
                {" "}
                <PDFExport
                  ref={contentArea}
                  // renderAnnotationLayer={false}
                  // renderTextLayer={false}
                  keepTogether="p"
                  paperSize="A4"
                  // margin="2cm"
                  forcePageBreak=".page-break"
                  fileName={`${singleDispatch && singleDispatch.po_number}.pdf`}
                  // allPages={true}

                  margin={{
                    bottom: 50,
                    left: 25,
                    right: 25,
                    top: 65,
                  }}
                  pageTemplate={PageTemplate}
                >
                  <div
      style={{
        height: "727px",
        minHeight: "727px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        color: "rgba(0, 0, 0, 0.84)",
      }}
    >
       
          <div
            style={{
              width: "calc(100% - 3px)",
              height: "calc(100% - 3px)",
              padding: "0", border: "1.5px solid rgba(0, 0, 0, 2.25)"
              
            }}
          >
            <div style={{ display: "flex", width: "100%"
              
             }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Bag PO Number :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {singleDispatch && singleDispatch.po_number
                  ? singleDispatch.po_number
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%", }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Date :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {singleDispatch && singleDispatch.date
                  ? moment(singleDispatch.date).format("ddd, MMM Do YYYY")
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%", }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Buyer:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {singleDispatch && singleDispatch.buyer ? singleDispatch.buyer : ""}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Buyer Address:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {singleDispatch && singleDispatch.buyer_address ? singleDispatch.buyer_address : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
               Buyer GST No.
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "uppercase",
                }}
              >
                {singleDispatch && singleDispatch.buyer_gst
                  ? singleDispatch.buyer_gst
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Seller :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singleDispatch && singleDispatch.seller ? singleDispatch.Seller : ""}
              </div>
            </div>
            <div style={{ display: "flex", width: "100%", }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Seller Address:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {singleDispatch && singleDispatch.seller_address ? singleDispatch.seller_address : ""}
              </div>
              </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Seller GST No.
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "uppercase",
                }}
              >
                {singleDispatch && singleDispatch.seller_gst
                  ? singleDispatch.seller_gst
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Name of Commodity:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singleDispatch && singleDispatch.commodity_name
                  ? singleDispatch.commodity_name
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Quantity:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singleDispatch && singleDispatch.quantity
                  ? singleDispatch.quantity + " bags"
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Price:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singleDispatch && singleDispatch.price
                  ? singleDispatch.price + " /Bag"
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Bags Weight :
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singleDispatch && singleDispatch.bags_weight
                  ? singleDispatch.bags_weight + " grams"
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Brand:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singlelistView && singlelistView.brand_name
                  ? singlelistView.brand_name
                  : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Delivery Place:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {`${
                  singleDispatch && singleDispatch.delivery_place
                    ? singleDispatch.delivery_place
                    : ""
                }`}
              </div>
            </div>
           
            {/* <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Taxes:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singleDispatch && singleDispatch.taxes ? singleDispatch.taxes : ""}
              </div>
            </div>
            */}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "190px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                 //  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                }}
              >
                Payment:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                {singleDispatch && singleDispatch.payment ? singleDispatch.payment : ""}
              </div>
            </div>
           
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  fotSize:"0.7rem"
                }}
              >
                {`In witness where of this purchase order is made on ${singleDispatch && singleDispatch.po_date
                  ? singleDispatch.po_date
                  : ""} and duly signed by the authorized representative of buyer.`}
              </div>
            </div>

           
          </div>
      </div>
      </PDFExport>
    </div>
    <StyledButton
    name="Download"
    onClick={() => {
      if (contentArea) {
        handleExportWithFunction();
      }
    }}
  /></>
  );
}

export default BagPurchasePdf;
