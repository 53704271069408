import { MenuItem } from "@material-ui/core";
import { divide, multiply, sum } from "lodash";
import { requests } from "./requests";
import { baseURL, moduleURL, noFmoduleIdURL } from "./http/config";

export const filterData = (array, field, compareValue, getSingleObj, yearToCompare) => {
  if (array && array.length > 0 && field && (compareValue || compareValue === false)) {
    const filteredData = array.filter((singleObj) => {
      if (singleObj[field] || singleObj[field] === false) {
        return singleObj[field] === compareValue;
      } else {
        return false;
      }
    });
    if (filteredData && filteredData.length > 0 && filteredData[0]) {
      if (getSingleObj) {
        return filteredData[0];
      } else {
        return filteredData;
      }
    }
  } else if (array && array.length > 0 && field) {
    const filteredData = array.filter((singleObj) => {
      if (singleObj[field]) {
        return singleObj[field];
      } else {
        return false;
      }
    });
    if (filteredData && filteredData.length > 0 && filteredData[0]) {
      if (getSingleObj) {
        return filteredData[0];
      } else {
        return filteredData;
      }
    }
  }
};
export const listItemDropdown = (array, field, content) => {
  return array && array.length > 0 ? (
    content
  ) : (
    <MenuItem
      disabled={true}
      key={`No ${field} added yet`}
      value={`No ${field} added yet`}
      id={`No ${field} added yet`}
    >
      {`No ${field} added yet`}
    </MenuItem>
  );
};
export const StopScroll = (e) => {
  e.target.addEventListener(
    "wheel",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );
};
export const scrollDiv = (id) => {
  const chat = document.getElementById(id);
  chat.scrollIntoView();
};
export const mediumScreen = () => {
  return window.innerWidth < 1025;
}
export const arraySortBykeyAllBrowser = (array, key, order = '', anotherkey = '', numericalPart='false') => {
  if (array) {
    return array.sort(function (a, b) {
      let x = typeof a[key] === 'string' ?  (!numericalPart ? a[key]?.toUpperCase() : parseInt(a[key].split('/').pop())) : a[key];
      let y = typeof b[key] === 'string' ? (!numericalPart ?  b[key]?.toUpperCase() : parseInt(b[key].split('/').pop())) : b[key];
      if (order === '') {
        if (typeof x === 'string' || x instanceof String) {
          return ((x < y) ? -1 : (x > y) ? 1 : anotherkey !== '' ? (a[anotherkey]?.toUpperCase() < b[anotherkey]?.toUpperCase()) ? -1 : (a[anotherkey]?.toUpperCase() > b[anotherkey]?.toUpperCase()) ? 1 : 0 : 0);
        } else {
          return ((x < y) ? -1 : ((x > y) ? 1 : anotherkey !== '' ? (a[anotherkey] < b[anotherkey]) ? -1 : (a[anotherkey] > b[anotherkey]) ? 1 : 0 : 0));
        }
      } else if (order === 'decreasing') {
        if (typeof x === 'string' || x instanceof String) {
          return ((x > y) ? -1 : ((x < y) ? 1 : anotherkey !== '' ? (a[anotherkey]?.toUpperCase() > b[anotherkey]?.toUpperCase()) ? -1 : (a[anotherkey]?.toUpperCase() < b[anotherkey]?.toUpperCase()) ? 1 : 0 : 0));
        } else {
          return ((x > y) ? -1 : ((x < y) ? 1 : anotherkey !== '' ? (a[anotherkey] > b[anotherkey]) ? -1 : (a[anotherkey] < b[anotherkey]) ? 1 : 0 : 0));
        }
      }
    });
  }
};
export const calculatePercentage = (originalAmount, percentage) => {
  return divide(multiply(originalAmount ? Number(originalAmount) : 0, percentage ? Number(percentage) : 0), 100);
};
export const calculateGstAmount = (originalAmount, percentage,percentageFrom) => {
  return sum([calculatePercentage(percentageFrom?percentageFrom:originalAmount, percentage), originalAmount ? Number(originalAmount) : 0]);
};
export const calculateExchangeValue = (amount, rate) => {
  return multiply(amount ? Number(amount) : 0, rate ? Number(rate) : 0);
};
export const calculatePrice = (amount, weight) => {
  return divide(amount ? Number(amount) : 0, weight ? Number(weight) : 0);
};

const removeBaseUrl = (text) => {
  const baseUrl = baseURL();
  const updatedText = text.includes(baseUrl) ? text.replace(baseUrl, "") : text;
  return updatedText;
}

export const fetchRecursivePaginatedData = async (method, apiUrl, params = {}, type, handleCountStateUpdate, responseDataList = [], totalCount = 0) => {
  console.log(method, apiUrl, params, type, handleCountStateUpdate, responseDataList , totalCount)
  try {
    const response = await requests({
      method: 'get',
      url: type === "noForwordSlash"
      ? removeBaseUrl(noFmoduleIdURL(apiUrl))
      : moduleURL(apiUrl),
      params: params
    });
    console.log(method, apiUrl, params, handleCountStateUpdate, response)
    const count = response.data.total_count; 
    const newTotalCount = totalCount + response.data.results?.length;
    const nextPageUrl = response.data.next; 
    handleCountStateUpdate((newTotalCount / count) * 100)

    const newData = responseDataList.concat(response.data.results);
    // If there is a next page, recursively fetch it
    if (nextPageUrl) {
      return fetchRecursivePaginatedData(
        method,
        nextPageUrl,
        {},
        'noForwordSlash',
        handleCountStateUpdate,
        newData, 
        newTotalCount
      );
    }

    console.log(newData)
    
    return { responseDataList: newData };
  } catch (error) {
    console.log(error, responseDataList)
    throw { error, responseDataList }
  }
};