import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

class SnackbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { error, errorMessage, success, autoHideDuration } = this.props;
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={true}
        autoHideDuration={autoHideDuration ? autoHideDuration : 2500}
        onClose={this.props.snackbarClose}
      >
        <SnackbarContent
          style={{ backgroundColor: error ? "#FF3D00" : "rgb(241 154 48)" }}
          message={
            <span id="message-id" style={{ color: "white" }}>
              {errorMessage
                ? errorMessage
                : error
                ? error
                : success
                ? success
                : this.props.fromBoard
                ? "Board added.."
                : "Study material added.."}
            </span>
          }
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.props.snackbarClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}
export default SnackbarComponent;
