import React from 'react';

// const METABASE_SITE_URL = "https://ssipldev.grainbyte.app/metabase";
// const METABASE_SECRET_KEY = "a31b6c99b734e2c0a25ce01a19530ba9459eb5fc9a8e49f66d19cab2788169aa";

const MetabaseDashboard = () => {
//   // Generate the token
//   const payload = {
//     resource: { dashboard: 1 },
//     params: {},
//     exp: Math.round(Date.now() / 1000) + (10 * 60) // 10-minute expiration
//   };
//   const token = jwt.sign(payload, METABASE_SECRET_KEY);

//   // Generate the iframe URL
//   const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

  return (
    <div style={{width:"100%",height:"100%"}}>   
       {/* Replace this div with your iframe */}
      <iframe src="https://ssipl.grainbyte.app/dashboard/"
       width="100%" height="100%" frameBorder="0"></iframe>
    </div>
  );
};

export default MetabaseDashboard;
// import React from 'react'

// import jwt from 'jsonwebtoken';

// function MetabaseDashboard() {
//   return (
//     <div>MetabaseDashboard</div>
//   )
// }

// export default MetabaseDashboard