import { Backdrop, Paper } from "@material-ui/core";
import React, { Component } from "react";
import { primary } from "../styles/colors";
import domtoimage from "dom-to-image";
import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import moment from "moment";

const styles = {
  paperStyle: (notEmpty) => ({
    //!notEmpty ?
    maxHeight: "calc(100% - 40px)", // : "calc(100% - 115.74px)",

    height: !notEmpty ? "283.75px" : "fit-content",
    borderRadius: "7px",

    width: "100%",
    margin: "20px 0px",
    padding: "20px",
    backgroundColor: "rgba(128, 128, 128, 0.04)",
  }),
};
class SummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryData: [],
      total_weight: 0,
      total_trips: 0,
      colorArr: ["white", "#8080800a"],
      toPrint: false,
    };
  }
  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  setInitialValues(props) {
    this.setState(
      {
        summaryData: props.summaryData ? props.summaryData : [],
      },
      () => {
        //---Below code to get total of each column----
        // if (this.state.summaryData.total_per_transporter) {
        //   this.setState({
        //     total_weight: Number(
        //       summaryData.allTrips.reduce(
        //         (previousValue, currentValue) => Number(previousValue) + Number(currentValue.tare_weight),
        //         0
        //       )
        //     ).toFixed(3),
        //     total_trips: Number(
        //       summaryData.allTrips.reduce(
        //         (previousValue, currentValue) => Number(previousValue) + Number(currentValue.total_trips),
        //         0
        //       )
        //     ),
        //   });
        // }
      }
    );
  }
  downloadImage = (fileName) => {
    this.setState({ toPrint: true }, () => {
      domtoimage
        .toJpeg(document.getElementById("mycanvas"), { quality: 0.95 })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = fileName ? `${fileName}.jpeg` : "image.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .then(() => {
          this.setState({ toPrint: false });
        });
    });
  };
  render() {
    const { summaryData, /*total_weight, total_trips, colorArr,*/ toPrint } = this.state;
    const { copyLiveParty, onlySummary } = this.props;
    return (
      <div className="d-flex width-100 height-100 d-flex-column ">
        {toPrint ? (
          <Backdrop style={{ zIndex: 1, color: "#fff" }} open={toPrint}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}{" "}
        <Paper
          className="d-flex d-flex-column"
          style={styles.paperStyle(summaryData.length > 0)}
          elevation={3}
          component="form"
        >
          {this.props.loading.Komals ? (
            <div className="alignC justiyC d-flex" style={{ width: "100%", height: "100%" }}>
              <StyledCircularProgress color="inherit" />
            </div>
          ) : copyLiveParty && copyLiveParty.length > 0 ? (
            <>
              {
                <div
                  className=" d-flex justifyFE marginB20"
                  style={{
                    width: onlySummary ? "fit-content" : "100%",
                    position: onlySummary && "absolute",
                    top: onlySummary && "20px",
                    right: onlySummary && "20px",
                  }}
                >
                  <StyledButton
                    name={"Download"}
                    withBg={true}
                    width="90px"
                    onClick={() => {
                      this.downloadImage(this.props.fileName);
                    }}
                  />
                </div>
              }

              <div
                className="d-flex d-flex-column scrollBlue"
                style={{
                  overflow: "auto",
                  borderRadius: "7px",
                  minHeight: "fit-content",
                  position: "relative",
                }}
              >
                <div
                  id="mycanvas"
                  style={
                    toPrint
                      ? {
                          height: "auto",
                          width: "fit-content",
                          padding: "20px",
                          borderRadius: "7px",
                          backgroundColor: "white",
                        }
                      : {}
                  }
                >
                  <Paper
                    style={
                      toPrint
                        ? {
                            height: "auto",
                            width: "fit-content",
                            padding: "20px",
                            borderRadius: "7px",
                            backgroundColor: "rgba(128, 128, 128, 0.04)",
                          }
                        : {}
                    }
                  >
                    {toPrint && (
                      <div
                        style={{
                          padding: "0 0 30px 0",
                          height: "50px",
                          width: "fit-content",
                          minWidth: "100%",
                          color: primary,
                          fontWeight: "400",
                        }}
                      >{`Trip dispatch date :- ${moment(new Date()).format("MMMM Do YYYY")}`}</div>
                    )}

                    {toPrint && (
                      <div
                        className="d-flex justifyFE"
                        style={{
                          height: "50px",
                          width: "100%",
                          padding: "30px 0 0 0",
                          minWidth: "100%",
                          color: primary,
                          fontWeight: "400",
                        }}
                      >{`Summary generated at :- ${moment(new Date()).format("MMMM Do YYYY, h:mm A")}`}</div>
                    )}
                  </Paper>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
              <Paper
                style={{
                  borderRadius: "5px 5px 0 0",
                  background: "rgb(30 53 89)",
                  height: "70px",
                  width: "fit-content",
                  minWidth: "100%",
                }}
              />{" "}
              <Paper
                style={{
                  borderRadius: "0px",
                  width: "fit-content",
                  minWidth: "100%",
                }}
              >
                {" "}
                <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                  <span style={{ fontSize: "1rem", margin: "50px 0" }}>Nothing to Show</span>
                </div>
              </Paper>
              <Paper
                style={{
                  borderRadius: "0",
                  background: "#3b69b0",
                  height: "50px",
                  width: "fit-content",
                  minWidth: "100%",
                }}
              />
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

export default SummaryTable;
