import { Tooltip } from "@material-ui/core";


const ToolTipComponent = (props) => {
    return (
        <Tooltip
            title={props?.condition
                ? props.title
                : props.condition2
                    ? props.title2
                    : props.condition3
                        ? props.title3
                        : props.condition4
                            ? props.title4
                            : props.condition5
                                ? props.title5
                                : ''
            }
            TransitionComponent={props.TransitionComponent || 'Zoom'}
            placement={props.placement || 'top-end'}
            onMouseOver={props.condition}
            leaveDelay={100}
            arrow={props?.arrow ?? true}
            style={{
                backgroundColor: props.backgroundColor || "#fff",
                color: props.color || 'rgba(0, 0, 0, 0.87)',
                fontSize: props.fontSize || 12,
                maxWidth: props.maxWidth || 300,
            }}
        >
            <span style={{
                backgroundColor: "inherit",
                color: "inherit",
                fontSize: "inherit",
                width: props?.spanWidth && props.spanWidth
            }}>
                {props.children}
            </span>
        </Tooltip>
    )
}

export default ToolTipComponent;