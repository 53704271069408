import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { CssFilledSelectTextField } from '../styles/StyledComponents'
import { MenuItem } from '@mui/material'
const CssTextField = withStyles({
  root: {
    '& .MuiSelect-select': {
      width: '100%',

      // boxShadow:
      //   '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    
      textTransform: 'capitalize',
      borderRadius: '10px',
      background: 'white',
      padding: '2px 14px', // Reduced padding to make it smaller in height
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(10px, 6px) scale(0.9)', // Adjusted label position and size
    },
    '& .MuiFilledInput-root': {
      background: 'inherit',
      border: '1px solid #ccc',
      borderRadius: '10px',
    },
  },
})(CssFilledSelectTextField)

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: 'auto', // Enable vertical scrolling
    },
  },
};

export default function RaisedSelectBar({
  data,
  labelField,
  selctedData,
  handleSearch,
  hintText,
}) {
  return (
    // <div style={{ minWidth: '90px', margin: '0 10px' }}>
    <CssTextField
      autoComplete="off"
      value={
        selctedData ? selctedData : '' //
      }
      onChange={handleSearch}
      style={{
        width: 'calc(100% - 10px)',
        margin: '10px 5px 0px',
      }}
      name={labelField}
      id="outlined-select-currency-native"
      select
      label={!selctedData && hintText}
      SelectProps={{ MenuProps: MenuProps }}
      variant="filled"
      // InputProps={{
      //   style: {
      //     height: '40px', // Ensure consistent height
      //   },
      // }}
    >
      {data.map((option) => (
        <MenuItem
          key={option[labelField]}
          value={option[labelField]}
          id={option[labelField]}
          style={{ textTransform: 'capitalize', fontFamily: 'Poppins', fontSize: 14 }}
        >
          {option[labelField]}
        </MenuItem>
      ))}
    </CssTextField>

  )
}
