import React, { useEffect } from "react";
import { connect } from "react-redux";
import SurveyCompanysComponent from "./SurveyCompanysComponent";
import { SurveyCompanysFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function SurveyCompanysContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    // if (props.user.uid) {
    props.SurveyCompanysFunction("get", EndPoints["SurveyCompanys"]);
    //}
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <SurveyCompanysComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  SurveyCompanys: state.modules.SurveyCompanys,
  loading: state.modules.loading,
  [EndPoints["SurveyCompanys"]]: state.modules[EndPoints["SurveyCompanys"]],
});
const mapDispatchToProps = {
  SurveyCompanysFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyCompanysContainer);
