import React from "react";
import RightIcon from "../layoutComponents/RightIcon";

function CustomAppBar(props) {
  return (
    <div style={{width:"100%",height:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
      <div style={{ width: "80%" }}></div>
      <div style={{ width: "20%" }}>
        <RightIcon {...props} />
      </div>
    </div>
  );
}

export default CustomAppBar;
