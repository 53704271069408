import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { useSnackbar } from "../snackbarContext";
import { fetchRecursivePaginatedData } from "../Regex";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import moment from "moment";
import { CSVLink } from "react-csv";
import { primary } from "../styles/colors";

export const CSVModuleDownload = (props) => {
  const { apiCall, fileName, header, itemHeader, mappingArr } = props.data;

  const [fetchReportProgress, setFetchReportProgress] = useState(0);
  const [responseData, setResponseData] = useState({
    header: [],
    data: [],
  });
  const [clicked, setClicked] = useState(false);
  const showSnackbar = useSnackbar();
  const refCSVLink = createRef();

  useEffect(() => {
    if (responseData.data?.length > 0) {
      refCSVLink.current.link.click();
    }
  }, [responseData?.data]);

  const handleCountStateUpdate = (data) => {
    setFetchReportProgress(data);
  };

  const getNestedValue = (obj, key) => {
    console.log(obj, key);
    return key
      .split(".")
      .reduce((acc, curr) => (acc[curr] ? acc[curr] : ""), obj);
  };

  const handleResponseData = (responseDataList, type = "") => {
    console.log(responseDataList, header, mappingArr);
    let headerArray = header ? header.map((item) => item.label) : [] ;
    let csvData = [];
    responseDataList.map((data) => {
      if (mappingArr && mappingArr?.length > 0) {
        data[mappingArr[0]].map((item, itemIndex) => {
          if (mappingArr[1]) {
            item[mappingArr[1]].map((itemNested, nestedIndex) => {
              let csvheaderRow = {};
              let csvItemRow = {};
              let csvNestedItemRow = {};

              if (nestedIndex === 0 && itemIndex === 0) {
                header && header.map((h, i) => {
                    if (headerArray[i].includes("Date")) {
                      csvheaderRow[headerArray[i]] = getNestedValue(data, h.key)
                        ? moment(getNestedValue(data, h.key)).format(
                            h?.format || "MMM Do YYYY"
                          )
                        : "";
                    } else {
                      csvheaderRow[headerArray[i]] = h?.key2
                        ? getNestedValue(data, h.key) +
                          (h?.key2Prefix ? h?.key2Prefix : "") +
                          (getNestedValue(data, h.key2) || "")
                        : getNestedValue(data, h.key) || "";
                    }
                });
              }
              else if(nestedIndex > 0){
                csvheaderRow = {}
              }

              itemHeader.map((h, i) => {
                let secondMapKey = h.key.includes(mappingArr[1]);
                let key2dotCount = h.key2 ? h.key2?.split(".").length - 1 : 0;
                console.log(h.label, secondMapKey, h.key2);
                if (secondMapKey) {
                  csvNestedItemRow[h.label] = h.key2
                    ? getNestedValue(
                        itemNested,
                        h.key.replace(
                          mappingArr[0] + "." + mappingArr[1] + ".",
                          ""
                        )
                      ) +
                      (h?.key2Prefix ? h?.key2Prefix : "") +
                      (getNestedValue(
                        key2dotCount >= 2
                          ? itemNested
                          : key2dotCount === 1
                          ? item
                          : data,
                        h.key2.replace(
                          mappingArr[0] + "." + mappingArr[1] + ".",
                          ""
                        )
                      ) || "")
                    : getNestedValue(
                        itemNested,
                        h.key.replace(
                          mappingArr[0] + "." + mappingArr[1] + ".",
                          ""
                        )
                      );
                } else {
                  if (nestedIndex === 0) {
                    csvItemRow[h.label] = h.key2
                      ? getNestedValue(
                          item,
                          h.key.replace(mappingArr[0] + ".", "")
                        ) +
                        (h?.key2Prefix ? h?.key2Prefix : "") +
                        (getNestedValue(
                          key2dotCount >= 2
                          ? itemNested
                          : key2dotCount === 1
                          ? item
                          : data,
                          h.key2.replace(mappingArr[0] + ".", "")
                        ) || "")
                      : getNestedValue(
                          item,
                          h.key.replace(mappingArr[0] + ".", "")
                        );
                  }
                  else if(nestedIndex > 0){
                    csvItemRow = {}
                  }
                }
              });
              let csvRowData = {
                ...csvheaderRow,
                ...csvItemRow,
                ...csvNestedItemRow,
              };
              console.log("if secondMap", csvRowData);
              csvData.push(csvRowData);
            });
          } else {
            let csvheaderRow = {};
            let csvItemRow = {};

            if (itemIndex === 0) {
              header && header.map((h, i) => {
                  if (headerArray[i].includes("Date")) {
                    csvheaderRow[headerArray[i]] = getNestedValue(data, h.key)
                      ? moment(getNestedValue(data, h.key)).format(
                          h?.format || "MMM Do YYYY"
                        )
                      : "";
                  } else {
                    csvheaderRow[headerArray[i]] = h?.key2
                      ? getNestedValue(data, h.key) +
                        (h?.key2Prefix ? h?.key2Prefix : "") +
                        (getNestedValue(data, h.key2) || "")
                      : getNestedValue(data, h.key) || "";
                  }
              })
            }

            itemHeader.map((h, i) => {
              let key2dotCount = h.key2 ? h.key2?.split(".").length - 1 : 0;
              csvItemRow[h.label] = h.key2
                ? getNestedValue(item, h.key.replace(mappingArr[0] + ".", "")) +
                  (h?.key2Prefix ? h?.key2Prefix : "") +
                  getNestedValue(
                    key2dotCount === 1
                          ? item
                          : data,
                    h.key2.replace(mappingArr[0] + ".", ""))
                : getNestedValue(item, h.key.replace(mappingArr[0] + ".", ""));
            });

            let csvRowItemData = { ...csvheaderRow, ...csvItemRow };
            console.log("else singleMap", csvRowItemData);
            csvData.push(csvRowItemData);
          }
        });
      } else {
        let csvRow = {};
        header && header.map((h, i) => {
          if (headerArray[i].includes("Date")) {
            csvRow[headerArray[i]] = getNestedValue(data, h.key)
              ? moment(getNestedValue(data, h.key)).format(
                  h?.format || "MMM Do YYYY"
                )
              : "";
          } else {
            csvRow[headerArray[i]] = h?.key2
              ? getNestedValue(data, h.key) +
                (h?.key2Prefix ? h?.key2Prefix : "") +
                (getNestedValue(data, h.key2) || "")
              : getNestedValue(data, h.key) || "";
          }
        });
        csvData.push(csvRow);
      }
    });
    if (itemHeader) {
      let headerItemArray = itemHeader.map((item) => item.label);
      headerArray.push(...headerItemArray);
    }
    console.log(headerArray, csvData);
    setResponseData({
      header: headerArray,
      data: csvData,
    });
  };

  const handleButtonClick = () => {
    setClicked(true);
    fetchRecursivePaginatedData(
      apiCall.method,
      apiCall.url,
      apiCall.params,
      "",
      handleCountStateUpdate
    )
      .then(({ responseDataList }) => {
        console.log(responseDataList);
        // setFetchReportProgress(0);
        handleResponseData(responseDataList);
        setClicked(false);
      })
      // .catch(({ error, responseDataList }) => {
      //   console.log(error);
      //   if (responseDataList === undefined) {
      //   } else {
      //     handleResponseData(responseDataList, "error");
      //   }
      //   error && showSnackbar(error?.message, "error");
      //   // setFetchReportProgress(0);
      //   setClicked(false);
      // });
  };

  return (
    <>
      <Button
        onClick={handleButtonClick}
        variant="outlined"
        size="small"
        disabled={clicked}
        style={{ 
          fontFamily: "Poppins", 
          width: 70, 
          margin: '0 10px 0 0' ,
          color: primary, 
          borderColor: primary,
          borderWidth: 2,
        }}
        startIcon={
          clicked ? (
            <Box style={{ position: "relative", height: 20 }}>
              <CircularProgress
                variant="determinate"
                style={{ color: "#ddd" }}
                size={20}
                thickness={6}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 2,
                  // color: "#4B87C5",
                  color: primary
                }}
                size={20}
                thickness={6}
                value={
                  fetchReportProgress === undefined ? 0 : fetchReportProgress
                }
              />
            </Box>
          ) : (
            <CloudDownloadOutlinedIcon />
          )
        }
      >
        {clicked ? (
          <Typography
            style={{
              fontFamily: "Poppins",
              // color: "#4B87C5",
              color: primary,
              fontSize: "0.9rem",
            }}
            variant="body1"
            component="div"
            // color="text.secondary"
          >
            {`${Math.round(
              fetchReportProgress === undefined ? 0 : fetchReportProgress
            )}%`}
          </Typography>
        ) : (
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "0.9rem",
              textTransform: "lowercase",
            }}
          >
            .csv
          </Typography>
        )}
      </Button>
      <CSVLink
        data={responseData.data || []}
        headers={responseData.header}
        filename={`${fileName} ${moment().format("DD-MM-YYYY , h-mm")}.csv`}
        className="hidden"
        ref={refCSVLink}
        target="_blank"
      />
    </>
  );
};
