import React, { useEffect } from "react";
import { connect } from "react-redux";
import IncotermsComponent from "./IncotermsComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function IncotermsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction("get", EndPoints["Incoterms"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <IncotermsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  Incoterms: state.modules.Incoterms,
  [EndPoints["Incoterms"]]: state.modules[EndPoints["Incoterms"]],
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncotermsContainer);
