import { loginURL, verifyOtpURL } from "../../http/config";
import { requests } from "../../requests";

export const verifyOtp = (otp, onLogin) => async (dispatch, getState) => {
  try {
    dispatch({ type: "AUTH_USER_REQUEST" });
    const options = {
      method: "post",
      url: verifyOtpURL,
      data: JSON.stringify(otp),
    };
    const response1 = await requests(options).then((response) => {
      if (response.error) {
        return { error: true, msg: response.data };
      } else {
        const data = response.data.data;
        const user_id = response.data.user_id;
        
        console.log(data,"editListeditList")
        const user = {
          uid: data.token, //"4300261de1a00f95318e76846b885e5ba87e5c62",
          username: data.first_name,
          email: data.email,
          userData: data,
          roles: data.roles,
          accessibleModules: data.accessibleModules,
          id: user_id,
          isLoggedIn: true,
          status: data.status,
        };
        const localStorageUser = {
          uid: data.token,
          id: user_id,
          username: data.first_name,
          email: data.email,
          roles: data.roles,
          isLoggedIn: true,
          accessibleModules: data.accessibleModules,

          status: data.status,
        };

        const newFunc = async () => {
          await localStorage.setItem(
            "ssipluser",
            JSON.stringify(localStorageUser)
          );
        };
        newFunc().then(() => {
          const users = localStorage.getItem("ssipluser")
            ? JSON.parse(localStorage.getItem("ssipluser"))
            : "";

          if (users && onLogin) {
            onLogin().then(() => {
              dispatch({
                type: "AUTH_USER_LOGGEDIN",
                payload: user,
              });
            });
          }
        });
        return response.error ? response : response.data;
      }
    });

    return await response1;
  } catch (error) {
    return {
      error: true,
      msg: error.response
        ? error.response.data
        : "Some error occured, please try again!",
    };
  }
};

export const LoginFunction = (userData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "AUTH_USER_REQUEST" });
    const options = {
      method: "post",
      url: loginURL,
      data: {
        email: userData.email,
        password: userData.password,
      },
    };
    const response1 = await requests(options).then((response) => {
      console.log(response, "response");
      if (response.error) {
        return response;
      } else {
        response.data = userData;
        const user = {
          email: userData.email,
          password: userData.password,
          accessibleModules: ["all"],
        };

        dispatch({ type: "AUTH_USER_LOGGEDIN", payload: user });
        return response;
      }
    });
    return await response1;
  } catch (error) {
    return {
      error: true,
      msg: error.response
        ? error.response.data
          ? error.response.data
          : error.response.msg
        : "Some error occured, please try again!",
    };
  }
};

export const LogOutFunction = () => async (dispatch, getState) => {
  localStorage.removeItem("ssipluser");

  dispatch({ type: "AUTH_USER_LOGGEDIN", payload: null });
  return {
    error: false,
  };
};
