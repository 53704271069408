import { useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { color3, primary } from "../styles/colors";
import { Popover, Typography } from "@mui/material";
import { Button, Divider, Tooltip } from "@material-ui/core";
import { mediumScreen } from "../Regex";

const TableDataHover = (props) => {
  const { rowHeader, rowList, subList } = props;

  const STATUS = [
    "paid",
    "Paid",
    "PAID",
    "pending",
    "Pending",
    "PENDING",
    "unpaid",
    "Unpaid",
    "UNPAID",
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  
  return rowHeader.secondLinerHover?.length > 0 ? (
    <>
      {rowHeader?.secondLiner !== "" ? (
        <Tooltip title={rowHeader?.secondLiner}>
          <Button
            aria-describedby={id}
            variant="text"
            onClick={handlePopoverOpen}
            style={{
              width: "fit-content",
              fontSize: "0.7em",
              fontFamily: "Poppins",
              cursor: "pointer",
              padding: STATUS.includes(rowList[rowHeader?.secondLiner])
                ? "0px"
                : "inherit",
              background: STATUS.includes(rowList[rowHeader?.secondLiner])
                ? rowList[rowHeader?.secondLiner] === "PAID"
                  ? "#ECFFF0"
                  : rowList[rowHeader?.secondLiner] === "PENDING"
                  ? "#FFFFED"
                  : rowList[rowHeader?.secondLiner] === "UNPAID"
                  ? "#FFCFCF"
                  : "inherit"
                : "inherit",
              borderRadius:
                STATUS.includes(rowList[rowHeader?.secondLiner]) && "16px",
              border:
                STATUS.includes(rowList[rowHeader?.secondLiner]) &&
                `1px solid ${
                  rowList[rowHeader?.secondLiner] === "PAID"
                    ? "#4CAF50"
                    : rowList[rowHeader?.secondLiner] === "PENDING"
                    ? color3
                    : rowList[rowHeader?.secondLiner] === "UNPAID"
                    ? "#D32F2F"
                    : "inherit"
                }`,
              color: STATUS.includes(rowList[rowHeader?.secondLiner])
                ? rowList[rowHeader?.secondLiner] === "PAID"
                  ? "#4CAF50"
                  : rowList[rowHeader?.secondLiner] === "PENDING"
                  ? color3
                  : rowList[rowHeader?.secondLiner] === "UNPAID"
                  ? "#D32F2F"
                  : "inherit"
                : open
                ? primary
                : "inherit",
              textTransform: "capitalize",
              textDecoration: "underline",
            }}
          >
            {rowList[rowHeader?.secondLiner]}
            {/* </Typography> */}
          </Button>
        </Tooltip>
      ) : (
        <span aria-describedby={id} onClick={handlePopoverOpen}>
          <InfoOutlinedIcon
            style={{
              color: open ? primary : "inherit",
              transform: "scale(0.9)",
              paddingTop: 5,
              cursor: "pointer",
            }}
          />
        </span>
      )}
      <Popover
        id={id}
        // sx={{ pointerEvents: "none", fontFamily: "Poppins" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <Typography sx={{ fontFamily: "Poppins" }}>
          <div style={{ width: "100%", minWidth: 260, padding: "4% 3%" }}>
            {rowHeader.secondLinerHover &&
              rowHeader.secondLinerHover.map((item, i) => (
                <>
                  <Typography
                    className="d-flex justifySB"
                    style={{ fontFamily: "Poppins", whiteSpace: "nowrap" }}
                  >
                    <div
                      style={{
                        // color: "#3B69B0",
                        color: "rgb(88,88,88)",
                        fontWeight: "bold",
                        width: "80%",
                        fontSize:  mediumScreen() ? "0.7rem" : "0.8rem",
                        marginRight: "5px",
                        width: "46%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item}
                    </div>
                    <div
                      style={{
                        color: "#575656",
                        textAlign: "right",
                        fontWeight: 500,
                        fontSize:  mediumScreen() ? "0.7rem" : "0.8rem",
                        width: "54%",
                        whiteSpace: "wrap",
                      }}
                    >
                      {rowList[`${item}Unit`]
                        ? ` ${rowList[`${item}Unit`]} `
                        : rowList[item]}
                      {/* {console.log(item, rowList[item])} */}
                    </div>
                  </Typography>
                  {i !== rowHeader.secondLinerHover.length - 1 && (
                    <Divider variant="middle" style={{ margin: "2% 0" }} />
                  )}
                </>
              ))}
          </div>
        </Typography>
      </Popover>
    </>
  ) : rowHeader.secondLiner !== "Status" && !rowHeader?.secondLinerComp?.visible ? (
    <Tooltip title={rowHeader?.secondLiner}>
      <Typography
        style={{
          fontSize: "0.75em",
          fontFamily: "Poppins",
          textTransform: "capitalize",
        }}
      >
        {typeof rowList[rowHeader?.secondLiner] === "function"
          ? rowList[rowHeader?.secondLiner]()
          : rowList[rowHeader?.secondLiner]}
      </Typography>
    </Tooltip>
  ) : rowHeader?.secondLiner === "" &&
    rowHeader?.secondLinerComp?.visible &&
    rowHeader?.secondLinerComp?.sameLine ? (
      typeof rowList[rowHeader?.secondLinerComp?.component] === "function" ? (
        <span>{rowList[rowHeader.secondLinerComp.component]()}</span>
      ) : (
        <Typography
          style={{
            fontSize: "0.75em",
            fontFamily: "Poppins",
            textTransform: "capitalize",
          }}
        >
          {rowList[rowHeader.secondLinerComp.component]}
        </Typography>
      )
    ) : (
    <Typography
      style={{
        fontSize: "0.75em",
        fontFamily: "Poppins",
        textTransform: "capitalize",
      }}
    >
      {typeof rowList[rowHeader?.secondLiner] === "function"
        ? rowList[rowHeader?.secondLiner]()
        : rowList[rowHeader?.secondLiner]}
    </Typography>
  );
};

export default TableDataHover;
