import React, { useEffect } from "react";
import { connect } from "react-redux";
import PaymentTermsComponent from "./PaymentTermsComponent";
import { PaymentTermsFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function PaymentTermsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    //if (props.user.uid) {
    props.PaymentTermsFunction("get", EndPoints["PaymentTerms"]);
    //  }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <PaymentTermsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  PaymentTerms: state.modules.PaymentTerms,
  loading: state.modules.loading,
  [EndPoints["PaymentTerms"]]: state.modules[EndPoints["PaymentTerms"]],
});
const mapDispatchToProps = {
  PaymentTermsFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentTermsContainer);
