import React from "react";
import { Worker, CharacterMap } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import Viewer, { RenderToolbar, Slot, SpecialZoomLevel } from "@phuocng/react-pdf-viewer";

interface WithoutToolbarExampleProps {
  fileUrl: string;
}
const characterMap: CharacterMap = {
  isCompressed: true,
  url: "https://unpkg.com/pdfjs-dist@3.6.172/cmaps/",
};

const WithoutToolbarExample: React.FC<WithoutToolbarExampleProps> = ({ fileUrl }) => {
  const layout = (
    isSidebarOpened: boolean,
    container: Slot,
    main: Slot,
    toolbar: RenderToolbar,
    sidebar: Slot
  ): React.ReactElement => {
    return (
      <div
        {...container.attrs}
        style={{
          border: "none",
          height: "100%",
          overflow: "hidden",
          width: "100%",
          position: "absolute",
        }}
      >
        {container.children}
        <div
          {...main.attrs}
          style={Object.assign(
            {},
            {
              height: "100%",
              position: "absolute",
              overflow: "hidden",
            },
            main.attrs.style
          )}
        >
          {main.children}
        </div>
      </div>
    );
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
      <Viewer characterMap={characterMap} fileUrl={fileUrl} layout={layout} defaultScale={SpecialZoomLevel.PageFit} />
    </Worker>
  );
};

export default WithoutToolbarExample;
