import React from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import * as fns from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const MuiDateRangePicker = (props) => {
    const classes = useStyles();
    const format = props.fomat || "yyyy-MM-dd";


    const getDisplayDateRange = (dateRange) => {
        const startDate = dateRange?.startDate
            ? fns.format(dateRange.startDate, format)
            : undefined;

        const endDate = dateRange?.endDate
            ? fns.format(dateRange.endDate, format)
            : undefined;

        return startDate || endDate ? `${startDate} - ${endDate}` : "";
    };

    return (
        <>
            <Modal
                className={classes.modal}
                open={props.open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <DateRangePicker
                                open={true}
                                toggle={props.toggle}
                                closeOnClickOutside={true}
                                onChange={(range) => {
                                    props.onDateRangeChange(range);
                                    props.toggle();
                                }}
                                initialDateRange={props.dateRange}
                            />
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export { MuiDateRangePicker };