import React from "react";

const styles = {
  container: {
    whiteSpace: "nowrap",
    fontSize: 12,
    color: "white",
    paddingRight: 100,width:"calc(100% - 48px)"

  },
};

export default function StaticVersionDisplay(props) {
  return <span style={styles.container} className="d-flex justifyC">Version {props.version}</span>;
}
