import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import CreateDialogForPort from "../../layoutComponents/CreateDialogForPort";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import NoData from "../../styles/Illustrations/NoData";
import Icon from "@mui/material/Icon";
import { ModeEdit } from "@mui/icons-material";
import Tooltip from "@material-ui/core/Tooltip";

import { EndPoints } from "../../utils/EndPoints";
import { Paper } from "@material-ui/core";
import Popup from "./components/Popup";
import EditForm from "./components/EditForm";
import NewForm from "./components/NewForm";
import moment from "moment";

var surveyorId = [];

export class SurveyCompanysComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["SurveyCompanys"],
        tooltip: `Click to approve pending ${EndPoints["SurveyCompanys"]}`,
        data: [],
        route: `/SurveyCompanys/${EndPoints["SurveyCompanys"]}`,
        goBackRoute: "/SurveyCompanys",
        primaryField: "name",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.setStateOfPopup.bind(this);
    this.state = {
      tabs: templateTabs,
      SurveyCompanys: [],
      singlelistView: null,
      surveyDialog: false,
      editList: false,
      loading: false,
      isPopup: false,
      isNew: false,
      errorMsg: { Godown: false },
    };
  }

  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["SurveyCompanys"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.SurveyCompanysFunction(
                "get",
                this.props.user.uid,
                EndPoints["SurveyCompanys"],
                this.state.singlelistView.id,
                "SurveyCompanys"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState(
      {
        singlelistView: value,
      },
      () => {
        // if (this.state.singlelistView && this.state.singlelistView.id) {
        //   this.props.SurveyCompanysFunction(
        //     "get",
        //     EndPoints["SurveyCompanys"],
        //     this.state.singlelistView.id,
        //     "SurveyCompanys"
        //   );
        // }
      }
    );
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
    // if (value && value.monthId) {
    //   if (this.state.selectedMonth.monthId !== value.monthId) {
    //     this.setState({ selectedMonth: value, singleDate: null, singleDateTrips: [], singleDateTripsCopy: [] });
    //     this.props.fetchlistViewArray(this.props.user.user.uid, value.monthId, value.yearId).then((dateRR) => {
    //       this.props.setlistViewArray(dateRR);
    //     });
    //   }
    // }
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["SurveyCompanys"]] &&
        props[EndPoints["SurveyCompanys"]].length > 0
          ? props[EndPoints["SurveyCompanys"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    console.log( props[EndPoints["SurveyCompanys"]] &&
    props[EndPoints["SurveyCompanys"]].length > 0
      ? props[EndPoints["SurveyCompanys"]].sort((a, b) => {
          var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
          var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
          return new Date(dateA) - new Date(dateB);
        })
      : [],"survaycompanies")
    this.setState({
      listViewArray:
        props[EndPoints["SurveyCompanys"]] &&
        props[EndPoints["SurveyCompanys"]].length > 0
          ? props[EndPoints["SurveyCompanys"]]
          : [],
      SurveyCompanys: props.SurveyCompanys,
      tabs,
      loading: props.loading,
    });
  }

  deleteItem = (godownNameToDelete) => {
    let { New } = this.state;

    let filteredGodown = New.filter(
      (godownName) => godownName !== godownNameToDelete
    );

    this.setState({
      New: filteredGodown,
    });
  };

  addClick = () => {
    if (!this.state.New.includes("")) {
      this.setState((prevState) => ({ New: [...prevState.New, ""] }));
    } else {
      this.setState({ error: "add" });
    }
  };
  handleChange1 = (i, event) => {
    let New = [...this.state.New];

    if (New[i] && New[i].name) {
      New[i].name = event.target.value;
    } else {
      New[i] = { name: event.target.value };
    }
    this.setState({ New, errorMsg: { ...this.state.errorMsg, Godown: false } });
  };
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  render() {
    const {
      tabs,
      listViewArray,
      SurveyCompanys,
      singlelistView,
      loading,
      editList,
      surveyDialog,
    } = this.state;
    // const { isMobile ,user} = this.props;
    // var labelField = "title";
    // if (listViewArray && listViewArray.length > 0 && listViewArray[0]) {
    //   Object.keys(listViewArray[0]).map((sKey) => {
    //     if (typeof listViewArray[0][sKey] === "string") {

    //       return (labelField = sKey);
    //     } else {
    //       return null;
    //     }
    //   });
    // }
    var labelField = "name"; //assign search field
    return (
      <Layout
        loading={loading[EndPoints["SurveyCompanys"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Survey Companies`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          // this.setState({
          //   surveyDialog: true,
          //   loadingFun: false,
          //   editList: false,
          // });
          this.setState({
            isPopup: true,
            isNew: true,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        <Paper
          className="width-100 height-100 padding15 backgroundWhite"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.SurveyCompanys === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <div style={{ width: "90%" }}>
                <h1 style={{ fontWeight: "400", fontSize: "32px" }}>
                  Survey Company Details
                </h1>
              </div>
              <Paper
                className="padding15 backgroundWhite  postionR overflowYA scrollBluePrimary"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "4%",
                  }}
                >
                  <Tooltip title="Edit">
                    <Icon
                      onClick={() => this.setState({ isPopup: true })}
                      style={{ cursor: "pointer" }}
                      component={ModeEdit}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 50px",
                    height: "calc(100% - 31.7px)",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Name:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        marginLeft: "10px",
                        color: "#808080",
                        margin: "8px 10px",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.name}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Phone:
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.phone}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Address:
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.address}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      GSTIN:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.gstin}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Contact Person:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.contact_person}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      City:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.city}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      State:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.state}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Pincode:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.pincode}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Surveyors:
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {singlelistView.surveyors_detail.map((item) => (
                        <p
                          style={{
                            fontSize: "18px",
                            marginLeft: "10px",
                            color: "#808080",
                            margin: "8px 10px",
                          }}
                        >
                          {item.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </Paper>
            </>
          )}
        </Paper>

        {/* {surveyDialog === true || editList !== false ? (
          <CreateDialogForPort
            tableData={() => {
              return (
                <>
                  <div>
                    <div style={{ margin: "10px 0" }}>
                      <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Add Surveyor{" "}
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      {New.map((godownName, i) => {
                        return (
                          <>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CssTextField
                                required={true}
                                type="text"
                                autoComplete="off"
                                style={
                                  {
                                    // width: "calc(50% - 20px)",
                                    marginTop: "15px",
                                    // display: "flex",
                                  } //disabled={tp}
                                }
                                error={errorMsg.Godown ? true : false}
                                helperText={
                                  errorMsg.Godown ? errorMsg.Godown : ""
                                }
                                id="outlined-basic"
                                label="Surveyor Name"
                                variant="outlined"
                                name="Godown"
                                value={godownName ? godownName.name : ""}
                                onChange={(e) => {
                                  this.handleChange1(i, e);
                                }}
                              />
                              <div style={{ display: "flex" }}>
                                <DeleteIcon
                                  color="error"
                                  style={{
                                    marginLeft: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (!godownName.id)
                                      this.deleteItem(godownName);
                                    else
                                      this.setState({
                                        deleteDialog: godownName,
                                      });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {editList && deleteDialog !== false ? (
                        <Dialog
                          open={true}
                          onClose={() => this.handleClose(false)}
                          aria-labelledby="confirm-dialog"
                        >
                          <DialogTitle id="confirm-dialog">
                            Make Changes ?
                          </DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this Surveyor ?
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              onClick={this.handleClose}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}
                            >
                              No
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                this.props
                                  .PortsFunction(
                                    "delete",
                                    "masters/surveyor",
                                    deleteDialog && deleteDialog.id,
                                    "postdata",
                                    null
                                  )
                                  .then((res3) => {
                                    if (res3.error) {
                                      this.setState({
                                        error: res3.data,
                                        loading: false,
                                      });
                                    } else {
                                      this.setState(
                                        {
                                          success: editList
                                            ? `${module} Edited`
                                            : "",
                                          deleteDialog: false,
                                        },
                                        () => {
                                          this.props
                                            .PortsFunction(
                                              "get",
                                              EndPoints["SurveyCompanys"]
                                            )
                                            .then((res) => {
                                              if (
                                                res &&
                                                res.length > 0 &&
                                                singlelistView &&
                                                singlelistView.id
                                              ) {
                                                res.map((s) => {
                                                  if (
                                                    s.id === singlelistView.id
                                                  ) {
                                                    this.setState({
                                                      singlelistView: s,
                                                      New:
                                                        s.godown_details &&
                                                        s.godown_details
                                                          .length > 0
                                                          ? s.godown_details
                                                          : [""],
                                                    });
                                                  }
                                                  return s;
                                                });
                                              }
                                            });
                                          // if (editList) {
                                          //   this.props.fetchAgain();
                                          // }
                                        }
                                      );
                                    }
                                  });
                              }}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ""
                      )}
                    </div>

                    <Button
                      style={{
                        justifyContent: "left",
                        border: "1px solid rgb(30 53 89)",
                        padding: "3px 10px",
                        margin: "15px 0",
                      }}
                      onClick={this.addClick}
                    >
                      Add Sruveyor
                    </Button>
                  </div>
                </>
              );
            }}
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            // onEditUpdate={(res) => {
            // if(res) { this.setState({ singlelistView: res,})}
            // }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}=
            module={"Survey Company"}
            selectedList={false}
            editList={editList ? editList : false}
            reset={() => {
              this.setState({
                loadingFun: false,
                editList: false,
                New: [""],
              });
            }}
            handleClose={() => {
              this.setState({
                surveyDialog: false,
                loadingFun: false,
                editList: false,
                New: [""],
              });
            }}
            settError={() => {
              this.setState({
                errorMsg: { ...errorMsg, Godown: "Please add Surveyor" },
              });
            }}
            postApi="masters/survey-company/list"
            tableValueDetailArray={New}
            editApi="masters/survey-company"
            inputArray={[
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "phone number",
                type: "textField",
                dataType: "number",
                onInput: "phone_number",
                only_number: true,
                not_required: true,
                api: "phone",
                defaultValue: {
                  value: editList && editList.phone,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                defaultValue: {
                  value: editList && editList.address,
                },
                api: "address",
              },
              {
                name: "contract person",
                type: "textField",
                dataType: "text",
                defaultValue: {
                  value: editList && editList.contact_person,
                },
                api: "unloading_agent",
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                defaultValue: {
                  value: editList && editList.city,
                },
              },
              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },

              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },
            ]}
            postData={this.props.PortsFunction}
            fetchAgain={() => {
              this.props
                .PortsFunction("get", EndPoints["Ports"])
                .then((res) => {
                  if (
                    res &&
                    res.length > 0 &&
                    singlelistView &&
                    singlelistView.id
                  ) {
                    res.map((s) => {
                      if (s.id === singlelistView.id) {
                        this.setState({ singlelistView: s });
                      }
                      return s;
                    });
                  }
                });
            }}
          />
        ) : (
          ""
        )} */}

        <Popup
          isNew={this.state.isNew}
          openPopup={this.state.isPopup}
          setOpenPopup={this.setStateOfPopup}
        >
          {this.state.isNew ? (
            <NewForm
              closePopup={this.setStateOfPopup}
              getFn={() =>
                this.props.SurveyCompanysFunction(
                  "get",
                  EndPoints["SurveyCompanys"]
                )
              }
            />
          ) : (
            singlelistView !== null && (
              <EditForm
                isPopup={this.state.isPopup}
                getFn={(response) => {
                  if (response&&response.data) {
                    this.setState({ singlelistView: response.data });
                  }
                  this.props.SurveyCompanysFunction(
                    "get",
                    EndPoints["SurveyCompanys"]
                  );
                }}
                // getFnSurvey={(res) => {
                //   if (res.data) {
                //     this.setState({ singlelistView: res.data });
                //   }
                // }}
                companyId={singlelistView.id}
                name={singlelistView.name}
                phone={singlelistView.phone}
                address={singlelistView.address}
                gstin={singlelistView.gstin}
                contactPerson={singlelistView.contact_person}
                city={singlelistView.city}
                state={singlelistView.state}
                pincode={singlelistView.pincode}
                surveyors={singlelistView.surveyors_detail}
                status={singlelistView.is_active}
                closePopup={this.setStateOfPopup}
              />
            )
          )}
        </Popup>
      </Layout>
    );
  }
}

export default SurveyCompanysComponent;
