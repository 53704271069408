
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { connect, useDispatch } from "react-redux";
import { AppName } from "./Defaults";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { StyledLoader } from "./styles/StyledComponents";
import routes from "./routes";
import LoginContainer from "./Modules/Login/LoginContainer";
import { BrandsManagementsFunction } from "./redux/index";
import "./styles/styles.css";
import { filterData } from "./Regex";
function App(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  document.title = AppName;
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem("ssipluser")
      ? JSON.parse(localStorage.getItem("ssipluser"))
      : "";

    setUser(user);
    if (user.isLoggedIn && user.uid && user.id) {
      setLoading(true);
      props.BrandsManagementsFunction("get", "users", user.id).then((res) => {
        if (res && res.id) {
          console.log(res, "user details");
          // res.modules_access.push('Reports')
          const users = {
            uid: user.uid,
            username: res.first_name,
            email: res.email,
            userData: user,
            roles: res.role,
            accessibleModules: res.modules_access,
            id: res.id,
            isLoggedIn: true,
            status: res.status,
          };
          const localStorageUser = {
            uid: user.uid,
            id: res.id,
            username: res.first_name,
            email: res.email,
            roles: res.role,
            isLoggedIn: true,
            accessibleModules: res.modules_access,
            status: res.status,
          };
          localStorage.setItem("ssipluser", JSON.stringify(localStorageUser));

          dispatch({
            type: "AUTH_USER_LOGGEDIN",
            payload: users,
          });
          setLoading(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    const user = localStorage.getItem("ssipluser")
      ? JSON.parse(localStorage.getItem("ssipluser"))
      : "";

    setUser(user);
  }, [props.user]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isMobile, setMobile] = React.useState(width <= 500);
  const handler = useCallback(() => {
    setWidth(window.innerWidth);
    setMobile(width <= 500);
  }, [setWidth]);
  useEventListener("resize", handler);
  const { loader } = props;
  const resturnFirstRpute=()=>{
    if(user.accessibleModules.includes("Purchase Orders")){
      return "Purchase Orders"
    }else if(user.accessibleModules.includes("Sales Orders")){
      return "Sales Orders"
    }else if(user.accessibleModules.includes("Ports")){
      return "Ports"
    }else{
      return user.accessibleModules[0]
    }
  }
  return (
    <Router>
      <Fragment>
        {loader || loading === true ? (
          <div className="mainDivForLogin">
            <StyledLoader />
          </div>
        ) : (
          <Routes>
            {console.log(user, "useruser")}
            {user &&
            user.isLoggedIn &&
            user.accessibleModules.length > 0 &&
            user.status === "approved" ? (
              user.accessibleModules.includes("Homes") ? (
                <>
                  {window.location.pathname !== "/Homes" && (
                    <Route
                      path={"/pending"}
                      element={
                        <Navigate to={"/Homes"} replace={true} />
                      }
                    />
                  )}
                  <Route
                    path={`/login`}
                    element={<Navigate to={"/Homes"} replace={true} />}
                  />
                  <Route
                    path={`/`}
                    element={<Navigate to={"/Homes"} replace={true} />}
                  />
                </>
              ) : filterData(
                  routes,
                  "access",
                  resturnFirstRpute(),
                  "onlyOne"
                ) ? (
                <>
                  <Route
                    path={`/login`}
                    element={
                      <Navigate
                        to={
                          filterData(
                            routes,
                            "access",
                            resturnFirstRpute()
                          )[2].path
                        }
                        replace={true}
                      />
                    }
                  />
                  <Route
                    path={`/`}
                    element={
                      <Navigate
                        to={
                          filterData(
                            routes,
                            "access",
                            resturnFirstRpute()
                          )[2].path
                        }
                        replace={true}
                      />
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : user && user.isLoggedIn && user.accessibleModules.length > 0 ? (
              <>
                {window.location.pathname !== "/pending" && (
                  <Route
                    path={window.location.pathname}
                    element={<Navigate to={"/pending"} replace={true} />}
                  />
                )}
              </>
            ) : (
              <>
                <Route
                  path={`/`}
                  element={<Navigate to="/login" replace={true} />}
                />
                <Route
                  path={`/Homes`}
                  element={<Navigate to="/login" replace={true} />}
                />
              </>
            )}
            <Route
              exact
              path="/login"
              element={<LoginContainer {...props} isMobile={isMobile} />}
            />

            {routes.map((route, i) => {
              return (
                user &&
                user.isLoggedIn &&
                user.accessibleModules &&
                (user.accessibleModules.includes(route.access) ||
                  route.access === "all") && (
                  <Route
                    key={i}
                    exact
                    path={route.path}
                    element={<route.component {...props} isMobile={isMobile} />}
                  />
                )
              );
            })}
          </Routes>
        )}
      </Fragment>
    </Router>
  );
}
function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
const mapDispatchToProps = { BrandsManagementsFunction };

export default connect(mapStateToProps, mapDispatchToProps)(App);

