import moment from "moment";
import { filterData } from "../../../Regex";
import { toWords } from "number-to-words";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function CustomInvoicePDF({ soItems, masters, singlelistView, downloadCI }) {
  // function printUI(props) {
  // const { downloadCI, onClose, masters, soItems } = props;
  const numberToIndianWords = (number) => {
    const crore = 10000000;
    const lakh = 100000;
    const thousand = 1000;

    let result = "";
    let remaining = number;

    if (remaining >= crore) {
      const crorePart = Math.floor(remaining / crore);
      result += toWords(crorePart) + " crore ";
      remaining = remaining % crore;
    }

    if (remaining >= lakh) {
      const lakhPart = Math.floor(remaining / lakh);
      result += toWords(lakhPart) + " lakh ";
      remaining = remaining % lakh;
    }

    if (remaining >= thousand) {
      const thousandPart = Math.floor(remaining / thousand);
      result += toWords(thousandPart) + " thousand ";
      remaining = remaining % thousand;
    }

    if (remaining > 0) {
      result += toWords(remaining);
    }

    return result.trim();
  };
  const numberToWordsWithDecimals = (number) => {
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100);

    let result = numberToIndianWords(integerPart);

    if (decimalPart > 0) {
      result += " and " + toWords(decimalPart) + " paise";
    }

    return result.trim();
  };

  const numberToInternationalWords = (number) => {
    const billion = 1000000000;
    const million = 1000000;
    const thousand = 1000;

    let result = "";
    let remaining = number;

    if (remaining >= billion) {
        const billionPart = Math.floor(remaining / billion);
        result += toWords(billionPart) + " billion ";
        remaining = remaining % billion;
    }

    if (remaining >= million) {
        const millionPart = Math.floor(remaining / million);
        result += toWords(millionPart) + " million ";
        remaining = remaining % million;
    }

    if (remaining >= thousand) {
        const thousandPart = Math.floor(remaining / thousand);
        result += toWords(thousandPart) + " thousand ";
        remaining = remaining % thousand;
    }

    if (remaining > 0) {
        result += toWords(remaining);
    }

    return result.trim();
  };
  const numberToWordsWithDecimalsInternational = (number) => {
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100);

    let result = numberToInternationalWords(integerPart);

    if (decimalPart > 0) {
      result += " and " + toWords(decimalPart) + " cents";
    }

    return result.trim().replace(/\b\w/g, char => char.toUpperCase());
  };

  const checkValidValue = (input) => {
    if (
      [null, "null", undefined, "undefined", NaN, "NaN", "", "NA"].includes(
        input
      )
    ) {
      return false;
    }
    return true;
  };

  console.log("CONTAINER", downloadCI, downloadCI.total_container_count, Number(downloadCI.total_container_count) > 0)

  function adjustFontSize(text, field) {
    let data = String(Number(text).toFixed(2)).length;
    console.log(String(Number(text).toFixed(2)), data);
    if(field === 'duty'){
      return data >= 9
      ? 7
      : data === 8
      ? 7.5
      : 8;
    } else{
    return data <=12
    ? 7.5
    : data === 13
    ? 7
    : data === 14
    ? 6.5
    : data >= 15
    ? 6
    : 5.8
    }
  }

  const selectedShipper =
    downloadCI && downloadCI.shipper
      ? filterData(
          masters && masters.shippersList,
          "id",
          downloadCI.shipper,
          "onlyOne"
        )
        ? filterData(
            masters && masters.shippersList,
            "id",
            downloadCI.shipper,
            "onlyOne"
          )
        : ""
      : "";
  const selectedBenificiary =
    downloadCI && downloadCI.beneficiary
      ? filterData(
          masters && masters.benificiarysList,
          "id",
          downloadCI.beneficiary,
          "onlyOne"
        )
        ? filterData(
            masters && masters.benificiarysList,
            "id",
            downloadCI.beneficiary,
            "onlyOne"
          )
        : ""
      : "";
  const selectedSo =
    downloadCI && downloadCI.sales_order
      ? filterData(
          masters && masters.salesOrderList,
          "id",
          Number(downloadCI.sales_order),
          "onlyOne"
        )
        ? filterData(
            masters && masters.salesOrderList,
            "id",
            Number(downloadCI.sales_order),
            "onlyOne"
          )
        : ""
      : "";

  const buyer =
    downloadCI && downloadCI.buyer
      ? filterData(
          masters && masters.buyersList,
          "id",
          downloadCI.buyer,
          "onlyOne"
        )
        ? filterData(
            masters && masters.buyersList,
            "id",
            downloadCI.buyer,
            "onlyOne"
          )
        : ""
      : "";
  const unit = downloadCI && downloadCI.unit ? downloadCI.unit : "";
  console.log("buyer", buyer, masters,downloadCI, downloadCI?.custom_invoice_details?.[0]?.loading_port);

  const items =
    downloadCI.custom_invoice_item_details &&
    downloadCI.custom_invoice_item_details.length > 0 &&
    downloadCI.custom_invoice_item_details;

  const currency = filterData(
    masters["CurrencyList"],
    "id",
    downloadCI.sales_order_details[0].currency,
    "onlyOne"
  )
    ? filterData(masters["CurrencyList"], "id", downloadCI.sales_order_details[0].currency, "onlyOne")
        .name
    : "";
  const soItemDetail = (soItemID) => {
    return filterData(soItems, "id", soItemID, "onlyOne")
      ? filterData(soItems, "id", soItemID, "onlyOne")
      : false;
  };
  console.log(items
    ? `Number of bags: ${items.reduce(
        (totalBags, d) => {
          const soItem = soItemDetail(d.so_item);
          console.log(soItem, "soItem");
          if (soItem) {
            const numberOfBags = soItem.packaging
              ? soItem.packaging.reduce(
                  (prev, curr) =>
                    Number(prev) +
                    Number(
                      d.pkg_counter[curr]
                        ? d.pkg_counter[curr]
                        : 0
                    ),
                  0
                )
              : 0;
              console.log(totalBags, "totalBags", totalBags + numberOfBags)
            return totalBags + numberOfBags;
          }
          return totalBags;
        },
        0
)}`
: "NO DAtA")
  const rowHeader = [
    "Exchange rate",
    `Price (${currency.includes("Dollar") ? "USD" : currency}) PER ${unit}`,
    `Amount in ${currency.includes("Dollar") ? "USD" : currency}`,
    `PRICE (INR) PER ${unit}`,
    `Amount in (INR)`,
  ];

  const dd1 = [
    {
      columns: [
        {
          width: "*",
          text: "Tax Invoice",
          alignment: "center",
          bold: true,
          fontSize: 14,
          fontFamily: "Arial",
        },
      ],
      margin: [0, 0, 0, 5], // Optional: Add some bottom margin for spacing
    },
    {
      columns: [
        {
          width: "*",
          text:
            downloadCI && downloadCI?.invoice_type === "GST"
              ? "Supply meant for export on payment of integrated tax".toUpperCase()
              : "Supply meant for export under bond or letter of undertaking without payment of IGST".toUpperCase(),
          alignment: "center",
          bold: true,
          fontSize: 9.2,
          fontFamily: "Arial",
        },
      ],
      margin:[0, 0, 0, 2],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            widths: [180, 60, 45, 105, 125],
            // heights: [20,20,20,20,20,20],
            body: [
              [
                {
                  text: "SHIPPER :",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "INVOICE NO.:",
                  style: "basicLeft",
                  colSpan: 2,
                  border: [true, true, false, false],
                },
                {}, // Empty object is okay here as it's spanned by previous column
                {
                  text: "DATE :",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "EXPORTER REF. :",
                  style: "basicLeft",
                  border: [true, true, true, false],
                },
              ],
              [
                {
                  text: selectedShipper && selectedShipper.name,
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, false, false],
                },
                {
                  text: downloadCI && downloadCI.invoice_number,
                  style: "basicLeft",
                  colSpan: 2,
                  margin: [0, -5, 0, 0],
                  border: [true, false, false, true],
                },
                {}, // Empty object here for colSpan
                {
                  text:
                    downloadCI?.invoice_date &&
                    moment(downloadCI?.invoice_date).format("DD.MM.YYYY"),
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, false, true],
                },
                {
                  text: downloadCI && downloadCI.exporter_ref_no,
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text:
                    selectedShipper &&
                    selectedShipper?.address.includes("C/O. ANAND OIL MILL,")
                      ? selectedShipper?.address
                          .replace("C/O. ANAND OIL MILL,", "")
                          .replace(/\n/g, " ")
                      : selectedShipper.address.replace(/\n/g, " "),
                  style: "basicLeft",
                  margin: [0, -4, 0, 0],
                  border: [true, false, false, false],
                },
                checkValidValue(downloadCI?.lc_no)
                  ? {
                      text: "LC NUMBER:",
                      style: "basicLeft",
                      border: [true, false, false, false],
                    }
                  : {
                      text: "CONTRACT NUMBER :",
                      style: "basicLeft",
                      colSpan: 3,
                      border: [true, false, false, false],
                    },
                checkValidValue(downloadCI?.lc_no)
                  ? {
                      text: "LC Date :",
                      style: "basicLeft",
                      border: [true, false, false, false],
                    }
                  : {},
                checkValidValue(downloadCI?.lc_no)
                  ? {
                      text: "CONTRACT NUMBER :",
                      style: "basicLeft",
                      border: [true, false, false, false],
                    }
                  : {},
                {
                  text: "DATED :",
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
              ],
              [
                {
                  text: `GST Number: ${
                    selectedShipper && selectedShipper.gstin
                      ? selectedShipper.gstin
                      : ""
                  }`,
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, false, false],
                },
                checkValidValue(downloadCI?.lc_no)
                  ? {
                      text: downloadCI && downloadCI.lc_no,
                      style: "basicLeft",
                      margin: [0, -5, 0, 0],
                      border: [true, false, false, true],
                    }
                  : {
                      text:
                        selectedSo && selectedSo.so_number
                          ? selectedSo.so_number
                          : "",
                      style: "basicLeft",
                      colSpan: 3,
                      margin: [0, -5, 0, 0],
                      border: [true, false, false, true],
                    },
                checkValidValue(downloadCI?.lc_no)
                  ? {
                      text: moment(downloadCI.lc_date).format("DD.MM.YYYY"),
                      style: "basicLeft",
                      margin: [0, -5, 0, 0],
                      border: [true, false, false, true],
                    }
                  : {},
                checkValidValue(downloadCI?.lc_no)
                  ? {
                      text:
                        selectedSo && selectedSo.so_number
                          ? selectedSo.so_number
                          : "",
                      style: "basicLeft",
                      margin: [0, -5, 0, 0],
                      border: [true, false, false, true],
                    }
                  : {},
                {
                  text: moment(downloadCI.sales_order_date).format(
                    "DD.MM.YYYY"
                  ),
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text: ``,
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: "PLACE OF RECEIPT BY PRE-CARRIER:",
                  style: "basicLeft",
                  colSpan: 4,
                  border: [true, false, true, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: ``,
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text:
                    downloadCI && downloadCI.precarrier_details?.precarrier
                      ? downloadCI.precarrier_details.precarrier
                      : "",
                  style: "basicLeft",
                  colSpan: 4,
                  margin: [0, -2, 0, 0],
                  border: [true, false, true, true],
                },
                {},
                {},
                {},
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            widths: [214, 328],
            body: [
              [
                {
                  text: "CONSIGNEE :",
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: "BUYER :",
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
              ],
              [
                {
                  text: "TO ORDER",
                  style: "basicLeft",
                  margin: [0, -2, 0, 0],
                  border: [true, false, false, false],
                },
                {
                  text: buyer ? buyer.client_name : "",
                  margin: [0, -2, 0, 0],
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
              ],
              [
                {
                  text:
                    selectedSo && selectedSo.consignee
                      ? selectedSo.consignee !== "TO ORDER"
                        ? selectedSo.consignee
                        : ""
                      : "",
                  style: "basicLeft",
                  margin: [0, -2, 0, 0],
                  border: [true, false, false, true],
                },
                {
                  text:
                    (buyer
                      ? [buyer?.address.replace(/\n/g, " ").replace(/,\s*$/, ""), buyer.city?.replace(/,\s*$/, ""), buyer.state?.replace(/,\s*$/, ""), buyer.country?.replace(/,\s*$/, "")].filter(Boolean).join(", ")
                      : "") + `\n${buyer.email ? "Email:" + buyer.email : ""}`,
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            widths: [103, 102, 129, 190],
            body: [
              [
                {
                  text: "NOTIFY 1 :",
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: "NOTIFY 2 :",
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {
                  text: "NOTIFY 3 :",
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {
                  text: " COUNTRY OF ORIGIN OF GOODS: INDIA",
                  style: "basicCenter",
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text: `${
                    downloadCI &&
                    downloadCI.notify_1 &&
                    filterData(
                      masters && masters.notifyList,
                      "id",
                      downloadCI && downloadCI.notify_1
                        ? Number(downloadCI.notify_1)
                        : "",
                      "onlyOne"
                    )
                      ? filterData(
                          masters && masters.notifyList,
                          "id",
                          downloadCI && downloadCI.notify_1
                            ? Number(downloadCI.notify_1)
                            : "",
                          "onlyOne"
                        ).notify.replace(/\n/g, " ")
                      : " "
                  }`,
                  style: "basicLeft",
                  fontSize: 6.5,
                  border: [true, false, false, true],
                },
                {
                  text: `${
                    downloadCI &&
                    downloadCI.notify_2 &&
                    filterData(
                      masters && masters.notifyList,
                      "id",
                      downloadCI && downloadCI.notify_2
                        ? Number(downloadCI.notify_2)
                        : "",
                      "onlyOne"
                    )
                      ? filterData(
                          masters && masters.notifyList,
                          "id",
                          downloadCI && downloadCI.notify_2
                            ? Number(downloadCI.notify_2)
                            : "",
                          "onlyOne"
                        ).notify.replace(/\n/g, " ")
                      : ""
                  }`,
                  style: "basicLeft",
                  fontSize: 6.5,
                  border: [true, false, true, true],
                },
                {
                  text: `${
                    downloadCI &&
                    downloadCI.notify_3 &&
                    filterData(
                      masters && masters.notifyList,
                      "id",
                      downloadCI && downloadCI.notify_3
                        ? Number(downloadCI.notify_3)
                        : "",
                      "onlyOne"
                    )
                      ? filterData(
                          masters && masters.notifyList,
                          "id",
                          downloadCI && downloadCI.notify_3
                            ? Number(downloadCI.notify_3)
                            : "",
                          "onlyOne"
                        ).notify.replace(/\n/g, " ")
                      : " "
                  }`,
                  style: "basicLeft",
                  fontSize: 6.5,
                  border: [true, false, true, true],
                },
                {
                  text: [
                    { text: "PLACE OF DELIVERY/SUPPLY: " + "\n" },
                    {
                      text: downloadCI && downloadCI?.discharge_port,
                      margin: [0, 8, 0, 0],
                    },
                  ],
                  style: "basicCenter",
                  margin: [0, 2, 0, 0],
                  border: [true, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            widths: [214, 129, 190],
            body: [
              [
                {
                  text: "NAME OF CARRYING VESSEL/FLIGHT NO.",
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text: "TERM OF DELIVERY & PAYMENT :",
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {
                  text: "BENEFICIARY NAME :",
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text:`${downloadCI?.vessel_number ? downloadCI?.vessel_number : ""}  ${
                            downloadCI?.flight_number ? downloadCI?.flight_number : ""
                          }`,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {
                  text: `Delivery : ${
                    downloadCI && downloadCI.delivery_place
                      ? downloadCI.delivery_place
                      : ""
                  }`,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {
                  text: selectedBenificiary && selectedBenificiary.name,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
              ],
              [
                { rowSpan: 2,
                  text: `PORT OF LOADING:\n${
                  ((downloadCI?.sales_order_details[0].load_ports[0]).toUpperCase()).includes("INDIA")
                  ? (downloadCI?.sales_order_details[0].load_ports[0]).toUpperCase()
                  : (masters.PortList.find(p => p.id === Number(downloadCI?.sales_order_details[0].load_ports[0]))?.name
                  ).toUpperCase() + ", INDIA" }`,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {
                  rowSpan: 3,
                  text: `Payment : ${
                    downloadCI && downloadCI.payment_terms
                      ? downloadCI.payment_terms
                      : ""
                  }`,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                { rowSpan: 3,
                  text: `A/C NO.: ${
                    selectedBenificiary && selectedBenificiary.account_no
                  }\nBANK NAME : ${
                    selectedBenificiary && selectedBenificiary.bank_name
                  }\nBANK ADDRESS : ${selectedBenificiary && selectedBenificiary.bank_address
                  }\nSWIFT CODE : ${
                    selectedBenificiary && selectedBenificiary.swift_code
                  }`,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
              ],
              [
                {},
                {},
                {},
              ],
              [
                {
                  text: `PORT OF DISCHARGE\n${downloadCI && downloadCI.discharge_port
                      ? downloadCI.discharge_port.toUpperCase()
                      : ""}`,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {},
                {},
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, -5, 0],
          stack: [
            {
              table: {
                widths: [80, 90, 55, 53, 63, 45, 48, 54],
                body: [
                  [
                    {
                      text: "MARKS AND NUMBER OF CONTAINER NO.",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: "NO. AND KIND OF PACKAGE",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: "DESCRIPTION OF GOODS",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: `QUANTITY IN (${
                        downloadCI && downloadCI.unit ? downloadCI.unit : ""
                      })`,
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: rowHeader[1]
                        ? rowHeader[1]
                        : `Price USD PER ${
                            downloadCI && downloadCI.unit ? downloadCI.unit : ""
                          }`,
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: rowHeader[2] ? rowHeader[2] : `Amount in (USD)`,
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: rowHeader[3]
                        ? rowHeader[3]
                        : `Price (INR) PER ${
                            downloadCI && downloadCI.unit ? downloadCI.unit : ""
                          }`,
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: rowHeader[4] ? rowHeader[4] : `Amount in INR`,
                      style: "basicCenter",
                      border: [true, false, true, true],
                    },
                  ],
                  [
                    {
                      text:
                        items &&
                        items
                          .map((d, i) => {
                            const soItem = soItemDetail(d.so_item);
                            if (
                              soItem &&
                              soItem.brand_details &&
                              soItem.brand_details.brand_name
                            ) {
                              return (
                                soItem.brand_details.brand_name +
                                (i !== items.length - 1 ? ", \n" : "")
                              );
                            }
                            return "";
                          })
                          .filter(Boolean)
                          .join(""),
                      style: "basicCenter",
                      margin: [0, 10, 0, 0],
                      border: [true, false, false, false],
                    },
                    {
                      text:
                        downloadCI &&
                        (downloadCI.total_container_count 
                        ? Number(downloadCI.total_container_count) > 0
                          ? `${downloadCI.total_container_count} X  20' CONTS` 
                          : `${downloadCI?.total_container_count}`
                        : "")
                        + "\n\n" +
                            `${
                              items
                                ? `Number of bags: ${items.reduce(
                                    (totalBags, d) => {
                                      const soItem = soItemDetail(d.so_item);
                                      if (soItem) {
                                        const numberOfBags = soItem.packaging
                                          ? soItem.packaging.reduce(
                                              (prev, curr) =>
                                                Number(prev) +
                                                Number(
                                                  d.pkg_counter[curr]
                                                    ? d.pkg_counter[curr]
                                                    : 0
                                                ),
                                              0
                                            )
                                          : 0;
                                        return totalBags + numberOfBags;
                                      }
                                      return totalBags;
                                    },
                                    0
                                  )}` // Initial totalBags is 0, accumulate the sum for each item
                                : ""
                            }`
                          ,
                      margin: [0, 10, 0, 0],
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text:
                        items &&
                        filterData(
                          masters.ItemCateoryList,
                          "id",
                          soItemDetail(items[0].so_item).item_category,
                          "onlyOne"
                        )
                          ? filterData(
                              masters.ItemCateoryList,
                              "id",
                              soItemDetail(items[0].so_item).item_category,
                              "onlyOne"
                            ).name
                          : "",
                      margin: [0, 10, 0, 0],
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: `GROSS WT.\n${
                        items &&
                        items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.gross_weight || 0),
                          0
                        ).toFixed(3)
                      }`,
                      margin: [0, 10, 0, 0],
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: items && items.map((d,i) => {
                        return (
                          d.delivery_terms
                          ? `${d.delivery_terms[0].includes("Dollar") 
                          ? "USD" 
                          : d.delivery_terms[0]
                        }\t${Number(d.delivery_terms[1]).toFixed(2)}/${d.delivery_terms[2]
                           }, ${d.delivery_terms[3]}${ i === items.length - 1 ? "" : ",\n" }`
                          : ""
                        )
                      }),
                      margin: [0, 10, 0, 0],
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, false],
                    },
                  ],
                  [
                    {
                      text: `HSN CODE :\n${
                        items &&
                        filterData(
                          masters.HSNlist,
                          "id",
                          soItemDetail(items[0].so_item).hsn_code,
                          "onlyOne"
                        )
                          ? filterData(
                              masters.HSNlist,
                              "id",
                              soItemDetail(items[0].so_item).hsn_code,
                              "onlyOne"
                            ).hsn_code
                          : ""
                      }`,
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: items
                        ? `Packaging in\n${
                            items.map(d => {
                              const soItem = soItemDetail(d.so_item);
                              if (soItem) {
                                return soItem.packaging.map((sP, index, arr) => {
                                  if (Number(d.pkg_counter[sP] || 0) <= Number(0)) {
                                    return '';
                                  }
                                  const pVal = filterData( masters.PackagingList,"id",Number(sP),"onlyOne") || {};

                                    const size = pVal.size ? Number(pVal.size) : "";
                                    const unit = pVal.unit ? pVal.unit : "";
                                    const bagType = pVal.bag_type ? pVal.bag_type : "";

                                    // Join the parts together and trim the result
                                    const packagingString = `${size}${unit} ${bagType}`.trim();

                                    // Add a comma and a newline unless it's the last item
                                    return packagingString + (index !== arr.length - 1 ? ",\n" : "");
                                }).filter(Boolean).join("") 
                              }
                            }).join("\n")
                          }`
                        : "",
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: `NET WT.\n${
                        items &&
                        items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.net_weight || 0),
                          0
                        ).toFixed(3)
                      }`,
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, false],
                    },
                  ],
                  [
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, true],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, true],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, true],
                    },
                    {
                      text: `Exchange Rate`,
                      style: "basicCenter",
                      border: [true, true, true, true],
                    },
                    {
                      text: items[0].exchange_rate
                        ? items[0].exchange_rate.toFixed(2)
                        : "",
                      style: "basicCenter",
                      border: [true, true, true, true],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, true, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#222";
                },
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length) {
                    return 0;
                  }
                  else if (i === 1 || i === node.table.body.length - 1) {
                    return 1;
                  }
                  else return 0;
                },
                vLineColor: function (i, node) {
                  return "#222";
                },
              },
            },
            {
              table: {
                widths: [135, 35, 55, 53, 63, 45, 48, 54],
                body: [
                  [
                    {
                      text: "CALCULATION OF EXCESS DUTY PAID UNDER PROTEST",
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text: `${currency.includes("Dollar") ? "USD" : currency} PMT`,
                      style: "basicCenter",
                      border: [true, true, false, true],
                    },
                    {
                      text: `${currency.includes("Dollar") ? "USD" : currency} TOTAL`,
                      style: "basicCenter",
                      border: [true, true, false, true],
                    },
                    {
                      text: "INR TOTAL",
                      style: "basicCenter",
                      border: [true, true, false, true],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, true, true, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [false, false, true, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [false, false, true, false],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [false, false, true, false],
                    },
                  ],
                  [
                    {
                      text: "TOTAL FOB VALUE (including export duty reimbursement from buyer)",
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.total_fob_pmt_in_other ? Number(downloadCI?.total_fob_pmt_in_other).toFixed(2) : "",
                      style: "basicRight",
                      fontSize: downloadCI.total_fob_pmt_in_other
                        ? adjustFontSize(downloadCI.total_fob_pmt_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.total_fob_value_in_other ? Number(downloadCI?.total_fob_value_in_other).toFixed(2) : "",
                      style: "basicCenter",
                      fontSize: downloadCI.total_fob_value_in_other
                        ? adjustFontSize(downloadCI.total_fob_value_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.total_fob_value_in_inr ? Number(downloadCI?.total_fob_value_in_inr).toFixed(2) : "",
                      style: "basicCenter",
                      fontSize: downloadCI.total_fob_value_in_inr
                        ? adjustFontSize(downloadCI.total_fob_value_in_inr, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    { text: "", border: [true, false, true, false] },
                    { text: "", border: [false, false, true, false] },
                    { text: "", border: [false, false, true, false] },
                    { text: "", border: [false, false, true, false] },
                  ],
                  [
                    {
                      text:`Duty @ ${Number(downloadCI?.duty_percentage_of_fob)}% of FOB Value as above`,
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.duty_of_fob_pmt_in_other ? Number(downloadCI?.duty_of_fob_pmt_in_other).toFixed(2)  : "",
                      style: "basicRight",
                      fontSize: downloadCI.duty_of_fob_pmt_in_other
                        ? adjustFontSize(downloadCI.duty_of_fob_pmt_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.duty_of_fob_value_in_other ? Number(downloadCI?.duty_of_fob_value_in_other).toFixed(2) : "",
                      style: "basicCenter",
                      fontSize: downloadCI.duty_of_fob_value_in_other
                        ? adjustFontSize(downloadCI.duty_of_fob_value_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.duty_of_fob_value_in_inr ? Number(downloadCI?.duty_of_fob_value_in_inr).toFixed(2) : "",
                      style: "basicCenter",
                      fontSize: downloadCI.duty_of_fob_value_in_inr
                        ? adjustFontSize(downloadCI.duty_of_fob_value_in_inr, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: "FOB VALUE",
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.fob_value_in_other || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) + Number(curr.fob_value_in_other || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.fob_value_in_other || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(Number(prev).toFixed(2)) + Number(Number(curr.fob_value_per_unit || 0).toFixed(2)),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(Number(prev).toFixed(2)) + Number(Number(curr.fob_value_per_unit || 0).toFixed(2)),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(Number(prev).toFixed(2)) + Number(Number(curr.fob_value_per_unit || 0).toFixed(2)),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.fob_value_in_inr || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) + Number(curr.fob_value_in_inr || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.fob_value_in_inr || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, true, true],
                    },
                  ],
                  [
                    {
                      text: "Total Export Duty payable as per us (which is reimbursed from buyer)",
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.payable_export_duty_pmt_in_other ? Number(downloadCI?.payable_export_duty_pmt_in_other).toFixed(2) : "",
                      style: "basicRight",
                      fontSize: downloadCI?.payable_export_duty_pmt_in_other
                        ? adjustFontSize(downloadCI?.payable_export_duty_pmt_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.payable_export_duty_value_in_other ? Number(downloadCI?.payable_export_duty_value_in_other).toFixed(2) :  "",
                      style: "basicCenter",
                      fontSize: downloadCI?.payable_export_duty_value_in_other
                        ? adjustFontSize(downloadCI?.payable_export_duty_value_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.payable_export_duty_value_in_inr ? Number(downloadCI?.payable_export_duty_value_in_inr).toFixed(2) : "",
                      style: "basicCenter",
                      fontSize: downloadCI?.payable_export_duty_value_in_inr
                        ? adjustFontSize(downloadCI?.payable_export_duty_value_in_inr, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: "OCEAN FREIGHT",
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.ocean_freight_in_other || 0),
                          0
                        )).toFixed(2),
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.ocean_freight_in_other || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.ocean_freight_in_other || 0),
                          0
                        ))
                        : 8,
                      style: "basicRight",

                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.ocean_freight_per_unit || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.ocean_freight_per_unit || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.ocean_freight_per_unit || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.ocean_freight_in_inr || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.ocean_freight_in_inr || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.ocean_freight_in_inr || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, true, true],
                    },
                  ],
                  [
                    {
                      text: "Duty paid under protest",
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.duty_paid_under_protest_pmt_in_other ? Number(downloadCI?.duty_paid_under_protest_pmt_in_other).toFixed(2)  : "",
                      style: "basicRight",
                      fontSize: downloadCI?.duty_paid_under_protest_pmt_in_other
                        ? adjustFontSize(downloadCI?.duty_paid_under_protest_pmt_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.duty_paid_under_protest_value_in_other ? Number(downloadCI?.duty_paid_under_protest_value_in_other).toFixed(2) : "",
                      style: "basicCenter",
                      fontSize: downloadCI?.duty_paid_under_protest_value_in_other
                        ? adjustFontSize(downloadCI?.duty_paid_under_protest_value_in_other, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: downloadCI?.duty_paid_under_protest_value_in_inr ? Number(downloadCI?.duty_paid_under_protest_value_in_inr).toFixed(2) : "",
                      style: "basicCenter",
                      fontSize: downloadCI?.duty_paid_under_protest_value_in_inr
                        ? adjustFontSize(downloadCI?.duty_paid_under_protest_value_in_inr, 'duty')
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: "INSURANCE",
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.insurance_in_other || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) + Number(curr.insurance_in_other || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.insurance_in_other || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.insurance_per_unit || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) + Number(curr.insurance_per_unit || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.insurance_per_unit || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.insurance_in_inr || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) + Number(curr.insurance_in_inr || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.insurance_in_inr || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, true, true],
                    },
                  ],
                  [
                    { text: "", border: [true, true, false, true] },
                    { text: "", border: [false, true, false, true] },
                    { text: "", border: [false, true, false, true] },
                    { text: "", border: [false, true, true, true] },
                    {
                      text: "TOTAL",
                      style: "basicRight",
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.total_amount_in_other || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.total_amount_in_other || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.total_amount_in_other || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.total_amount_per_unit || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.total_amount_per_unit || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.total_amount_per_unit || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.total_amount_in_inr || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.total_amount_in_inr || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.total_amount_in_inr || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, true, true],
                    },
                  ],
                  [
                    {
                      text: `RATE OF IGST (IN %)\t\t\t\t ${
                        downloadCI.invoice_type === "GST"
                          ? items &&
                            items.reduce(
                              (prev, curr) =>
                                Number(prev) + Number(curr.rate_of_igst || 0),
                              0
                            )
                          : "0"
                      }%`,
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {
                      text: `IGST AMOUNT ON FOB VALUE`,
                      colSpan: 4,
                      style: "basicLeft",
                      border: [true, true, false, true],
                    },
                    {},
                    {},
                    {},
                    {
                      text:
                        downloadCI.invoice_type === "GST"
                          ? items &&
                            items.reduce(
                              (prev, curr) =>
                                Number(prev) +
                                Number(curr.igst_amount_in_other || 0),
                              0
                            )
                          : "0.00",
                      style: "basicRight",
                      border: [true, true, false, true],
                    },
                    { text: "", border: [true, true, false, true] },
                    {
                      text:
                        downloadCI.invoice_type === "GST"
                          ? items &&
                            items.reduce(
                              (prev, curr) =>
                                Number(prev) +
                                Number(curr.igst_amount_in_inr || 0),
                              0
                            )
                          : "0.00",
                      style: "basicRight",
                      border: [true, true, true, true],
                    },
                  ],
                  [
                    {
                      text:
                        downloadCI.invoice_type === "GST"
                          ? "TOTAL SHIPMENT VALUE OF GOODS INCLUDING IGST"
                          : `TOTAL VALUE OF SHIPPED CARGO`,
                      colSpan: 4,
                      style: "basicCenter",
                      border: [true, true, false, true],
                    },
                    {},
                    {},
                    {},
                    {
                      text: currency.includes("Dollar") ? "USD" : currency,
                      style: "basicRight",
                      border: [true, true, false, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.final_shipped_amount_in_other || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.final_shipped_amount_in_other || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.final_shipped_amount_in_other || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, false, true],
                    },
                    {
                      text: `INR`,
                      style: "basicRight",
                      border: [true, true, true, true],
                    },
                    {
                      text:
                        items &&
                        Number(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.final_shipped_amount_in_inr || 0),
                          0
                        )).toFixed(2),
                      style: "basicRight",
                      fontSize: items.reduce(
                        (prev, curr) =>
                          Number(prev) +
                          Number(curr.final_shipped_amount_in_inr || 0),
                        0
                      )
                        ? adjustFontSize(items.reduce(
                          (prev, curr) =>
                            Number(prev) +
                            Number(curr.final_shipped_amount_in_inr || 0),
                          0
                        ))
                        : 8,
                      border: [true, true, true, true],
                    },
                  ],
                  [
                    {
                      text: [
                        `CARGO PROCURED FROM PRIVATELY HELD STOCK\n`,
                        {
                          text:
                            downloadCI?.transit_code &&
                            downloadCI.transit_code + "\n",
                        },
                        {
                          text: downloadCI.rodtep && downloadCI.rodtep + "\n",
                        },
                        "LETTER OF UNDERTAKING WITHOUT PAYMENT OF IGST",
                      ],
                      colSpan: 3,
                      style: "basicCenter",
                      border: [true, true, false, true],
                    },
                    {},
                    {},
                    {
                      text: "",
                      colSpan: 5,
                      border: [true, false, true, true],
                    },
                    {},
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `AMOUNT IN WORDS :   ${
                        currency.includes("Dollar") ? "USD" : currency
                      } ${numberToWordsWithDecimalsInternational(
                        Number(
                          items &&
                            items.reduce(
                              (prev, curr) =>
                                Number(prev) +
                                Number(curr.final_shipped_amount_in_other || 0),
                              0
                            )
                        )
                      ).toUpperCase()} ONLY`,
                      colSpan: 8,
                      style: "basicLeft",
                      border: [true, true, true, true],
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `AMOUNT IN WORDS :   RS. ${numberToWordsWithDecimals(
                        Number(
                          items &&
                            items.reduce(
                              (prev, curr) =>
                                Number(prev) +
                                Number(curr.final_shipped_amount_in_inr || 0),
                              0
                            )
                        )
                      ).toUpperCase()} ONLY`,
                      colSpan: 8,
                      style: "basicLeft",
                      border: [true, true, true, true],
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                  ],
                ],
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#222";
                },
                vLineColor: function (i, node) {
                  return "#222";
                },
              },
            },
          ],
        },
      ],
    },

    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            dontBreakRows: true,
            headerRows: 2,
            widths: [214, 328],
            body: [
              [
                { text: "", border: [true, false, false, false] },
                {
                  text: "SRI SAINATH INDUSTRY PVT LTD\n\n\n\n",
                  style: "basicRight",
                  margin: [0, 0, 20, 0],
                  border: [false, false, true, false],
                },
              ],
              [
                { text: "", border: [true, false, false, true] },
                {
                  text: "Authorized Signatory Director",
                  fontSize: 7.5,
                  margin: [0, 0, 40, 0],
                  style: "basicRight",
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            dontBreakRows: true,
            headerRows: 4,
            widths: ["*"],
            body: [
              [
                {
                  text: "CIN NUMBER : U14200CT2007PTC020290",
                  style: "basicLeft",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "EMAIL : sainath@srisainath.com",
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "WEB : www.srisainath.com",
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "FAX : +91 771 4032240",
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
    },
  ];

  // dd2
  const dd2 = [
    {
      columns: [
        {
          width: "*",
          text: "PACKING LIST",
          alignment: "center",
          bold: true,
          fontSize: 14,
          fontFamily: "Arial",
        },
      ],
      margin: [0, 0, 0, 15], // Optional: Add some bottom margin for spacing
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            widths: [220, 85, 65, 145],
            body: [
              [
                {
                  text: "SHIPPER :",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "INVOICE NO.:",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "DATE :",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "EXPORTER REF. :",
                  style: "basicLeft",
                  border: [true, true, true, false],
                },
              ],
              [
                {
                  text: selectedShipper && selectedShipper.name,
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: downloadCI && downloadCI.invoice_number,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {
                  text:
                    downloadCI &&
                    moment(downloadCI?.invoice_date).format("DD.MM.YYYY"),
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text: downloadCI && downloadCI.exporter_ref_no,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text:
                    selectedShipper &&
                    selectedShipper.address.includes("C/O. ANAND OIL MILL,")
                      ? selectedShipper.address
                          .replace("C/O. ANAND OIL MILL,", "")
                          .replace(/\n/g, " ")
                      : selectedShipper.address.replace(/\n/g, " "),
                  style: "basicLeft",
                  margin: [0, -4, 0, 0],
                  border: [true, false, false, false],
                },
                {
                  text: "CONTRACT NUMBER :",
                  colSpan: 2,
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {},
                {
                  text: "DATED :",
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
              ],
              [
                {
                  text: `GST Number: ${
                    selectedShipper && selectedShipper.gstin
                      ? selectedShipper.gstin
                      : ""
                  }`,
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, false, true],
                },
                {
                  text:
                    selectedSo && selectedSo.so_number
                      ? selectedSo.so_number
                      : "",
                  style: "basicLeft",
                  colSpan: 2,
                  margin: [0, -5, 0, 0],
                  border: [true, false, false, true],
                },
                {},
                {
                  text: moment(downloadCI.sales_order_date).format(
                    "DD.MM.YYYY"
                  ),
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text: "CONSIGNEE :",
                  style: "basicLeft",
                  border: [true, true, false, false],
                },
                {
                  text: "Other references :",
                  colSpan: 3,
                  style: "basicLeft",
                  border: [true, true, true, false],
                },
                {},
                {},
              ],
              [
                {
                  text:
                    selectedSo && selectedSo.consignee
                      ? selectedSo.consignee
                      : "",
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: "",
                  colSpan: 3,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {},
                {},
              ],
              [
                {
                  text: "",
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: "BUYER :",
                  colSpan: 3,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {},
                {},
              ],
              [
                {
                  text: "",
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: buyer ? buyer.client_name : "",
                  colSpan: 3,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {},
                {},
              ],
              [
                {
                  text: "",
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text:
                    (buyer
                      ? [buyer?.address.replace(/\n/g, " ").replace(/,\s*$/, ""), buyer.city?.replace(/,\s*$/, ""), buyer.state?.replace(/,\s*$/, ""), buyer.country?.replace(/,\s*$/, "")].filter(Boolean).join(", ")
                      : "") + `\n${buyer.phone ? "Phone:" + buyer.phone : ""}`,
                  colSpan: 3,
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, true, true],
                },
                {},
                {},
              ],
              [
                {
                  text: [
                    downloadCI.notify_1 &&
                    filterData(
                      masters && masters.notifyList,
                      "id",
                      downloadCI && downloadCI.notify_1
                        ? Number(downloadCI.notify_1)
                        : "",
                      "onlyOne"
                    )?.notify
                      ? `NOTIFY 1: ${filterData(
                          masters && masters.notifyList,
                          "id",
                          downloadCI && downloadCI.notify_1
                            ? Number(downloadCI.notify_1)
                            : "",
                          "onlyOne"
                        ).notify.replace(/\n/g, " ")}\n`
                      : "",
                  ],
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {
                  text: "PRE-CARRIAGE BY: ",
                  colSpan: 2,
                  rowSpan: 2,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {},
                {
                  text: `PLACE OF RECEIPT BY PRE-CARRIER:`,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
              ],
              [
                {
                  text:
                    downloadCI.notify_2 &&
                    filterData(
                      masters && masters.notifyList,
                      "id",
                      downloadCI && downloadCI.notify_2
                        ? Number(downloadCI.notify_2)
                        : "",
                      "onlyOne"
                    )?.notify
                      ? `NOTIFY 2: ${filterData(
                          masters && masters.notifyList,
                          "id",
                          downloadCI && downloadCI.notify_2
                            ? Number(downloadCI.notify_2)
                            : "",
                          "onlyOne"
                        ).notify.replace(/\n/g, " ")}\n`
                      : "",
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                "",
                "",
                {
                  text:
                    downloadCI && downloadCI.precarrier_details?.precarrier
                      ? downloadCI.precarrier_details.precarrier
                      : "",
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text:
                    downloadCI.notify_3 &&
                    filterData(
                      masters && masters.notifyList,
                      "id",
                      downloadCI && downloadCI.notify_3
                        ? Number(downloadCI.notify_3)
                        : "",
                      "onlyOne"
                    )?.notify
                      ? `NOTIFY 3: ${filterData(
                          masters && masters.notifyList,
                          "id",
                          downloadCI && downloadCI.notify_3
                            ? Number(downloadCI.notify_3)
                            : "",
                          "onlyOne"
                        ).notify.replace(/\n/g, " ")}`
                      : "",
                  rowSpan: 2,
                  margin: [0, -2, 0, 0],
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {
                  text: `COUNTRY OF ORIGIN OF GOODS:`,
                  colSpan: 2,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {},
                {
                  text: `COUNTRY OF FINAL DESTINATION: `,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
              ],
              [
                "",
                {
                  text: `INDIA`,
                  colSpan: 2,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {},
                {
                  text:
                  downloadCI && downloadCI.discharge_port
                  ? downloadCI.discharge_port.toUpperCase()
                  : "",
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text: "NAME OF CARRYING VESSEL/FLIGHT NO.",
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text: "TERM OF DELIVERY & PAYMENT :",
                  colSpan: 3,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {},
                {},
              ],
              [
                {
                  text:`${downloadCI?.vessel_number ? downloadCI?.vessel_number : ""}  ${
                            downloadCI?.flight_number ?  downloadCI?.flight_number : ""
                          }`,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {
                  colSpan: 3,
                  text: `Delivery : ${
                    downloadCI && downloadCI.delivery_place
                      ? downloadCI.delivery_place
                      : ""
                  }`,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {},
                {},
              ],
              [
                {
                  text: "PORT OF LOADING: ",
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {
                  colSpan: 3,
                  text: `Payment : ${
                    downloadCI && downloadCI.payment_terms
                      ? downloadCI.payment_terms
                      : ""
                  }`,
                  rowSpan: 2,
                  style: "basicLeft",
                  border: [true, false, true, false],
                },
                {},
                {},
              ],
              [
                {
                  text:
                    downloadCI &&
                    ((downloadCI?.sales_order_details[0].load_ports[0]).toUpperCase()).includes("INDIA")
                    ? (downloadCI?.sales_order_details[0].load_ports[0]).toUpperCase()
                    : (
                      masters.PortList.find(p => p.id === Number(downloadCI?.sales_order_details[0].load_ports[0]))?.name
                    ).toUpperCase() + ", INDIA",
                  style: "basicLeft",
                  margin: [0, -5, 0, 0],
                  border: [true, false, true, true],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: `PORT OF DISCHARGE \n ${
                    downloadCI && downloadCI.discharge_port
                      ? downloadCI.discharge_port.toUpperCase()
                      : ""
                  }`,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {
                  colSpan: 3,
                  text: ``,
                  style: "basicLeft",
                  border: [true, false, true, true],
                },
                {},
                {},
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          stack: [
            {
              table: {
                dontBreakRows: true,
                widths: [100, 111, 152, 152],
                body: [
                  [
                    {
                      text: "MARKS AND NUMBER OF CONTAINER NO.",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: "NO. AND KIND OF PACKAGE",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: "DESCRIPTION OF GOODS",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: `QUANTITY IN (${
                        downloadCI && downloadCI.unit ? downloadCI.unit : ""
                      })`,
                      style: "basicCenter",
                      border: [true, false, true, true],
                    },
                  ],
                  [
                    {
                      text:
                        items &&
                        items
                          .map((d, i) => {
                            const soItem = soItemDetail(d.so_item);
                            if (
                              soItem &&
                              soItem.brand_details &&
                              soItem.brand_details.brand_name
                            ) {
                              return (
                                soItem.brand_details.brand_name +
                                (i !== items.length - 1 ? ", \n" : "")
                              );
                            }
                            return "";
                          })
                          .filter(Boolean)
                          .join(""),
                      margin: [0, 10, 0, 0],
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text:
                        downloadCI &&
                        (downloadCI.total_container_count 
                        ? Number(downloadCI.total_container_count) > 0
                          ? `${downloadCI.total_container_count} X  20' CONTS` 
                          : `${downloadCI?.total_container_count}`
                        : "")
                        + "\n\n" +
                            `${
                              items
                                ? `Number of bags: ${items.reduce(
                                    (totalBags, d) => {
                                      const soItem = soItemDetail(d.so_item);
                                      if (soItem) {
                                        const numberOfBags = soItem.packaging
                                          ? soItem.packaging.reduce(
                                              (prev, curr) =>
                                                Number(prev) +
                                                Number(
                                                  d.pkg_counter[curr]
                                                    ? d.pkg_counter[curr]
                                                    : 0
                                                ),
                                              0
                                            )
                                          : 0;
                                        return totalBags + numberOfBags;
                                      }
                                      return totalBags;
                                    },
                                    0
                                  )}` // Initial totalBags is 0, accumulate the sum for each item
                                : ""
                            }`
                          ,
                      style: "basicCenter",
                      margin: [0, 10, 0, 0],
                      border: [true, false, false, false],
                    },
                    {
                      text:
                        items &&
                        filterData(
                          masters.ItemCateoryList,
                          "id",
                          soItemDetail(items[0].so_item).item_category,
                          "onlyOne"
                        )
                          ? filterData(
                              masters.ItemCateoryList,
                              "id",
                              soItemDetail(items[0].so_item).item_category,
                              "onlyOne"
                            ).name
                          : "",
                      margin: [0, 10, 0, 0],
                      style: "basicCenter",
                      border: [true, false, false, false],
                    },
                    {
                      text: `(GROSS WT.)\n${
                        items &&
                        items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.gross_weight || 0),
                          0
                        ).toFixed(3)
                      } `,
                      margin: [0, 10, 0, 0],
                      style: "basicCenter",
                      border: [true, false, true, false],
                    },
                  ],
                  [
                    {
                      text: `COUNTRY OF ORIGIN\n${
                        downloadCI && downloadCI.origin_country
                          ? downloadCI.origin_country
                          : ""
                      }`,
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: items
                        ? `Packaging in\n${
                            items.map(d => {
                              const soItem = soItemDetail(d.so_item);
                              if (soItem) {
                                return soItem.packaging.map((sP, index, arr) => {
                                  if (Number(d.pkg_counter[sP] || 0) <= Number(0)) {
                                    return '';
                                  }
                                  const pVal = filterData( masters.PackagingList,"id",Number(sP),"onlyOne") || {};
                                    console.log("PVAL",d.pkg_counter,sP)
                                    const size = pVal.size ? Number(pVal.size) : "";
                                    const unit = pVal.unit ? pVal.unit : "";
                                    const bagType = pVal.bag_type ? pVal.bag_type : "";

                                    // Join the parts together and trim the result
                                    const packagingString = `${size}${unit} ${bagType}`.trim();

                                    // Add a comma and a newline unless it's the last item
                                    return packagingString + (index !== arr.length - 1 ? ",\n" : "");
                                }).filter(Boolean).join("")
                              }
                            }).join("\n")
                          }`
                        : "",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: "",
                      style: "basicCenter",
                      border: [true, false, false, true],
                    },
                    {
                      text: `(NET WT.)\n${
                        items &&
                        items.reduce(
                          (prev, curr) =>
                            Number(prev) + Number(curr.net_weight || 0),
                          0
                        ).toFixed(3)
                      }`,
                      style: "basicCenter",
                      border: [true, false, true, true],
                    },
                  ],
                ],
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#222";
                },
                vLineColor: function (i, node) {
                  return "#222";
                },
              },
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, 0],
          table: {
            dontBreakRows: true,
            headerRows: 2,
            widths: [230, 303],
            body: [
              [
                {
                  text: "Declaration:",
                  style: "basicLeft",
                  border: [true, false, false, false],
                },
                {
                  text: "SRI SAINATH INDUSTRY PVT LTD\n\n\n\n",
                  style: "basicRight",
                  margin: [0, 0, 20, 0],
                  border: [false, false, true, false],
                },
              ],
              [
                {
                  text: `We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.`,
                  style: "basicLeft",
                  border: [true, false, false, true],
                },
                {
                  text: "\nAuthorized Signatory Director",
                  fontSize: 7.5,
                  margin: [0, 0, 40, 0],
                  style: "basicRight",
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#222";
            },
            vLineColor: function (i, node) {
              return "#222";
            },
          },
        },
      ],
    },
  ];

  const documentDefinition = {
    pageMargins: [20, 20, 20, 10],
    content: [
      dd1, // First page content
      { text: "", pageBreak: "after" }, // Page break after dd1
      dd2, // Second page content
    ],

    styles: {
      basicLeft: {
        color: "#333",
        fontSize: 8,
        alignment: "left",
      },
      basicRight: {
        color: "#333",
        fontSize: 8,
        alignment: "right",
      },
      basicCenter: {
        color: "#333",
        fontSize: 8,
        alignment: "center",
      },
    },
  };

  // pdfMake.createPdf(documentDefinition).download(`${downloadCI.invoice_number}.pdf`)
  pdfMake.createPdf(documentDefinition).open();
}

export default CustomInvoicePDF;
