import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReportsComponent from "./ReportsComponent";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";
import { ReportsFunction } from "../../redux";

function ReportsContainer(props) {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.ReportsFunction("get", EndPoints["Reports"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return (
    <ReportsComponent
      {...props}
      params={params}
      loader={loader}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  Reports: state.modules.Reports,
  loading: state.modules.loading,
  [EndPoints["Reports"]]: state.modules[EndPoints["Reports"]],
});
const mapDispatchToProps = {
  ReportsFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsContainer);
