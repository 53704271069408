import React from "react";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@material-ui/core";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

const Popup = ({ children, openPopup, setOpenPopup, isNew }) => {
  return (
    <Dialog
      open={openPopup}
      keepMounted
      // onSubmit={() => setOpenPopup(false)}
      onClose={() => setOpenPopup(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        style={{
          fontSize: "20.8px",
          fontWeight: "400",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgb(241 154 48)",
          // backgroundColor: "#1a9520",
          color: "#fff",
          padding: "5px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "flex-start",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GroupOutlinedIcon
            style={{
              color: "white",

              marginRight: "7px",
            }}
          />
          {isNew ? "Add New Client" : "Edit Client Details"}
        </div>
        <Tooltip title="Close">
          <CloseIcon style={{ cursor: "pointer" }} color="#fff" onClick={() => setOpenPopup(false)} />
        </Tooltip>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
