import React, { useState } from "react";
import { Button, IconButton, InputAdornment, MenuItem } from "@mui/material";
import { primary } from "../../styles/colors";
import { CssTextField } from "../../styles/StyledComponents";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { MuiDateRangePicker } from "../../layoutComponents/DateRangePicker";
import moment from "moment";
import TableComponent from "../../layoutComponents/TableComponent";

const styles = {
  dateButtonsGroup: {
    width: 20,
    height: 25,
    color: primary,
  },
};

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: "auto", // Enable vertical scrolling
    },
  },
};

const status = [
  { label: "DRAFT", value: 0 },
  { label: "OPEN", value: 1 },
  { label: "CLOSED", value: 2 },
  { label: "CANCELLED", value: 3 },
];

const ReportPage = (props) => {
  const [value, setValue] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [dateRangeTitle, setDateRangeTitle] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const toggle = () => setDatePickerOpen(!datePickerOpen);

  const handleDateChange = (date) => {
    if (date?.startDate || date?.endDate) {
      setDateRangeTitle(
        `Date ${moment(date?.startDate).format("DD-MMM-YY")} to ${moment(
          date?.endDate
        ).format("DD-MMM-YY")}`
      );
      setDateRange(date);
      setDatePickerOpen(!datePickerOpen);
    } else {
      setDateRangeTitle("");
      setDateRange({});
    }
  };

  const {
    tabModule,
    header
  } = props;

  return (
    <div style={{ padding: 12 }}>
      <div className="d-flex justifySB alignC">
        <div>
          <CssTextField
            autoComplete="off"
            value={value}
            onChange={(e) => {
              console.log(e);
              setValue(e.target.value);
            }}
            select
            label="Status"
            name="status"
            style={{
              width: 190,
              margin: "0 5px",
            }}
            SelectProps={{ MenuProps: MenuProps }}
            variant="outlined"
          >
            {status.map((option) => (
              <MenuItem
                key={option.label}
                value={option.label}
                id={option.label}
                style={{
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </CssTextField>
          <CssTextField
            id="input-with-icon-textfield"
            label="Start Date - From Date"
            value={`${moment(dateRange?.startDate).format(
              "DD-MMM-YY"
            )} to ${moment(dateRange?.endDate).format("DD-MMM-YY")}`}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={styles.dateButtonsGroup}
                    disableAutoFocus={true} // Prevent focusing when the menu opens
                    disableEnforceFocus={true}
                    onClick={(e) => {
                      setDatePickerOpen(!datePickerOpen);
                    }}
                  >
                    <DateRangeIcon color="grey" />
                  </IconButton>

                  <MuiDateRangePicker
                    open={datePickerOpen}
                    toggle={toggle}
                    dateRange={dateRange}
                    onDateRangeChange={(e) => handleDateChange(e)}
                  />
                </InputAdornment>
              ),
            }}
            style={{
              width: 250,
              margin: "0 5px",
            }}
            variant="outlined"
          />
        </div>
        <div>
          <Button
            onClick={() => {}}
            style={{
              background: "rgb(241 154 48)",
              color: "white",
              padding: "5px 10px",
              fontSize: 14,
              fontFamily: "Poppins",
              fontWeight: 500,
            }}
            size="small"
          >
            Get Report
          </Button>
        </div>
      </div>
      <div style={{ margin: "10px 0 0 0", paddingLeft: 5 }}>
        <TableComponent
          module={`Report ${tabModule} Table`}
          noAddButton={true}
          subMenu={false}
          noSearchBar={true}
          loader={tableDataLoading === true ? true : false}
          header={header}
          tableList={tableData ? tableData : []}
        />
      </div>
    </div>
  );
};

export default ReportPage;
