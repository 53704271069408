import { Divider } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { CompanyFullName } from "../Defaults";
import { primary } from "../styles/colors";

function PdfLayout(props) {
  const { title, file, noBottom ,} = props;
  console.log(file, title, "aaaaaaaaaaa");
  const HDivider = <Divider style={{ minWidth: "100%", width: "100%" }} />;
  const VDivider = (
    <Divider
      variant="vertical"
      style={{ height: "100%", minHeight: "29.19px" }}
    />
  );
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "53.18px",
          padding: "15px 0",
          width: "calc(100% - 40px)",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          textTransform: "capitalize",
          margin: "0 20px",
        }}
      >
        <div
          style={{
            width: "100px",
            height: "100%",
            overflow: "hidden",
            minWidth: "100px",
          }}
        >
          Logo
      {/* <img
        alt="Logo"
        src="ssipllogo.png"
        // style={{ height: "calc(100% + 40px)", width: "100%", margin: "-30px 0 -10px 0", overflow: "hidden" }}
      />{" "} */}
        </div>
        <div
          style={{
            width: "calc(100% - 270px)",
            margin: "0 10px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              color: "black",
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0 2px 0 0",
              fontSize: "0.9rem",
              width: "100%",
            }}
          >
            {CompanyFullName}
          </h2>
          <div style={{ fontSize: "0.8rem", fontWeight: "600" }}>
            CIN No: U14200CT2007PTC020290
          </div>
        </div>
        <div
          style={{
            width: "150px",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "150px",
              display: "flex",

              fontSize: "0.7rem",
              justifyContent: "flex-end",
            }}
          ></div>
          {/* <div
            style={{
              width: "150px",
              display: "flex",
              fontSize: "0.6rem",
              justifyContent: "flex-end",
            }}
          >
            This is a Computer Generated
          </div> */}
        </div>
      </div>{" "}
      {file && (
        <div
          style={{
            width: "calc(100% - 45px)",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "0 20px",
            padding: "0px",
            fontSize: "0.8rem",
            // padding: "5px 0px",
            border:"1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <div
            style={{
              width: "39%",
              display: "flex",
              fontSize: "0.9rem",
              fontWeight: "bold",
              justifyContent: "center",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              marginLeft: "0px",
              // padding: "2px",
            }}
          >
            {file.name}
          </div>
          <div
            style={{
              display: "flex",
              // padding: "2px",/
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "0.6rem",
              }}
            >
              {file.po_num ?file.po_num :"" + file.value}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "0.6rem",
              }}
            >
              {file.date
                ? "Dated " + moment(file.date).format("ddd, MMM Do YYYY")
                : ""}
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          margin: "0 20px",
          width: "calc(100% - 44px)",
          height: noBottom
            ? file
              ? "calc(100% - 84px)"
              : "calc(100% - 53.18px)"
            : file
            ? "calc(100% - 124px)"
            : "calc(100% - 93.18px)",
          // borderTop: `2px solid ${primary}`,

          fontSize: "0.7rem",
          position: "relative",
          color: "black",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100% - 60px)",
            position: "relative",
          }}
        >
          {props.children}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            minHeight: "60px",
            height: "60px",
          }}
        >
          <div
            style={{
              padding: "5px",
              display: "flex",
              minHeight: "100%",
              height: "100%",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div style={{ padding: "5px", width: "100%", display: "flex" }}>
              For SRI SAINATH INDUSTRY PVT LTD
            </div>
            <div
              style={{
                padding: "5px",
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <div> Authorized Signatory</div> <div> Broker</div>
              <div> Accepted - Seller ( with sign and seal )</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "calc(100% - 40px)",
          display: "flex",
          flexDirection: "column",
          height: "40px",
          margin: "5px 20px",
          fontSize: "0.6rem",
        }}
      >
        {HDivider}
        <div style={{ width: "100%", fontWeight: "bold" }}>
          Registered Off : Behind Samrat Talkies, Civil Station Road, Raipur
          -492001,(C.G.) Ph:+91 771 2887453,6460574
        </div>
        <div
          style={{
            width: "100%",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            Fax: +91 771 4032240, Email : sainath@srisainath.com Web:{" "}
            <div style={{ color: primary }}>www.srisainath.com</div>
          </div>
          <div>This is computer generated</div>
        </div>
        {HDivider}
      </div>
    </div>
  );
}

export default PdfLayout;
