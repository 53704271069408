import React, { Component } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import MuiAlert from "@mui/material/Alert";
import { Stepper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ReactQuill from "react-quill";
import parse from "html-react-parser";

import { lightGrey, primary, red, white } from "../../styles/colors";
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Slide,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import TabComponent from "../../layoutComponents/TabComponent";
import RadioButton from "../../layoutComponents/RadioButton";
import MultipleSelectBar from "../../layoutComponents/MultipleSelectBar";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import ReactDOM from "react-dom";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import SalesOrderPdf from "./SalesOrderPdf";

import { BottomArrow, CrossIcon, TopArrow, EditIcon } from "../../styles/Icons";
import {
  CssKeyboardDatePicker,
  CssTextField,
  StyledButton,
  StyledCircularProgress,
  CssTextField95,
} from "../../styles/StyledComponents";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { CheckCircle, Add } from "@material-ui/icons";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filterData, StopScroll } from "../../Regex";
import { EndPoints } from "../../utils/EndPoints";
import { CssButton } from "../PurchaseOrders/PurchaseOrdersComponent";
import CustomInvoice from "../Ports/CustomInvoice";
import UploadSoFile from "./UploadSoFile";
import { getSpecificationId } from "../../layoutComponents/ShowSpecifications";
import { FormControlLabel, Switch } from "@mui/material";
import { ConstructionOutlined } from "@mui/icons-material";
var converter = require("number-to-words");
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
  },
  none: { height: 0, width: 0 },
  innerDiv: {
    height: "50%",
    background: "white",
    borderRadius: "10px",
    marginBottom: "20px",
    pointerEvents: "none",
    width: "100%",
  },
  innerDiv1: {
    height: "65%",
    background: "#8080801a",
    borderRadius: "10px",
    padding: "10px 10px 0 10px",
    width: "100%",
    pointerEvents: "none",
  },
  innerMainDiv: {
    width: "100%",
    display: "flex",
  },
};
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: 'auto', // Enable vertical scrolling
    },
  },
};
export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CssValueTextField = withStyles({
  root: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      color: "black",
    },
    "& label.Mui-focused": {
      color: "rgb(241 154 48)",
    },
    "& .MuiOutlinedInput-root": {
      caretColor: "transparent",
      borderColor: primary,
      "& fieldset": {
        borderColor: "#0000ff69",
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },
    },
  },
})(TextField);
const MaxMin = [
  { name: "Greater Than", id: "greater_than" },
  { name: "Greater Than Or Equal To", id: "greater_than_or_equal_to" },
  { name: "Less Than", id: "less_than" },
  { name: "Not Equal To", id: "not_equal_to" },
  { name: "Equal To", id: "equal_to" },
  { name: "Less Than Or Equal To", id: "less_than_or_equal_to" },
];
export class DetailsSo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      editDialog: false,
      loadingFun: false,
      error: false,
      // success: false,
      added: false,
      calenderOpenLS: false,
      selctedSOStatus: "DRAFT",
      selctedTYpeOfSO: "SSIPL",
      MaxMin: MaxMin,
      // addendum: "false",
      specificationArr: [],
      specificationArrCopy: [],
      specificationId: false,
      submittedSpecifies: {},
      defaultStep: 0,
      currentStep: 0,
      specificationHeader: [
        {
          name: "Type",
          type: "select",
          dataType: "text",
          api: "name",
          array: [],
          value: "name",
          filter: (array, specificationArr) => {
            return (
              array &&
              array.length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ) &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ).length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              )
            );
          },
        },
        {
          name: "Condition",
          type: "select",
          dataType: "text",
          api: "condition",
          array: MaxMin,
          value: "name",
        },
        {
          name: "Value",
          type: "textField",
          dataType: "number",
          api: "value",
        },
      ],
      errorMsg: {
        S0Number: false,
        Unit: false,
        Incoterm: false,
        Buyer: false,
        TotalQ: false,
        TotalRate: false,
        contractTypeName: false,
        BorkerName: false,
        Borkerage: false,
        portArry: false,
        Consignee: false,
        MillerName: false,
        BrandName: false,
        HSN: false,
        Rate: false,
        soPaymentTerm: false,
        Terms: false,
        LoadPort: false,
        DischargePort: false,
        packagingArr: false,
        previewPdf: false,
      },
      S0Number: "",
      previewPdf: false,
      Unit: "",
      Incoterm: "",
      Buyer: "",
      TotalQ: "",
      TotalRate: "",
      contractTypeName: "",
      contractTypeId: "",
      BorkerName: "",
      BorkerNameId: "",
      Borkerage: "",
      BorkerageId: "",
      portArry: [],
      Consignee: "",
      MillerNameId: "",
      BuyerId: "",
      MillerName: "",
      MillerAddress: "",
      MillerGST: "",
      ContactPerson: "",
      last_survey: new Date(),
      calenderOpen: false,
      SalesOrderId: "",
      editSnack: false,
      SalesOrder: "",
      BrandName: false,
      selectedBrandMultipleName: false,
      LoadPort: "",
      DischargePort: "",
      ItemCategory: "",
      ItemType: "",
      packagingArr: [],
      HSN: "",
      HSN_id: "",
      total_quantity: "",
      Rate: "",
      searchedData: new Date(),
      shipmentDate: new Date(),
      // selectedSoid: false,
      soPaymentTerm: "",
      soPaymentTermId: "",
      onlyDialog: false,
      Terms: "",
      downloadPdf: false,
      showSpeci: false,
      selectedCI: false,
      ShippingTerms: "",
      ShippingTermsId: "",
      soPolicy: false,
      uploadedFile: "",
      portsList: [],
      loading: false,
      itemVar: {},
      requiredToggle: true,
    };
  }
  

  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };
  reset = (noSnack) => {
    const {
      Buyer,
      TotalQ,
      TotalRate,
      contractTypeName,
      soPaymentTerm,
      MillerName,
      ContactPerson,
      BorkerName,
      BrandName,
      Terms,
      portArry,
      packagingArr,
      LoadPort,
      DischargePort,
      Rate,
      Unit,
      S0Number,
      Incoterm,
      HSN,
      SalesOrder,
      uploadedFile,
      Currency,
      ShippingTerms,
      TotalContainer,
      ShipmentBy,
      Consignee,
    } = this.state;

    if (
      !TotalRate &&
      !Buyer &&
      !DischargePort &&
      !LoadPort &&
      !TotalQ &&
      !Rate &&
      !Unit &&
      !S0Number &&
      !Incoterm &&
      !ShippingTerms &&
      !TotalContainer &&
      !ShipmentBy &&
      !Currency &&
      !Consignee &&
      !HSN &&
      !Terms &&
      !BrandName &&
      !BorkerName &&
      !ContactPerson &&
      !MillerName &&
      !soPaymentTerm &&
      !contractTypeName &&
      packagingArr.length === 0 &&
      portArry.length === 0 &&
      !SalesOrder &&
      !uploadedFile
    ) {
      this.setState({
        error: noSnack ? false : "Nothing to reset !",
        itemVar: {},
        errorMsg: {
          TotalRate: false,
          LoadPort: false,
          DischargePort: false,
          Buyer: false,
          TotalQ: false,
          Unit: false,
          S0Number: false,
          Incoterm: false,
          ShippingTerms: false,
          TotalContainer: false,
          ShipmentBy: false,
          Currency: false,
          Consignee: false,
          contractTypeName: false,
          BorkerName: false,
          Borkerage: false,
          portArry: false,
          Consignee: false,
          MillerName: false,
          BrandName: false,
          HSN: false,
          Rate: false,
          soPaymentTerm: false,
          Terms: false,
          packagingArr: false,
          requiredToggle: true,
        },
      });
    } else {
      this.setState(
        {
          TotalRate: "",
          DischargePort: "",
          uploadedFile: "",
          LoadPort: "",
          Buyer: "",
          TotalQ: "",
          BrandNameId: "",
          BrandName: false,
          selectedBrandMultipleName: false,
          selctedSOStatus: "DRAFT",
          SalesOrder: "",
          SalesOrderId: "",
          contractTypeName: "",
          soPaymentTerm: "",
          MillerName: "",
          MillerAddress: "",
          MillerGST: "",
          ContactPerson: "",
          ItemCategory: "",
          ItemTypeId: "",
          ItemCategoryId: "",
          ItemType: "",
          BorkerName: "",
          Borkerage: "",
          Terms: "",
          Consignee: "",
          portArry: [],
          packagingArr: [],
          Rate: "",
          Unit: "",
          S0Number: "",
          Incoterm: "",
          ShippingTerms: "",
          TotalContainer: '',
          ShipmentBy: "",
          Currency: "",
          HSN: "",
          HSN_id: "",
          itemVar: {},
          errorMsg: {
            TotalRate: false,
            LoadPort: false,
            DischargePort: false,
            Unit: false,
            S0Number: false,
            Incoterm: false,
            ShippingTerms: false,
            TotalContainer: false,
            ShipmentBy: false,
            Currency: false,
            Consignee: false,
            contractTypeName: false,
            BorkerName: false,
            Borkerage: false,
            portArry: false,
            MillerName: false,
            BrandName: false,
            HSN: false,
            Rate: false,
            soPaymentTerm: false,
            Terms: false,
            packagingArr: false,
          },
          requiredToggle: true,
        },
        () => {
          this.setState({ success: noSnack ? false : "Reset successfully !" });
        }
      );
    }
  };
  fetchPolicy = (iC, sC) => {
    console.log(iC, sC, "iC,sC");
    this.props
      .SalesOrdersFunction("get", EndPoints["Policys"], null, "policy", null, {
        inco_term: iC ? iC : "",
        shipping_term: sC ? sC : "",
      })
      .then((soPolicy) => {
        console.log(soPolicy, "soPolicy");
        this.setState({ soPolicy: soPolicy.length > 0 ? soPolicy[0] : "" });
      });
  };

  setData = () => {
    const {
      ClientList,
      BrandList,
      ItemCateoryList,
      ItemList,
      BrokerList,
      ContractTypeList,
      PackagingList,
      PaymentTermList,
      SO_Items_List,
      soItemheader,
      HSNlist,
      singlelistView,
      soPackahging,
      masters,
      Incoterms,
      CurrencyList,
    } = this.props;
    const { editDialog, portsList } = this.state;
    const oldnewPortArr = editDialog.load_ports
      ? editDialog.load_ports.map((s) => {
          return s.toString();
        })
      : [];
    const newPortArr = [];
    if (portsList && portsList.length > 0) {
      portsList.map((sPack) => {
        if (oldnewPortArr.includes(sPack.id.toString())) {
          newPortArr.push(sPack);
          return sPack;
        } else {
          return null;
        }
      });
    }

    const newSOItemArray = [];
    const itemVar = {};
    if (SO_Items_List && SO_Items_List.length > 0) {
      SO_Items_List &&
        SO_Items_List.map((sList, i) => {
          itemVar[`${i + 1}`] = sList.extras;
          const packaging_details = [];
          const pId = filterData(soPackahging, "so_items", sList.id)
            ? filterData(soPackahging, "so_items", sList.id).map((s) => {
                return s.packaging.toString();
              })
            : [];
          if (PackagingList && PackagingList.length > 0) {
            PackagingList.map((sPack) => {
              const newObject =
                soPackahging &&
                filterData(soPackahging, "so_items", sList.id) &&
                filterData(
                  soPackahging &&
                    filterData(soPackahging, "so_items", sList.id),
                  "packaging",
                  sPack.id,
                  "onlyOne"
                )
                  ? filterData(
                      soPackahging &&
                        filterData(soPackahging, "so_items", sList.id),
                      "packaging",
                      sPack.id,
                      "onlyOne"
                    )
                  : null;

              if (pId.includes(sPack.id.toString()) || pId.includes(sPack.id)) {
                sPack.remaining_bags = newObject ? newObject.remaining_bags : 0;
                sPack.used_bags = newObject
                  ? newObject.used_bags
                    ? newObject.used_bags
                    : 0
                  : 0;
                return packaging_details.push(sPack);
              } else {
                return null;
              }
            });
          }
          const addData = {
            brand: sList.brand,
            hsn_code: sList.hsn_code ? sList.hsn_code : "",
            packagingArr: packaging_details.map((sP) => {
              sP.sizeUnit = `${sP.size} ${sP.unit}`;
              return sP;
            }),
            total_quantity: sList.total_quantity,

            packaging: sList.packaging,
          };
          addData["id"] = sList.id;
          addData["remaining_bags"] = sList.remaining_bags;
          addData["dispatched_quantity"] = sList.dispatched_quantity;

          addData[soItemheader[1]] =
            ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemCateoryList.filter((sM) =>
              sM.id === sList.item_category ? sList.item_category : 0
            )[0]
              ? ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name &&
                ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name
              : "";
          addData[`${soItemheader[1]}id`] = sList.item_category;

          addData[soItemheader[2]] =
            ItemList &&
            ItemList.length > 0 &&
            ItemList.filter((sM) =>
              sM.id === sList.item_type ? sList.item_type : 0
            )[0]
              ? ItemList.filter((sM) =>
                  sM.id === sList.item_type ? sList.item_type : 0
                )[0].broken_percentage
              : "";
          addData[`${soItemheader[2]}id`] = sList.item_type;

          addData[soItemheader[0]] =
            BrandList &&
            BrandList.length > 0 &&
            BrandList.filter((sM) =>
              sM.id === sList.brand ? sList.brand : 0
            )[0]
              ? BrandList.filter((sM) =>
                  sM.id === sList.brand ? sList.brand : 0
                )[0].brand_name
              : "";
          addData[`${soItemheader[0]}id`] = sList.brand;

          addData.selectedBrandMultipleName = `${
            filterData(BrandList, "id", sList.brand, "onlyOne")
              ? filterData(BrandList, "id", sList.brand, "onlyOne").brand_name
              : ""
          }-${
            filterData(ItemCateoryList, "id", sList.item_category, "onlyOne")
              ? filterData(
                  ItemCateoryList,
                  "id",
                  sList.item_category,
                  "onlyOne"
                ).name
              : ""
          }-${
            filterData(ItemList, "id", sList.item_type, "onlyOne")
              ? filterData(ItemList, "id", sList.item_type, "onlyOne")
                  .broken_percentage
              : ""
          } %`;
          addData[soItemheader[3]] =
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }) &&
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }).length > 0
              ? packaging_details.map((sP) => {
                  return `${sP.size} ${sP.unit}`;
                })[0]
              : "-";
          addData[`${soItemheader[3]}Id`] = packaging_details;
          addData["packagageArrUsed"] = packaging_details.map((sP) => {
            const newObj = {};
            newObj.id = sP.id;
            newObj.bag_type = sP.bag_type;
            newObj.size = sP.size;
            newObj.unit = sP.unit;
            newObj.sizeUnit = `${sP.size} ${sP.unit}`;
            newObj.used_bags_po = sP.used_bags ? sP.used_bags : 0;
            newObj.used_bags = 0;
            newObj.remaining_bags = sP.remaining_bags;
            return newObj;
          });
          
          addData[soItemheader[4]] =
            HSNlist &&
            HSNlist.length > 0 &&
            HSNlist.filter(
              (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
            )[0]
              ? HSNlist.filter(
                  (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
                )[0].hsn_code
              : "";

          addData[`${soItemheader[4]}id`] = sList.hsn_code
            ? sList.hsn_code
            : "";
          addData[soItemheader[5]] = sList.total_quantity;
          addData[`${soItemheader[5]}Unit`] = `${sList.total_quantity} ${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;

          addData[soItemheader[6]] = sList.contract_rate;
          addData[soItemheader[7]] = sList.buyers_marking;
          
          addData[soItemheader[9]] = sList.quantity_tolerance;
          
          addData[soItemheader[10]] = sList.bag_weight;
          // addData[soItemheader[8]] =
          // CurrencyList && CurrencyList.length > 0 && CurrencyList.filter((sM) => sM.id === (sList.name ? sList.name : 0))[0]
          //   ? CurrencyList.filter((sM) => sM.id === (sList.name ? sList.name : 0))[0].name
          //   : "";

          addData["specificationArr"] = sList["so_specifications"];
          addData["Specifications"] = sList["so_specifications"];

          addData[`${soItemheader[6]}Unit`] = `${sList.contract_rate}/${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;

          addData.id = sList.id;
          addData.so_item_id = sList.id;
          newSOItemArray.push(addData);
          return sList;
        });
    }

    if (editDialog !== false) {
      // this.props.setPoItems(newPOItemArray, newPOItemArray);
      this.props.setSoItems(newSOItemArray, newSOItemArray);
      console.log(editDialog, "soPolicy");
      this.setState(
        {
          itemVar: itemVar,
          marking: editDialog.marking ? editDialog.marking : "",
          duty_payment_term: editDialog.duty_payment_term ? editDialog.duty_payment_term : "",
          CurrencyId: editDialog.currency ? editDialog.currency : "",
          Currency: filterData(
            masters["CurrencyList"],
            "id",
            Number(editDialog.currency),
            "onlyOne"
          )
            ? filterData(
                masters["CurrencyList"],
                "id",
                Number(editDialog.currency),
                "onlyOne"
              ).name
            : "",
          IncotermId: editDialog.inco_term ? editDialog.inco_term : "",
          Incoterm: filterData(
            masters["Incoterms"],
            "id",
            Number(editDialog.inco_term),
            "onlyOne"
          )
            ? filterData(
                masters["Incoterms"],
                "id",
                Number(editDialog.inco_term),
                "onlyOne"
              ).name
            : "",
          uploadedFile: editDialog.so_file_upload,
          S0Number: editDialog.so_number,
          TotalContainer: editDialog.total_no_of_containers,
          selctedTYpeOfSO: editDialog.so_type,
          soPaymentTermId: editDialog.so_payment_term,
          soPaymentTerm: editDialog.so_payment_term
            ? filterData(
                masters["SoPaymentList"],
                "id",
                Number(editDialog.so_payment_term),
                "onlyOne"
              )
              ? filterData(
                  masters["SoPaymentList"],
                  "id",
                  Number(editDialog.so_payment_term),
                  "onlyOne"
                ).term
              : ""
            : "",

          ShippingTermsId: editDialog.shipping_term,
          ShipmentBy: editDialog.shipment_by,
          ShippingTerms: editDialog.shipping_term
            ? filterData(
                masters["ShippingTerms"],
                "id",
                Number(singlelistView.shipping_term),
                "onlyOne"
              )
              ? filterData(
                  masters["ShippingTerms"],
                  "id",
                  Number(singlelistView.shipping_term),
                  "onlyOne"
                ).value
              : ""
            : "",

          soPolicy: {
            id: editDialog.so_policy,
            policy: editDialog.policy_of_so_policy,
          },
          Varaible: editDialog.extras,
          selctedSOStatus: editDialog.status ? editDialog.status : "OPEN",
          requiredToggle: editDialog.status === 'DRAFT' ? true : false,
          SalesOrder:
            editDialog.sales_order_details &&
            editDialog.sales_order_details.so_number
              ? editDialog.sales_order_details.so_number
              : "",
          SalesOrderId:
            editDialog.sales_order_details && editDialog.sales_order_details.id
              ? editDialog.sales_order_details.id
              : "",

          searchedData: editDialog.contract_date,
          shipmentDate: editDialog.shipment_date,
          BuyerId: editDialog.buyer ? editDialog.buyer : 0,
          Buyer:
            ClientList &&
            ClientList.length > 0 &&
            ClientList.filter((sM) =>
              sM.id === editDialog.buyer ? editDialog.buyer : 0
            )[0]
              ? ClientList.filter((sM) =>
                  sM.id === editDialog.buyer ? editDialog.buyer : 0
                )[0].client_name
              : "",
          TotalRate: editDialog.rate,
          TotalQ: editDialog.total_quantity,

          Terms: editDialog.terms ? editDialog.terms : "",
          portArry: newPortArr,
          Consignee: editDialog.consignee ? editDialog.consignee : "",
          // LoadPort: editDialog.load_port ? editDialog.load_port : "",
          // LoadPort: newPortArr,
          DischargePort: editDialog.discharge_port
            ? editDialog.discharge_port
            : "",

          Unit: editDialog.unit,
        },
        () => {
          this.setState({ loadingFun: false });
        }
      );
    }
  };
  handleClearFields = () => {
    this.setState({
      added: false,
      loadingFun: false,
      // success: false,
      error: false,
    });
  };
  handleQedit = (tq, q) => {
    let x = tq - q;
    // this.setState({ TotalQ: x });
    return x;
  };
  handleRedit = (tr, r) => {
    let x = tr - r;
    // this.setState({ TotalRate: x });
    return x;
  };
  handleQuant = (q, tq) => {
    q = parseFloat(q);
    let Tq = tq ? parseFloat(tq) : 0;

    let a = q + Tq;
    // console.log(a, "aaaaaaaa");
    return a;
  };
  setInitialValues = (next) => {
    const { openDialog, requiredToggle, onlyDialog, PortsList } = next;
    const { loading, selctedStatus, selectedMonth } = this.state;
    console.log(openDialog, onlyDialog, loading,requiredToggle, "loading next next");
    if (loading !== true) {
      this.setState({
        openDialog,
        requiredToggle: this.state.selctedSOStatus === 'DRAFT' ? true : false,
        onlyDialog,
        specificationHeader: [
          {
            name: "Type",
            type: "select",
            dataType: "text",
            api: "name",
            array: next.specifiationTypes
              ? next.specifiationTypes.map((s) => {
                  return { ...s, name: s.text };
                })
              : [],
            value: "name",
            filter: (array, specificationArr) => {
              return (
                array &&
                array.length > 0 &&
                array.filter(
                  (ab) =>
                    !filterData(specificationArr, "name", ab.name, "onlyOne")
                ) &&
                array.filter(
                  (ab) =>
                    !filterData(specificationArr, "name", ab.name, "onlyOne")
                ).length > 0 &&
                array.filter(
                  (ab) =>
                    !filterData(specificationArr, "name", ab.name, "onlyOne")
                )
              );
            },
          },
          {
            name: "Condition",
            type: "select",
            dataType: "text",
            api: "condition",
            array: MaxMin,
            value: "name",
          },
          {
            name: "Value",
            type: "textField",
            dataType: "number",
            api: "value",
          },
        ],
        portsList:
          PortsList && PortsList.length > 0
            ? PortsList.map((sP) => {
                sP.nameCity = `${sP.name} - ${sP.city}`;
                return sP;
              })
                .concat(this.state?.Incoterm && this.state?.Incoterm === 'FOB' ? [] : [{ nameCity: "Any Indian Port", id: "Any Indian Port" }])
                .sort((a, b) => a.nameCity.localeCompare(b.nameCity))
            : this.state?.Incoterm && this.state?.Incoterm === 'FOB' ? [] : [{ nameCity: "Any Indian Port", id: "Any Indian Port" }],
            // : [],
      });
    }
    if (openDialog !== this.state.openDialog) {
      this.setState(
        {
          previewPdf: false,
          openDialog,
          requiredToggle: this.state.selctedSOStatus === 'DRAFT' ? true : false,
          specificationHeader: [
            {
              name: "Type",
              type: "select",
              dataType: "text",
              api: "name",
              array: next.specifiationTypes
                ? next.specifiationTypes.map((s) => {
                    return { ...s, name: s.text };
                  })
                : [],
              value: "name",
              filter: (array, specificationArr) => {
                return (
                  array &&
                  array.length > 0 &&
                  array.filter(
                    (ab) =>
                      !filterData(specificationArr, "name", ab.name, "onlyOne")
                  ) &&
                  array.filter(
                    (ab) =>
                      !filterData(specificationArr, "name", ab.name, "onlyOne")
                  ).length > 0 &&
                  array.filter(
                    (ab) =>
                      !filterData(specificationArr, "name", ab.name, "onlyOne")
                  )
                );
              },
            },
            {
              name: "Condition",
              type: "select",
              dataType: "text",
              api: "condition",
              array: MaxMin,
              value: "name",
            },
            {
              name: "Value",
              type: "textField",
              dataType: "number",
              api: "value",
            },
          ],
          defaultStep: 0,
          currentStep: 0,
          submittedSpecifies: {},
          ShippingTerms: "",
          ShippingTermsId: "",
          TotalContainer: '',
          ShipmentBy: "",
          Currency: "",
          Varaible: "",
          uploadedFile: "",
          added: false,
          loadingFun: false,
          // success: false,
          error: false,
        },
        () => {}
      );
    }
  };
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props.openDialog);
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  clearSurveyFields = () => {
    const errorMsg = this.state.errorMsg;
    errorMsg.percentageValue = false;

    errorMsg.selectedSurrvayStatus = false;

    errorMsg.selectedCondition = false;
    this.setState({
      errorMsg,
      percentageValue: "",
      selectedSurrvayStatus: "",
      selectedCondition: "",
      selectedConditionId: "",
      specificationId: false,
    });
  };

  handleRequiredToggleChange = (e) => {
    this.setState({ requiredToggle: e.target.checked }, () => {
      if(this.state.requiredToggle) {
        const errorMsg = this.state.errorMsg;
        errorMsg.selctedSOStatus = false;
        this.setState({ errorMsg, selctedSOStatus: 'DRAFT',});
      }
      else{
        const errorMsg = this.state.errorMsg;
        errorMsg.selctedSOStatus = false;
        this.setState({ errorMsg, selctedSOStatus: 'OPEN',});
      }
    })
  }

  render() {
    const {
      singlelistView,
      soItemsArrayTOPreview,
      SOITemTable,
      PaymentTermList,
      status,
      unit,
      buyer,
      totalQ,
      totalRate,
      ContractTypeList,
      BrokerList,
      // MillerList,
      ClientList,
      SalesOrderList,
      BrandList,
      ItemCateoryList,
      ItemList,
      PackagingList,
      HSNlist,
      soItemheader,
      selctedStatus,
      selectedMonth,
      soItemsArray,
      soItemsArrayCopy,
      onlyDialog,
      noTotalQuantity,
      showTotal,
      masters,
      Incoterms,
      SoPaymentList,
      CurrencyList,
      soPolicyList,
    } = this.props;
    const {
      previewPdf,
      selctedTYpeOfSO,
      specificationHeader,
      openDialog,
      editDialog,
      loadingFun,
      success,
      error,
      added,
      calenderOpenLS,
      errorMsg,
      selctedSOStatus,
      Unit,
      Buyer,
      TotalQ,
      TotalRate,
      LoadPort,
      DischargePort,
      contractTypeName,
      contractTypeId,
      BorkerName,
      BorkerNameId,
      BorkerageId,
      portArry,
      BuyerId,
      MillerNameId,
      MillerName,
      MillerAddress,
      MillerGST,
      ContactPerson,
      last_survey,
      SalesOrder,
      SalesOrderId,
      BrandName,
      selectedBrandMultipleName,
      ItemCategory,
      ItemType,
      packagingArr,
      total_quantity,
      HSN,
      HSN_id,
      Rate,
      editSnack,
      BrandNameId,
      itemsId,
      ItemCategoryId,
      calenderOpen,
      searchedData,
      shipmentDate,
      selectedSoid,
      soPaymentTerm,
      soPaymentTermId,
      Terms,
      tQuan,
      downloadPdf,
      tRate,
      selectedCI,
      SurveyStatus,
      MaxMin,
      percentageValue,
      specificationArr,
      specificationArrCopy,
      selectedConditionId,
      selectedCondition,
      selectedSurrvayStatus,
      specificationId,
      marking,
      showSpeci,
      Incoterm,
      IncotermId,
      submittedSpecifies,
      BuyerMarking,bag_weight,duty_payment_term,quantity_tolerance,
      Currency,
      S0Number,
      TotalContainer,
      ShipmentBy,
      CurrencyId,
      ShippingTerms,
      ShippingTermsId,
      soPolicy,
      Varaible,
      uploadedFile,
      portsList,
      loading,
      Consignee,
      addendum,
      requiredToggle
      // onlyDialog,
    } = this.state;
    const details = [
      {
        name: "SO Number",
        value: singlelistView && singlelistView ? singlelistView.so_number : "",
        name1: "Contract Date",
        value1:
          singlelistView &&
          moment(singlelistView.contract_date).format("ddd, MMM Do YYYY"),
      },
      {
        name: "Unit",
        value: singlelistView && singlelistView.unit,
        name1: "Buyer",
        value1:
          singlelistView && singlelistView.buyer
            ? filterData(ClientList, "id", singlelistView.buyer, "onlyOne")
              ? filterData(ClientList, "id", singlelistView.buyer, "onlyOne")
                  .client_name
              : ""
            : "",
      },

      {
        name: "Total Container",
        value: singlelistView && singlelistView.total_no_of_containers,
        name1: "Total Quantity",
        value1:
          singlelistView &&
          `${singlelistView.total_quantity} ${
            singlelistView && singlelistView.unit
          }`,
      },

      {
        name: "Discharge Port",
        value: singlelistView && singlelistView.discharge_port,
        name1: "Load Port",
        value1:
          singlelistView &&
          singlelistView.load_ports.map((sP) => {
            return filterData(portsList, "id", Number(sP), "onlyOne")
              ? filterData(portsList, "id", Number(sP), "onlyOne").nameCity
              : "";
          }),
      },
      {
        name: "Incoterm",
        value:
          singlelistView && singlelistView.inco_term
            ? filterData(
                masters["Incoterms"],
                "id",
                Number(singlelistView.inco_term),
                "onlyOne"
              )
              ? filterData(
                  masters["Incoterms"],
                  "id",
                  Number(singlelistView.inco_term),
                  "onlyOne"
                ).name
              : ""
            : "",
        name1: "Shipping Terms",
        value1:
          singlelistView && Number(singlelistView.shipping_term)
            ? filterData(
                masters["ShippingTerms"],
                "id",
                Number(singlelistView.shipping_term),
                "onlyOne"
              )
              ? filterData(
                  masters["ShippingTerms"],
                  "id",
                  Number(singlelistView.shipping_term),
                  "onlyOne"
                ).value
              : ""
            : "",
      },
      {
        name: "Status",
        value: singlelistView && singlelistView.status,
        name1: "Consignee Name",
        value1: singlelistView && singlelistView.consignee,
      },
      {
        name: "Shipment Date",
        value:
          singlelistView &&
          moment(singlelistView.shipment_date).format("ddd, MMM Do YYYY"),
        name1: "Shipment By",
        value1:
          singlelistView && singlelistView ? singlelistView.shipment_by : "",
      },
    ];
    const detailsAddedum = [
      {
        name: "Unit",
        value: singlelistView && singlelistView.unit,
        name1: "Total Quantity",
        value1:
          singlelistView &&
          `${singlelistView.total_quantity} ${
            singlelistView && singlelistView.unit
          }`,
      },

      // {

      //   name1: "Total Quantity",
      //   value1:
      //     singlelistView &&
      //     `${singlelistView.total_quantity} ${
      //       singlelistView && singlelistView.unit
      //     }`,
      // },

      {
        name: "Discharge Port",
        value: singlelistView && singlelistView.discharge_port,
        name1: "Load Port",
        value1:
          singlelistView &&
          singlelistView.load_ports.map((sP) => {
            return filterData(portsList, "id", Number(sP), "onlyOne")
              ? filterData(portsList, "id", Number(sP), "onlyOne").nameCity
              : "";
          }),
      },
      {
        name: "Incoterm",
        value:
          singlelistView && singlelistView.inco_term
            ? filterData(
                masters["Incoterms"],
                "id",
                Number(singlelistView.inco_term),
                "onlyOne"
              )
              ? filterData(
                  masters["Incoterms"],
                  "id",
                  Number(singlelistView.inco_term),
                  "onlyOne"
                ).name
              : ""
            : "",
        name1: "Shipping Terms",
        value1:
          singlelistView && Number(singlelistView.shipping_term)
            ? filterData(
                masters["ShippingTerms"],
                "id",
                Number(singlelistView.shipping_term),
                "onlyOne"
              )
              ? filterData(
                  masters["ShippingTerms"],
                  "id",
                  Number(singlelistView.shipping_term),
                  "onlyOne"
                ).value
              : ""
            : "",
      },

      {
        name: "Shipment Date",
        value:
          singlelistView &&
          moment(singlelistView.shipment_date).format("ddd, MMM Do YYYY"),
        name1: "Shipment By",
        value1:
          singlelistView && singlelistView ? singlelistView.shipment_by : "",
      },
    ];
    const fetchData = (res, formData, edit,editId) => {
      // console.log(singlelistView.shipment_by,"aaaaaaaaaaaaaaaaaaaaaaaaaa")
      if (
        ((!edit && res.split("so_id=")[1]) || (edit && res.id)) &&
        selctedTYpeOfSO === "BUYER" &&
        typeof uploadedFile !== "string"
      ) {
        this.props
          .SalesOrdersFunction(
            "patch",
            "dispatch/sales-order",
            Number(edit ? res.id : res.split("so_id=")[1]),
            null,
            formData
          )
          .then((res2) => {
            console.log(res2, "res2res2res2");
            if (res2.error) {
              this.setState({
                error:
                  typeof res2.data === "string"
                    ? res2.data
                    : res2.data.length > 0
                    ? res2.data
                        .map((s) => {
                          return `${s.field ? `${s.field} :` : ""} ${
                            typeof s.message === "string"
                              ? s.message
                              : s.message.length > 0 &&
                                s.message[0] &&
                                s.message[0]
                          }`;
                        })
                        .join(" ,")
                    : res2.data,
              });
            } else {
              // this.props
              //   .SalesOrdersFunction(
              //     "get",
              //     EndPoints["SalesOrders"],
              //     null,
              //     null,
              //     null,
              //     selctedStatus === "CLOSED" && selectedMonth
              //       ? {
              //           month: selectedMonth.getMonth() + 1,
              //           status: selctedStatus,
              //         }
              //       : {
              //           status: selctedStatus,
              //         }
              //   )
              //   .then(() => {
              this.props.closeDialog(editId);

              this.setState(
                {
                  openDialog: false,
                  editDialog: false,
                  error: "",
                  loading: false,
                  editSnack: false,
                },
                () => {
                  this.setState({
                    success: edit
                      ? "Sales Order Edited!"
                      : "Sales Order Added!",
                  });
                }
              );

              // });
            }
          });
      } else {
        this.props.closeDialog(editId);

        this.setState(
          {
            openDialog: false,
            editDialog: false,
            error: "",
            loading: false,
            editSnack: false,
          },
          () => {
            this.setState({
              success: edit ? "Sales Order Edited!" : "Sales Order Added!",
            });
          }
        );

        // });
      }
    };
    const scrollDiv = (id) => {
      console.log(id, "lllllllllllllllllllllllllll");
      const chat = document.getElementById(id);
      chat.scrollIntoView();
    };
    const colorArr = [white, lightGrey];
    const addItemsFunction = () => {
      if (BrandName !== false) {
        if (ItemCategory || requiredToggle) {
          if (ItemType || requiredToggle) {
            if (packagingArr.length > 0 || requiredToggle) {
              if (HSN || requiredToggle) {
                if (total_quantity || requiredToggle) {
                  if (Rate || requiredToggle) {
                    if (BuyerMarking || requiredToggle) {
                      // if(Currency){
                      let x = this.handleQuant(total_quantity, TotalQ);
                      let y = this.handleQuant(Rate, TotalRate);

                      {
                        x && this.setState({ TotalQ: x });
                      }
                      {
                        y && this.setState({ TotalRate: y });
                      }

                      const newsoItemsArray = soItemsArrayCopy;
                      const addData = {
                        brand: BrandNameId,

                        hsn_code: HSN_id,

                        item_type: itemsId,
                        item_category: ItemCategoryId,
                        packagingArr: packagingArr,
                        total_quantity: total_quantity,
                        //name:CurrencyId,

                        packaging: packagingArr.map((sP) => {
                          return sP.id;
                        }),
                      };
                      addData[soItemheader[1]] = ItemCategory;
                      addData[`${soItemheader[1]}id`] = ItemCategoryId;

                      addData[soItemheader[2]] = ItemType;

                      addData[`${soItemheader[2]}id`] = itemsId;

                      addData[soItemheader[0]] = BrandName;
                      addData[`${soItemheader[0]}id`] = BrandNameId;

                      addData[soItemheader[3]] = packagingArr
                        .map((sP) => {
                          return `${sP.size} ${sP.unit}`;
                        })
                        .join(" ,");
                      addData[`${soItemheader[3]}Id`] = packagingArr.map(
                        (sP) => {
                          sP.sizeUnit = `${sP.size} ${sP.unit}`;
                          return sP;
                        }
                      );
                      addData[`packagageArrUsed`] = packagingArr.map((sP) => {
                        sP.sizeUnit = `${sP.size} ${sP.unit}`;
                        return sP;
                      });
                      addData[soItemheader[4]] = HSN;
                      addData[`${soItemheader[4]}id`] = HSN_id;
                      addData[soItemheader[5]] = total_quantity;
                      addData[`${soItemheader[5]}Unit`] = `${total_quantity} ${
                        Unit ? Unit : ""
                      }`;

                      addData[soItemheader[6]] = Rate;
                      console.log(BuyerMarking, Currency, "BuyerMarking");
                      addData[soItemheader[7]] = BuyerMarking;
                      addData[soItemheader[9]] =quantity_tolerance 
                      addData[soItemheader[10]] =  bag_weight
                      addData["quantity_tolerance"] =quantity_tolerance 
                      addData["bag_weight"] =  bag_weight

                      // addData[soItemheader[8]] = Currency;
                      // addData[`${soItemheader[8]}id`] = CurrencyId;

                      addData["specificationArr"] = specificationArr;
                      addData["Specifications"] = specificationArr;
                      addData["specifications"] = specificationArr;

                      addData[`${soItemheader[6]}Unit`] = `${Rate}/${
                        Unit ? Unit : ""
                      }`;
                      console.log(selectedSoid, "selectedSoid");
                      addData.so_item_id = selectedSoid
                        ? selectedSoid
                        : newsoItemsArray.length + 1;
                        selectedSoid && (addData.id = selectedSoid);
                        // ? selectedSoid
                        // : '';
                      addData.selectedBrandMultipleName =
                        selectedBrandMultipleName
                          ? selectedBrandMultipleName
                          : "";
                      const newArr = [];

                      if (newsoItemsArray.length > 0) {
                        console.log(newsoItemsArray, "newsoItemsArray");
                        newsoItemsArray.map((s) => {
                          console.log(s, "s", addData);
                          if (s.so_item_id === addData.so_item_id) { // edit any item which added already
                            newArr.push(addData);
                            console.log(newArr, "newArr1");
                          } else {
                            console.log(s, "same add data");
                            newArr.push(s);
                            console.log(newArr, "newArr2");
                          }
                          return s;
                        });
                        // console.log("BOOLEAN", selectedSoid, Boolean(selectedSoid))
                        if (!Boolean(selectedSoid)) {
                          newArr.push(addData);
                          console.log(newArr, "newArr3");
                        }
                      } else {
                        newArr.push(addData);
                        console.log(newArr, "newArr4");
                      }
                      // this.props.setPoItems(
                      this.props.setSoItems(newArr, newArr);

                      console.log(newArr, addData, "add data");

                      this.setState({
                        itemsId: "",
                        ItemType: "",
                        ItemCategoryId: "",
                        ItemCategory: "",
                        packagingArr: [],
                        BrandNameId: "",
                        BrandName: false,
                        selectedBrandMultipleName: false,
                        HSN_id: "",
                        HSN: "",
                        total_quantity: "",
                        selectedSoid: false,
                        Rate: "",
                        BuyerMarking: "",bag_weight:"",
                        quantity_tolerance:"",
                        // CurrencyId:"",
                        // Currency:"",
                        specificationArr: [],
                        specificationArrCopy: [],
                      });
                      // } else {
                      //   const errorMsg = this.state.errorMsg;
                      //   errorMsg.Currency = "Please give Contract Currency!";

                      //   this.setState({ errorMsg });
                      // }
                    } else {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.BuyerMarking =
                        "Please give Contract Buyer Marking!";

                      this.setState({ errorMsg });
                    }
                  } else {
                    const errorMsg = this.state.errorMsg;
                    errorMsg.Rate = "Please give Contract rate!";

                    this.setState({ errorMsg });
                  }
                } else {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.total_quantity = "Please give total_quantity!";

                  this.setState({ errorMsg });
                }
              } else {
                const errorMsg = this.state.errorMsg;
                errorMsg.HSN = "Please give HSN!";

                this.setState({ errorMsg });
              }
            } else {
              const errorMsg = this.state.errorMsg;
              errorMsg.packagingArr = "Please give packaging!";
              this.setState({ errorMsg });
            }
          } else {
            const errorMsg = this.state.errorMsg;
            errorMsg.ItemType = "Please select ItemType!";
            this.setState({ errorMsg });
          }
        } else {
          const errorMsg = this.state.errorMsg;
          errorMsg.ItemCategory = "Please select ItemCategory!";
          this.setState({ errorMsg });
        }
      } else {
        const errorMsg = this.state.errorMsg;
        errorMsg.BrandName = "Please give BrandName!";
        this.setState({ errorMsg });
      }
    };
    const SpecificationTable = () => {
      return (
        <div className="d-flex width-100 flexWrap justifyC">
          <div className="width-100 " style={{ margin: "20px 10px" }}>
            <div
              style={{
                backgroundColor: "#8080801a",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}
            >
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgb(128 128 128 / 64%)" }}
              >
                {specificationHeader.map((sC) => (
                  <div
                    key={sC.name}
                    className="justifyC d-flex alignC"
                    style={{
                      width: "calc(25% - 54px)",
                      margin: "12px 10px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}
                  >
                    {sC.name}
                  </div>
                ))}
                <div
                  className="justifyC d-flex alignC"
                  style={{
                    margin: "12px 5px",
                    width: "82px",
                    display: "flex",
                    alignItems: "center",
                    color: "#100f0f",
                    textTransform: "capitalize",
                  }}
                >
                  Action
                </div>
              </div>
            </div>
            <div
              style={{
                height: "fit-content",
                width: "100%",
                minWidth: "100%",
                position: "relative",
                borderRadius: "0 0 7px 7px",
                borderRight: "1px solid rgba(128, 128, 128, 0.64)",
                borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
                borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
                padding: "5px 0",
              }}
            >
              {specificationArr &&
                specificationArr.map((sA, i) => (
                  <div
                    className={`dividerBorder d-flex width-100 justifySE `}
                    style={{
                      backgroundColor:
                        colorArr && colorArr[i % colorArr.length],
                      height: "44px",
                    }}
                  >
                    {specificationHeader.map((sC) => (
                      <div
                        key={sC.name}
                        className="justifyC d-flex alignC"
                        style={{
                          width: "calc(25% - 54px)",
                          margin: "12px 10px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}
                      >
                        {sA[sC.api]
                          ? `${sA[sC.api]} ${
                              sC.name === "Value" &&
                              this.props.specifiationTypes.find(
                                (st) => st.text === sA["name"]
                              )
                                ? this.props.specifiationTypes.find(
                                    (st) => st.text === sA["name"]
                                  ).unit
                                : ""
                            }`
                          : sC.api === "name"
                          ? `${sA["name"] ?? sA["Type"]}`
                          : "-"}
                      </div>
                    ))}
                    <div
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "10px",
                        width: "82px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        const submittedData =sA
                        specificationHeader.map((singleInput) => {
                          submittedData[singleInput.name] = sA[singleInput.api];
                          submittedData[`${singleInput.name}Id`] =
                            sA[`${singleInput.api}Id`];

                          this.setState({
                            errorMsg: {
                              ...errorMsg,
                              [singleInput.name]: false,
                            },
                          });
                          return singleInput;
                        });

                        const arr = [];
                        specificationArrCopy.map((s) => {
                          if (sA.lid === s.lid) {
                          } else {
                            arr.push(s);
                          }
                          return s;
                        });
                        this.setState({
                          submittedSpecifies: {
                            ...submittedData,
                            lid:
                              sA.lid || sA.lid === 0
                                ? sA.lid
                                : specificationArrCopy.length + 1,
                          },
                          specificationArr: arr,

                          errorMsg: { ...errorMsg, addSpecification: false },
                        });
                      }}
                    >
                      <StyledButton
                        name={<EditIcon />}
                        toolTip="Edit"
                        maxHeight="24px"
                        padding="0"
                        height="24px"
                        width="40px"
                        minWidth="0"
                      />
                    </div>
                  </div>
                ))}
              <div
                className="d-flex width-100 justifySE"
                style={{
                  backgroundColor:
                    colorArr &&
                    colorArr[specificationArr.length % colorArr.length],
                  minHeight: "60px",
                }}
              >
                {specificationHeader.map((singleInput, i) =>
                  singleInput.type === "select" ? (
                    <CssTextField95
                      required={
                        singleInput.name === "Condition" &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? false
                          : true
                      }
                      error={errorMsg[singleInput.name] ? true : false}
                      autoComplete="off"
                      disabled={
                        singleInput.name === "Condition" &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? true
                          : false
                      }
                      value={
                        submittedSpecifies[singleInput.name]
                          ? submittedSpecifies[singleInput.name]
                          : ""
                      }
                      style={{ width: "calc(25% - 54px)", margin: "10px" }}
                      select
                      label={
                        singleInput.name === "Condition" &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? "No Condition"
                          : singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                      }
                      SelectProps={{ disableunderline: "true", MenuProps: MenuProps }}
                      variant="outlined"
                      onChange={(e) => {
                        if (e.target.value) {
                          const id = `${singleInput.name}Id`;
                          this.setState(
                            singleInput.name === "Condition"
                              ? {
                                  submittedSpecifies: {
                                    ...submittedSpecifies,
                                    [singleInput.name]: e.target.value,
                                    [id]: e.currentTarget.id,
                                  },
                                }
                              : {
                                  submittedSpecifies: {
                                    ...submittedSpecifies,
                                    [singleInput.name]: e.target.value,
                                    [id]: e.currentTarget.id,
                                    Condition:
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ) &&
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ).value_type === "text"
                                        ? ""
                                        : "",
                                    ConditionId:
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ) &&
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ).value_type === "text"
                                        ? ""
                                        : "",
                                  },
                                  errorMsg: {
                                    ...errorMsg,
                                    [singleInput.name]: false,
                                  },
                                }
                          );
                        }
                      }}
                    >
                      {singleInput &&
                      singleInput.array &&
                      singleInput.array.length > 0 ? (
                        singleInput.filter ? (
                          singleInput.filter(
                            singleInput.array,
                            specificationArr
                          ) &&
                          singleInput.filter(
                            singleInput.array,
                            specificationArr
                          ).length > 0 ? (
                            singleInput
                              .filter(singleInput.array, specificationArr)
                              .map((option) => (
                                <MenuItem
                                  style={{ textTransform: "capitalize" }}
                                  key={option[singleInput.value]}
                                  value={option[singleInput.value]}
                                  id={
                                    option.id
                                      ? option.id
                                      : option[singleInput.value]
                                  }
                                >
                                  {singleInput.endAdornment
                                    ? `${option[singleInput.value]} ${
                                        singleInput.endAdornment
                                      }`
                                    : option[singleInput.value]}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={`No type is remaining.`}
                              value={`No type is remaining.`}
                              id={`No type is remaining.`}
                            >
                              {`No type is remaining.`}
                            </MenuItem>
                          )
                        ) : (
                          singleInput.array.map((option) => (
                            <MenuItem
                              style={{ textTransform: "capitalize" }}
                              key={option[singleInput.value]}
                              value={option[singleInput.value]}
                              id={
                                option.id
                                  ? option.id
                                  : option[singleInput.value]
                              }
                            >
                              {singleInput.endAdornment
                                ? `${option[singleInput.value]} ${
                                    singleInput.endAdornment
                                  }`
                                : option[singleInput.value]}
                            </MenuItem>
                          ))
                        )
                      ) : (
                        <MenuItem
                          disabled={true}
                          key={`No ${singleInput.name} added yet`}
                          value={`No ${singleInput.name} added yet`}
                          id={`No ${singleInput.name} added yet`}
                        >
                          {`No ${singleInput.name} added yet`}
                        </MenuItem>
                      )}
                    </CssTextField95>
                  ) : (
                    <CssTextField95
                      autoFocus={singleInput.autoFocus ? true : false}
                      error={errorMsg[singleInput.name] ? true : false}
                      autoComplete="off"
                      InputProps={{
                        endAdornment:
                          this.props.specifiationTypes &&
                          submittedSpecifies["Type"]
                            ? this.props.specifiationTypes.find(
                                (st) => st.text === submittedSpecifies["Type"]
                              )
                              ? this.props.specifiationTypes.find(
                                  (st) => st.text === submittedSpecifies["Type"]
                                ).unit
                              : ""
                            : "",
                      }}
                      onFocus={StopScroll}
                      style={{ width: "calc(25% - 54px)", margin: "10px" }}
                      id="outlined-basic"
                      label={
                        singleInput.name.charAt(0).toUpperCase() +
                        singleInput.name.slice(1)
                      }
                      type={
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? "text"
                          : singleInput.dataType
                      }
                      value={
                        submittedSpecifies[singleInput.name]
                          ? submittedSpecifies[singleInput.name]
                          : ""
                      }
                      name={singleInput.name}
                      onKeyDown={ this.props.specifiationTypes.find(
                        (st) => st.text === submittedSpecifies["Type"]
                      ) &&
                      this.props.specifiationTypes.find(
                        (st) => st.text === submittedSpecifies["Type"]
                      ).value_type === "text"?(evt) => {}:(evt) => {
                        if (singleInput.only_number === true) {
                          evt.key === "." && evt.preventDefault();
                        }
                        if (singleInput.dataType === "number") {
                          evt.key === "e" && evt.preventDefault();

                          evt.key === "E" && evt.preventDefault();
                          evt.key === "-" && evt.preventDefault();
                          evt.keyCode === 38 && evt.preventDefault();
                          evt.keyCode === 40 && evt.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        if (singleInput.only_number === true) {
                          e.target.value = e.target.value
                            .replace(/[^\w]|_/g, "")
                            .toLowerCase();
                        }
                      }}
                      onChange={(event) => {
                        console.log(
                          this.props.specifiationTypes,
                          submittedSpecifies["Type"],
                          "this.props.specifiationTypes&&submittedSpecifies"
                        );
                        this.setState({
                          submittedSpecifies: {
                            ...submittedSpecifies,
                            [singleInput.name]:
                              event.target.value && singleInput.upperCase
                                ? event.target.value.toUpperCase()
                                : event.target.value,
                          },
                          errorMsg: { ...errorMsg, [singleInput.name]: false },
                        });
                      }}
                      variant="outlined"
                      // className="textC"
                    />
                  )
                )}
                <div
                  className="justifySA d-flex"
                  style={{
                    margin: "10px",
                    width: "82px",
                    display: "flex",
                    color: "#100f0f",
                    textTransform: "capitalize",
                  }}
                >
                  <StyledButton
                    onClick={() => {
                      const newObj = submittedSpecifies;
                      const correct = [];
                      const er = { ...errorMsg };
                      specificationHeader.map((singleInput, i) => {
                        if (
                          singleInput.name === "Condition" &&
                          this.props.specifiationTypes.find(
                            (st) => st.text === submittedSpecifies["Type"]
                          ) &&
                          this.props.specifiationTypes.find(
                            (st) => st.text === submittedSpecifies["Type"]
                          ).value_type === "text"
                            ? true
                            : submittedSpecifies[singleInput.name]
                        ) {
                          correct.push(singleInput);
                          newObj[singleInput.api] =
                            submittedSpecifies[singleInput.name];
                          newObj[`${singleInput.api}Id`] =
                            submittedSpecifies[`${singleInput.name}Id`];
                          if (i === 1 && submittedSpecifies[singleInput.name]) {
                            newObj["Condition"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`ConditionId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                          }
                          if (i === 0) {
                            newObj["Type"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`TypeId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                            newObj["name"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`nameId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                          }
                        } else {
                          er[singleInput.name] = `Please add !`;
                        }
                      });
                      if (correct.length === 3) {
                        const arr = specificationArr;
                        console.log(submittedSpecifies, "submittedSpecifies");
                        arr.push({
                          ...newObj,
                          lid:
                            submittedSpecifies.lid ||
                            submittedSpecifies.lid === 0
                              ? submittedSpecifies.lid
                              : specificationArrCopy.length + 1,
                        });
                        console.log(arr, "arrarr");
                        this.setState({
                          specificationArr: arr,
                          specificationArrCopy: arr,
                          submittedSpecifies: {},
                          errorMsg: {},
                        });
                      } else {
                        this.setState({ errorMsg: er });
                      }
                    }}
                    background={
                      errorMsg["addSpecification"] === true ? red : ""
                    }
                    withBg={errorMsg["addSpecification"] === true}
                    name={<Add />}
                    toolTip="Add"
                    maxHeight="37.99px"
                    padding="0"
                    height="37.99px"
                    width="40px"
                    minWidth="0"
                  />{" "}
                  <StyledButton
                    name={<CrossIcon color={primary} strokeWidth="2" />}
                    toolTip="Clear"
                    maxHeight="37.99px"
                    onClick={() => {
                      this.setState({
                        requiredToggle: false,
                      })
                      if (
                        submittedSpecifies &&
                        (submittedSpecifies["Type"] ||
                          submittedSpecifies["Condition"] ||
                          submittedSpecifies["Value"])
                      ) {
                        this.setState({ submittedSpecifies: {}, errorMsg: {} });
                      } else {
                        this.setState({
                          error: "Nothing to Clear",
                          errorMsg: {},
                        });
                      }
                    }}
                    padding="0"
                    margin="0 0 0 2px"
                    height="37.99px"
                    width="40px"
                    minWidth="0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="update-button"
            className="d-flex alignC justifyFE width-100"
            style={{
              margin: "10px",
              // width: "calc(25% - 20px)",
            }}
          >
            <StyledButton
              name="clear"
              onClick={() => {
                // this.props.setPoItems(null, poItemsArrayCopy);
                this.props.setSoItems(null, soItemsArrayCopy);
                this.setState({
                  itemsId: "",
                  ItemType: "",
                  ItemCategoryId: "",
                  ItemCategory: "",
                  packagingArr: [],
                  BrandNameId: "",
                  BrandName: false,
                  selectedBrandMultipleName: false,
                  HSN_id: "",
                  HSN: "",
                  selectedSoid: false,
                  total_quantity: "",
                  Rate: "",
                  Buyer: "",
                  // TotalQ: "",
                  specificationArr: [],
                  specificationArrCopy: [],

                  // TotalRate: TotalRate - Rate,

                  // total_quantity: "",
                });
              }}
            />
            <StyledButton
              onClick={() => {
                if (BrandName !== false) {
                  if (ItemCategory || requiredToggle) {
                    if (ItemType || requiredToggle) {
                      if (packagingArr.length > 0 || requiredToggle) {
                        if (HSN || requiredToggle) {
                          if (total_quantity || requiredToggle) {
                            if (Rate || requiredToggle) {
                              if (specificationArr.length > 0 || requiredToggle) {
                                this.setState(
                                  {
                                    defaultStep: 0,
                                    currentStep: 0,
                                    errorMsg: {},
                                    submittedSpecifies: {},
                                  },
                                  () => {
                                    addItemsFunction();
                                  }
                                );
                              } else if (
                                submittedSpecifies &&
                                (submittedSpecifies["Type"] ||
                                  submittedSpecifies["Condition"] ||
                                  submittedSpecifies["Value"])
                              ) {
                                this.setState({
                                  errorMsg: {
                                    ...errorMsg,
                                    addSpecification: true,
                                  },
                                });
                              } else {
                                this.setState(
                                  {
                                    defaultStep: 0,
                                    currentStep: 0,
                                    errorMsg: {},
                                    submittedSpecifies: {},
                                  },
                                  () => {
                                    addItemsFunction();
                                  }
                                );
                              }
                            } else {
                              const errorMsg = this.state.errorMsg;
                              scrollDiv("rate");
                              errorMsg.Rate = "Please give an Contract rate!";

                              this.setState({ errorMsg });
                            }
                          } else {
                            const errorMsg = this.state.errorMsg;
                            scrollDiv("total-quantity");
                            errorMsg.total_quantity =
                              "Please give an total quantity!";

                            this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          scrollDiv("hsn");
                          errorMsg.HSN = "Please give an HSN!";

                          this.setState({ errorMsg });
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        scrollDiv("packaging");
                        errorMsg.packagingArr = "Please give packaging!";
                        this.setState({ errorMsg });
                      }
                    } else {
                      const errorMsg = this.state.errorMsg;
                      scrollDiv("brand");
                      errorMsg.ItemType = "Please select an ItemType!";
                      this.setState({ errorMsg });
                    }
                  } else {
                    const errorMsg = this.state.errorMsg;
                    scrollDiv("brand");
                    errorMsg.ItemCategory = "Please select an ItemCategory!";
                    this.setState({ errorMsg });
                  }
                } else {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.BrandName = "Please give an BrandName!";
                  scrollDiv("brand");
                  this.setState({ errorMsg });
                }
              }}
              name={`${editDialog !== false ? "Update" : "Add"} Item`}
              minWidth="30px"
              width="150px"
              margin="0 10px"
              withBg={true}
              background={errorMsg["updatedButton"] ? red : primary}
            />
          </div>
        </div>
      );
    };
    const editFunct = (singleDate) => {
      console.log("SINGLE DATE", singleDate, this.props);
        this.setState({
          TotalQ: TotalQ - singleDate["Total Item Quantity"],
        
          TotalRate: TotalRate - singleDate["Contract Rate"],
        });
      
      const newArr = [];
      soItemsArrayCopy.map((singleItem) => {
        if (singleItem.so_item_id === singleDate.so_item_id) {
        } else {
          newArr.push(singleItem);
        }
        return singleItem;
      });
      this.props.setSoItems(null, newArr);
      this.setState(
        {
          specificationArr: singleDate["specificationArr"]
            ? singleDate["specificationArr"].map((ss) => {
                const specId = Number(ss.nameId ?? ss.name);
                return ss["conditionId"]
                  ? {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    }
                  : {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      Condition: getSpecificationId(ss["condition"]),

                      conditionId: ss["condition"],
                      ConditionId: ss["condition"],
                      condition: getSpecificationId(ss["condition"]),
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    };
              })
            : [],
          specificationArrCopy: singleDate["specificationArr"]
            ? singleDate["specificationArr"].map((ss) => {
                const specId = Number(ss.nameId ?? ss.name);
                return ss["conditionId"]
                  ? {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    }
                  : {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      Condition: getSpecificationId(ss["condition"]),
                      conditionId: ss["condition"],
                      ConditionId: ss["condition"],
                      condition: getSpecificationId(ss["condition"]),
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    };
              })
            : [],
          selectedSoid: singleDate.so_item_id,
          itemsId: singleDate["Item Typeid"],
          ItemType: singleDate["Item Type"],
          ItemCategoryId: singleDate["Item Categoryid"],
          ItemCategory: singleDate["Item Category"],
          packagingArr: singleDate.packagingArr,
          BrandNameId: singleDate["Brandid"],
          BrandName: singleDate["Brand"],
          HSN_id: singleDate.hsn_code,
          HSN: singleDate["HSN Code"],
          total_quantity: singleDate["Total Item Quantity"],

          Rate: singleDate["Contract Rate"],
          BuyerMarking: singleDate["Buyer Marking"],
          bag_weight: singleDate["Bag Weight"],
          quantity_tolerance: singleDate["Quantity Tolerance"],

          selectedBrandMultipleName: Boolean(BrandList.find(br => br.id === singleDate["Brandid"] && Boolean(br.item_category) ))
          ? `${singleDate["Brand"]}-${singleDate["Item Category"]}-${singleDate["Item Type"]} %`
          :  `${singleDate["Brand"]}`,
        },
        () => {}
      );
    };
    return (
      <div style={onlyDialog !== true ? styles.mainDiv : styles.none}>
        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 2000}
            success={success}
            snackbarClose={() => {
              this.setState({
                error: "",
                success: "",
              });
            }}
          />
        ) : (
          ""
        )}
        {openDialog === true || editDialog !== false ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100vh - 62px)";
                target.style.minHeight = "calc(100vh - 62px)";
                target.style.height = "calc(100vh - 62px)";
                target.style.minWidth = "calc(100% - 62px)";
                target.style.maxWidth = "calc(100% - 62px)";
                target.style.margin = "32px";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.props.closeDialog();

              this.setState(
                {
                  openDialog: false,
                  editDialog: false,
                  added: false,
                },
                () => {
                  this.reset("no snac");
                }
              );
            }}
            TransitionComponent={Transition}
          >
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}
              onClick={() => {
                this.props.closeDialog();
                this.reset("no snac");
                this.setState({
                  openDialog: false,
                  editDialog: false,
                  added: false,
                });
              }}
            >
              <h3 style={styles.heading}>
                <DiscountOutlinedIcon
                  style={{ color: "white", margin: "0 5px 0 0" }}
                />
                {editDialog ? "Edit " : "Create "} Sales Order
              </h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton 
                  style={{ padding: "5px", borderRadius: "5px" }} 
                  onClick={() => 
                    this.setState({  requiredToggle: false, })
                  }
                >
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>
            <DialogContent
              className="dashedDividerBottom"
              style={{ height: "100%" }}
            >
              {loadingFun || loading === true ? (
                <Backdrop
                  style={{ zIndex: 1, color: "#fff" }}
                  open={loadingFun}
                >
                  <StyledCircularProgress color="inherit" />
                </Backdrop>
              ) : (
                ""
              )}
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  success={success}
                  autoHideDuration={error ? 5000 : 2000}
                  snackbarClose={() => {
                    console.log("sales oreer success ", success);
                    if (
                      error === "Nothing to reset !" ||
                      success === "Reset successfully !"
                    ) {
                      this.setState({
                        error: "",
                        success: "",
                        loading: false,
                      });
                    } else {
                      if (
                        success === "Sales Order Added!" ||
                        success === "Sales Order Edited!"
                      ) {
                        console.log("sales oreer ", success);
                        // this.reset("no snac");
                        // // this.handleClearFields();
                        // this.props.closeDialog();

                        // this.setState({
                        //   openDialog: false,
                        //   editDialog: false,
                        //   error: "",
                        //   success: "",
                        //   loading: false,
                        // });
                        this.props.closeDialog();

                        this.setState({
                          openDialog: false,
                          editDialog: false,

                          error: "",
                          success: "",
                          loading: false,
                          editSnack: false,
                        });
                      } else if (error) {
                        this.setState({
                          error: "",
                          success: "",
                        });
                      }
                    }
                  }}
                />
              ) : (
                ""
              )}
              {loading === true ? (
                <StyledCircularProgress color="inherit" />
              ) : previewPdf ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  {console.log(previewPdf.so_items, "ddddddddddddd")}
                  <SalesOrderPdf
                    preview={true}
                    specifiationTypes={this.props.specifiationTypes}
                    tableList={previewPdf.so_items}
                    // tableList={previewPdf.soItemsArrayTOPreview}
                    header={[
                      "S.No",
                      "Description",
                      "Quantity",
                      "Price",
                      "Value",
                      "Specifications",
                    ]}
                    {...this.state}
                    {...this.props}
                    deliveryTerm={soItemsArray.map((singleItem, i) => (
                      <div
                        className="d-flex alignC"
                        style={{
                          // justifyContent: "space-between",
                          fontSize: "0.6rem",
                          // color: "grey",
                          background: "white",
                          width: "100%",
                          margin: "5px 0",
                        }}
                      >
                      {console.log("Incoterm", Incoterm, Incoterm === 'FOB', portArry[0]?.nameCity.split("-")[0])}
                        {/* {Currency ? <span style={{ color: "black" }}>{`${Currency} `}</span> : ""}
                    <span style={{ color: "black" }}>{singleItem["Contract Rate"]}</span> */}
                        :{" "}
                        {`${Currency}  ${
                          singleItem["Contract Rate"]
                        } P${Unit} ${Incoterm} in
                    ${Incoterm === 'FOB' ? portArry[0]?.nameCity.split("-")[0] + ", INDIA" : DischargePort}`}
                        {this.state.itemVar[`${i + 1}`]
                          ? " " + this.state.itemVar[`${i + 1}`]
                          : ""}
                      </div>
                    ))}
                    ValueinWords={soItemsArray.map((singleItem, i) => (
                      <div
                        className="d-flex alignC"
                        style={{
                          // justifyContent: "space-between",
                          fontSize: "0.6rem",
                          // color: "grey",
                          background: "white",
                          width: "100%",
                          margin: "5px 0",
                        }}
                      >
                        : {console.log(CurrencyList, "aaaaaaaaaaaaaaa")}
                        {singlelistView &&
                        filterData(
                          CurrencyList,
                          "id",
                          singlelistView && singlelistView.currency,
                          "id",
                          "onlyOne"
                        )
                          ? filterData(
                              CurrencyList,
                              "id",
                              singlelistView && singlelistView.currency,
                              "id",
                              "onlyOne"
                            ).name +
                            " " +
                            Number(singleItem["Contract Rate"]) *
                              Number(singleItem["total_quantity"]) +
                            "/- (" +
                            filterData(
                              CurrencyList,
                              "id",
                              singlelistView.currency,
                              "id",
                              "onlyOne"
                            ).name +
                            " " +
                            converter.toWords(
                              Number(singleItem["Contract Rate"]) *
                                Number(singleItem["total_quantity"])
                            ) +
                            " only.)"
                          : " "}
                      </div>
                    ))}
                    singlelistView={previewPdf.sales_order ?? {}}
                    widthList={[
                      "20px",
                      "100px",
                      "50px",
                      "70px",
                      "70px",
                      "200px",
                    ]}
                  />
                </div>
              ) : added ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "#1a9520",
                    }}
                  />
                  <span
                    className="heading"
                    style={{ color: "#1a9520", margin: "10px 0 0 0" }}
                  >
                    {added}
                  </span>
                </div>
              ) : (
                <>
                  {addendum ? (
                    ""
                  ) : (
                    <>
                      <div className="d-flex justifyFE width-100">
                      {editDialog && editDialog.status !== 'DRAFT' 
                        ? null
                        : <div style={{ padding: '3px 10px' }}>
                            <FormControlLabel
                              control={
                                <Switch
                                  size={"small"}
                                  checked={requiredToggle}
                                  onChange={this.handleRequiredToggleChange}
                                  color="warning"
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label={'DRAFT'}
                              InputLabelProps={{ styles: { FontFamily: 'Poppins' } }}
                            />
                          </div>
                        }
                        <RadioButton
                          array={["SSIPL", "BUYER"]}
                          selected={selctedTYpeOfSO ? selctedTYpeOfSO : "SSIPL"}
                          onChange={(type) => {
                            this.setState({ selctedTYpeOfSO: type });
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div lassName="d-flex justifyFE width-100" id="uploadedFile">
                    {selctedTYpeOfSO === "SSIPL" ? (
                      ""
                    ) : (
                      <>
                        <UploadSoFile
                          edit={editDialog.uploadedFile}
                          uploadedFile={uploadedFile}
                          onChange={(data) => {
                            this.setState({
                              uploadedFile: data ? data : "",
                              errorMsg: { ...errorMsg, uploadedFile: false },
                            });
                          }}
                          error={
                            errorMsg["uploadedFile"]
                              ? errorMsg["uploadedFile"]
                              : false
                          }
                        />
                      </>
                    )}
                  </div>
                  {addendum === true ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          fontSize: "1rem",
                          color: "grey",
                          background: "white",
                          // margin: "20px 0",
                          minHeight: "71.91px",
                        }}
                      >
                        <CssTextField
                          required={true}
                          autoComplete="off"
                          value={
                            Unit ? Unit : "" //
                          }
                          error={errorMsg.Unit ? true : false}
                          helperText={errorMsg.Unit ? errorMsg.Unit : ""}
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Unit = false;
                            this.setState({
                              errorMsg,
                              Unit: e.target.value,
                            });
                          }}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          id="unit"
                          select
                          label="Unit"
                          variant="outlined"
                          InputLabelProps={{}}
                          SelectProps={{ MenuProps: MenuProps}}
                        >
                          {unit &&
                            unit.length > 0 &&
                            unit.map((option) => (
                              <MenuItem key={option.name} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </CssTextField>
                        <CssTextField
                          autoComplete="off"
                          required={!requiredToggle ? true : false}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          error={errorMsg.Incoterm ? true : false}
                          helperText={
                            errorMsg.Incoterm
                              ? "Please Select Incoterm!"
                              : false
                          }
                          id="Incoterm"
                          select
                          label="Incoterm"
                          variant="outlined"
                          name="Incoterm"
                          value={Incoterm ? Incoterm : ""}
                          SelectProps={{ MenuProps: MenuProps}}
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Incoterm = false;

                            this.fetchPolicy(
                              e.currentTarget.id,
                              ShippingTermsId
                            );
                            this.setState({
                              errorMsg,
                              Incoterm: e.target.value,
                              IncotermId: e.currentTarget.id,
                            });
                          }}
                        >
                          {masters["Incoterms"] &&
                            masters["Incoterms"].length > 0 &&
                            masters["Incoterms"].map((option) => (
                              <MenuItem
                                key={option.name.toString()}
                                value={option.name}
                                id={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </CssTextField>
                        <CssTextField
                          autoComplete="off"
                          required={!requiredToggle ? true : false}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          error={errorMsg.ShippingTerms ? true : false}
                          helperText={
                            errorMsg.ShippingTerms
                              ? "Please Select Shipping Terms!"
                              : false
                          }
                          id="ShippingTerms"
                          select
                          label="Shipping Term"
                          variant="outlined"
                          name="ShippingTerms"
                          value={ShippingTerms ? ShippingTerms : ""}
                          SelectProps={{ MenuProps: MenuProps}}
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.ShippingTerms = false;

                            this.fetchPolicy(IncotermId, e.currentTarget.id);
                            this.setState({
                              errorMsg,
                              ShippingTerms: e.target.value,
                              ShippingTermsId: e.currentTarget.id,
                            });
                          }}
                        >
                          {masters["ShippingTerms"] &&
                            masters["ShippingTerms"].length > 0 &&
                            masters["ShippingTerms"].map((option) => (
                              <MenuItem
                                key={option.value.toString()}
                                value={option.value}
                                id={option.id}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                        </CssTextField>
                        <MultipleSelectBar
                          id="portArry"
                          required={!requiredToggle ? true : false}
                          width={"calc(25% - 20px)"}
                          margin={"10px"}
                          value={portArry ? portArry : ""}
                          array={
                            portsList && portsList.length > 0 
                            ? Incoterm && Incoterm === 'FOB' ? portsList.filter(d => d.nameCity !=="Any Indian Port") : portsList 
                            : []
                          }
                          label="Load Port"
                          error={errorMsg.portArry ? true : false}
                          helperText={
                            errorMsg.portArry ? errorMsg.portArry : ""
                          }
                          selectedArr={(val) => {
                            console.log("val",val)
                            const errorMsg = this.state.errorMsg;
                            errorMsg.portArry = false;
                            let data = [];
                            if(Incoterm === 'FOB') {
                              val[val.length - 1] && data.push(val[val.length - 1]) 
                            } else  {
                             data = [...val]
                            }

                            this.setState({ portArry: data, errorMsg });
                          }}
                          title="nameCity"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          fontSize: "1rem",
                          color: "grey",
                          background: "white",
                          // margin: "20px 0",
                          minHeight: "71.91px",
                        }}
                      >
                        <CssTextField
                          autoComplete="off"
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          error={errorMsg.DischargePort ? true : false}
                          required={!requiredToggle ? true : false}
                          helperText={
                            errorMsg.DischargePort
                              ? "Please add Discharge Port!"
                              : false
                          }
                          InputProps={{}}
                          id="DischargePort"
                          label="Discharge Port"
                          variant="outlined"
                          name="DischargePort"
                          value={DischargePort ? DischargePort : ""}
                          onChange={this.handleChange}
                        ></CssTextField>
                        <CssTextField
                          required={true}
                          autoComplete="off"
                          value={
                            Currency ? Currency : "" //
                          }
                          error={errorMsg.Currency ? true : false}
                          helperText={
                            errorMsg.Currency ? errorMsg.Currency : ""
                          }
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Currency = false;

                            this.setState({
                              errorMsg,
                              CurrencyId: e.currentTarget.id,
                              Currency: e.target.value,
                            });
                          }}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          id="Currency"
                          select
                          label="Currency"
                          variant="outlined"
                          SelectProps={{ MenuProps: MenuProps}}
                        >
                          {masters["CurrencyList"] &&
                            masters["CurrencyList"].length > 0 &&
                            masters["CurrencyList"].map((option) => (
                              <MenuItem
                                key={option.name.toString()}
                                value={option.name}
                                id={option.id}
                              >
                                {option.symbol
                                  ? `${option.name}  (${option.symbol})`
                                  : option.name}
                              </MenuItem>
                            ))}
                        </CssTextField>
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}
                        >
                          <CssKeyboardDatePicker
                            label={"Shipment Date"}
                            variant="inline"
                            onOpen={() => {
                              this.setState({ calenderOpen: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpen: false });
                            }}
                            // 
                            // 
                            // minDate={"2022-01-22"}
                            error={false}
                            invalidLabel=""
                            onKeyDown={(evt) => {
                              evt.keyCode === 8 && evt.preventDefault();
                            }}
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpen ? <TopArrow /> : <BottomArrow />
                            }
                            autoOk
                            inputVariant="outlined"
                            style={
                              { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                            }
                            format="do MMM, yyyy"
                            //  style={
                            //   styles.withDivider50 //disabled={tp}
                            // }
                            id="shipmentDate"
                            value={shipmentDate ? shipmentDate : new Date()}
                            //views={['year', 'month']}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              this.setState({ shipmentDate: newValue });
                            }}
                          />
                        </MuiPickersUtilsProvider>

                        <CssTextField
                          required={!requiredToggle ? true : false}
                          type="text"
                          onFocus={StopScroll}
                          autoComplete="off"
                          //style={styles.withDivider50}
                          error={errorMsg.ShipmentBy ? true : false}
                          helperText={
                            errorMsg.ShipmentBy ? errorMsg.ShipmentBy : false
                          }
                          id="ShipmentBy"
                          label="Shipment By"
                          // onKeyDown={(evt) => {
                          //   // evt.key === "e" && evt.preventDefault();

                          //   // evt.key === "E" && evt.preventDefault();
                          //   evt.key === "-" && evt.preventDefault();
                          //   evt.keyCode === 38 && evt.preventDefault();
                          //   evt.keyCode === 40 && evt.preventDefault();
                          // }}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          variant="outlined"
                          name="ShipmentBy"
                          value={ShipmentBy}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 20);
                          }}
                          onChange={this.handleChange}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {!addendum ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          fontSize: "1rem",
                          color: "grey",
                          background: "white",
                          // margin: "20px 0",
                          minHeight: "71.91px",
                        }}
                      >
                        <>
                          <MuiPickersUtilsProvider
                            libInstance={moment()}
                            utils={DateFnsUtils}
                          >
                            <CssKeyboardDatePicker
                              label={"Contract Date"}
                              variant="inline"
                              onOpen={() => {
                                this.setState({ calenderOpen: true });
                              }}
                              onClose={() => {
                                this.setState({ calenderOpen: false });
                              }}
                              
                              
                              // minDate={"2022-01-22"}
                              error={false}
                              invalidLabel=""
                              onKeyDown={(evt) => {
                                evt.keyCode === 8 && evt.preventDefault();
                              }}
                              onKeyPress={(ev) => {
                                ev.preventDefault();
                              }}
                              allowKeyboardControl={false}
                              keyboardIcon={
                                calenderOpen ? <TopArrow /> : <BottomArrow />
                              }
                              autoOk
                              inputVariant="outlined"
                              style={
                                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                              }
                              format="do MMM, yyyy"
                              //  style={
                              //   styles.withDivider50 //disabled={tp}
                              // }
                              value={searchedData ? searchedData : new Date()}
                              //views={['year', 'month']}
                              InputAdornmentProps={{ position: "end" }}
                              onChange={(newValue) => {
                                this.setState({ searchedData: newValue });
                              }}
                            />
                          </MuiPickersUtilsProvider>

                          <CssTextField
                            required={!requiredToggle ? true : false}
                            autoComplete="off"
                            value={
                              selctedSOStatus ? selctedSOStatus : "" //
                            }
                            disabled={editDialog === false ? true : editDialog.status === 'DRAFT'
                            ? true
                            : false}
                            error={errorMsg.selctedSOStatus ? true : false}
                            helperText={
                              errorMsg.selctedSOStatus
                                ? errorMsg.selctedSOStatus
                                : ""
                            }
                            onChange={(e) => {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.selctedSOStatus = false;
                              this.setState({
                                errorMsg,
                                selctedSOStatus: e.target.value,
                              });
                            }}
                            style={
                              { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                            }
                            id="status"
                            select
                            label="Status"
                            variant="outlined"
                            InputLabelProps={{}}
                            SelectProps={{ MenuProps: MenuProps}}
                          >
                            {status &&
                              status.length > 0 &&
                              status.map((option) => (
                                <MenuItem
                                  key={option.name.toString()}
                                  value={option.name}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                          </CssTextField>
                          <CssTextField
                            required={true}
                            type="text"
                            onFocus={StopScroll}
                            autoComplete="off"
                            //style={styles.withDivider50}
                            error={errorMsg.S0Number ? true : false}
                            helperText={
                              errorMsg.S0Number ? errorMsg.S0Number : false
                            }
                            id="S0Number"
                            label="SO NO."
                            style={
                              { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                            }
                            // InputProps={{
                            //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                            // }}
                            variant="outlined"
                            name="S0Number"
                            value={S0Number}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 20);
                            }}
                            onChange={this.handleChange}
                          />
                        </>
                        <CssTextField
                          required={true}
                          autoComplete="off"
                          value={
                            Unit ? Unit : "" //
                          }
                          error={errorMsg.Unit ? true : false}
                          helperText={errorMsg.Unit ? errorMsg.Unit : ""}
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Unit = false;
                            this.setState({
                              errorMsg,
                              Unit: e.target.value,
                            });
                          }}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          id="unit"
                          select
                          label="Unit"
                          variant="outlined"
                          InputLabelProps={{}}
                          SelectProps={{ MenuProps: MenuProps}}
                        >
                          {unit &&
                            unit.length > 0 &&
                            unit.map((option) => (
                              <MenuItem key={option.name} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </CssTextField>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          fontSize: "1rem",
                          color: "grey",
                          background: "white",
                          // margin: "20px 0",
                          minHeight: "71.91px",
                        }}
                      >
                        <CssTextField
                          autoComplete="off"
                          required={!requiredToggle ? true : false}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          error={errorMsg.Incoterm ? true : false}
                          helperText={
                            errorMsg.Incoterm
                              ? "Please Select Incoterm!"
                              : false
                          }
                          id="Incoterm"
                          select
                          label="Incoterm"
                          variant="outlined"
                          name="Incoterm"
                          value={Incoterm ? Incoterm : ""}
                          SelectProps={{ MenuProps: MenuProps}}
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Incoterm = false;

                            this.fetchPolicy(
                              e.currentTarget.id,
                              ShippingTermsId
                            );
                            this.setState({
                              errorMsg,
                              Incoterm: e.target.value,
                              IncotermId: e.currentTarget.id,
                            });
                          }}
                        >
                          {masters["Incoterms"] &&
                            masters["Incoterms"].length > 0 &&
                            masters["Incoterms"].map((option) => (
                              <MenuItem
                                key={option.name.toString()}
                                value={option.name}
                                id={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </CssTextField>
                        <CssTextField
                          autoComplete="off"
                          required={!requiredToggle ? true : false}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          error={errorMsg.ShippingTerms ? true : false}
                          helperText={
                            errorMsg.ShippingTerms
                              ? "Please Select Shipping Terms!"
                              : false
                          }
                          id="ShippingTerms"
                          select
                          label="Shipping Term"
                          variant="outlined"
                          name="ShippingTerms"
                          value={ShippingTerms ? ShippingTerms : ""}
                          SelectProps={{ MenuProps: MenuProps}}
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.ShippingTerms = false;

                            this.fetchPolicy(IncotermId, e.currentTarget.id);
                            this.setState({
                              errorMsg,
                              ShippingTerms: e.target.value,
                              ShippingTermsId: e.currentTarget.id,
                            });
                          }}
                        >
                          {masters["ShippingTerms"] &&
                            masters["ShippingTerms"].length > 0 &&
                            masters["ShippingTerms"].map((option) => (
                              <MenuItem
                                key={option.value.toString()}
                                value={option.value}
                                id={option.id}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                        </CssTextField>

                        <CssTextField
                          autoComplete="off"
                          required={true}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          error={errorMsg.Buyer ? true : false}
                          helperText={
                            errorMsg.Buyer ? "Please Select Buyer!" : false
                          }
                          id="Buyer"
                          select
                          label="Buyer"
                          variant="outlined"
                          name="Buyer"
                          value={Buyer ? Buyer : ""}
                          SelectProps={{ MenuProps: MenuProps}}
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Buyer = false;

                            this.setState({
                              errorMsg,
                              Buyer: e.target.value,
                              BuyerId: e.currentTarget.id,
                            });
                          }}
                        >
                          {ClientList &&
                            ClientList.length > 0 &&
                            ClientList.map((option) => (
                              <MenuItem
                                key={option.client_name.toString()}
                                value={option.client_name}
                                id={option.id}
                              >
                                {option.client_name}
                              </MenuItem>
                            ))}
                        </CssTextField>
                        <CssTextField
                          required={false}
                          type="text"
                          onFocus={StopScroll}
                          autoComplete="off"
                          //style={styles.withDivider50}
                          error={errorMsg.TotalContainer ? true : false}
                          helperText={
                            errorMsg.TotalContainer
                              ? errorMsg.TotalContainer
                              : false
                          }
                          id="TotalContainer"
                          label="Total Container Count"
                          // onKeyDown={(evt) => {
                          //   evt.key === "e" && evt.preventDefault();

                          //   evt.key === "E" && evt.preventDefault();
                          //   evt.key === "-" && evt.preventDefault();
                          //   evt.keyCode === 38 && evt.preventDefault();
                          //   evt.keyCode === 40 && evt.preventDefault();
                          // }}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          // InputProps={{
                          //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                          // }}
                          variant="outlined"
                          name="TotalContainer"
                          value={TotalContainer}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 20);
                          }}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          fontSize: "1rem",
                          color: "grey",
                          background: "white",
                          // margin: "20px 0",
                          minHeight: "71.91px",
                        }}
                      >
                        <MultipleSelectBar
                          id="portArry"
                          required={!requiredToggle ? true : false}
                          width={"calc(25% - 20px)"}
                          margin={"10px"}
                          value={portArry ? portArry : ""}
                          array={
                            portsList && portsList.length > 0 
                            ? Incoterm && Incoterm === 'FOB' ? portsList.filter(d => d.nameCity !=="Any Indian Port") : portsList  
                            : []
                          }
                          label="Load Port"
                          error={errorMsg.portArry ? true : false}
                          helperText={
                            errorMsg.portArry ? errorMsg.portArry : ""
                          }
                          selectedArr={(val) => {
                            console.log("val",val, val[val.length - 1] )
                            const errorMsg = this.state.errorMsg;
                            errorMsg.portArry = false;
                            let data = [];
                            if(Incoterm === 'FOB') {
                              val[val.length - 1] && data.push(val[val.length - 1]) 
                            } else  {
                             data = [...val]
                            }

                            this.setState({ portArry: data, errorMsg });
                          }}
                          title="nameCity"
                        />
                        <CssTextField
                          autoComplete="off"
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          error={errorMsg.DischargePort ? true : false}
                          required={!requiredToggle ? true : false}
                          helperText={
                            errorMsg.DischargePort
                              ? "Please add Discharge Port!"
                              : false
                          }
                          InputProps={{}}
                          id="DischargePort"
                          label="Discharge Port"
                          variant="outlined"
                          name="DischargePort"
                          value={DischargePort ? DischargePort : ""}
                          onChange={this.handleChange}
                        ></CssTextField>
                        <CssTextField
                          required={true}
                          autoComplete="off"
                          value={
                            Currency ? Currency : "" //
                          }
                          error={errorMsg.Currency ? true : false}
                          helperText={
                            errorMsg.Currency ? errorMsg.Currency : ""
                          }
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Currency = false;

                            this.setState({
                              errorMsg,
                              CurrencyId: e.currentTarget.id,
                              Currency: e.target.value,
                            });
                          }}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          id="Currency"
                          select
                          label="Currency"
                          variant="outlined"
                          SelectProps={{ MenuProps: MenuProps}}
                        >
                          {masters["CurrencyList"] &&
                            masters["CurrencyList"].length > 0 &&
                            masters["CurrencyList"].map((option) => (
                              <MenuItem
                                key={option.name.toString()}
                                value={option.name}
                                id={option.id}
                              >
                                {option.symbol
                                  ? `${option.name}  (${option.symbol})`
                                  : option.name}
                              </MenuItem>
                            ))}
                        </CssTextField>

                        <CssTextField
                          id="Consignee"
                          required={!requiredToggle ? true : false}
                          autoComplete="off"
                          value={
                            Consignee ? Consignee : "" //
                          }
                          error={errorMsg.Consignee ? true : false}
                          helperText={
                            errorMsg.Consignee
                              ? "Please add Consignee Name"
                              : false
                          }
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Consignee = false;
                            this.setState({
                              errorMsg,
                              Consignee: e.target.value,
                            });
                          }}
                          style={
                            {
                              width: "calc(25% - 20px)",
                              margin: "10px",
                            } //disabled={tp}
                          }
                          //id="outlined-select-currency-native"
                          label="Consignee Name"
                          variant="outlined"
                          InputLabelProps={{}}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          fontSize: "1rem",
                          color: "grey",
                          background: "white",
                          // margin: "20px 0",
                          minHeight: "71.91px",
                        }}
                      >
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}
                        >
                          <CssKeyboardDatePicker
                            label={"Shipment Date"}
                            variant="inline"
                            onOpen={() => {
                              this.setState({ calenderOpen: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpen: false });
                            }}
                            // 
                            // 
                            // minDate={"2022-01-22"}
                            error={false}
                            invalidLabel=""
                            onKeyDown={(evt) => {
                              evt.keyCode === 8 && evt.preventDefault();
                            }}
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpen ? <TopArrow /> : <BottomArrow />
                            }
                            autoOk
                            inputVariant="outlined"
                            style={
                              { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                            }
                            format="do MMM, yyyy"
                            //  style={
                            //   styles.withDivider50 //disabled={tp}
                            // }
                            id="shipmentDate"
                            value={shipmentDate ? shipmentDate : new Date()}
                            //views={['year', 'month']}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              this.setState({ shipmentDate: newValue });
                            }}
                          />
                        </MuiPickersUtilsProvider>

                        <CssTextField
                          required={!requiredToggle ? true : false}
                          type="text"
                          onFocus={StopScroll}
                          autoComplete="off"
                          //style={styles.withDivider50}
                          error={errorMsg.ShipmentBy ? true : false}
                          helperText={
                            errorMsg.ShipmentBy ? errorMsg.ShipmentBy : false
                          }
                          id="ShipmentBy"
                          label="Shipment By"
                          // onKeyDown={(evt) => {
                          //   evt.key === "e" && evt.preventDefault();

                          //   evt.key === "E" && evt.preventDefault();
                          //   evt.key === "-" && evt.preventDefault();
                          //   evt.keyCode === 38 && evt.preventDefault();
                          //   evt.keyCode === 40 && evt.preventDefault();
                          // }}
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          variant="outlined"
                          name="ShipmentBy"
                          value={ShipmentBy}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 20);
                          }}
                          onChange={this.handleChange}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* <div style={{border:"1px solid rgb(128 128 128 / 64%)"}}> */}
                  <div
                    style={{
                      fontSize: "1rem",
                      margin: "20px 0",
                      fontWeight: "500",
                      //background: "#03a9f429",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    Add Multiple SO Items
                  </div>
                  <div
                    style={{ border: "1px solid rgba(128, 128, 128, 0.64)" }}
                    className="borderRadius7"
                    id="item"
                  >
                    {SOITemTable(
                      "edit",
                      soItemsArray,
                      null,
                      true,
                      null,
                      null,
                      null,
                      null,
                      editFunct,
                      Unit
                    )}
                    {editDialog !== false ? "" : " "}

                    {/* {editDialog === false ||
                    (BrandName !== false && editDialog !== false) ? ( */}
                      <>
                        <div style={{ margin: "20px", padding: "10px" }}>
                          {this.state.defaultStep === 0 ? (
                            <div>
                              <div
                                style={
                                  {
                                    display: "flex",
                                    justifyContent: "space-between",

                                    fontSize: "1rem",
                                    color: "grey",
                                    background: "white",
                                    // margin: "20px 0",
                                    width: "100%",
                                    minHeight: "71.91px",
                                  } //height: "100%",
                                }
                              >
                                <CssTextField
                                  required={true}
                                  autoComplete="off"
                                  value={
                                    selectedBrandMultipleName
                                      ? selectedBrandMultipleName
                                      : ""
                                  }
                                  error={errorMsg.BrandName ? true : false}
                                  helperText={
                                    errorMsg.BrandName ? errorMsg.BrandName : ""
                                  }
                                  onChange={(e) => {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.BrandName = false;
                                    errorMsg.ItemCategory = false;
                                    errorMsg.ItemType = false;
                                    const brandSelected =
                                      BrandList &&
                                      BrandList.length > 0 &&
                                      BrandList.filter(
                                        (option) =>
                                          option.id.toString() ===
                                          e.currentTarget.id.toString()
                                      );

                                    const iCName =
                                      ItemCateoryList &&
                                      ItemCateoryList.length > 0 &&
                                      ItemCateoryList.filter(
                                        (sIc) =>
                                          sIc.id ===
                                          (brandSelected &&
                                            brandSelected[0].item_category &&
                                            brandSelected[0].item_category)
                                      ) &&
                                      ItemCateoryList &&
                                      ItemCateoryList.length > 0 &&
                                      ItemCateoryList.filter(
                                        (sIc) =>
                                          sIc.id ===
                                          (brandSelected &&
                                            brandSelected[0].item_category &&
                                            brandSelected[0].item_category)
                                      )[0] &&
                                      ItemCateoryList &&
                                      ItemCateoryList.length > 0 &&
                                      ItemCateoryList.filter(
                                        (sIc) =>
                                          sIc.id ===
                                          (brandSelected &&
                                            brandSelected[0].item_category &&
                                            brandSelected[0].item_category)
                                      )[0].name;

                                    const iTname =
                                      ItemList &&
                                      ItemList.length > 0 &&
                                      ItemList.filter(
                                        (sIc) =>
                                          sIc.id ===
                                          (brandSelected &&
                                            brandSelected[0].item_type &&
                                            brandSelected[0].item_type)
                                      ) &&
                                      ItemList.filter(
                                        (sIc) =>
                                          sIc.id ===
                                          (brandSelected &&
                                            brandSelected[0].item_type &&
                                            brandSelected[0].item_type)
                                      )[0] &&
                                      ItemList.filter(
                                        (sIc) =>
                                          sIc.id ===
                                          (brandSelected &&
                                            brandSelected[0].item_type &&
                                            brandSelected[0].item_type)
                                      )[0].broken_percentage;

                                    this.setState({
                                      errorMsg,
                                      BrandNameId: e.currentTarget.id,
                                      BrandName:
                                        brandSelected &&
                                        brandSelected[0].brand_name,
                                      // ItemCategoryId:
                                      //   brandSelected &&
                                      //   brandSelected[0].item_category &&
                                      //   brandSelected[0].item_category,
                                      // ItemCategory: iCName,
                                      // itemsId:
                                      //   brandSelected &&
                                      //   brandSelected[0].item_type &&
                                      //   brandSelected[0].item_type,
                                      // ItemType: iTname,
                                      selectedBrandMultipleName: e.target.value,
                                    });
                                  }}
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  id="outlined-select-currency-native"
                                  select
                                  label="Brand"
                                  variant="outlined"
                                  InputLabelProps={{}}
                                  SelectProps={{ MenuProps: MenuProps}}
                                >
                                  {BrandList &&
                                    BrandList.length > 0 &&
                                    BrandList.sort((a, b) =>
                                      (Boolean(a.item_category) || Boolean(a.item_type)) - 
                                      (Boolean(b.item_category) || Boolean(b.item_type))
                                    ).map((option) => (
                                      <MenuItem
                                        key={option.brand_name}
                                        value={option.item_category && option.item_type
                                        ?  `${option.brand_name}-${
                                          filterData(
                                            ItemCateoryList,
                                            "id",
                                            option.item_category,
                                            "onlyName"
                                          )
                                            ? filterData(
                                                ItemCateoryList,
                                                "id",
                                                option.item_category,
                                                "onlyName"
                                              ).name
                                            : ""
                                        }-${
                                          filterData(
                                            ItemList,
                                            "id",
                                            option.item_type,
                                            "onlyName"
                                          )
                                            ? filterData(
                                                ItemList,
                                                "id",
                                                option.item_type,
                                                "onlyName"
                                              ).broken_percentage
                                            : ""
                                        } %`
                                        : `${option.brand_name}`}
                                        id={option.id}
                                      >
                                        { option.item_category && option.item_type
                                         ? `${option.brand_name}-${
                                          filterData(
                                            ItemCateoryList,
                                            "id",
                                            option.item_category,
                                            "onlyName"
                                          )
                                            ? filterData(
                                                ItemCateoryList,
                                                "id",
                                                option.item_category,
                                                "onlyName"
                                              ).name
                                            : ""
                                        }-${
                                          filterData(
                                            ItemList,
                                            "id",
                                            option.item_type,
                                            "onlyName"
                                          )
                                            ? filterData(
                                                ItemList,
                                                "id",
                                                option.item_type,
                                                "onlyName"
                                              ).broken_percentage
                                            : ""
                                        } %`
                                        : `${option.brand_name}`}
                                      </MenuItem>
                                    ))}
                                </CssTextField>
                                <CssTextField
                                  required={!requiredToggle ? true : false}
                                  autoComplete="off"
                                  value={
                                    ItemCategory ? ItemCategory : "" //
                                  }
                                  error={errorMsg.ItemCategory ? true : false}
                                  helperText={
                                    errorMsg.ItemCategory
                                      ? errorMsg.ItemCategory
                                      : ""
                                  }
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  select
                                  onChange={(e) => {
                                      const errorMsg = this.state.errorMsg;
                                      errorMsg.ItemCategory = false;
                                      this.setState({
                                        errorMsg,
                                        ItemCategoryId: ItemCateoryList.find(
                                          (d) => d.name === e.target.value
                                        ).id,
                                        ItemCategory: e.target.value,
                                      });
                                    }}
                                  id="outlined-select-currency-native"
                                  label="Item Category"
                                  variant="outlined"
                                  // disabled={true}
                                  InputLabelProps={{}}
                                  SelectProps={{ MenuProps: MenuProps}}
                                >
                                {ItemCateoryList &&
                                      ItemCateoryList.length > 0 &&
                                      ItemCateoryList.map((option) => (
                                        <MenuItem
                                          key={option.name}
                                          value={option.name}
                                          id={option.id}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                      </CssTextField>
                                <CssTextField
                                  required={!requiredToggle ? true : false}
                                  autoComplete="off"
                                  value={
                                    ItemType ? ItemType : "" //
                                  }
                                  error={errorMsg.ItemType ? true : false}
                                  helperText={
                                    errorMsg.ItemType ? errorMsg.ItemType : ""
                                  }
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  select
                                  onChange={(e) => {
                                      const errorMsg = this.state.errorMsg;
                                      errorMsg.ItemType = false;
                                      console.log(e);
                                      this.setState({
                                        errorMsg,
                                        itemsId: ItemList.find(
                                          (d) =>
                                            d.broken_percentage ===
                                            e.target.value
                                        ).id,
                                        ItemType: e.target.value,
                                      });
                                    }}
                                  id="outlined-select-currency-native"
                                  label="Item Type"
                                  variant="outlined"
                                  // disabled={true}
                                  InputLabelProps={{}}
                                  SelectProps={{ MenuProps: MenuProps}}
                                >
                                {ItemList &&
                                      ItemList.length > 0 &&
                                      ItemList.map((option) => (
                                        <MenuItem
                                          key={option.broken_percentage}
                                          value={option.broken_percentage}
                                          id={option.id}
                                        >
                                          {`${option.broken_percentage} %`}
                                        </MenuItem>
                                      ))}
                                </CssTextField>
                                <MultipleSelectBar
                                  required={!requiredToggle ? true : false}
                                  requiredToggle={!requiredToggle ? true : false}
                                  width={"calc(25% - 20px)"}
                                  margin={"10px"}
                                  value={packagingArr}
                                  array={
                                    PackagingList && PackagingList.length > 0
                                      ? PackagingList.map((sP) => {
                                          sP.sizeUnit = `${sP.size}${sP.unit} - ${sP.bag_type}`;
                                          return sP;
                                        })
                                      : []
                                  }
                                  error={
                                    errorMsg.packagingArr
                                      ? errorMsg.packagingArr
                                      : false
                                  }
                                  helperText={
                                    errorMsg.packagingArr
                                      ? errorMsg.packagingArr
                                      : ""
                                  }
                                  title="sizeUnit"
                                  selectedArr={(val) => {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.packagingArr = false;

                                    this.setState({
                                      errorMsg,
                                      packagingArr: val,
                                    });
                                  }}
                                />
                              </div>
                              <div
                                style={
                                  {
                                    display: "flex",
                                    // justifyContent: 'space-between',

                                    fontSize: "1rem",
                                    color: "grey",
                                    background: "white",
                                    // margin: "20px 0",
                                    width: "100%",
                                    minHeight: "71.91px",
                                  } //height: "100%",
                                }
                              >
                                <CssTextField
                                  required={!requiredToggle ? true : false}
                                  autoComplete="off"
                                  value={
                                    HSN ? HSN : "" //
                                  }
                                  error={errorMsg.HSN ? true : false}
                                  helperText={errorMsg.HSN ? errorMsg.HSN : ""}
                                  onChange={(e) => {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.HSN = false;
                                    this.setState({
                                      errorMsg,
                                      HSN: e.target.value,
                                      HSN_id: e.currentTarget.id,
                                    });
                                  }}
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  id="outlined-select-currency-native"
                                  select
                                  label="HSN Code"
                                  variant="outlined"
                                  InputLabelProps={{}}
                                  SelectProps={{ MenuProps: MenuProps}}
                                >
                                  {HSNlist &&
                                    HSNlist.length > 0 &&
                                    HSNlist.map((option) => (
                                      <MenuItem
                                        key={option.hsn_code}
                                        value={option.hsn_code}
                                        id={option.id}
                                      >
                                        {option.hsn_code}
                                      </MenuItem>
                                    ))}
                                </CssTextField>
                                <CssTextField
                                  required={!requiredToggle ? true : false}
                                  type="number"
                                  onFocus={StopScroll}
                                  autoComplete="off"
                                  //style={styles.withDivider50}
                                  error={errorMsg.total_quantity ? true : false}
                                  helperText={
                                    errorMsg.total_quantity
                                      ? errorMsg.total_quantity
                                      : false
                                  }
                                  id="outlined-basic"
                                  label="Total Item Quantity"
                                  onKeyDown={(evt) => {
                                    evt.key === "e" && evt.preventDefault();

                                    evt.key === "E" && evt.preventDefault();
                                    evt.key === "-" && evt.preventDefault();
                                    evt.keyCode === 38 && evt.preventDefault();
                                    evt.keyCode === 40 && evt.preventDefault();
                                  }}
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  InputProps={{
                                    endAdornment: Unit,
                                  }}
                                  // InputProps={{
                                  //   endAdornment: <InputAdornment position="start">{Unit}</InputAdornment>,
                                  // }}
                                  variant="outlined"
                                  name="total_quantity"
                                  value={total_quantity}
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .toString()
                                      .slice(0, 20);
                                  }}
                                  onChange={this.handleChange}
                                />
                                <CssTextField
                                  required={!requiredToggle ? true : false}
                                  type="number"
                                  onFocus={StopScroll}
                                  autoComplete="off"
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  onKeyDown={(evt) => {
                                    // if (singleInput.only_number === true) {
                                    //   evt.key === "." && evt.preventDefault();
                                    // }
                                    evt.key === "e" && evt.preventDefault();

                                    evt.key === "E" && evt.preventDefault();
                                    evt.key === "-" && evt.preventDefault();
                                    evt.keyCode === 38 && evt.preventDefault();
                                    evt.keyCode === 40 && evt.preventDefault();
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        /{Unit}
                                      </InputAdornment>
                                    ),
                                  }}
                                  // InputProps={{
                                  //   startAdornment: <InputAdornment position="start">{Unit}</InputAdornment>,
                                  // }}
                                  error={errorMsg.Rate ? true : false}
                                  helperText={
                                    errorMsg.Rate ? "Please Add Rate!" : false
                                  }
                                  id="outlined-basic"
                                  label="Contract Rate"
                                  variant="outlined"
                                  name="Rate"
                                  value={Rate}
                                  onChange={this.handleChange}
                                />
                                
                                <CssTextField
                                  required={!requiredToggle ? true : false}
                                  autoComplete="off"
                                  value={
                                    BuyerMarking ? BuyerMarking : "" //
                                  }
                                  error={errorMsg.BuyerMarking ? true : false}
                                  helperText={
                                    errorMsg.BuyerMarking
                                      ? "Please add Buyer Marking"
                                      : false
                                  }
                                  onChange={(e) => {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.BuyerMarking = false;
                                    this.setState({
                                      errorMsg,
                                      BuyerMarking: e.target.value,
                                    });
                                  }}
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  id="outlined-select-currency-native"
                                  label="Buyer’s marking"
                                  variant="outlined"
                                  InputLabelProps={{}}
                                />
                              </div>
                              <div
                                style={
                                  {
                                    display: "flex",
                                    // justifyContent: 'space-between',

                                    fontSize: "1rem",
                                    color: "grey",
                                    background: "white",
                                    // margin: "20px 0",
                                    width: "100%",
                                    minHeight: "71.91px",
                                  } //height: "100%",
                                }
                              >
                                 <CssTextField
                                  required={false}
                                  autoComplete="off"
                                  value={
                                    quantity_tolerance ? quantity_tolerance : "" //
                                  }
                                  error={errorMsg.quantity_tolerance ? true : false}
                                  helperText={
                                    errorMsg.quantity_tolerance
                                      ? "Please add Bag Weight "
                                      : false
                                  }
                                  onChange={(e) => {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.quantity_tolerance = false;
                                    this.setState({
                                      errorMsg,
                                      quantity_tolerance: e.target.value,
                                    });
                                  }}
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  id="outlined-select-currency-native"
                                  label="Quantity Tolerance"
                                  variant="outlined"
                                  InputLabelProps={{}}
                                />
                                <CssTextField
                                  required={false}
                                  autoComplete="off"
                                  value={
                                    bag_weight ? bag_weight : "" //
                                  }
                                  error={errorMsg.bag_weight ? true : false}
                                  helperText={
                                    errorMsg.bag_weight
                                      ? "Please add Bag Weight "
                                      : false
                                  }
                                  onChange={(e) => {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.bag_weight = false;
                                    this.setState({
                                      errorMsg,
                                      bag_weight: e.target.value,
                                    });
                                  }}
                                  style={
                                    {
                                      width: "calc(25% - 20px)",
                                      margin: "10px",
                                    } //disabled={tp}
                                  }
                                  id="outlined-select-currency-native"
                                  label="Bag Weight"
                                  variant="outlined"
                                  InputLabelProps={{}}
                                />
                              </div>
                              <div
                                style={
                                  {
                                    display: "flex",
                                    // justifyContent: 'space-between',

                                    fontSize: "1rem",
                                    color: "grey",
                                    background: "white",
                                    // margin: "20px 0",
                                    width: "100%",
                                    minHeight: "71.91px",
                                  } //height: "100%",
                                }
                              >
                                {this.state.defaultStep === 1 ? (
                                  ""
                                ) : (
                                  <div
                                    style={{
                                      width: "calc(100% - 20px)",
                                      margin: "10px",
                                    }}
                                    className="d-flex justifyFE"
                                    id="update-button"
                                  >
                                    <StyledButton
                                      name="Next"
                                      background={
                                        errorMsg["updatedButton"]
                                          ? red
                                          : primary
                                      }
                                      onClick={() => {
                                        if (BrandName !== false) {
                                          if (ItemCategory || requiredToggle) {
                                            if (ItemType || requiredToggle) {
                                              if (packagingArr.length > 0 || requiredToggle) {
                                                if (HSN || requiredToggle) {
                                                  if (total_quantity || requiredToggle) {
                                                    if (Rate || requiredToggle) {
                                                      if (BuyerMarking || requiredToggle) {
                                                        // if(Currency){
                                                        this.setState({
                                                          defaultStep: 1,
                                                          currentStep: 1,
                                                          errorMsg: {},
                                                        });
                                                        // } else {
                                                        //   const errorMsg = this.state.errorMsg;
                                                        //   errorMsg.Currency = "Please give Currency!";

                                                        //   this.setState({ errorMsg });
                                                        // }
                                                      } else {
                                                        const errorMsg =
                                                          this.state.errorMsg;
                                                        errorMsg.BuyerMarking =
                                                          "Please give Contract Buyer Marking!";

                                                        this.setState({
                                                          errorMsg,
                                                        });
                                                      }
                                                    } else {
                                                      const errorMsg =
                                                        this.state.errorMsg;
                                                      errorMsg.Rate =
                                                        "Please give Contract rate!";

                                                      this.setState({
                                                        errorMsg,
                                                      });
                                                    }
                                                  } else {
                                                    const errorMsg =
                                                      this.state.errorMsg;
                                                    errorMsg.total_quantity =
                                                      "Please give total quantity!";

                                                    this.setState({
                                                      errorMsg,
                                                    });
                                                  }
                                                } else {
                                                  const errorMsg =
                                                    this.state.errorMsg;
                                                  errorMsg.HSN =
                                                    "Please give HSN!";

                                                  this.setState({
                                                    errorMsg,
                                                  });
                                                }
                                              } else {
                                                const errorMsg =
                                                  this.state.errorMsg;
                                                errorMsg.packagingArr =
                                                  "Please give packaging!";
                                                this.setState({
                                                  errorMsg,
                                                });
                                              }
                                            } else {
                                              const errorMsg =
                                                this.state.errorMsg;
                                              errorMsg.ItemType =
                                                "Please select ItemType!";
                                              this.setState({
                                                errorMsg,
                                              });
                                            }
                                          } else {
                                            const errorMsg =
                                              this.state.errorMsg;
                                            errorMsg.ItemCategory =
                                              "Please select ItemCategory!";
                                            this.setState({ errorMsg });
                                          }
                                        } else {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.BrandName =
                                            "Please give BrandName!";
                                          this.setState({ errorMsg });
                                        }
                                      }}
                                      margin="10px 0"
                                      withBg={true}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            SpecificationTable()
                          )}
                          <Stepper activeStep={this.state.defaultStep}>
                            <Step
                              onClick={() => {
                                if (this.state.defaultStep === 1) {
                                  this.setState({ defaultStep: 0 });
                                }
                              }}
                              style={{
                                cursor: this.state.defaultStep ? "pointer" : "",
                              }}
                            >
                              <StepLabel
                                style={{
                                  marginLeft: "200px",
                                }}
                              >
                                Add Item Details
                              </StepLabel>
                            </Step>
                            <Step>
                              <StepLabel
                                style={{
                                  marginRight: "200px",
                                }}
                              >
                                Add Multiple Specifications
                              </StepLabel>
                            </Step>
                          </Stepper>
                        </div>
                      </>
                    {/* ) : (
                      ""
                    )} */}
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        border: "1px solid #80808042",
                        padding: "10px",
                        //width: "80%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        // alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          color: "black",
                          width: "120px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Delivery Terms :{" "}
                      </div>
                      <div style={{ width: "calc(100% - 130px)" }}>
                        {soItemsArray.map((singleItem, i) => (
                          <div
                            className="d-flex alignC"
                            style={{
                              // justifyContent: "space-between",
                              fontSize: "1rem",
                              color: "grey",
                              background: "white",
                              width: "100%",
                              margin: "10px 0",
                            }}
                          >
                            {/* {Currency ? <span style={{ color: "black" }}>{`${Currency} `}</span> : ""}
                            <span style={{ color: "black" }}>{singleItem["Contract Rate"]}</span> */}
                            {`${Currency}  ${
                              singleItem["Contract Rate"]
                            } P${Unit} ${Incoterm} in
                            ${Incoterm === 'FOB' ? portArry[0]?.nameCity.split("-")[0] + ", INDIA" : DischargePort}`}
                            {/* <span style={{ color: "black" }}>{Unit}</span>
                            <span style={{ color: "black" }}>{Incoterm}</span>
                            <span style={{ color: "black" }}>{`${DischargePort} in `}</span>
                            <span style={{ color: "black" }}>{ShippingTerms}</span>
                            <span style={{ color: "black" }}>
                              {singleItem.packagingArr.map((a) => {
                                return `${a.sizeUnit} ,`;
                              })} */}
                            {/* </span> */}
                            <CssTextField95
                              // required={true}
                              autoComplete="off"
                              value={
                                this.state.itemVar[`${i + 1}`]
                                  ? this.state.itemVar[`${i + 1}`]
                                  : "" //
                              }
                              error={
                                errorMsg[this.state.itemVar[`${i + 1}`]]
                                  ? true
                                  : false
                              }
                              helperText={
                                errorMsg[this.state.itemVar[`${i + 1}`]]
                                  ? "Please add Varaible"
                                  : false
                              }
                              onChange={(e) => {
                                const errorMsg = this.state.errorMsg;
                                errorMsg[
                                  this.state.itemVar[`${i + 1}`]
                                ] = false;
                                this.setState({
                                  errorMsg,
                                  itemVar: {
                                    ...this.state.itemVar,
                                    [`${i + 1}`]: e.target.value,
                                  },
                                });
                              }}
                              style={
                                { margin: "0 5px" } //disabled={tp}
                              }
                              id="Variable"
                              label="Write Text"
                              variant="outlined"
                              InputLabelProps={{}}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {addendum ? (
                    ""
                  ) : (
                    <>
                      <div
                        style={{
                          border: "1px solid #80808042",
                          padding: "10px",
                          width: "100%",
                          marginBottom: "20px",
                          borderRadius: "5px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span style={{ color: "black" }}>Payment Terms : </span>

                        <CssTextField95
                          required={!requiredToggle ? true : false}
                          autoComplete="off"
                          value={
                            soPaymentTerm ? soPaymentTerm : "" //
                          }
                          id="soPaymentTerm"
                          error={errorMsg.soPaymentTerm ? true : false}
                          helperText={
                            errorMsg.soPaymentTerm ? errorMsg.soPaymentTerm : ""
                          }
                          onChange={(e) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.soPaymentTerm = false;
                            this.setState({
                              errorMsg,
                              soPaymentTermId: e.currentTarget.id,
                              soPaymentTerm: e.target.value,
                            });
                          }}
                          style={
                            { width: "calc(100% - 120px)" } //disabled={tp}
                          }
                          select
                          label="SO Payment Terms"
                          variant="outlined"
                          SelectProps={{ MenuProps: MenuProps}}
                        >
                          {masters["SoPaymentList"] &&
                            masters["SoPaymentList"].length > 0 &&
                            masters["SoPaymentList"].map((option) => (
                              <MenuItem
                                key={option.term.toString()}
                                value={option.term}
                                id={option.id}
                              >
                                {option.term}
                              </MenuItem>
                            ))}
                        </CssTextField95>
                      </div>
                    </>
                  )}
                    <div style={{ width: "100%" }}>
                    <div
                      style={{
                        border: "1px solid #80808042",
                        padding: "10px",
                        //width: "80%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        // alignItems: "center",
                        display: "flex",
                      }}
                    >   <CssTextField95
                              required={false}
                              autoComplete="off"
                              value={
                               duty_payment_term  
                                  ?duty_payment_term  
                                  : "" //
                              }
                              error={
                                errorMsg.duty_payment_term  
                                  ? true
                                  : false
                              }
                              helperText={
                                errorMsg.duty_payment_term  
                                  ? "Please add Varaible"
                                  : false
                              }
                              onChange={(e) => {
                                const errorMsg = this.state.errorMsg;
                                errorMsg.duty_payment_term   = false;
                                this.setState({
                                  errorMsg,
                                  duty_payment_term  : e.target.value
                                });
                              }}
                              style={
                                { margin: "0 5px",width:"100%" } //disabled={tp}
                              }
                              id="duty_payment_term"
                              label="Duty Payment Term"
                              variant="outlined"
                              InputLabelProps={{}}
                            /></div></div>
                  {Incoterm && ShippingTerms ? (
                    <>
                      <span id="policy">SO Policy :</span>
                      <ReactQuill
                        theme="snow"
                        id="outlined-basic"
                        autoComplete="new-password"
                        style={{
                          width: "100%",
                        }}
                        // id="policy"
                        label={"So Policy"}
                        value={soPolicy ? soPolicy.policy : ""}
                        onChange={(newContent) => {
                          this.setState({
                            soPolicy: soPolicy
                              ? { id: soPolicy.id, policy: newContent }
                              : { policy: newContent },
                          });
                        }}
                        variant="outlined"
                      />

                      {/* <JoditEditor
                      style={{
                        width: "100%",
                      }}
                      label={"So Policy"}
                      value={soPolicy ? soPolicy.policy : ""}
                      onChange={(newContent) => {
                        this.setState({
                          soPolicy: soPolicy ? { id: soPolicy.id, policy: newContent } : newContent,
                        });
                      }}
                      variant="outlined"
                    /> */}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {console.log(previewPdf, "previewPdf")}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}
            >
              {added ? (
                editDialog !== false ? (
                  <StyledButton
                    onClick={() => {
                      this.reset("no snac");
                      this.handleClearFields();
                    }}
                    name={"Add Another"}
                    withBg={true}
                    background={primary}
                    width="120px"
                    margin="0 10px 0 0"
                  />
                ) : (
                  ""
                )
              ) : (
                <>
                  <StyledButton
                    onClick={() => {
                      if (previewPdf) {
                        this.setState({ previewPdf: false });
                      } else {
                        this.reset();
                      }
                    }}
                    name={
                      previewPdf
                        ? "Back"
                        : editDialog
                        ? "Reset to default"
                        : "Reset"
                    }
                    margin="0 10px 0 0"
                  />
                  <StyledButton
                    onClick={() => {
                      if (
                        addendum ||
                        (selctedTYpeOfSO === "BUYER" && uploadedFile) ||
                        selctedTYpeOfSO === "SSIPL"
                      ) {
                        if (addendum || S0Number) {
                          if (Unit) {
                            if (Incoterm || requiredToggle) {
                              if (ShippingTerms || requiredToggle) {
                                if (addendum || Buyer) {
                                  // if (addendum || TotalContainer) {
                                    if (portArry.length > 0 || requiredToggle) {
                                      if (DischargePort || requiredToggle) {
                                        if (Currency) {
                                          if (addendum || Consignee || requiredToggle) {
                                            console.log(
                                              shipmentDate,
                                              "clicked 2"
                                            );
                                            if (shipmentDate) {
                                              if (ShipmentBy || requiredToggle) {
                                                console.log(
                                                  soItemsArray,
                                                  "clicked 2"
                                                );
                                                if (soItemsArray.length > 0) {
                                                  console.log("clicked 2");
                                                  if (
                                                    addendum ||
                                                    soPaymentTerm || requiredToggle
                                                  ) {
                                                    console.log("clicked 2");
                                                    if (
                                                      addendum ||
                                                      (soPolicy &&
                                                        // soPolicy.id &&
                                                        soPolicy.policy) || requiredToggle
                                                    ) {
                                                      this.setState({
                                                        loading: true,
                                                      });
                                                      const formData =
                                                        new FormData();
                                                      formData.append(
                                                        "so_file_upload",
                                                        selctedTYpeOfSO ===
                                                          "BUYER"
                                                          ? uploadedFile
                                                          : null
                                                      );
                                                      console.log(TotalContainer,"TotalContainer")
                                                      const addData = {
                                                        contract_date:
                                                          moment(
                                                            searchedData
                                                          ).format(
                                                            "YYYY-MM-DD"
                                                          ),
                                                        shipment_date:
                                                          moment(
                                                            shipmentDate
                                                          ).format(
                                                            "YYYY-MM-DD"
                                                          ),
                                                        buyer: Number(BuyerId),
                                                        total_quantity: TotalQ,
                                                        rate: TotalRate,
                                                        status: selctedSOStatus,
                                                        so_type:
                                                          selctedTYpeOfSO,
                                                        total_no_of_containers:
                                                          TotalContainer&&TotalContainer>0?TotalContainer:0,
                                                        shipment_by: ShipmentBy,
                                                        inco_term: IncotermId,
                                                        shipping_term:
                                                          ShippingTermsId,
                                                        so_policy: soPolicy.id
                                                          ? soPolicy.id
                                                          : "",
                                                        policy_of_so_policy:
                                                          soPolicy.policy,
                                                        currency: CurrencyId,
                                                        unit: Unit,
                                                        consignee: Consignee,
                                                        so_number: S0Number,
                                                        so_payment_term:
                                                          soPaymentTermId,
                                                        load_ports:
                                                          portArry.map((sP) => {
                                                            return sP.id ===
                                                              90000 ||
                                                              sP.id === "90000"
                                                              ? "Any Indian Port"
                                                              : sP.id;
                                                          }),
                                                        discharge_port:
                                                          DischargePort,
                                                        total_quantity:
                                                          soItemsArray.reduce(
                                                            (
                                                              previousValue,
                                                              currentValue
                                                            ) =>
                                                              Number(
                                                                previousValue
                                                              ) +
                                                              Number(
                                                                currentValue[
                                                                  "Total Item Quantity"
                                                                ]
                                                              ),
                                                            0
                                                          ),
                                                      };
                                                      console.log(
                                                        soItemsArray,
                                                        "soItemsArray",
                                                        editDialog
                                                      );
                                                      if (
                                                        editDialog === false
                                                      ) {
                                                        formData.append(
                                                          "is_active",
                                                          true
                                                        );
                                                        addData[
                                                          "is_active"
                                                        ] = true;
                                                      }
                                                      addData.duty_payment_term  =duty_payment_term  ??"";
                                                      if (
                                                        editDialog === false
                                                      ) {
                                                        addData[
                                                          "dispatched_quantity"
                                                        ] = 0;
                                                        addData[
                                                          "remaining_quantity"
                                                        ] = soItemsArray.reduce(
                                                          (
                                                            previousValue,
                                                            currentValue
                                                          ) =>
                                                            Number(
                                                              previousValue
                                                            ) +
                                                            Number(
                                                              currentValue[
                                                                "Total Item Quantity"
                                                              ]
                                                            ),
                                                          0
                                                        );
                                                      }
                                                      const addData_SO_ITEM =
                                                        [];
                                                      soItemsArray.map(
                                                        (ss, i) => {
                                                          const newData = {};
                                                          console.log("SS",ss, soItemsArray)
                                                          newData[
                                                            "item_category"
                                                          ] =
                                                            ss[
                                                              "Item Categoryid"
                                                            ] || "";
                                                          newData["item_type"] =
                                                            ss["Item Typeid"] || "";
                                                          newData["brand"] =
                                                            ss["Brandid"];
                                                          newData[
                                                            "contract_rate"
                                                          ] =
                                                            ss["Contract Rate"] || "";
                                                          newData[
                                                            "buyers_marking"
                                                          ] =
                                                            ss["Buyer Marking"] || "";
                                                          newData["extras"] =
                                                            this.state.itemVar[
                                                              `${i + 1}`
                                                            ]
                                                              ? this.state
                                                                  .itemVar[
                                                                  `${i + 1}`
                                                                ]
                                                              : "";
                                                          if (
                                                            ss.specificationArr &&
                                                            ss.specificationArr
                                                              .length > 0
                                                          ) {
                                                            const new_specifications =
                                                              [];
                                                            const old_specifications =
                                                              [];
                                                            ss.specificationArr.map(
                                                              (spec) => {
                                                                if (
                                                                  spec.created_at
                                                                ) {
                                                                  old_specifications.push(
                                                                    {
                                                                      name: Number(
                                                                        spec.nameId ??
                                                                          spec.name
                                                                      ),
                                                                      condition:
                                                                        spec.conditionId ===
                                                                        "no_condition"
                                                                          ? ""
                                                                          : spec.conditionId
                                                                          ? spec.conditionId ??
                                                                            ""
                                                                          : spec.condition ??
                                                                            "",
                                                                      value:
                                                                        spec.value,
                                                                      id: spec.id,
                                                                      so_items:
                                                                        spec.so_items,
                                                                    }
                                                                  );
                                                                } else {
                                                                  new_specifications.push(
                                                                    {
                                                                      name: Number(
                                                                        spec.nameId ??
                                                                          spec.name
                                                                      ),
                                                                      condition:
                                                                        spec.conditionId ===
                                                                        "no_condition"
                                                                          ? ""
                                                                          : spec.conditionId
                                                                          ? spec.conditionId ??
                                                                            ""
                                                                          : spec.condition ??
                                                                            "",
                                                                      value:
                                                                        spec.value,
                                                                      so_items:
                                                                        editDialog !==
                                                                        false
                                                                          ? ss.id
                                                                          : "",
                                                                    }
                                                                  );
                                                                }
                                                                return spec;
                                                              }
                                                            );

                                                            newData[
                                                              "new_so_specifications"
                                                            ] =
                                                              editDialog !==
                                                              false
                                                                ? new_specifications
                                                                : "";
                                                            newData[
                                                              "so_specifications"
                                                            ] =
                                                              old_specifications.length >
                                                              0
                                                                ? old_specifications
                                                                : editDialog !==
                                                                  false
                                                                ? []
                                                                : new_specifications;
                                                          }
                                                          newData.quantity_tolerance= ss.quantity_tolerance ??""
                                                          newData.bag_weight= ss.bag_weight ??""
                                                          newData["packaging"] =
                                                            ss.packaging &&
                                                            ss.packaging
                                                              .length > 0
                                                              ? ss.packaging.map(
                                                                  (sp) => {
                                                                    return Number(
                                                                      sp
                                                                    );
                                                                  }
                                                                )
                                                              : [];

                                                          newData["hsn_code"] =
                                                            ss["HSN Codeid"];
                                                          if (
                                                            editDialog !== false
                                                          ) {
                                                            newData[
                                                              "sales_order"
                                                            ] = editDialog.id;
                                                          } else {
                                                            newData[
                                                              "so_packaging"
                                                            ] =
                                                              ss.packaging &&
                                                              ss.packaging
                                                                .length > 0
                                                                ? ss.packaging.map(
                                                                    (sp) => {
                                                                      const newIb =
                                                                        {
                                                                          packaging:
                                                                            Number(
                                                                              sp
                                                                            ),
                                                                        };
                                                                      return newIb;
                                                                    }
                                                                  )
                                                                : [];
                                                          }

                                                          newData[
                                                            "total_quantity"
                                                          ] =
                                                            ss[
                                                              "Total Item Quantity"
                                                            ] || "";
                                                          if (
                                                            editDialog !== false
                                                          ) {
                                                            ss.id && (newData["id"] = ss.id)
                                                          }
                                                          if(Boolean(ss["Brandid"])){
                                                            addData_SO_ITEM.push(
                                                              newData
                                                            );
                                                          }
                                                          return ss;
                                                        }
                                                      );

                                                      const newSOAddObj = {};
                                                      newSOAddObj[
                                                        "sales_order"
                                                      ] = addData;
                                                      newSOAddObj["so_items"] =
                                                        previewPdf
                                                          ? addData_SO_ITEM
                                                          : soItemsArray;

                                                      if (previewPdf) {
                                                        this.props
                                                          .SalesOrdersFunction(
                                                            editDialog !== false
                                                              ? "patch"
                                                              : "post",

                                                            addendum
                                                              ? "dispatch/sales-order"
                                                              : editDialog !==
                                                                false
                                                              ? "dispatch/sales-order"
                                                              : "dispatch/sales-order/create",
                                                            editDialog !== false
                                                              ? editDialog.id
                                                              : null,
                                                            null,
                                                            editDialog !== false
                                                              ? addData
                                                              : newSOAddObj,
                                                            null,
                                                            "noeditparam"
                                                          )
                                                          .then((res) => {
                                                            if (res.error) {
                                                              this.setState({
                                                                loadingFun: false,
                                                                previewPdf: false,
                                                                loading: false,
                                                                error:
                                                                  typeof res.data ===
                                                                  "string"
                                                                    ? res.data
                                                                    : res.data
                                                                        .length >
                                                                      0
                                                                    ? res.data
                                                                        .map(
                                                                          (
                                                                            s
                                                                          ) => {
                                                                            return `${
                                                                              s.field
                                                                                ? `${s.field} :`
                                                                                : ""
                                                                            } ${
                                                                              typeof s.message ===
                                                                              "string"
                                                                                ? s.message
                                                                                : s
                                                                                    .message
                                                                                    .length >
                                                                                    0 &&
                                                                                  s
                                                                                    .message[0] &&
                                                                                  s
                                                                                    .message[0]
                                                                            }`;
                                                                          }
                                                                        )
                                                                        .join(
                                                                          " ,"
                                                                        )
                                                                    : res.data,
                                                              });
                                                            } else {
                                                              if (
                                                                editDialog !==
                                                                false
                                                              ) {
                                                                this.props
                                                                  .SalesOrdersFunction(
                                                                    addendum===true
                                                                      ? "patch"
                                                                      : editDialog !==
                                                                        false
                                                                      ? "put"
                                                                      : "post",
                                                                    addendum===true
                                                                      ? "dispatch/article-2/bulk-update"
                                                                      : editDialog !==
                                                                        false
                                                                      ? "dispatch/so-items/bulk-update"
                                                                      : "dispatch/so-items/list",
                                                                    null,
                                                                    null,
                                                                    addData_SO_ITEM
                                                                  )
                                                                  .then(
                                                                    (res2) => {
                                                                      if (
                                                                        res2.error
                                                                      ) {
                                                                        this.setState(
                                                                          {
                                                                            previewPdf: false,
                                                                            loading: false,
                                                                            error:
                                                                              typeof res2.data ===
                                                                              "string"
                                                                                ? res2.data
                                                                                : res2
                                                                                    .data
                                                                                    .length >
                                                                                  0
                                                                                ? res2.data
                                                                                    .map(
                                                                                      (
                                                                                        s
                                                                                      ) => {
                                                                                        return `${
                                                                                          s.field
                                                                                            ? `${s.field} :`
                                                                                            : ""
                                                                                        } ${
                                                                                          typeof s.message ===
                                                                                          "string"
                                                                                            ? s.message
                                                                                            : s
                                                                                                .message
                                                                                                .length >
                                                                                                0 &&
                                                                                              s
                                                                                                .message[0] &&
                                                                                              s
                                                                                                .message[0]
                                                                                        }`;
                                                                                      }
                                                                                    )
                                                                                    .join(
                                                                                      " ,"
                                                                                    )
                                                                                : res2.data,
                                                                            loadingFun: false,
                                                                            // editSnack: true,
                                                                          }
                                                                        );
                                                                      } else {
                                                                        fetchData(
                                                                          res,
                                                                          formData,
                                                                          "edit",
                                                                          editDialog.id
                                                                        );
                                                                      }
                                                                    }
                                                                  );
                                                              } else {
                                                                fetchData(
                                                                  res,
                                                                  formData,
                                                                  "",
                                                                  editDialog.id
                                                                );
                                                              }
                                                            }
                                                          });
                                                      } else {
                                                        this.setState({
                                                          previewPdf:
                                                            newSOAddObj,
                                                          loading: false,
                                                        });
                                                      }
                                                    } else {
                                                      const errorMsg =
                                                        this.state.errorMsg;
                                                      scrollDiv("policy");
                                                      errorMsg.policy =
                                                        "Please add policy";
                                                      this.setState({
                                                        errorMsg,
                                                        error:
                                                          "Please add SO Policy!",
                                                      });
                                                    }
                                                  } else {
                                                    const errorMsg =
                                                      this.state.errorMsg;
                                                    scrollDiv("soPaymentTerm");
                                                    errorMsg.soPaymentTerm =
                                                      "Please Select Terms!";
                                                    this.setState({
                                                      errorMsg,
                                                    });
                                                  }
                                                } else {
                                                  scrollDiv("update-button");
                                                  const errorMsg =
                                                    this.state.errorMsg;
                                                  errorMsg.ItemCategory =
                                                    "Please select  ItemCategory!";
                                                  errorMsg.ItemType =
                                                    "Please select  ItemType!";
                                                  errorMsg.BrandName =
                                                    "Please select  Brand!";
                                                  errorMsg.packagingArr =
                                                    "Please select  Packaging!";
                                                  errorMsg.HSN =
                                                    "Please select  HSN!";
                                                  this.setState({ errorMsg });
                                                  errorMsg.updatedButton =
                                                    "Please add";
                                                  this.setState({ errorMsg });
                                                }
                                              } else {
                                                const errorMsg =
                                                  this.state.errorMsg;
                                                scrollDiv("Consignee");
                                                errorMsg.Consignee =
                                                  "Please add Consignee!";
                                                this.setState({
                                                  errorMsg,
                                                });
                                              }
                                            } else {
                                              const errorMsg =
                                                this.state.errorMsg;
                                              errorMsg.shipmentDate =
                                                "Please give  Discgarge Port!";
                                              scrollDiv("shipmentDate");
                                              this.setState({
                                                errorMsg,
                                              });
                                            }
                                          } else {
                                            const errorMsg =
                                              this.state.errorMsg;
                                            errorMsg.ShipmentBy =
                                              "Please give  Shipment By!";
                                            scrollDiv("ShipmentBy");
                                            this.setState({
                                              errorMsg,
                                            });
                                          }
                                        } else {
                                          const errorMsg = this.state.errorMsg;
                                          scrollDiv("Currency");
                                          errorMsg.Currency =
                                            "Please select Currency!";
                                          this.setState({
                                            errorMsg,
                                          });
                                        }
                                      } else {
                                        const errorMsg = this.state.errorMsg;
                                        errorMsg.DischargePort =
                                          "Please give  Discgarge Port!";
                                        scrollDiv("DischargePort");
                                        this.setState({
                                          errorMsg,
                                        });
                                      }
                                    } else {
                                      const errorMsg = this.state.errorMsg;
                                      scrollDiv("portArry");
                                      errorMsg.portArry =
                                        "Please give  Load Port!";
                                      this.setState({
                                        errorMsg,
                                      });
                                    }
                                  // } else {
                                  //   const errorMsg = this.state.errorMsg;
                                  //   scrollDiv("TotalContainer");
                                  //   errorMsg.TotalContainer =
                                  //     "Please add total container!";
                                  //   this.setState({ errorMsg });
                                  // }
                                } else {
                                  const errorMsg = this.state.errorMsg;
                                  scrollDiv("Buyer");
                                  errorMsg.Buyer = "Please select a Buyer!";
                                  this.setState({ errorMsg });
                                }
                              } else {
                                const errorMsg = this.state.errorMsg;
                                scrollDiv("ShippingTerms");
                                errorMsg.ShippingTerms =
                                  "Please select a ShippingTerms!";
                                this.setState({ errorMsg });
                              }
                            } else {
                              const errorMsg = this.state.errorMsg;
                              scrollDiv("Incoterm");
                              errorMsg.Incoterm = "Please select a Incoterm!";
                              this.setState({ errorMsg });
                            }
                          } else {
                            const errorMsg = this.state.errorMsg;
                            scrollDiv("unit");
                            errorMsg.Unit = "Please select a Unit!";
                            this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          scrollDiv("S0Number");
                          errorMsg.S0Number = "Please Add a S0Number!";
                          this.setState({ errorMsg });
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        scrollDiv("uploadedFile");
                        errorMsg.uploadedFile = "Please upload a file !";
                        this.setState({ errorMsg });
                      }
                    }}
                    name={
                      previewPdf
                        ? editDialog === false
                          ? "Submit"
                          : "Update"
                        : "Proceed"
                    }
                    withBg={true}
                    background={"#1a9520"}
                    width="120px"
                  />
                </>
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {onlyDialog !== true && singlelistView && (
          <>
            

            <TabComponent
              // onChange={(newVal) => {
              //   const selctedTab =
              //     newVal === 0
              //       ? "unpaid"
              //       : newVal === 1
              //       ? "pending"
              //       : newVal === 2
              //       ? "paid":""

              //   this.setState({ selctedTab }, () => {})})
              noPadding={true}
              list={[
                {
                  name: "Custom Invoice",
                  component: (
                    <CustomInvoice
                      setSElectedCI={(selected) => {
                        this.setState({ selectedCI: selected });
                      }}
                      {...this.state}
                      {...this.props}
                      PortsFunction={this.props.SalesOrdersFunction}
                      noAddButton={true}
                    />
                  ),
                },
                {
                  name: "SO Details",
                  component: (
                    <>
                      <div
                        style={{ marginTop: "15px" }}
                        className="positionAB  width-100 d-flex justifySB alignC"
                      >
                        {singlelistView && singlelistView.so_type ? (
                          <div
                            className="textTU fontWeightBold"
                            style={{
                              margin: "0 10px 0 0",
                            }}
                          >{`SO Type : ${singlelistView.so_type}`}</div>
                        ) : (
                          ""
                        )}{" "}
                        <div className="d-flex">
                          {singlelistView.is_editable === true ? (
                            <CssButton
                              style={{
                                background: "rgb(241 154 48)",
                                color: "white",
                                marginRight: "10px",
                                textTransform: "capitalize",
                              }}
                              onClick={() => {
                                this.setState(
                                  {
                                    editDialog: singlelistView,
                                    loadingFun: true,
                                    addendum: false,
                                  },

                                  () => {
                                    this.setData();
                                  }
                                );
                              }}
                            >
                              Edit{" "}
                              <ModeOutlinedIcon
                                fontSize="small"
                                style={{ height: "17px", margin: "0 0 3px 0" }}
                              />
                            </CssButton>
                          ) : (
                            ""
                          )}
                            <SalesOrderPdf specifiationTypes={this.props.specifiationTypes}
                tableList={soItemsArrayTOPreview}
                header={[
                  "S.No",
                  "Description",
                  "Quantity",
                  "Price",
                  "Value",
                  "Specifications",
                ]}
                {...this.state}
                {...this.props}
                addendum={addendum}
                onClose={() => {
                  this.setState({
                    downloadPdf: false,
                  });
                }}
                widthList={["20px", "100px", "50px", "70px", "70px", "200px"]}
              />
                        </div>
                      </div>

                      <div
                        style={{
                          height: "calc(100% - 57.5px)",
                          width: "fit-content",
                          minWidth: "100%",
                          overflowX: "auto",
                          margin: "57.5px 0 0 0",
                        }}
                        className="scrollBluePrimary"
                      >
                        <div
                          style={{
                            backgroundColor: "#8080801a",
                            padding: "1em 10px",
                            borderRadius: "10px",
                            width: "100%",
                            minWidth: "100%",
                            pointerEvents: "none",
                          }}
                        >
                          {details.map((sDetail) => (
                            <div style={{ width: "100%", display: "flex" }}>
                              <div
                                style={{ width: "500px" }}
                                className="d-flex"
                              >
                                <div
                                  style={{ width: "151.1px" }}
                                  className="d-flex justifySB fontSize1"
                                >
                                  {sDetail.name} <div>:</div>
                                </div>
                                <div
                                  style={{
                                    width: "calc(100% - 171.1px)",
                                    fontSize: "1rem",
                                    margin: "0 0 0 20px",
                                    color: "#808080",
                                  }}
                                >
                                  {sDetail.value}
                                </div>
                              </div>
                              {sDetail.name1 && (
                                <div
                                  style={{ width: "clac(100% - 500px)" }}
                                  className="d-flex"
                                >
                                  <div
                                    style={{ width: "151.1px" }}
                                    className="d-flex justifySB fontSize1"
                                  >
                                    {sDetail.name1} <div>:</div>
                                  </div>
                                  <div
                                    style={{
                                      width: "calc(100% - 171.1px)",
                                      fontSize: "1rem",
                                      margin: "0 0 0 20px",
                                      color:
                                        sDetail.name1 === "Status"
                                          ? "#f39c12"
                                          : "#808080",
                                    }}
                                  >
                                    {sDetail.value1}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                        <div
                          style={{
                            margin: "10px 0 0 0",
                            width: "100%",
                            minWidth: "100%",
                          }}
                        >
                          {SOITemTable(
                            null,
                            soItemsArrayTOPreview,
                            null,
                            null,
                            "fit-content",
                            null,
                            null,
                            "fromSO",
                            null,
                            null,
                            true
                          )}
                        </div>

                        <div
                          style={{
                            backgroundColor: "#8080801a",
                            padding: "1em 10px",
                            borderRadius: "10px",
                            margin: "10px 0",
                            display: "flex",
                            width: "100%",
                            minWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              width: "120px",
                              margin: "0 10px 0 0",
                            }}
                          >
                            Delivery Terms :{" "}
                          </div>{" "}
                          <div style={{ width: "calc(100% - 130px)" }}>
                            {singlelistView.so_delivery_terms &&
                            singlelistView.so_delivery_terms.length > 0
                              ? singlelistView.so_delivery_terms[1] &&
                                singlelistView.so_delivery_terms[1].length > 0
                                ? singlelistView.so_delivery_terms[1].map(
                                    (singleItem, i) => (
                                      <div
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                          width: "100%",
                                        }}
                                      >
                                        {`${
                                          singlelistView.so_delivery_terms[0]
                                        } ${singleItem}/${
                                          singlelistView.so_delivery_terms[2]
                                        } ${
                                          singlelistView.so_delivery_terms[3]
                                        }, ${
                                          singlelistView.so_delivery_terms[3] === 'FOB'
                                          ? filterData(this.props.PortsList, "id", Number(singlelistView.load_ports[0]), "onlyOne").name + ", INDIA"
                                          : singlelistView.so_delivery_terms[4]
                                        } ${
                                          singlelistView.so_delivery_terms[7][i]
                                            ? ` ${singlelistView.so_delivery_terms[7][i]}.`
                                            : "."
                                        }`}
                                      </div>
                                    )
                                  )
                                : ""
                              : ""}
                          </div>
                        </div>

                        <div
                          style={{
                            backgroundColor: "#8080801a",
                            padding: "1em 10px",
                            borderRadius: "10px",
                            display: "flex",
                            width: "100%",
                            minWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              width: "120px",
                              margin: "0 10px 0 0",
                            }}
                          >
                            Payment Terms :{" "}
                          </div>

                          <div style={{ width: "calc(100% - 120px)" }}>
                            {singlelistView && singlelistView.so_payment_term
                              ? filterData(
                                  masters["SoPaymentList"],
                                  "id",
                                  Number(singlelistView.so_payment_term),
                                  "onlyOne"
                                )
                                ? filterData(
                                    masters["SoPaymentList"],
                                    "id",
                                    Number(singlelistView.so_payment_term),
                                    "onlyOne"
                                  ).term
                                : ""
                              : ""}
                          </div>
                        </div>     <div
                          style={{
                            backgroundColor: "#8080801a",
                            padding: "1em 10px",
                            borderRadius: "10px",
                            display: "flex",
                            width: "100%",
                            minWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              width: "120px",
                              margin: "0 10px 0 0",
                            }}
                          >
                            Duty Payment Term :{" "}
                          </div>

                          <div style={{ width: "calc(100% - 120px)" }}>
                            {singlelistView && singlelistView.duty_payment_term
                              ? singlelistView.duty_payment_term
                              : ""}
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#8080801a",
                            padding: "0 10px",
                            borderRadius: "10px",
                            display: "flex",
                            margin: "10px 0",
                            width: "100%",
                            minWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              width: "80px",
                              margin: "1em 10px 1em 0",
                            }}
                          >
                            So Policy :{" "}
                          </div>
                          <div style={{ width: "calc(100% - 90px)" }}>
                            {singlelistView &&
                            singlelistView.policy_of_so_policy
                              ? parse(singlelistView?.policy_of_so_policy)
                              : ""}
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  name: "SO Addendum",
                  component: (
                    <>
                      <div
                        style={{ marginTop: "15px" }}
                        className="positionAB  width-100 d-flex justifySB alignC"
                      >
                        {singlelistView && singlelistView.so_type ? (
                          <div
                            className="textTU fontWeightBold"
                            style={{
                              margin: "0 10px 0 0",
                            }}
                          >{`SO Type : ${singlelistView.so_type}`}</div>
                        ) : (
                          ""
                        )}
                        {console.log(
                          singlelistView,
                          singlelistView.is_editable,
                          "singlelistView"
                        )}
                        <div className="d-flex">
                          {singlelistView.is_editable === true ? (
                            <>
                              <CssButton
                                style={{
                                  background: "rgb(241 154 48)",
                                  color: "white",
                                  marginRight: "10px",
                                  textTransform: "capitalize",
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      editDialog: singlelistView,
                                      loadingFun: true,
                                      addendum: true,
                                    },

                                    () => {
                                      this.setData();
                                    }
                                  );
                                }}
                              >
                                Edit{" "}
                                <ModeOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    height: "17px",
                                    margin: "0 0 3px 0",
                                  }}
                                />
                              </CssButton>
                            </>
                          ) : (
                            ""
                          )}
                         <SalesOrderPdf specifiationTypes={this.props.specifiationTypes}
                tableList={soItemsArrayTOPreview}
                header={[
                  "S.No",
                  "Description",
                  "Quantity",
                  "Price",
                  "Value",
                  "Specifications",
                ]}
                {...this.state}
                {...this.props}
                addendum={addendum}
                onClose={() => {
                  this.setState({
                    downloadPdf: false,
                  });
                }}
                widthList={["20px", "100px", "50px", "70px", "70px", "200px"]}
              />
                        </div>
                      </div>

                      <div
                        style={{
                          height: "calc(100% - 57.5px)",
                          width: "fit-content",
                          minWidth: "100%",
                          overflowX: "auto",
                          margin: "57.5px 0 0 0",
                        }}
                        className="scrollBluePrimary"
                      >
                        <div
                          style={{
                            backgroundColor: "#8080801a",
                            padding: "1em 10px",
                            borderRadius: "10px",
                            width: "100%",
                            minWidth: "100%",
                            pointerEvents: "none",
                          }}
                        >
                          {detailsAddedum.map((sDetail) => (
                            <div style={{ width: "100%", display: "flex" }}>
                              <div
                                style={{ width: "500px" }}
                                className="d-flex"
                              >
                                <div
                                  style={{ width: "151.1px" }}
                                  className="d-flex justifySB fontSize1"
                                >
                                  {sDetail.name} <div>:</div>
                                </div>
                                <div
                                  style={{
                                    width: "calc(100% - 171.1px)",
                                    fontSize: "1rem",
                                    margin: "0 0 0 20px",
                                    color: "#808080",
                                  }}
                                >
                                  {sDetail.value}
                                </div>
                              </div>
                              {sDetail.name1 && (
                                <div
                                  style={{ width: "clac(100% - 500px)" }}
                                  className="d-flex"
                                >
                                  <div
                                    style={{ width: "151.1px" }}
                                    className="d-flex justifySB fontSize1"
                                  >
                                    {sDetail.name1} <div>:</div>
                                  </div>
                                  <div
                                    style={{
                                      width: "calc(100% - 171.1px)",
                                      fontSize: "1rem",
                                      margin: "0 0 0 20px",
                                      color:
                                        sDetail.name1 === "Status"
                                          ? "#f39c12"
                                          : "#808080",
                                    }}
                                  >
                                    {sDetail.value1}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                        <div
                          style={{
                            margin: "10px 0 0 0",
                            width: "100%",
                            minWidth: "100%",
                          }}
                        >
                          {SOITemTable(
                            null,
                            soItemsArrayTOPreview,
                            null,
                            null,
                            "fit-content",
                            null,
                            null,
                            "fromSO",
                            null,
                            null,
                            true
                          )}
                        </div>

                        <div
                          style={{
                            backgroundColor: "#8080801a",
                            padding: "1em 10px",
                            borderRadius: "10px",
                            margin: "10px 0",
                            display: "flex",
                            width: "100%",
                            minWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              width: "120px",
                              margin: "0 10px 0 0",
                            }}
                          >
                            Delivery Terms :{" "}
                          </div>{" "}
                          <div style={{ width: "calc(100% - 130px)" }}>
                            {singlelistView.so_delivery_terms &&
                            singlelistView.so_delivery_terms.length > 0
                              ? singlelistView.so_delivery_terms[1] &&
                                singlelistView.so_delivery_terms[1].length > 0
                                ? singlelistView.so_delivery_terms[1].map(
                                    (singleItem, i) => (
                                      <div
                                        style={{
                                          color: "black",
                                          textAlign: "left",
                                          width: "100%",
                                        }}
                                      >
                                        {`${
                                          singlelistView.so_delivery_terms[0]
                                        } ${singleItem}/${
                                          singlelistView.so_delivery_terms[2]
                                        } ${
                                          singlelistView.so_delivery_terms[3]
                                        }, ${
                                          singlelistView.so_delivery_terms[3] === 'FOB'
                                          ? filterData(this.props.PortsList, "id", Number(singlelistView.load_ports[0]), "onlyOne").name + ", INDIA"
                                          : singlelistView.so_delivery_terms[4]
                                        } ${
                                          singlelistView.so_delivery_terms[7][i]
                                            ? ` ${singlelistView.so_delivery_terms[7][i]}.`
                                            : "."
                                        }`}
                                      </div>
                                    )
                                  )
                                : ""
                              : ""}
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </>
        )}
      </div>
    );
  }
}

export default DetailsSo;
